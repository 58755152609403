import { Credits } from "./credits";

export interface WorkHistory extends Credits {
  _id: string;
  type: string;
  work_type: string;
  complete_time?: string;
  duration?: string;
}

export class WorkRequest {
  customer: string = "";
  workType: string = "";
  user: string = "";
}

export class WorkCountResponse {
  count: WorkCount = new WorkCount();
}

export class WorkCount {
  [key: string]: any;
  limitCount: string = "";
  usedCount: string = "";
  remainingCount: string = "";
  createDate: string = "";
  expirationDate: string = "";
}
