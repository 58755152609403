import {
  _ComponentSettings,
  Language,
  Text,
} from "../../../shared/settings/settings";
import { AlertsServiceType } from "./alerts/alerts.service.type";
import { ApplicationAreaServiceType } from "./application-area/application-area.service.type";
import { AttachmentServiceType } from "./attachment/attachment.service.type";
import { AttachmentsServiceType } from "./attachments/attachments.service.type";
import { AuthServiceType } from "./auth/auth.service.type";
import { ChangesServiceType } from "./changes/changes.service.type";
import { CommodityGroupResponsibleServiceType } from "./commodity-group-responsible/commodity-group-responsible.service.type";
import { CustomerName } from "../../../shared/config/customers";
import { CustomersServiceType } from "./customers/customers.service.type";
import { DetailTableServiceType } from "./part/detail-table/detail-table.service.type";
import { DinCodeResponsibleServiceType } from "./din-code-responsible/din-code-responsible.service.type";
import { DocModel } from "./state/state";
import { DocsServiceType } from "./docs/docs.service.type";
import { FieldIdProxy } from "../../../shared/proxies/field-id.proxy";
import { FieldServiceType } from "./field/field.service.type";
import { FileServiceType } from "./file/file.service.type";
import { FilterFieldsServiceType } from "./filter-fields/filter-fields.service.type";
import { FilterServiceType } from "./filter/filter.service.type";
import { FilterListServiceType } from "./filter-list/filter-list.service.type";
import { FilterTableServiceType } from "./filter-table/filter-table.service.type";
import { FilterTreeServiceType } from "./filter-tree/filter-tree.service.type";
import { FilterHeaderTableServiceType } from "./filter-header-table/filter-header-table.service.type";
import { GraphsServiceType } from "./graphs/graphs.service.type";
import { HeaderServiceType } from "./header/header.service.type";
import { HistoryServiceType } from "./history/history.service.type";
import { HomeServiceType } from "./home/home.service.type";
import { ImpactServiceType } from "./impact/impact.service.type";
import { ImpactsServiceType } from "./impacts/impacts.service.type";
import { ImportServiceType } from "./import/import.service.type";
import { ImportXmlServiceType } from "./import-xml/import-xml-service.type";
import { InjectionToken } from "@angular/core";
import { InternalItemsServiceType } from "./internal-items/internal-items.service.type";
import { LeftNavServiceType } from "./left-nav-component/left-nav.service.type";
import { ListIdProxy } from "../../../shared/proxies/list-id.proxy";
import { ListProxy } from "../../../shared/proxies/list.proxy";
import { MailServiceType } from "./mail/mail.service.type";
import { ManufacturerMatchingServiceType } from "./manufacturer-matching/manufacturer-matching.service.type";
import { ManufacturerNameServiceType } from "./manufacturer/manufacturer-name/manufacturer-name.service.type";
import { ManufacturerServiceType } from "./manufacturer/manufacturer.service.type";
import { CreateManufacturerServiceType } from "./create-manufacturer-modal/create-manufacturer-modal.service-type";
import { ManufacturersFilterTableServiceType } from "./RM/manufacturers-filter-table/manufacturers-filter-table.service.type";
import { ManufacturerUtilsServiceType } from "./manufacturer/manufacturer-utils.service.type";
import { MessageServiceType } from "./message/message.service.type";
import { ModelProxy } from "../../../shared/proxies/model.proxy";
import { OperationsServiceType } from "./operations/operations.service.type";
import { OverrideMpnFieldsServiceType } from "./override-mpnFields-modal/override-mpnFields-service.type";
import { PaginatorServiceType } from "./paginator/paginator.service.type";
import { PartServiceType } from "./part/part.service.type";
import { PartsFilterTableServiceType } from "./RM/parts-filter-table/parts-filter-table.service.type";
import { RMServiceType } from "./RM/RM.service.type";
import { PcnGeneratorServiceType } from "./pcn-generator/pcn-generator.service.type";
import { PcnServiceType } from "./pcn/pcn.service.type";
import { PcnsServiceType } from "./pcns/pcns.service.type";
import { PermissionProxy } from "../../../shared/proxies/permission.proxy";
import { PersonServiceType } from "./person/person.service.type";
import { PostServiceType } from "./post/post.service.type";
import { ProductCategoryServiceType } from "./product-category/product-category.service.type";
import { ProfileServiceType } from "./profile/profile.service.type";
import { ProxyServiceType } from "./core/proxy/proxy.service.type";
import { RMSearchServiceType } from "./rm-search/rm-search.service.type";
import { RMSubmenuServiceType } from "./rm-submenu/rm-submenu.service.type";
import { RoutingServiceType } from "./routing/routing.service.type";
import { ExternalDataFilterServiceType } from "./external-data-filter/external-data-filter.service.type";
import { Settings, Type } from "../../../shared/components";
import { SpinnerServiceType } from "./core/spinner/spinner.service.type";
import { StateServiceType } from "./state/state.service.type";
import { StepperServiceType } from "./stepper/stepper.service.type";
import { SVHCItemsServiceType } from "./SVHC-items/SVHC-items.service.type";
import { SyncServiceType } from "./sync/sync.service.type";
import { TableServiceType } from "./table/table.service.type";
import { TaskCompletedServiceType } from "./task-completed-note/task-completed.service.type";
import { TasksServiceType } from "./tasks/tasks.service.type";
import { TextProxy } from "../../../shared/proxies/text.proxy";
import { ThreadServiceType } from "./thread/thread.service.type";
import { TrainServiceType } from "./train/train.service.type";
import { TreeCellServiceType } from "./tree-cell/tree-cell.service.type";
import { TreeRowServiceType } from "./tree-row/tree-row.service.type";
import { TreeServiceType } from "./tree/tree.service.type";
import { UrlProxy } from "../../../shared/proxies/url.proxy";
import { UserSettingsServiceType } from "./user-settings/user-settings.service.type";
import { UsersServiceType } from "./users/users.service.type";
import { VehicleResponsibleServiceType } from "./vehicle-responsible/vehicle-responsible.service.type";
import { View } from "../../../shared/models/view";
import { PanelServiceType } from "./panel/panel.service.type";
import { TypeAheadBasicServiceType } from "./field/type-ahead/typeahead-basic.service.type";
import { OCHomeServiceType } from "./obsolescence-community/OC-home/OC-home.service.type";
import { OCPostServiceType } from "./obsolescence-community/OC-post/OC-post.service.type";
import { OCCommentServiceType } from "./obsolescence-community/OC-comment/OC-comment.service.type";
import { OCPostItemServiceType } from "./obsolescence-community/OC-post-item/OC-post-item.service.type";
import { AdditionalInfoProxy } from "../../../shared/proxies/additional-info.proxy";
import { ReleaseNotesModalServiceType } from "./release-notes/release-notes-modal.service.type";
import { FooterServiceType } from "./footer/footer.service.type";

export const APP_TYPE = new InjectionToken<AppType>("APP_TYPE");

export interface AppType {
  numberOfUnreadAlerts: number;
  numberOfUnreadChangeAlerts: number;
  numberOfUnreadLeadTimeAlerts: number;
  numberOfUnreadPcns: number;
  numberOfUnreadInvMonAlerts: number;
  numberOfUnreadEnvComplianceAlerts: number;
  successText: string;
  hasSuccess: boolean;
  errorText: string;
  errorLoginName: boolean;
  errorLoginPassword: boolean;
  loginError: boolean;
  error: boolean;
  hasError: boolean;
  readonly busy: boolean;
  unlockedId: string | null;

  language: Language;
  readonly user: string | null;
  readonly expectUser: string;
  readonly customer: CustomerName;

  readonly view: View;

  element: Type | null;

  types: Type[];
  type: Type | any;
  expectType: Type;

  id: string | null;
  readonly expectId: string;
  newId: string | null;
  readonly currentId: string | null;

  model: DocModel;
  debugJson: boolean;

  readonly logo: string;
  readonly langLogo: string;

  // Components
  readonly auth: AuthServiceType;
  readonly routing: RoutingServiceType;
  readonly thread: ThreadServiceType;
  readonly pcn: PcnServiceType;
  readonly post: PostServiceType;
  readonly file: FileServiceType;
  readonly graphs: GraphsServiceType;
  readonly filterList: FilterListServiceType;
  readonly filter: FilterServiceType;
  readonly import: ImportServiceType;
  readonly importXml: ImportXmlServiceType;
  readonly docs: DocsServiceType;
  readonly home: HomeServiceType;
  readonly impact: ImpactServiceType;
  readonly impacts: ImpactsServiceType;
  readonly vehicleResponsible: VehicleResponsibleServiceType;
  readonly dinCodeResponsible: DinCodeResponsibleServiceType;
  readonly commodityGroupResponsible: CommodityGroupResponsibleServiceType;
  readonly part: PartServiceType;
  readonly RM: RMServiceType;
  readonly RMSearch: RMSearchServiceType;
  readonly operations: OperationsServiceType;
  readonly manufacturer: ManufacturerServiceType;
  readonly manufacturerMatching: ManufacturerMatchingServiceType;
  readonly pcnGenerator: PcnGeneratorServiceType;
  readonly externalDataFilter: ExternalDataFilterServiceType;
  readonly field: FieldServiceType;
  readonly users: UsersServiceType;
  readonly userSettings: UserSettingsServiceType;
  readonly profile: ProfileServiceType;
  readonly customers: CustomersServiceType;
  readonly table: TableServiceType;
  readonly mail: MailServiceType;
  readonly person: PersonServiceType;
  readonly tree: TreeServiceType;
  readonly treeCell: TreeCellServiceType;
  readonly treeRow: TreeRowServiceType;
  readonly leftNav: LeftNavServiceType;
  readonly message: MessageServiceType;
  readonly alerts: AlertsServiceType;
  readonly paginator: PaginatorServiceType;
  readonly manufacturersFilter: ManufacturersFilterTableServiceType;
  readonly partsTable: PartsFilterTableServiceType;
  readonly RMSubmenu: RMSubmenuServiceType;
  readonly train: TrainServiceType;
  readonly filterTable: FilterTableServiceType;
  readonly filterHeaderTable: FilterHeaderTableServiceType;
  readonly state: StateServiceType;
  readonly proxy: ProxyServiceType;
  readonly spinner: SpinnerServiceType;
  readonly tasks: TasksServiceType;
  readonly filterTree: FilterTreeServiceType;
  readonly filterFields: FilterFieldsServiceType;
  readonly sync: SyncServiceType;
  readonly stepper: StepperServiceType;
  readonly pcns: PcnsServiceType;
  readonly internalItems: InternalItemsServiceType;
  readonly applicationArea: ApplicationAreaServiceType;
  readonly taskCompleted: TaskCompletedServiceType;
  readonly attachment: AttachmentServiceType;
  readonly attachments: AttachmentsServiceType;
  readonly header: HeaderServiceType;
  readonly history: HistoryServiceType;
  readonly overrideMpnFields: OverrideMpnFieldsServiceType;
  readonly manufacturerUtils: ManufacturerUtilsServiceType;
  readonly SVHCItems: SVHCItemsServiceType;
  readonly change: ChangesServiceType;
  readonly detailTable: DetailTableServiceType;
  readonly productCategory: ProductCategoryServiceType;
  readonly manufacturerName: ManufacturerNameServiceType;
  readonly createManufacturer: CreateManufacturerServiceType;
  readonly panel: PanelServiceType;
  readonly typeAheadBasic: TypeAheadBasicServiceType;
  readonly OCHome: OCHomeServiceType;
  readonly OCPost: OCPostServiceType;
  readonly OCComment: OCCommentServiceType;
  readonly OCPostItem: OCPostItemServiceType;
  readonly releaseNotesModal: ReleaseNotesModalServiceType;
  readonly footer: FooterServiceType;

  // Settings
  readonly settings: Settings;
  readonly elements: Type[];
  readonly languages: Language[];
  readonly text: TextProxy;
  readonly permission: PermissionProxy;
  readonly fieldId: FieldIdProxy;
  readonly list: ListProxy;
  readonly listId: ListIdProxy;
  readonly url: UrlProxy;
  readonly additionalInfo: AdditionalInfoProxy;

  isLocked(id?: string | null): boolean;
  createId(): string;
  isSelected(id: string): boolean;
  expect(id: string | null | undefined): string;
  clearModel(): void;
  createModel(id: string | null, type: Type): DocModel;

  importXlsx(files: FileList): Promise<void>;
  importJson(files: FileList): Promise<void>;
  exportXlsx(): void;

  getSettings(type: keyof _ComponentSettings, element?: Type | null): string[];
  getText(id?: string | null): string;
  getTypeText(type: string): string;
  getUrl(id: string): string;
  getList(id: string): string[];

  lock(): void;
  unlock(): void;
  run<T>(f: () => Promise<T>): Promise<T>;
  getModelProxy(model: DocModel): ModelProxy;
  getTranslatedText(text: Text, language?: Language): string;
}
