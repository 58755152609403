import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  applicationArea: {
    type: FieldTypes.value,
    text: {
      de: "Application area",
      en: "Application area",
    },
  },
};

const settings = {
  permission: {},
  text: {
    applicationArea: {
      de: "Application area",
      en: "Application area",
    },
    newOption: {
      de: "Neuen Einsatzbereich hinzufügen",
      en: "Add a new  application area",
    },
    cancel: { de: "Abbrechen", en: "Cancel" },
    save: { de: "Speichern", en: "Save" },
  },
  url: {},
  field,
  list: {},
  additionalInfo: {},
};

export const applicationArea: ComponentSettings<typeof settings> = settings;
