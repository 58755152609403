import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {
    seeAttachments: true,
  },
  text: {
    files: {
      de: "Anhänge",
      en: "Attachments",
      nl: "Bijlagen",
    },
    upload: {
      de: "Erlaubte Dateitypen: $.\nVerbotene Sonderzeichen: #",
      en: "Allowed file types:\n$.\nForbidden special characters:\n#",
      nl: "Toegestane bestandstypen: $.\nVerboden speciale tekens: #.",
    },
    delete: {
      de: "Anhang entfernen",
      en: "Remove attachment",
      nl: "Verwijder bijlage",
    },
    addTag: {
      de: "Etikett / Tag hinzufügen",
      en: "Add Tag",
      nl: "Voeg tag toe",
    },
    download: {
      de: "Download Anhang",
      en: "Download attachment",
      nl: "Download bijlage",
    },
    openLink: {
      de: "Anhang in einem neuen Tab öffnen",
      en: "Open attachment in a new tab",
      nl: "Open bijlage in een nieuw tabblad",
    },
    deleteQuestion: {
      de: "Möchten Sie diesen Anhang entfernen?",
      en: "Would you like to remove this attachment?",
      nl: "Wil je deze bijlage verwijderen?",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    caseAttachments: {
      de: "Anhänge, die zu diesem Fall hinzugefügt wurden",
      en: "Attachments added to this case",
      nl: "Bijlagen toegevoegd aan dit Issue",
    },
    allAttachments: {
      de: "Alle zu diesem Fall hinzugefügten Anhänge",
      en: "All attachments added to this case",
      nl: "Alle bijlagen die aan deze zaak zijn toegevoegd",
    },
    commentsAttachments: {
      de: "Kommentare Anhänge",
      en: "Comments attachments",
      nl: "Commentaar bijlagen",
    },
    solutionsAttachments: {
      de: "Lösung Anhänge",
      en: "Solutions attachments",
      nl: "Oplossings bijlagen",
    },
    safetyEvaluationTemplates: {
      de: "Herunterladbare Vorlagen, die ausgefüllt werden müssen: ",
      en: "Downloadable Templates that need to be filled: ",
      nl: "Downloadbare sjablonen die gevuld moeten worden: ",
    },
    noTag: {
      de: "Ohne Tags",
      en: "No tags",
      nl: "Zonder Tags",
    },
    allTags: {
      de: "Alle Tags",
      en: "All tags",
      nl: "Alle Tags",
    },
    sensitive: {
      de: "Sensibel markierte",
      en: "Marked  sensitive",
      nl: "Gevoelig gemarkeerde",
    },
    all: {
      de: "Alle Anhänge",
      en: "All attachments",
      nl: "Alle bijlagen",
    },
    lastAdded: {
      de: "Zuletzt hinzugefügte",
      en: "Last added",
      nl: "Laatst toegevoegde",
    },
    showMode: {
      de: "Anzeigen:",
      en: "Showing:",
      nl: "Tonen:",
    },
    ascending: {
      de: "Aufsteigend",
      en: "Ascending",
      nl: "Oplopend",
    },
    descending: {
      de: "Absteigend",
      en: "Descending",
      nl: "Aflopend",
    },
    uploadTime: {
      de: "Upload-Zeit",
      en: "Upload Time",
      nl: "Uploadtijd",
    },
    tagText: {
      de: "Nur die Anhänge anzeigen, die den ausgewählten Tag enthalten",
      en: "Display only the attachments that contain the selected tag",
      nl: "Toon alleen de bijlagen die de geselecteerde tag bevatten.",
    },
    markAsSensitive: {
      de: "Als sensibel markiert",
      en: "Marked as sensitive",
      nl: "Gemarkeerd als gevoelig",
    },
    unmarkAsSensitive: {
      de: "Anhang als sensibel markieren",
      en: "Mark attachment as sensitive",
      nl: "Markeer bijlage als gevoelig",
    },
    unmarkQuestion: {
      de: "Sind Sie sicher, dass Sie die Markierung dieses Anhangs aufheben möchten?",
      en: "Are you sure you want to unmark this attachment?",
      nl: "Weet u zeker dat u deze bijlage wilt demarkeren?",
    },
    OMWarning: {
      de: "Informationen zu Zeichnungen (ZB oder Schemata) dürfen in den entsprechenden Aufgaben ausschließlich und nur über Links zu dem jeweiligen Windchill-Objekt angegeben und eingetragen werden. Es ist nicht zulässig, diese als Anhänge hinzuzufügen (in jeglicher Form, z. B. als PDF oder TIF usw.).",
      en: "Information to drawings (ZB or schemata) may only be included and added in the corresponding tasks exclusively and only via links to the respective Windchill object. It is not allowed to add them as attachments (in any form e.g. as PDF or TIF etc.)",
      nl: "Informatie met betrekking tot tekeningen (ZB of schema's) mag uitsluitend en alleen via links naar het overeenkomstige Windchill-object in de bijbehorende taken worden opgenomen en toegevoegd. Het is niet toegestaan deze als bijlagen toe te voegen (in welke vorm dan ook, zoals PDF of TIF, enz.).",
    },
  },
  list: {},
  url: {},
  field: {},
  additionalInfo: {},
};

export const attachments: ComponentSettings<typeof settings> = settings;
