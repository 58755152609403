<app-modal
  #deleteAlertsModal
  [modalMessage]="app.text.alert.deleteMessage"
  [modalTitle]="app.text.alert.modalTitle"
  (ok)="(true)"
>
</app-modal>

<!-- Quick filters -->
<div class="row">
  <div class="col-md-12">
    <button
      class="btn btn-sm btn-task my-2"
      [ngClass]="{ 'btn-default-orange': app.tasks.tasksTab === myOpenTasks }"
      (click)="app.tasks.setTasksTab(myOpenTasks)"
    >
      <i class="fas fa-tasks"></i>
      {{ app.text.post.myTasks }}
    </button>
    <button
      class="btn btn-sm btn-task my-2"
      [ngClass]="{
        'btn-default-orange': app.tasks.tasksTab === createdOpenTasks
      }"
      (click)="app.tasks.setTasksTab(createdOpenTasks)"
    >
      <i class="fas fa-list-ul"></i>
      {{ app.text.post.createdTasks }}
    </button>
    <button
      *ngIf="app.permission.post.seeAllTasksTab"
      class="btn btn-sm btn-task my-2"
      [ngClass]="{
        'btn-default-orange': app.tasks.tasksTab === allOpenTasks
      }"
      (click)="app.tasks.setTasksTab(allOpenTasks)"
    >
      <i class="fas fa-list-ul"></i>
      {{ app.text.post.allTasks }}
    </button>
    <div class="dropdown quickFilters pull-right my-2">
      <button
        *ngIf="app.permission.post.canExportTasks"
        class="btn btn-sm btn-clean pull-right"
        (click)="app.tasks.exportData()"
      >
        <i class="fa fa-download"></i>&nbsp;
        {{ app.text.post.exportTasks }}
      </button>
      <button
        class="btn dropdown-toggle"
        type="button"
        id="quickFilters"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="icon-center me-1">
          <i class="fas fa-eye menu-icons"></i>
        </div>
        <span>&nbsp;{{ app.text.home.showOnly }}&nbsp;</span>
        <span class="text-danger">
          {{
            app.filterFields.getQuickFilterText(app.tasks.quickFilterSelected)
          }}
        </span>
      </button>
      <div
        id="quickFiltersDropdown"
        class="dropdown-menu"
        aria-labelledby="quickFiltersDropdown"
        role="menu"
      >
        <a
          class="dropdown-item pointer"
          *ngFor="let item of app.tasks.quickFilters"
          (click)="
            app.tasks.addQuickFilter(item); app.tasks.quickFilterSelected = item
          "
          >{{ app.text.post[item] }}</a
        >
      </div>
    </div>
  </div>
</div>

<app-paginator
  [length]="app.tasks.tasks.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>

<div class="tableFixHead">
  <table class="table table-striped spacing-table">
    <thead>
      <tr>
        <th
          class="p-1"
          [ngSwitch]="column"
          *ngFor="let column of app.tasks.taskColumns"
        >
          <div
            (click)="
              app.table.toggleSortDirection('post.' + column);
              app.table.sort(app.tasks.tasks)
            "
          >
            <span *ngSwitchCase="'taskDueDate'">
              {{ app.text.post.taskDueDate }}
            </span>
            <span *ngSwitchCase="'user_id'">
              {{ app.text.post.creator }}
            </span>
            <span *ngSwitchDefault>
              {{ app.field.getLabel("post." + column) }}
            </span>
            <span *ngIf="'post.' + column === app.table.sortField">
              <i
                *ngIf="app.table.sortDirection === 1"
                class="fa fa-chevron-up"
              ></i>
              <i
                *ngIf="app.table.sortDirection === -1"
                class="fa fa-chevron-down"
              ></i>
            </span>
          </div>
          <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-table
              [column]="column"
              [columns]="app.tasks.taskColumns"
              [docType]="'post'"
              [cleanDocs]="app.tasks.cleanTasks"
              [selectedFilters]="app.tasks.selectedFilters"
              (resultedDocs)="app.filterTable.getFilterResults($event, 'task')"
              [resetComponent]="app.tasks.resetFilterInputsObservable"
            >
            </app-filter-table>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let task of app.paginator.getPage(app.tasks.tasks)"
        [ngClass]="{ isSelected: app.isSelected(task) }"
        class="pointer"
        (click)="
          app.routing.openInNewTab(null, 'thread.omfNumber', task.omfNumber)
        "
      >
        <td
          [ngSwitch]="app.table.getColumnTypeDisplay(column)"
          *ngFor="let column of app.tasks.taskColumns"
          class="py-1 px-2"
        >
          <div *ngSwitchCase="'navigateAction'">
            {{ task[column] }}
          </div>
          <div *ngSwitchCase="'limit'" class="d-flex">
            <div [ngClass]="{ 'show-less': showShortDesciption }">
              {{ task[column] }}
            </div>
            <button
              *ngIf="task[column].length > 400"
              class="btn"
              type="button"
              (click)="alterDescriptionText(); $event.stopPropagation()"
            >
              <i
                *ngIf="showShortDesciption"
                class="fas fa-chevron-down icon-style"
              ></i>
              <i
                *ngIf="!showShortDesciption"
                class="fas fa-chevron-up icon-style"
              ></i>
            </button>
          </div>
          <div
            *ngSwitchCase="'dueDate'"
            [ngStyle]="
              app.tasks.getTaskDueDateColor(task[column], task.taskCompleted)
            "
          >
            {{ task[column] }}
          </div>
          <div *ngSwitchCase="'optionText'">
            <span *ngIf="!task[column]">
              {{
                app.field.getOptionText(app.fieldId.post.taskCompleted, false)
              }}</span
            >
            <span *ngIf="task[column]">
              {{
                app.field.getOptionText(
                  app.fieldId.post.taskCompleted,
                  task.taskCompleted
                )
              }}
            </span>
          </div>
          <div *ngSwitchCase="'normal'">
            <div *ngIf="column === 'finalSolution'">
              {{
                app.field.getOptionText(
                  app.fieldId.post.resolveClass,
                  task[column]
                )
              }}
            </div>
            <div *ngIf="column !== 'finalSolution'">
              {{ task[column] }}
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngIf="app.tasks.loading" colspan="99" class="text-center">
          {{ app.text.core.loading }}
        </td>
        <td
          *ngIf="!app.tasks.loading && app.tasks.tasks.length === 0"
          colspan="99"
          class="text-center"
        >
          {{ app.text.app.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-paginator
  [length]="app.tasks.tasks.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
