import { ComponentSettings, empty } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    modalTitle: {
      de: "LNB Mengenüberwachung",
      en: "Quantity monitoring",
    },
    close: { de: "Schließen", en: "Close", nl: "Sluit" },
    noCPN: {
      de: "Im Fall ist keine Kundenteilenummer (KTN) angegeben",
      en: "You must provide a CPN on the case",
    },
    noData: {
      de: "Keine Daten für diese Kundenteilenummer (KTN)",
      en: "No Customer Part Number (CPN) is provided in the case",
    },
  },
  list: {
    steps: empty,
  },
  url: {},
  field: {},
  additionalInfo: {},
};

export const stock: ComponentSettings<typeof settings> = settings;
