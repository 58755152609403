import { Component, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { doc2Model } from "../api.service";
import { Task } from "../../../../shared/models/task";
import { Subscription } from "rxjs";
import { Post } from "../../../../shared/models/post";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-panel",
  templateUrl: "./panel.component.html",
  styleUrls: ["./panel.component.scss"],
})
export class PanelComponent implements OnInit {
  @ViewChild("taskCompletedModal", { static: false }) taskCompletedModal: any;
  @ViewChild("discardChangesModal", { static: false }) discardChangesModal: any;
  threadStatus = "";
  columns: string[] = [];

  private _panelSubscription: Subscription = new Subscription();
  private _discardModalSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  ngOnInit() {
    this.columns = this.app.list.post.taskFieldsList;
    const descriptionIndex = this.columns.indexOf(
      this.app.fieldId.post.taskDescription
    );
    this.columns.splice(descriptionIndex, 1);

    this._panelSubscription = this.app.panel.triggerPanel.subscribe(
      async (value) => {
        if (value) {
          this.app.panel.cleanItem = Object.assign(
            {},
            this.app.panel.selectedItem
          );
          this.app.panel.selectedItemModel = doc2Model(
            "post",
            this.app.panel.selectedItem
          );
          this.threadStatus = this.getThreadStatus(this.app.panel.selectedItem);
          this.app.panel.creditsSubject.next(this.app.panel.selectedItem);
          this.app.panel.hasEditPermission(this.app.panel.selectedItem);

          if (this.app.customers.expectCurrent === Customer.KNDS) {
            this.app.taskCompleted.checkOpenTasks(
              this.app.panel.selectedItem as any
            );
          }
        }
      }
    );

    this._discardModalSubscription = this.app.panel.openDiscardModal.subscribe(
      async (value) => {
        if (value && this.discardChangesModal) {
          this.discardChangesModal.open();
        }
      }
    );
  }

  getThreadStatus(post: Task): string {
    if (post == null) {
      return "";
    }
    const snapshot = post.snapshot;
    if (
      snapshot == null ||
      snapshot.thread == null ||
      snapshot.thread.omfStatus == null
    ) {
      return "";
    }
    return this.app.field.getOptionText(
      this.app.fieldId.thread.omfStatus,
      snapshot.thread.omfStatus
    );
  }

  async discardChanges() {
    // Discard changes for the current selected item
    const cleanItemModel = doc2Model("post", this.app.panel.cleanItem);

    this.app.panel.selectedItem.taskDescription =
      this.app.panel.cleanItem.taskDescription;
    this.app.panel.selectedItemModel = cleanItemModel;
    this.app.panel.closePanel();

    // Reroute to a user profile if logged in user decided to discard changes while navigate on a profile
    if (this.app.panel.userProfile !== "") {
      await this.app.routing.navigate(
        this.app.customers.expectCurrent,
        "profile",
        this.app.panel.userProfile
      );
    }
  }

  showCompleteButton() {
    if (
      this.app.taskCompleted.showCompleteTaskIcon(
        this.app.panel.selectedItem as Post
      ) &&
      this.app.panel.checkIfFieldsAreValid()
    ) {
      return true;
    }
    return false;
  }

  showReopenButton() {
    if (
      this.app.taskCompleted.showReopenTaskIcon(
        this.app.panel.selectedItem as Post
      ) &&
      this.app.panel.checkIfFieldsAreValid()
    ) {
      return true;
    }
    return false;
  }

  async deleteTask() {
    const post = this.app.panel.selectedItem as Post;
    await this.app.post.delete(post);
    this.app.panel.closePanel();
  }

  hasPermissionToAddAttachment() {
    if (this.app.permission.post.addAttachment) {
      return true;
    }

    // User who created the post(comm/sol/task) or task responsible should be able to upload files
    if (
      this.app.panel.selectedItem.user_id === this.app.user ||
      this.app.panel.selectedItem.taskResponsible === this.app.user
    ) {
      return true;
    }

    return false;
  }
  ngOnDestroy() {
    this._panelSubscription.unsubscribe();
    this._discardModalSubscription.unsubscribe();
    this.app.panel.userProfile = "";
  }
}
