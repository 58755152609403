import { Component, OnInit } from "@angular/core";
import { getApp } from "../../app/app";
import { Customer } from "../../../../shared/types/customers";
import { Change } from "../../../../shared/models/change";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Component({
  selector: "app-changes",
  templateUrl: "./changes.component.html",
  styleUrls: ["./changes.component.scss"],
})
export class ChangesComponent implements OnInit {
  columns: string[] = [];
  selectedFilters: { columnName: string; value: string }[] = [];
  loading = true;

  app = getApp((app) => {
    this.app = app;
  });
  columnsShort: string[] = [];

  constructor() {}

  public async ngOnInit(): Promise<void> {
    // reset changes array when switching between tabs to avoid displaying some old data until the actual changes are loading
    this.app.change.changes = [];
    this.app.table.clearSelection();
    this.getListOfColumns();
    await this.app.change.getAllChanges();
    this.loading = false;
    this.app.table.clearSelection();
  }

  getListOfColumns() {
    this.columns = this.app.list.change.columns;
    this.columnsShort = this.columns.map((field) => field.split(".")[1]);
    this.columns.unshift("select");
    this.columnsShort.unshift("select");
  }

  async markItems() {
    this.app.spinner.showSpinner();
    await this.app.mail.markItemsAsSeen("seen");
    await this.app.change.getAllChanges();
    this.app.filterTable.resetAllFilters.next(true);
    this.app.mail.updateUnreadCount.next(true);
    this.app.spinner.hideSpinner();
  }

  async deleteChangesDocs() {
    await this.app.mail.deleteNotifications();
    await this.app.change.getAllChanges();
    this.app.filterTable.resetAllFilters.next(true);
    this.app.mail.updateUnreadCount.next(true);
  }

  changeNavigationByCustomer(customer: string, changeDoc: Change) {
    switch (customer) {
      case Customer.OC:
        if (!StringUtils.isNullOrEmpty(changeDoc.docId)) {
          // let url = `/${customer}/oc-home/${changeDoc.docId}`;
          // return this.app.routing.openInNewTab(url);
          this.app.change.postId = changeDoc.docId;
          this.app.change.fromAlert = true;
          this.app.routing.navigateOCPost(changeDoc.docId, true);
          return;
        } else {
          return this.app.routing.navigateOCHome();
        }
      default:
        return this.app.routing.openInNewTab(
          null,
          "change.omfNumber",
          changeDoc.omfNumber
        );
    }
  }
}
