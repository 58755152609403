import {
  ComponentSettings,
  FieldTypes,
  FilterTypes,
  empty,
} from "../settings/settings";
import { creditsFields } from "./credits.settings";

const field = {
  _id: {
    type: FieldTypes.text,
    text: {
      de: "Database ID",
      en: "Database ID",
    },
    search: false,
  },
  _rev: {
    type: FieldTypes.text,
    text: {
      de: "Database Revision Number",
      en: "Database Revision Number",
    },
    search: false,
  },
  omfNumber: {
    type: FieldTypes.value,
    text: {
      de: "Fall-Nummer",
      en: "Case Number",
      nl: "Issue nummer",
    },
    search: false,
  },
  ...creditsFields,
  omfVehicleName: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeugname",
      en: "Vehicle-Name",
      nl: "Voertuignaam",
    },
    required: true,
    mutable: true,
  },
  omfVehicleFleet: {
    type: FieldTypes.value,
    text: {
      de: "Flotte",
      en: "Fleet",
      nl: "Vloot",
    },
    mutable: true,
  },
  omfVehicleClass: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeugklasse",
      en: "Vehicle-Type",
      nl: "Voertuigklasse",
    },
    mutable: true,
  },
  vehicleGroup: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeugfamilie",
      en: "Vehicle group",
      nl: "Voertuigfamilie",
    },
    show: false,
    persist: false,
  },
  artNumber: {
    type: FieldTypes.value,
    text: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    persist: false,
    filter: FilterTypes.startsWith,
  },
  omfCommodityRespName: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Name",
      en: "Responsible Name",
      nl: "Verantwoordelijke naam",
    },
    mutable: true,
  },
  omfVehicleRespDep: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Abteilung",
      en: "Responsible Department",
      nl: "Verantwoordelijke afdeling",
    },
    mutable: true,
  },
  omfVehicleRespEmail: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Email",
      en: "Responsible Email",
      nl: "Verantwoordelijke e-mail",
    },
    mutable: true,
  },
  omfVehicleCnt: {
    type: FieldTypes.number,
    text: {
      de: "Anzahl der betroffenen Teile",
      en: "Number of Components",
      nl: "Aantal betrokken Items",
    },
  },
  productGroup: {
    text: {
      de: "Produktgruppe",
      en: "Product group",
      nl: "Productgroep",
    },
    type: FieldTypes.options,
    options: {},
    show: false,
    persist: false,
  },
  level: {
    type: FieldTypes.options,
    text: {
      de: "Level",
      en: "Level",
      nl: "Niveau",
    },
    options: {
      part: { text: { de: "Bauteil", en: "Part", nl: "Onderdeel" } },
      assembly: { text: { de: "Baugruppe", en: "Assembly", nl: "Assemblage" } },
      product: { text: { de: "Produkt", en: "Product", nl: "Product" } },
    },
  },
  impacts: {
    type: FieldTypes.data,
    text: { de: "Auswirkungen", en: "Impacts", nl: "Impacts" },
    search: false,
  },
  actions: {
    type: FieldTypes.text,
    text: {
      de: "Aktionen",
      en: "Actions",
      nl: "Acties",
    },
    search: false,
  },
  project: {
    type: FieldTypes.value,
    text: {
      de: "Projekt",
      en: "Project",
      nl: "Project",
    },
    search: false,
  },
  projectNumber: {
    type: FieldTypes.options,
    text: {
      de: "Projekt Nr.",
      en: "Project Nr.",
      nl: "Project Nr.",
    },
    options: {
      PUMA: {
        text: {
          de: "SPz PUMA",
          en: "SPz PUMA",
          nl: "SPz PUMA",
        },
      },
      BOXER: {
        text: {
          de: "Boxer",
          en: "Boxer",
          nl: "Boxer",
        },
      },
      DINGO: {
        text: {
          de: "Dingo2L",
          en: "Dingo2L",
          nl: "Dingo2L",
        },
      },
      LEO: {
        text: {
          de: "KPz Leopard 2",
          en: "KPz Leopard 2",
          nl: "KPz Leopard 2",
        },
      },
    },
    search: false,
  },
  projectResponsible: {
    type: FieldTypes.value,
    text: {
      de: "Projekt Responsible",
      en: "Project Responsible",
      nl: "Project Responsible",
    },
  },
  verwa: {
    type: FieldTypes.options,
    text: {
      de: "VERWA",
      en: "VERWA",
      nl: "VERWA",
    },
    options: {
      1: { text: { de: "1", en: "1" } },
      2: { text: { de: "2", en: "2" } },
      3: { text: { de: "3", en: "3" } },
      4: { text: { de: "4", en: "4" } },
      5: { text: { de: "5", en: "5" } },
      6: { text: { de: "6", en: "6" } },
      7: { text: { de: "7", en: "7" } },
      T: { text: { de: "T", en: "T" } },
      8: { text: { de: "8", en: "8" } },
      9: { text: { de: "9", en: "9" } },
      D: { text: { de: "D", en: "D" } },
    },
    search: false,
  },
};

const settings = {
  permission: {
    edit: false,
    editOwn: false,
    canAddImpactToCase: false,
    canImportImpacts: false,
    canDeleteImpacts: false,
  },
  text: {
    impact: { de: "Auswirkung", en: "Impact", nl: "Impact" },
    impacts: { de: "Auswirkungen", en: "Impacts", nl: "Impacts" },
    add: { de: "Hinzufügen", en: "Add", nl: "Voeg toe" },
    fields: { de: "Auswirkung", en: "Impact", nl: "Impact" },
    editImpact: {
      de: "Auswirkung bearbeiten",
      en: "Edit impact",
    },
    save: {
      de: "Änderungen übernehmen",
      en: "Save Changes",
      nl: "Wijzigingen opslaan",
    },
    impactNameExist: {
      de: "Die Auswirkung existiert bereits.",
      en: "The impact already exists.",
      nl: "Er bestaat al een voertuig met dezelfde naam.",
    },
    savedSuccesfully: {
      de: "Die Auswirkung wurde erfolgreich gespeichert.",
      en: "The impact saved successfully.",
      nl: "Voertuig succesvol opgeslagen.",
    },
    deleteImpact: {
      de: "Auswirkung löschen",
      en: "Delete Impact",
      nl: "Impact verwijderen",
    },
    deleteMessage: {
      de: "Wollen Sie dieses Fahrzeug wirklich entfernen?",
      en: "Do you really want to remove this vehicle?",
      nl: "Wil je dit voertuig echt weghalen?",
    },
    deleteAllImpacts: {
      de: "Möchten Sie alle Fahrzeuge aus dem Fall löschen?",
      en: "Do you want to delete all vehicles from the case?",
      nl: "Wilt u alle voertuigen uit de koffer verwijderen?",
    },
    deleteImpacts: {
      de: "Alle Wirkungen löschen",
      en: "Delete all impacts",
      nl: "Alle effecten verwijdere",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard Changes",
      nl: "Wijzigingen negeren",
    },
    close: {
      de: "Detailansicht schließen",
      en: "Close Detail View",
      nl: "Detailweergave sluiten",
    },
    sumOfUsages: {
      de: "Summe der Verwendungen:",
      en: "Sum of Usages:",
      nl: "Som van de Gebruiken:",
    },
    freqUse: {
      de: "Summe der betroffenen Artikel:",
      en: "Sum of affected items: ",
      nl: "Som van de betrokken items: ",
    },
    messageInvalidForm: {
      de: "Die folgenden Felder sind ungültig:",
      en: "The following Fields are Invalid:",
      nl: "De volgende velden zijn ongeldig:",
    },
    greenBullet: {
      de: "Rückmeldung erfolgt",
      en: "Rückmeldung erfolgt",
    },
    redBullet: {
      de: "Rückmeldung ausstehend",
      en: "Rückmeldung ausstehend",
    },
    grayBullet: {
      de: "Rückmeldung nicht erforderlich",
      en: "Rückmeldung nicht erforderlich",
    },
    responsible: {
      de: "Verantwortlich",
      en: "Responsible",
    },
    wfResponsible: {
      de: "Verantwortlich für diesen Schritt",
      en: "Responsible for this step",
      nl: "Verantwoordelijk voor deze stap",
    },
    deputy: {
      de: "Vertreter",
      en: "Deputy",
    },
    sign: {
      de: "Sign",
      en: "Sign",
    },
    uploadMultiple: {
      de: "Liste von BA/BR hochladen",
      en: "Upload multiple",
      nl: "Meerdere uploaden",
    },
    delete: {
      de: "Sind Sie sicher, dass Sie dieses Fahrzeug löschen möchten?",
      en: "Are you sure you want to delete this vehicle?",
      nl: "Weet u zeker dat u dit voertuig wilt verwijderen?",
    },
    import: {
      de: "Importieren",
      en: "Import",
      nl: "Importeren",
    },
    projectNumber: {
      de: "Projekt Nr.",
      en: "Project Nr.",
      nl: "Project Nr.",
    },
  },
  url: {},
  field,
  list: {
    columns: empty,
    RMClientColumns: empty,
    columns90: empty,
    fields: empty,
    pdfColumns: empty,
    boxesLeft: empty,
    boxesRight: empty,
    fieldsOnFilter: empty,
  },
  additionalInfo: {},
};

export const impact: ComponentSettings<typeof settings> = settings;
