import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    imprint: { de: "Impressum", en: "Imprint", nl: "Uitgave" },
    privacy: { de: "Datenschutz", en: "Privacy", nl: "Privacy" },
    contact: { de: "Kontakt", en: "Contact", nl: "Contact" },
    terms: {
      de: "AGB/Nutzungsbedingungen",
      en: "Terms and Conditions",
      nl: "Voorwaarden en bepalingen",
    },
    termsOC: {
      de: "Obsolescence Community Terms of Use",
      en: "Obsolescence Community Terms of Use",
      nl: "Obsolescence Community Terms of Use",
    },
    imprintUrl: {
      de: "http://www.am-sys.com/impressum",
      en: "http://www.am-sys.com/en/imprint",
      nl: "http://www.am-sys.com/en/imprint",
    },
    privacyUrl: {
      de: "http://www.am-sys.com/privacy-policy",
      en: "http://www.am-sys.com/en/privacy",
      nl: "http://www.am-sys.com/en/privacy",
    },
    contactUrl: {
      de: "https://www.am-sys.com/kontakt/",
      en: "https://www.am-sys.com/en/contact/",
      nl: "https://www.am-sys.com/en/contact/",
    },
    facebookUrl: {
      de: "https://www.facebook.com/AMSYSGmbH/",
      en: "https://www.facebook.com/AMSYSGmbH/",
      nl: "https://www.facebook.com/AMSYSGmbH/",
    },
    twitterUrl: {
      de: "https://twitter.com/obsolescencecom",
      en: "https://twitter.com/obsolescencecom",
      nl: "https://twitter.com/obsolescencecom",
    },
    linkedinUrl: {
      de: "https://www.linkedin.com/company/amsys-gmbh---applicable-management-systems",
      en: "https://www.linkedin.com/company/amsys-gmbh---applicable-management-systems",
      nl: "https://www.linkedin.com/company/amsys-gmbh---applicable-management-systems",
    },
    xingUrl: {
      de: "https://www.xing.com/pages/amsysgmbh-applicablemanagementsystems",
      en: "https://www.xing.com/pages/amsysgmbh-applicablemanagementsystems",
      nl: "https://www.xing.com/pages/amsysgmbh-applicablemanagementsystems",
    },
    contactInfo: {
      de: "+49 (0) 89 9974 080 00\ninfo@am-sys.com",
      en: "+49 (0) 89 9974 080 00\ninfo@am-sys.com",
      nl: "+49 (0) 89 9974 080 00\ninfo@am-sys.com",
    },
    address: {
      de: "ABC-Straße 19 | 22880 Wedel-Hamburg | Germany",
      en: "ABC-Strasse 19 | 22880 Wedel-Hamburg | Germany",
      nl: "ABC-Strasse 19 | 22880 Wedel-Hamburg | Germany",
    },
    companyName: {
      de: "AMSYS GmbH",
      en: "AMSYS GmbH",
      nl: "AMSYS GmbH",
    },
    contactPcn: {
      de: "Talk to us",
      en: "Talk to us",
      nl: "Talk to us",
    },
    poweredBy: {
      de: "Powered & designed by:",
      en: "Powered & designed by:",
      nl: "Powered & designed by:",
    },
  },
  list: {},
  url: {
    terms:
      "https://www.obsolescence-management.net/wp-content/uploads/Nutzungsbedingungen_Portal.pdf",
    termsOC: "https://www.obsolescence.com/terms/",
  },
  field: {},
  additionalInfo: {},
};

export const footer: ComponentSettings<typeof settings> = settings;
