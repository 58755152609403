import { ComponentSettings, FieldTypes } from "../settings/settings";
import { getPublicFieldsMultiselect } from "./profile.settings";

const field = {
  "Firma/Mandant": {
    type: FieldTypes.value,
    text: { de: "Firma", en: "Company", nl: "Bedrijf" },
  },
  Adresse: {
    type: FieldTypes.value,
    text: { de: "Adresse", en: "Address", nl: "Adres" },
  },
  "PLZ Ort": {
    type: FieldTypes.value,
    text: { de: "Postleitzahl", en: "Postcode", nl: "Postcode" },
  },
  City: {
    type: FieldTypes.value,
    text: { de: "Stadt", en: "City", nl: "Stad" },
  },
  Land: {
    type: FieldTypes.value,
    text: { de: "Land", en: "Country", nl: "Land" },
  },
  "Telefon/Zentrale": {
    type: FieldTypes.value,
    text: {
      de: "Telefon/Zentrale",
      en: "Telephone/Main Office",
      nl: "Telefoon",
    },
  },
  Fax: { type: FieldTypes.value, text: { de: "Fax", en: "Fax", nl: "Fax" } },
  Email: {
    type: FieldTypes.value,
    text: { de: "Email", en: "Email", nl: "e-mail" },
  },
  Internetadresse: {
    type: FieldTypes.value,
    text: { de: "Internetadresse", en: "Web-Address", nl: "Internet adres" },
  },
};

export const settings = {
  permission: {},
  list: {
    organizationProfile: Object.keys(field).map(
      (field) => "organizationProfile." + field
    ),
  },
  url: {},
  text: {
    organizationProfile: {
      de: "Organisation",
      en: "Organization",
      nl: "Organisatie",
    },
  },
  field: {
    ...field,
    publicFields: getPublicFieldsMultiselect(field),
  },
  additionalInfo: {},
};

export const organizationProfile: ComponentSettings<typeof settings> = settings;
