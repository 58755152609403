import {
  Component,
  Input,
  ViewChild,
  Output,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { getApp } from "../app";
import { Subject } from "rxjs";
import { Customer } from "../../../../shared/types/customers";
import { downloadFile, uploadFile } from "../api.service";
import { saveAs } from "file-saver-es";
import { RED } from "../impact/wf-automation/wf-automation.component";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

const MODAL = "modal";
const EXTERNAL_FILE = "externalFile";
const FILE = "file";
const SPECIAL_CHARACTER = "specialCharacter";
const RESPONSIBLE = "responsible";
const UPLOAD = "upload";
const ATTACHMENT = "attachment";
const NORMAL = "normal";

@Component({
  selector: "app-field",
  templateUrl: "./field.component.html",
  styleUrls: ["./field.component.scss"],
})
export class FieldComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() disabled = false;
  @Input() field = "";
  @Input() model: any = {};
  @Input() index = 0;
  @Input() maxLength = 128;
  @Input() rows = 4;
  @Input() showMandatory = false;
  @Input() showSearchButton = true;
  /** the input bellow is used for displaying the filter's input in a right way */
  @Input() differentDisplay = false;
  @Input() forceEnabled = false;
  /** this input does not return true in any component */
  // @Input() labelAbove = false;
  /** this input has been replaced with other inputs for posts */
  // @Input() threadComments = false;
  /** this input is no longer used */
  // @Input() impactInput = false;
  @Output() selected: Subject<void> = new Subject();
  @ViewChild("searchField", { static: false }) searchField: any;
  @ViewChild("d", { static: false }) d: any;
  @ViewChild("fileInput", { static: false }) fileInput: any;
  @Input() publicFieldName: string | null = null;
  @Input() typeaheadPlaceholder = "Search...";

  /** the input bellow is used for displaying the fields according to the new design */
  @Input() showLabel = true;
  @Input() labelInside = false;
  @Input() orangeField = false;
  @Input() itemsInputValue = false;
  @Input() largeInput = false;
  @Input() noStriped = false;
  @Input() taskInput = false;
  @Input() taskLabel = false;
  @Input() isFinalSolutin = false;
  @Input() shadowInput = false;
  @Input() lightOddField = false;
  @Input() panelInput = false;
  modal = MODAL;
  externalFile = EXTERNAL_FILE;
  file = FILE;
  specialCharacter = SPECIAL_CHARACTER;
  responsible = RESPONSIBLE;
  upload = UPLOAD;
  attachment = ATTACHMENT;
  normal = NORMAL;
  search = "";
  searchFocus = false;
  nonSearchableDropdowns = false;
  isShortened = true;
  red = RED;

  constructor(private _cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.app.field.inputSubject.subscribe((value) => {
      if (value) {
        this.searchFocus = false;
      }
    });
    this.getNonSearchableDropdowns();
    // Used to prevent an ExpressionChangedAfterItHasBeenCheckedError when using the duplicate case feature
    this._cdRef.detectChanges();

    if (this.app.type === "part" && this.app.RM.mode === "tree") {
      this.typeaheadPlaceholder = this.app.text.RM.filterByAssembly;
    }
  }

  getNonSearchableDropdowns() {
    if (this.field == null) {
      return;
    }
    if (
      (this.field.split(".")[0] === "responsibles" ||
        this.field === this.app.fieldId.thread.dinCodeRespName ||
        this.field === this.app.fieldId.thread.componentResponsible ||
        this.field === this.app.fieldId.thread.deputy) &&
      this.app.customers.expectCurrent === Customer.DB
    ) {
      this.nonSearchableDropdowns = true;
    }
  }

  showMore() {
    this.isShortened = !this.isShortened;
  }
  /** Function to display the value saved in the databse for fields with type option
   * if the value coming from different db doc(ex: dropdowns with users) was deleted
   * but still saved on the current doc
   */
  displayValue(field: string, model: any) {
    if (
      !this.app.field.getFieldSettings(field).multiple &&
      !this.app.field.getOptions(field).includes(model) &&
      field != this.app.fieldId.user.roles
      /** check if still works properly !!!! */
    ) {
      return true;
    }
    return false;
  }

  click(field: string) {
    const { model } = this;
    const type = this.app.field.getType(field);

    // hasFinalSolution is set as true only for NS
    if (
      type === "checkbox" &&
      (this.app.unlockedId !== null || field === "post.acceptedSolution") &&
      !this.app.post.hasFinalSolution
    ) {
      model[field] = !model[field];
    }
  }

  startSearch() {
    this.searchFocus = true;
    if (!this.app.field.isMultiple(this.field)) {
      setTimeout(() => {
        this.searchField.nativeElement.focus();
      }, 100);
      this.app.field.isMultipleSelected = false;
    } else {
      this.app.field.isMultipleSelected = true;
    }
  }

  blur() {
    setTimeout(() => {
      this.searchFocus = false;
    }, 1000);
  }

  setMultipleFocus(field: any) {
    this.app.field.isMultipleSelected = false;
    if (!this.app.field.isMultiple(field)) {
      this.app.field.inputSubject.next(true);
    }
    // used for fields of type value, which display a dropdown
    if (
      field === "impact.omfVehicleName" ||
      field === "train.trainName" ||
      field === "manufacturer.partNumber" ||
      field === "thread.dinCodeRespName"
    ) {
      this.searchFocus = true;
    }
  }

  isMultipleField(field: string) {
    if (
      this.app.field.isMultiple(field) &&
      !this.app.field.isMultipleSelected
    ) {
      return true;
    } else {
      return false;
    }
  }

  isDisabled() {
    const { app, field, forceEnabled } = this;
    if (this.model == null) {
      return this.disabled;
    }
    const mutable = app.field.isMutable(field);

    const currentValue = this.model[field];
    const isSet = currentValue != null && currentValue !== "";

    // forceEnabled is used on fields that are dispalyed by filtration mode
    if (forceEnabled) {
      return false;
    }
    // edit omf number
    if (
      field === "thread.omfNumber" &&
      app.permission.thread.chooseCaseNumber &&
      app.thread.isNew
    ) {
      return false;
    }
    /** added excception since this check was setting the value to true all the time
     * if the conditions were respected
     */
    if (this.app.state.createFromPart && ((!mutable && isSet) || !mutable)) {
      return true;
    }

    /** forced the manufacturer.partNumber to false, because we need to be able to set the partNumber from the list of cpns */
    // if (!this.app.state.createFromPart && field === "manufacturer.partNumber") {
    //   this.disabled = false;
    //   return this.disabled;
    // }
    return this.disabled;
  }

  hasPermissionToEdit() {
    if (
      !this.app.permission.thread.chooseSourceCase &&
      this.app.leftNav.selectedBox === "thread.main"
    ) {
      return true;
    }
    return false;
  }

  calculateMaxLength(field: string) {
    if (this.app.field.getFieldSettings(field).maxLength) {
      return this.app.field.getFieldSettings(field).maxLength;
    } else {
      return this.maxLength;
    }
  }

  getTextrareaRows(field: string) {
    if (this.app.field.getFieldSettings(field).rows) {
      return this.app.field.getFieldSettings(field).rows;
    } else {
      return this.rows;
    }
  }

  isEnabled() {
    return !this.isDisabled();
  }

  onFieldClicked(event: any) {
    if (this.isDisabled()) {
      return;
    }
    switch (this.app.field.getType(this.field)) {
      case "options": {
        this.startSearch();
        break;
      }
      case "date": {
        // TODO: open datepicker (unless it was closed with the current event)
        // console.log(this.d);
        // this.d.toggle();
        return;
      }
      case "checkbox": {
        return;
      }
      default: {
        // NOP
      }
    }
    event.stopPropagation();
  }

  onDateSelected(value: string) {
    this.selected.next();
  }

  setJson(json: string) {
    try {
      const value = JSON.parse(json);
      this.model[this.field] = value;
      this.onOptionSelected();
    } catch (err) {
      // NOP
    }
  }

  clearModel(field: string, option: string) {
    if (!this.app.field.isMultiple(field)) {
      this.model[field] = "";
    }
    return;
  }
  isValidDate(dateString: string) {
    this.model[this.field] = dateString;
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (dateString == null) {
      return;
    }
    if (!this.model[this.field].match(regEx)) {
      this.model[this.field] = "";
      return false; // Invalid format
    }
    return true;
  }

  onOptionSelected() {
    this.selected.next();
    this.searchField.value = "";
    if (!this.app.field.isMultiple(this.field)) {
      this.searchFocus = false;
    } else {
      this.app.field.isMultipleSelected = true;
    }
  }
  onItemSelected(option: string, field: string) {
    this.model[field] = option;
    this.app.filterList.add();
  }

  createClass() {
    if (this.app.view === "home") {
      return (
        this.field.replace(".", "_") +
        "_" +
        this.model[this.app.fieldId.thread.omfNumber]
      );
    } else {
      if (!this.app.state.showHistory) {
        return this.field.replace(".", "_");
      }
    }
    let classInput = "";
    /** The following class is added for for all default input (exlude finalSolution field from post-item)  */

    if (this.isFinalSolutin) {
      classInput = "col-md-4 finalSol";
    }
  }

  // togglePublicFieldName() {
  //   if (this.isDisabled()) {
  //     return;
  //   }
  //   const { model, field, publicFieldName } = this;
  //   if (publicFieldName == null) {
  //     return;
  //   }
  //   const [, shortName] = field.split(".");
  //   const publicFields = new Set(model[publicFieldName]);
  //   if (publicFields.has(shortName)) {
  //     publicFields.delete(shortName);
  //   } else {
  //     publicFields.add(shortName);
  //   }
  //   model[publicFieldName] = Array.from(publicFields).sort();
  // }

  // isPublic() {
  //   const { model, field, publicFieldName } = this;
  //   const [, shortName] = field.split(".");
  //   if (publicFieldName == null) {
  //     return false;
  //   }
  //   const publicFields = new Set(model[publicFieldName]);
  //   return publicFields.has(shortName);
  // }

  getTreeStyle(fieldId: string) {
    const [type, element] = fieldId.split(".");
    switch (element) {
      case "obsolescenceStatus":
      case "obsolescenceStatus2years":
      case "obsolescenceStatus4years":
      case "obsolescenceStatus6years":
      case "obsolescenceStatus8years":
      case "totalRisk":
      case "impact":
      case "likelihood":
      case "euRoHS":
      case "likelihood_yteol":
      case "likelihood_yteosr":
      case "conflictMineralStatus":
      case "reachAffected":
      case "leadTime":
      case "catComplexityImpact":
      case "freqUseImpact":
      case "likelihood_secSource":
      case "lifecycleCode":
      case "chinaRoHSStatus":
      case "tscaStatus":
      case "pfasStatus":
      case "californiaStatus":
        if (this.app.unlockedId === null) {
          return true;
        }
        break;
      default:
        return false;
    }
  }

  getFieldType(fieldId: string, value: string) {
    switch (fieldId) {
      case "manufacturer.loadReplacements":
      case "manufacturer.svhcItems":
      case "manufacturer.pcnHistory":
        return MODAL;
      case "manufacturer.ipc":
        if (
          this.app.manufacturer.currentManufacturer[
            this.app.fieldId.manufacturer.seId
          ]
        ) {
          return FILE;
        }
        break;
      case "manufacturer.eolLink":
      case "manufacturer.conformityCertificate":
      case "manufacturer.materialDeclaration":
      case "manufacturer.conflictMineralStatement":
      case "manufacturer.datasheet":
      case "manufacturer.chinaRoHSDeclaration":
      case "manufacturer.tscaDeclaration":
      case "manufacturer.pfasDeclaration":
      case "manufacturer.californiaDeclaration":
        const docType = fieldId.split(".")[0];
        const isLink = this.app.field.isLink(value);
        const isManualOverride =
          docType === "manufacturer"
            ? this.app.manufacturer.currentManufacturer[
                this.app.fieldId.manufacturer.obsolescenceStatusOverride
              ]
            : false;

        if (isLink && !isManualOverride) {
          return EXTERNAL_FILE;
        } else if (isManualOverride) {
          return this.getFieldTypeByDocStatus(docType, fieldId);
        }
        break;
      case "thread.artNumber":
        if (this.app.customers.current === Customer.BVG) {
          return SPECIAL_CHARACTER;
        }
        return NORMAL;
      case "thread.dinCodeRespName":
      case "thread.componentResponsible":
      case "thread.deputy":
        if (this.app.customers.current === Customer.DB) {
          return RESPONSIBLE;
        }
        return NORMAL;
      default:
        return NORMAL;
    }
  }

  async downloadFile(field: string) {
    if (field === this.app.fieldId.manufacturer.ipc) {
      await this.app.manufacturer.getIPC();
    }
  }

  getFieldTypeByDocStatus(docType: string, fieldId: string) {
    let fieldType = UPLOAD;

    if (docType === "manufacturer") {
      // If docType is manufacturer the field could contain a link form the SE
      const isLink = this.app.field.isLink(
        this.app.manufacturer.currentManufacturer[fieldId]
      );

      if (isLink) {
        fieldType = ATTACHMENT;
      }
    }

    const currentFileName = this.app.attachment.getDocNameByFieldId(fieldId);
    if (currentFileName != null && currentFileName !== "") {
      fieldType = ATTACHMENT;
    }
    return fieldType;
  }

  navigateToCase(threadId: string) {
    return `/${this.app.customers.expectCurrent}/thread/${threadId}`;
  }

  showUnderline() {
    if (
      this.isEnabled() &&
      !this.labelInside &&
      !this.shadowInput &&
      !this.panelInput
    ) {
      return true;
    }
    return false;
  }

  isMandatory() {
    if (
      this.showMandatory ||
      (!this.disabled &&
        this.app.field.isRequired(this.field) &&
        !this.app.field.isValid(this.field, this.model[this.field]) &&
        !this.differentDisplay)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getInputStyle() {
    const defaultClasses = "lineHeight custom-field";
    let classInput = "";
    /** The following class is added for for all default input (exlude finalSolution field from post-item)  */
    if (this.showLabel) {
      classInput = "col-md-6";
    }
    /** The following class is added for inputs that must have full width (eg. [field]="app.fieldId.thread.descr") */
    if (this.largeInput) {
      classInput = "col-md-12";
    }
    /** The following class is added for inputs with label inside */
    if (this.labelInside) {
      classInput = ["labelInside col-md-11", classInput].join(" ");
    }
    /** The following class has been added for inputs with label inside and orange design (eg. vehicles/impacts) */
    if (this.orangeField) {
      classInput = ["no-borders input-style", classInput].join(" ");
    }
    /** The following class has been added for the design of the input values used in item-message and item-post */
    if (this.itemsInputValue) {
      classInput = ["col-md-12 px-4", classInput].join(" ");
    }
    if (this.isFinalSolutin) {
      classInput = "col-md-1";
    }
    if (this.taskInput) {
      classInput = ["col-md-8", classInput].join(" ");
    }
    /** The following class has been added for the design of the inputs from add new user page and profile page */
    if (this.shadowInput) {
      classInput = ["col-md-7 no-padding shadowInput m-1 ", classInput].join(
        " "
      );
    }
    if (this.panelInput) {
      classInput = ["col-md-8 no-padding panel-field m-1 ", classInput].join(
        " "
      );
    }
    return `${defaultClasses} ${classInput}`;
  }

  getLabelStyle() {
    const defaultClasses = "fieldLabel";
    let classLabel = "";
    /** The following class is added for for all default labels  */
    if (!this.isFinalSolutin) {
      classLabel = "col-md-6 ps-4";
    }
    if (this.isFinalSolutin) {
      classLabel = "col-md-11";
    }
    if (this.taskLabel) {
      classLabel = "col-md-3 font-italic";
    }
    /** The following class has been added for the design of the label from add new user page and profile page */
    if (this.shadowInput) {
      classLabel = "col-md-4 m-1";
    }
    if (this.panelInput) {
      classLabel = "col-md-3 m-1 small-label";
    }

    return `${defaultClasses} ${classLabel}`;
  }
  async uploadDocOnField() {
    if (this.app.field.current == null) {
      return;
    }

    const customer = this.app.customers.expectCurrent;
    const docType = this.app.field.current.split(".")[0];
    const fieldId = this.app.field.current.split(".")[1];
    const file: File = this.fileInput.nativeElement.files[0];
    const fileName = file.name;
    const docId = this.app.docs.getIdByDocType(docType);
    const attachmentLink = [
      customer,
      docType,
      EncodingUtils.encodeBase64(docId),
      fileName,
      fieldId,
    ].join("/");

    if (this.app.file.isFileTooLarge(file)) {
      this.app.state.hasError = true;
      this.app.state.errorText = this.app.text.app.fileTooLargeError;
      return;
    }

    if (this.app.file.fileFormatAccepted(file)) {
      this.app.state.hasError = false;
      this.app.state.errorText = "";

      await uploadFile(file, attachmentLink);
      await this.app.docs.getModelByDocType(docType, docId);
    } else {
      this.app.hasError = true;
      this.app.errorText = this.app.text.app.fileFormatNotAccpeted;
    }
  }

  async downloadDocAttachedToField(fieldId: string) {
    const customer = this.app.customers.expectCurrent;
    const docType = fieldId.split(".")[0];
    const docId = this.app.docs.getIdByDocType(docType);
    const fileName = this.app.attachment.getDocNameByFieldId(fieldId);
    const attachmentLink = [
      customer,
      docType,
      EncodingUtils.encodeBase64(docId),
      fileName,
    ].join("/");
    const data = await downloadFile(attachmentLink);
    const blob = new Blob([data], { type: data.type });

    saveAs(blob, fileName);
  }

  selectOption($event: any) {
    this.model[this.field] = $event;
    if (this.field === "impact.omfVehicleName" && this.app.view != "home") {
      this.app.vehicleResponsible.setImpactDetails(this.model[this.field]);
    }
    this.selected.next();
  }

  hideLabel(value: any) {
    const isArray = Array.isArray(value);
    if (isArray) {
      if (value.length > 0 || this.searchFocus) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        (value !== null && value !== "" && value !== undefined) ||
        this.searchFocus
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  replaceSpecialCharacters(field: string) {
    if (
      (field === "user.name" || field == "user.email") &&
      this.app.view === "user"
    ) {
      this.model[field] = this.model[field]
        .replace(/ä/g, "ae") // Replace ä with ae
        .replace(/ö/g, "oe") // Replace ö with oe
        .replace(/ü/g, "ue") // Replace ü with ue
        .replace(/Ä/g, "Ae") // Replace Ä with Ae
        .replace(/Ö/g, "Oe") // Replace Ö with Oe
        .replace(/Ü/g, "Ue") // Replace Ü with Ue
        .replace(/ß/g, "ss"); // Replace ß with ss (for German sharp s)
      return this.model[field];
    } else {
      return this.model[field];
    }
  }
}
