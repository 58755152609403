import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {
    view: false,
  },
  text: {
    title: {
      de: "Daten exportieren",
      en: "Export Data",
      nl: "Gegevens exporteren",
    },
    maunfacturers: {
      de: "Hersteller",
      en: "Manufacturers",
      nl: "Fabrikanten",
    },
    messages: { de: "Meldungen", en: "Messages", nl: "Berichten" },
    all: { de: "Alle", en: "All", nl: "Alle" },
    description: {
      de: "Laden Sie die nächsten Arten von Dokumenten im JSON-Format herunter.",
      en: "Download next types of documents in JSON format.",
      nl: "Download de volgende soorten documenten in JSON formaat.",
    },
  },
  list: {},
  url: {},
  field: {},
  additionalInfo: {},
};

export const debug: ComponentSettings<typeof settings> = settings;
