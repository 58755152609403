import { catchError, Observable, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { REDIRECT_REASON } from "../routing/routing.service";
import { Router } from "@angular/router";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response) => {
        if (
          response.status === 400 &&
          response.error.message === "no permission"
        ) {
          if (typeof Storage !== "undefined") {
            sessionStorage.setItem(REDIRECT_REASON, "SESSION_EXPIRED");
          }

          this.router.navigateByUrl("/login");
        }

        throw response;
      })
    );
  }
}
