import { Injectable, TemplateRef } from "@angular/core";
import { ReleaseNotesModalServiceType } from "./release-notes-modal.service.type";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../environments/environment";
import { getApp } from "../app";
import { updateProfileReleaseVersion } from "../api.service";
import { ReleaseNote } from "../../../../shared/models/releaseNotes";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ReleaseNotesModalService implements ReleaseNotesModalServiceType {
  private modalRef: any; // To store modal reference
  private modalTemplate!: TemplateRef<any>; // Store template reference
  public releaseNotes: ReleaseNote[] = [];

  app = getApp((app) => {
    this.app = app;
  });

  constructor(
    private releaseNotesModalService: NgbModal,
    private http: HttpClient
  ) {}

  setModalTemplate(template: TemplateRef<any>) {
    this.modalTemplate = template;
  }

  async open() {
    await this.getReleaseNotes();
    if (this.modalTemplate) {
      this.modalRef = this.releaseNotesModalService.open(this.modalTemplate, {
        windowClass: "mediumModal",
        backdrop: "static",
      });
    }
  }

  async getReleaseNotes(): Promise<ReleaseNote[]> {
    this.http
      .get<ReleaseNote[]>(
        environment.releaseNotesUrl + "?time=" + new Date().getTime()
      )
      .subscribe((data: any) => {
        this.releaseNotes = data;
      });

    return this.releaseNotes;
  }

  async close() {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
      if (
        this.app.profile.ownProfile.releaseVersions == null ||
        !this.app.profile.ownProfile.releaseVersions.includes(
          environment.version
        )
      ) {
        await updateProfileReleaseVersion(environment.version);
      }
    }
  }
}
