import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { ReleaseNotesModalService } from "./release-notes-modal.service";

@Component({
  selector: "app-release-notes-modal",
  templateUrl: "./release-notes-modal.component.html",
  styleUrl: "./release-notes-modal.component.scss",
})
export class ReleaseNotesModalComponent implements OnInit {
  @ViewChild("content", { static: true }) content!: TemplateRef<any>;

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private releaseNotesModalService: ReleaseNotesModalService) {}

  async ngOnInit() {
    this.releaseNotesModalService.setModalTemplate(this.content);
  }
}
