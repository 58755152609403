<input
  #fileInput
  type="file"
  accept=".xlsx, .csv"
  style="display: none"
  (change)="upload()"
  multiple
/>
<app-modal
  #delete
  [modalMessage]="app.text.import.deleteAllDocs"
  [modalTitle]="app.text.import.deleteTitle"
  (ok)="app.import.deleteDocs(deletingType)"
>
</app-modal>

<app-import-stepper></app-import-stepper>
<div
  class="container-fluid app-container col-md-12 mt-5 p-0"
  [ngClass]="{ errorBackground: app.import.step === 'error' }"
>
  <div class="col-md-7 m-auto" *ngIf="app.import.step === 'first'">
    <p class="card-text">{{ app.text.import.selectType }}</p>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label
          class="input-group-text no-border card-text ps-0 col-md-1"
          for="inputGroupSelect01"
          >{{ app.text.import.options }}</label
        >
      </div>
      <select
        class="custom-select select-input col-md-10"
        id="inputGroupSelect01"
        [(ngModel)]="app.import.type"
        onchange="this.dataset.chosen = this.value"
        (click)="emptyImportMessages()"
      >
        <option value="null">{{ app.text.import.choose }}</option>
        <option *ngFor="let type of types" [value]="type" class="select-text">
          {{ getLabel(type) }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-8 m-auto" *ngIf="app.import.step === 'second'">
    <p class="card-text mx-2 my-3">{{ app.text.import.selectFile }}</p>
    <div class="d-flex my-3">
      <div>
        <button
          class="btn btn-import"
          (click)="fileInput.click()"
          [title]="app.text.import.upload"
        >
          <i class="fa fa-upload"></i>&nbsp;&nbsp;{{
            app.text.import.importFile
          }}
        </button>
      </div>
      <div class="mt-1" *ngIf="app.language === 'en'">
        <a
          class="link"
          href="./assets/docs/Item.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'part'"
        >
          <i class="fas fa-file-word fa-lg"> </i>&nbsp;&nbsp;{{
            app.text.import.downloadSample
          }}
          <!-- <div class="col-md-12 text-center">Download sample</div> -->
        </a>
        <a
          class="link"
          href="./assets/docs/Assembly.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'assembly'"
        >
          <i class="fas fa-file-word"> </i>&nbsp;&nbsp;{{
            app.text.import.downloadSample
          }}
        </a>
        <a
          class="link"
          href="./assets/docs/Manufacturer.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'manufacturer'"
        >
          <i class="fas fa-file-word"> </i>&nbsp;&nbsp;{{
            app.text.import.downloadSample
          }}
        </a>
        <a
          class="link"
          href="./assets/docs/Cases_en.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'thread'"
        >
          <i class="fas fa-file-word"> </i>&nbsp;&nbsp;{{
            app.text.import.downloadSample
          }}
        </a>
      </div>
      <div class="mt-1" *ngIf="app.language === 'de'">
        <a
          class="link"
          href="./assets/docs/Item.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'part'"
        >
          <i class="fas fa-file-word fa-lg"> </i>&nbsp;&nbsp;{{
            app.text.import.downloadSample
          }}
          <!-- <div class="col-md-12 text-center">Download sample</div> -->
        </a>
        <a
          class="link"
          href="./assets/docs/Assembly.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'assembly'"
        >
          <i class="fas fa-file-word"> </i>&nbsp;&nbsp;{{
            app.text.import.downloadSample
          }}
        </a>
        <a
          class="link"
          href="./assets/docs/Manufacturer.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'manufacturer'"
        >
          <i class="fas fa-file-word"> </i>&nbsp;&nbsp;{{
            app.text.import.doawnloadSample
          }}
        </a>
        <a
          class="link"
          href="./assets/docs/Cases_de.xlsx"
          target="_blank"
          *ngIf="app.import.type === 'thread'"
        >
          <i class="fas fa-file-word"> </i>&nbsp;&nbsp;{{
            app.text.import.doawnloadSample
          }}
        </a>
      </div>
    </div>
    <div class="col-md-12 mt-4 rules no-padding">
      <ul class="col-md-12 py-3">
        <div class="mb-3">Rules for a succesfull upload</div>
        <li class="ml-4">
          Make sure the date fields respect the following standard rules:
          "2015.03.25" / "Mar.25.2015" / "25.Mar.2015"
        </li>
        <li class="ml-4">
          Make sure that all table heads match the fields configured on your
          system.
        </li>
        <li class="ml-4">
          Make sure you include "_language" to your template-sheet name (eg:
          "Template_de / Template_en").
        </li>
        <li class="ml-4">
          Make sure that the current set language matches the imported language.
        </li>
      </ul>
    </div>
  </div>

  <div
    class="col-md-12 mt-5"
    *ngIf="app.import.step === 'third' && !app.import.showGenerate"
  >
    <button
      class="btn btn-save pull-right py-2 mx-2"
      *ngIf="app.import.step === 'third'"
      (click)="
        app.import.saveAllDocsBasedOnType(app.state.importBuffer.docs.data)
      "
      [title]="app.text.import.next"
      [disabled]="app.part.hasInvalidCPNs"
    >
      {{ app.text.import.save }}
    </button>
    <button
      class="btn btn-clear pull-right"
      *ngIf="app.import.step === 'third'"
      (click)="onClear()"
    >
      <i class="fas fa-redo"></i>&nbsp; {{ app.text.import.clear }}
    </button>

    <div *ngIf="app.state.importBuffer.docs.data" class="json-card">
      <pre class="format">
      <div *ngIf="app.part.hasInvalidCPNs">
        <b>{{ app.text.part.invalidPartNumbers }}</b>
        <div class="lh-1 scroll">
          <div *ngFor="let partNumber of app.part.invalidPartNumbers">
              {{ partNumber }}
          </div>
        </div>
      </div>
      <div *ngIf="!app.import.savingStarted  && !app.part.hasInvalidCPNs">
        {{ app.state.importBuffer.docs.data.length }} docs ready to be saved!
      </div>
      <div *ngIf="app.import.savingStarted">
        <div>
          <b>{{ app.state.importBuffer.docs.data.length }}</b> docs left!
        </div>
        <div class="message" *ngFor="let message of app.import.importMessages">
          {{message}}
        </div>
      </div>
      </pre>
    </div>

    <div class="pt-5 mb-5 mx-2">
      <div class="row" *ngIf="app.import.duplicatedItems.length > 0">
        <div class="col-md-12 mb-1">
          <dl class="row alert alert-danger pb-1 m-1">
            <dt class="col-sm-1 p-1">
              <i class="fas fa-times-hexagon"></i>
            </dt>
            <dd class="col-sm-11 error-content">
              <p class="m-0 p-0 error-message-wrong">
                {{ app.text.app.wentWrong }}
              </p>
              <p class="m-0 p-0 error-info">
                {{ app.text.part.removedBom }}
                <span *ngFor="let i of app.import.duplicatedItems">
                  &nbsp;&nbsp;{{ i }}&nbsp;&nbsp;
                </span>
              </p>
            </dd>
          </dl>
        </div>
      </div>
      <div class="tableFixHead" *ngIf="!app.state.importBuffer.docs.data">
        <table class="table spacing-table table-striped">
          <thead>
            <tr *ngIf="app.import.type">
              <th class="table-cell">Select</th>
              <th class="table-cell" *ngFor="let id of app.import.importedKeys">
                {{ getFieldLabel(app.import.type, id) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of app.import.docs">
              <td
                [ngClass]="{ invalidPartNumber: doc.invalidPartNumber }"
                class="table-cell"
              >
                <button
                  class="btn"
                  *ngIf="app.import.checkIfSelected(doc)"
                  [ngbTooltip]="app.text.import.removeItem"
                  (click)="app.import.toggleDoc(doc, 'remove')"
                >
                  <i class="far fa-check-square"></i>
                </button>
                <button
                  class="btn"
                  *ngIf="!app.import.checkIfSelected(doc)"
                  [ngbTooltip]="app.text.import.addItem"
                  (click)="app.import.toggleDoc(doc, 'add')"
                >
                  <i class="far fa-square"></i>
                </button>
              </td>
              <td
                [ngClass]="{ invalidPartNumber: doc.invalidPartNumber }"
                class="table-cell"
                *ngFor="let id of app.import.importedKeys"
              >
                <div>
                  <span
                    *ngIf="
                      app.import.type === 'manufacturer' &&
                      id !== 'parts' &&
                      id !== 'nameRaw' &&
                      id !== 'manufacturerPartNumberRaw' &&
                      id !== 'vehicleName' &&
                      id !== 'partNumber' &&
                      app.import.type === 'manufacturer'
                    "
                    >{{ doc[id] }}</span
                  >
                  <span
                    *ngIf="app.import.type !== 'manufacturer' && id !== 'parts'"
                    >{{ doc[id] }}</span
                  >
                </div>
                <div *ngIf="id === 'parts'">
                  <span *ngFor="let partNumber of doc.bom"
                    >{{ partNumber }}&nbsp;&nbsp;</span
                  >
                </div>
                <div
                  *ngIf="id === 'nameRaw'"
                  [ngbTooltip]="app.text.import.docExists"
                >
                  <span [ngClass]="{ redLine: app.import.markAsExisting(doc) }">
                    {{ doc[id] }}
                  </span>
                </div>
                <div
                  *ngIf="
                    id === 'manufacturerPartNumberRaw' ||
                    id === 'vehicleName' ||
                    (id === 'partNumber' && app.import.type === 'manufacturer')
                  "
                  [ngbTooltip]="app.text.import.docExists"
                >
                  <span [ngClass]="{ redLine: app.import.markAsExisting(doc) }">
                    {{ doc[id] }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button
      class="btn pull-right"
      *ngIf="app.import.step !== 'third'"
      (click)="app.import.saveAllDocsBasedOnType()"
      [title]="app.text.import.next"
    >
      {{ app.text.import.next }}&nbsp;
      <i class="far fa-arrow-right"></i>
    </button>
  </div>
  <div class="col-md-12" *ngIf="app.import.showGenerate">
    <div class="col-md-12">
      <small class="text-muted"
        ><i class="fa fa-info-circle"></i>&nbsp;{{
          app.getText("operations.generateTreeDescription")
        }}</small
      >
    </div>
    <div class="col-md-12">
      <button
        class="btn pull-right btn btn-outline-secondary"
        (click)="
          app.operations.triggerWork('build-bom'); app.tree.generateTree()
        "
      >
        {{ app.getText("operations.generateTree") }}&nbsp;
        <i class="fas fa-sitemap fa-rotate-270 fa-xs"></i>
      </button>
    </div>
  </div>
  <div class="col-md-12 mt-5" *ngIf="app.import.step === 'error'">
    {{ app.text.import.fieldsMissing }}
    <ul *ngIf="app.import.invalidKeys">
      <li *ngFor="let field of app.import.invalidKeys">
        {{ app.text.import.column }}&nbsp;{{ field.columnIndex }} /

        <span class="text-danger">
          {{ field.fieldId | json }}
        </span>
      </li>
    </ul>
    {{ app.text.import.tryAgain }}
  </div>
  <div
    class="col-md-7 m-auto"
    *ngIf="app.import.step === 'first' && app.import.type !== null"
  >
    <button
      *ngIf="app.import.type !== 'assembly'"
      class="btn btn-sm pull-left btn-delete"
      (click)="delete.open(); deletingType = app.import.type"
      [title]="app.text.import.next"
    >
      {{ app.text.import.deleteAllDocsType }}&nbsp;&nbsp;
      <i class="fad fa-trash"></i>
    </button>

    <div *ngIf="app.import.importMessages.length > 0" class="json-card">
      <pre class="format">
        <div *ngIf="app.import.docs.length > 0">
          <b>{{ app.import.docs.length }}</b> docs left!
        </div>
        <div class="message" *ngFor="let message of app.import.importMessages">
          {{message}}
        </div>
      </pre>
    </div>
  </div>
</div>
