import { ComponentSettings } from "../settings/settings";

const field = {};

const settings = {
  text: {
    pcnGenerator: {
      de: "PCN Eingang",
      en: "PCN Generator",
      nl: "PCN Inbox",
    },
    notification: {
      de: "Benachrichtigungen",
      en: "Notifications",
      nl: "Notificaties",
    },
    alert: {
      de: "Alarme",
      en: "Alerts",
      nl: "RM Alerts",
    },
    change: {
      de: "Änderungs-Alarm",
      en: "Change Alerts",
      nl: "RM Changes",
    },
    pcnInbox: {
      de: "PCN Eingang",
      en: "PCN Inbox",
      nl: "PCN Inbox",
    },
    pcnItem: {
      de: "PCN Einheit",
      en: "PCN Item",
      nl: "PCN Artikel",
    },
    selected: {
      de: "Benachrichtigungen ausgewählt.",
      en: "notifications selected.",
      nl: "meldingen geselecteerd.",
    },
    selectAll: {
      de: "Klicken Sie hier, um alle auszuwählen:",
      en: "Click here to select all:",
      nl: "Klik hier om alles te selecteren:",
    },
    deselectAll: {
      de: "Klicken Sie hier, um alle zu deaktivieren",
      en: "Click here to deselect all",
      nl: "Klik hier om alles te deselecteren",
    },
    notificationDescr: {
      de: "Benachrichtigungen zeigen die letzten Änderungen an favorisierten oder selbst erstellten Fällen, Kommentare, Aufgaben oder Lösungen an.",
      en: "Notifications displays the latest changes about favorite or own created cases, comments, tasks or solutions.",
      nl: "Notificaties tonen de laatste wijzigingen in favoriete of zelfgemaakte cases, commentaren, taken of oplossingen.",
    },
    pcnDescr: {
      de: "PCN Inbox zeigt alle PCNs / PDNs, die Ihnen von Ihren Lieferanten oder Herstellern ausgestellt wurden, in Ihrer persönlichen 'PCN Inbox'",
      en: "PCN Inbox displays all the PCNs / PDNs issued to you, by your suppliers or manufactures under your own personalised 'PCN Inbox'",
      nl: "PCN Inbox toont alle PCN's / PDN's die u van uw leveranciers of fabrikanten krijgt in uw persoonlijke 'PCN Inbox'",
    },
    alertsDescr: {
      de: "Alarme zeigen die neuesten Änderungen des Obsoleszenz-Status, in Bezug auf die mit der Obsoleszenz Datenbank zugeordneten Hersteller-Teilenummern.",
      en: "Alerts displays the latest obsolescence status changes, regarding your matched manufacturer part numbers with the obsolescence database.",
      nl: "Alerts tonen de laatste wijzigingen in de verouderingsstatus, in relatie tot de onderdeelnummers van de fabrikant die gekoppeld zijn aan de verouderingsdatabase.",
    },
    changesDescr: {
      de: "Änderungs-Alarm zeigt die neusten PCNs / PDNs, in Bezug auf die mit der Obsoleszenz Datenbank zugeordneten Hersteller-Teilenummern.",
      en: "Change Alters displays the latest PCNs / PDNs issued regarding your matched manufacturer part numbers with the obsolescence database.",
      nl: "Change Alert toont de meest recente PCN's / PDN's, in relatie tot de onderdeelnummers van de fabrikant die gekoppeld zijn aan de verouderingsdatabase.",
    },
    leadTimeDescr: {
      de: "Lieferzeiten-Alarm zeigt die neuesten Änderungen des Lieferzeiten Status, in Bezug auf die mit der Obsoleszenz Datenbank zugeordneten Hersteller-Teilenummern.",
      en: "Lead time alerts displays the latest lead time status changes regarding your matched manufacturer part numbers with the obsolescence database.",
      nl: "Het levertijd alarm toont de laatste wijzigingen in de levertijd status, in relatie tot de fabrikant onderdeelnummers die gekoppeld zijn aan de verouderingsdatabase.",
    },
    envComplianceDescr: {
      de: "Umweltkonformität Alarm zeigen die neuesten Statusänderungen zu Umweltvorschriften für Ihre übereinstimmenden Herstellerteilenummern an. Diese Alarme umfassen Aktualisierungen zu EU RoHS Status, Enthält SVHC, Globaler PFAS Bewertungsstatus, TSCA Status, California Proposition 65 und China RoHS Status.",
      en: "Environmental Compliance Alerts display the latest status changes related to environmental regulations for your matched manufacturer part numbers. These alerts cover updates on EU RoHS Status, Contains SVHC, Global PFAS Assessment Status, TSCA Status, California Proposition 65, and China RoHS Status.",
      nl: "Environmental Compliance Alerts tonen de nieuwste statuswijzigingen met betrekking tot milieuregelgeving voor je overeenkomende fabrikantonderdeelnummers. Deze waarschuwingen omvatten updates over EU RoHS-status, Bevat SVHC, Wereldwijde PFAS-beoordelingsstatus, TSCA-status, California Proposition 65 en China RoHS-status.",
    },
  },
  url: {},
  field,
  list: {},
  permission: {
    showNewCasesNotifications: false,
  },
  additionalInfo: {},
};

export const mail: ComponentSettings<typeof settings> = settings;
