import { ComponentSettings, FieldTypes, empty } from "../settings/settings";

const field = {
  firstName: {
    type: FieldTypes.value,
    text: { de: "Vorname", en: "First name", nl: "Voornaam" },
  },
  lastName: {
    type: FieldTypes.value,
    text: { de: "Nachname", en: "Last name", nl: "Achternaam" },
  },
  shortName: {
    type: FieldTypes.value,
    text: { de: "Namenskürzel", en: "Short name", nl: "Afkorting" },
  },
  telephone: {
    type: FieldTypes.value,
    text: { de: "Telefonnummer", en: "Telephone number", nl: "Telefoonnummer" },
  },
  email: {
    type: FieldTypes.value,
    text: { de: "Email", en: "Email", nl: "e-mail" },
  },
  department: {
    type: FieldTypes.value,
    text: { de: "Abteilung", en: "Department", nl: "Afdeling" },
  },
};

const settings = {
  permission: {},
  url: {},
  list: { featureFields: empty },
  text: {
    person: {
      de: "Person",
      en: "Person",
      nl: "Persoon",
    },
    people: {
      de: "Personen",
      en: "People",
      nl: "Personen",
    },
  },
  field,
  additionalInfo: {},
};

export const person: ComponentSettings<typeof settings> = settings;
