import { ComponentSettings, empty } from "../settings/settings";

export const settings = {
  permission: {
    manage: false,
    editOwn: false,
    addExpiryDate: false,
  },
  list: {
    columns: empty,
    boxes: empty,
    main: empty,
  },
  url: {},
  text: {
    resetPassword: {
      de: "Kennwort zurücksetzen",
      en: "Reset Password",
      nl: "Reset Wachtwoord",
    },
    requestPassword: {
      de: "Passwortänderung anfordern",
      en: "Request password change",
      nl: "Wachtwoord wijziging aanvragen",
    },
    forgotPassword: {
      de: "Ich habe mein Passwort vergessen",
      en: "I forgot my password",
      nl: "Ik ben mijn wachtwoord vergeten",
    },
    password: {
      de: "Kennwort zurücksetzen",
      en: "Reset Password",
      nl: "Reset Wachtwoord",
    },
    save: {
      de: "Änderungen übernehmen",
      en: "Save changes",
      nl: "Wijzigingen opslaan",
    },
    succesUserUpdated: {
      de: "Benutzer erfolgreich aktualisiert",
      en: "User updated successfully",
      nl: "Gebruiker succesvol bijgewerkt",
    },
    emailNotCorrect: {
      de: "Die eingeführte E-Mail ist nicht korrekt",
      en: "The introduced email is not correct",
      nl: "De ingevoerde e-mail is niet correct",
    },
    userNotFound: {
      de: "Benutzer nicht gefunden",
      en: "User not found!",
      nl: "Gebruiker niet gevonden",
    },
    messageSent: {
      de: "Eine Nachricht zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet.",
      en: "A reset password message was sent to your email.",
      nl: "Een reset wachtwoord bericht werd verzonden naar uw e-mail.",
    },
    linkExpired: {
      de: "Der Link ist abgelaufen! Weiterleitung zum Login...",
      en: "Link has expired! Redirecting to login...",
      nl: "Link is verlopen! Doorverwijzen naar inloggen...",
    },
    tokenHasBeenUsed: {
      de: "Die Aktivierungs-URL wurde bereits verwendet, bitte fordern Sie ein neues Passwort an.",
      en: "The activation URL has be already used, please request another change password.",
      nl: "De activatie URL is reeds gebruikt, gelieve een ander paswoord aan te vragen.",
    },
  },
  field: {},
  additionalInfo: {},
};

export const resetPassword: ComponentSettings<typeof settings> = settings;
