import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { getApp } from "../../../app/app";
import { AppType } from "../../../app/app.type";
import { DocModel } from "../../../app/state/state";
import { Customer } from "../../../../../shared/types/customers";
import { StringUtils } from "../../../../../shared/utils/string.utils";
import { UserOptions } from "../../../../../shared/models/user";

@Component({
  selector: "app-typeahead-basic",
  templateUrl: "./typeahead-basic.component.html",
  styleUrls: ["./typeahead-basic.component.scss"],
})
export class TypeAheadBasicComponent implements OnInit, OnDestroy {
  @ViewChild("overrideMpnFieldsModal", { static: false })
  overrideMpnFieldsModal: any;

  @Input() ids: string[] = [];
  @Input() field = "";
  @Input() docModel = {} as DocModel;
  @Input() maxLength = 128;
  @Input() placeholder = "";

  selected = false;
  result: string[] = [];
  selectedValue = "";
  isDefaultTypeAhead = false;
  showDropdownList = false;
  valueSelected = false;
  timeoutId: any = 0;
  selectedItemIndex = -1;
  displayOptionsTop = false;

  private _clearTypeaheadFieldSubscription: Subscription = new Subscription();
  private _resetTypeaheadFilterSubscription: Subscription = new Subscription();

  app = getApp((app: AppType) => {
    this.app = app;
  });

  ngOnInit() {
    this.app.manufacturer.displayCpnSuggestions = false;
    this.app.manufacturer.displayMpnSuggestions = false;

    this.app.manufacturer.correspondingCpns = [];
    this.app.manufacturer.correspondingMpns = [];
    this.resetInput();

    this.setTypeAheadType(this.field);
    this.displayDropdownTop();

    this._clearTypeaheadFieldSubscription =
      this.app.RMSearch.clearField.subscribe((value) => {
        /** clean up data when the filter type is reset */
        if (value) {
          this.clearContent(this.field);
          this.app.RMSearch.RMDocs = [];
        }
      });
    this.app.filterHeaderTable.resetAllFilters.next(false);
    this._resetTypeaheadFilterSubscription =
      this.app.filterHeaderTable.resetAllFilters.subscribe((value) => {
        if (value) {
          (this.app.typeAheadBasic.model as any)[this.field] = "";
          (this.app.model as any)[this.field] = "";
        }
      });

    if (this.docModel[this.field] !== "") {
      this.selected = true;
    }

    this.app.thread.currentExistingCases.cpn =
      this.app.thread.existingCasesPerCpn;
    this.app.thread.currentExistingCases.mpn =
      this.app.thread.existingCasesPerMpn;
    this.app.thread.currentExistingCases.pcnId =
      this.app.thread.existingCasesPerPcnId;
  }

  async selectValue(option: string) {
    const value = option;
    this.app.typeAheadBasic.model[this.field] = value;
    this.app.model[this.field] = value;
    this.selected = true;
    let isResponsibleKey = false;
    /** check if current field is of type responsibles  - used just for DB */
    if (this.field.split(".")[0] === "responsibles") {
      isResponsibleKey = true;
    }
    const type =
      this.app.type != null ? this.app.type : this.field.split(".")[0];
    switch (type) {
      case "thread":
        if (this.app.view !== "home") {
          if (
            this.field === "thread.crtNumber" ||
            this.field === "thread.artNumber"
          ) {
            this.valueSelected = true;
            await this.doCpnMpnOperations(this.field, value);
            this.app.thread.thread[this.field] = value;

            this.app.overrideMpnFields.openOverrideModal = true;
            this.overrideMpnFieldsModal.open();
          }
          if (this.field === "thread.pcnID") {
            this.app.thread.thread[this.field] = value;
            this.valueSelected = true;
          }
          if (this.field === "thread.creator") {
            if (this.app.customers.expectCurrent === Customer.KNDS) {
              await this.app.field.getManufacturerCodeByName(value);
            }
            this.app.thread.thread[this.field] = value;
            this.valueSelected = true;
          }
          if (this.field === "thread.cageCode") {
            this.app.filterList.add();
            this.app.field.getManufacturerNameByCode(value);
            this.app.thread.thread[this.field] = value;
            this.valueSelected = true;
          }
        }
        if (
          (this.field === "thread.pcnID" ||
            this.field === "thread.omfNumber" ||
            this.field === "thread.crtNumber" ||
            this.field === "thread.creator" ||
            this.field === "thread.artNumber" ||
            this.field === "thread.masterRecord") &&
          this.app.view === "home"
        ) {
          this.app.filterList.add();
        }
        break;
      case "impact":
        if (
          isResponsibleKey &&
          this.app.customers.expectCurrent === Customer.DB
        ) {
          this.app.typeAheadBasic.model[this.field] = value;
          this.app.impact.currentImpact["impact.responsibles"][this.field] =
            value;
        }
        break;
      case "vehicleResponsible":
        if (isResponsibleKey) {
          this.app.typeAheadBasic.model[this.field] = value;
          this.app.vehicleResponsible.model["vehicleResponsible.responsibles"][
            this.field
          ] = value;
        }
        break;
      case "rm.search":
        this.app.RMSearch.model = value;
        await this.app.RMSearch.getRMSearchResults();
        break;
      case "part":
        if (this.app.RM.mode === "tree") {
          this.app.filterTree.filter(this.field, value);
        } else {
          this.app.filterHeaderTable.filter(this.field, value);
        }
        break;
      case "manufacturer":
        this.app.filterHeaderTable.filter(this.field, value);
        break;
      case "seMatch":
      case "z2DataMatch":
        this.app.model[this.field] = value;
        this.app.createManufacturer.setModel();
        break;
      default:
        this.app.model[this.field] = value;
        break;
    }
    this.showDropdownList = false;
  }

  isSelected(field: string) {
    if (this.app.model[field] != null && this.app.model[field] !== "") {
      this.selectedValue = this.app.model[field];
      return true;
    }
    if (this.docModel[field] != null && this.docModel[field] !== "") {
      this.selectedValue = this.docModel[field];
      return true;
    }
    return false;
  }

  isInvalid(value: string) {
    this.app.field.isValid(this.field, value);
    if (this.app.type !== "impact" && this.app.type !== "vehicleResponsible") {
      return false;
    } else {
      /** check if current inputed value is an existing value, if not display an error message */
      if (!StringUtils.isNullOrEmpty(value)) {
        if (this.ids.findIndex((v: string) => v === value) === -1) {
          if (this.app.type === "impact") {
            this.app.impact.invalidFields.add(this.field);
            this.app.impact.newInvalidField.next(
              this.app.impact.invalidFields.size
            );
          }
          if (this.app.type === "vehicleResponsible") {
            this.app.vehicleResponsible.invalidFields.add(this.field);
            this.app.vehicleResponsible.newInvalidField.next(
              this.app.vehicleResponsible.invalidFields.size
            );
          }
          return true;
        } else {
          if (this.app.type === "impact") {
            this.app.impact.invalidFields.delete(this.field);
            this.app.impact.newInvalidField.next(
              this.app.impact.invalidFields.size
            );
          }
          if (this.app.type === "vehicleResponsible") {
            this.app.vehicleResponsible.invalidFields.delete(this.field);
            this.app.vehicleResponsible.newInvalidField.next(
              this.app.vehicleResponsible.invalidFields.size
            );
          }
          return false;
        }
      } else {
        return false;
      }
    }
  }

  async saveInput(value: string) {
    setTimeout(async () => {
      /** if the input is of type thread and the thread is in edit mode, set the value on its model */
      if (
        !this.valueSelected &&
        this.app.unlockedId != null &&
        (this.field === "thread.crtNumber" ||
          this.field === "thread.artNumber" ||
          this.field === "thread.pcnID")
      ) {
        this.app.thread.thread[this.field] = value;
        this.app.model[this.field] = value;
        await this.doCpnMpnOperations(this.field, value);
      } else {
        if (!this.valueSelected) {
          this.app.model[this.field] = value;
          this.app.thread.thread[this.field] = value;
        }
      }
      this.showDropdownList = false;
    }, 100);
  }

  async resetInput() {
    // timeout added because the focusOut is executed before the selection of a item from the dropdown
    setTimeout(() => {
      if (this.selected) {
        return;
      }
      this.app.typeAheadBasic.model[this.field] = "";
      this.showDropdownList = false;
    }, 100);
  }

  async onKeyUp(event: any) {
    if (
      event.key === "ArrowUp" ||
      event.key === "ArrowDown" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      this.moveSelection(event.key);
      return;
    }

    /** apply enter event only if the filter fields are not partNumber & manufacturerPartNumber */
    if (event.key === "Enter") {
      if (
        (this.app.type === "rm.search" &&
          this.app.RMSearch.filterMode === "part.partNumber") ||
        this.app.RMSearch.filterMode === "manufacturer.manufacturerPartNumber"
      ) {
        return;
      }
      const model = event.target.value;
      this.app.RMSearch.model = model.trim();
      this.app.RMSearch.getRMSearchResults();
    }

    const type = this.field.split(".")[0];
    const field = this.field.split(".")[1];

    if (
      this.app.typeAheadBasic.model[this.field] != null &&
      this.app.typeAheadBasic.model[this.field].length > 0
    ) {
      // added for DB - Add vehicle - the dropdown should display a list with all DB users
      if (type === "responsibles") {
        const options = this.app.users.userRoles.map(
          (user: UserOptions) => user.name
        );
        this.filterWithoutSearchEngine(options);
        return;
      }

      if (this.app.view === "home" && field === "omfNumber") {
        this.filterWithoutSearchEngine(this.app.filterList.omfNumbers);
        return;
      }

      if (this.app.view === "thread" && field === "cageCode") {
        this.filterWithoutSearchEngine(this.app.field.cageCodeList);
        return;
      }

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        const options = await this.app.field.getTypeaheadOptionsBasedOnType(
          type,
          field,
          this.app.typeAheadBasic.model[this.field]
        );
        this.ids = options;
        this.showDropdownList = this.ids.length > 0 ? true : false;
      }, 300);
    } else {
      this.ids = [];
      this.showDropdownList = false;
    }
  }

  filterWithoutSearchEngine(data: string[]) {
    if (this.app.typeAheadBasic.model[this.field].length >= 2) {
      this.ids = data.filter(
        (word) =>
          word != null &&
          word
            .toLowerCase()
            .indexOf(this.app.typeAheadBasic.model[this.field].toLowerCase()) >
            -1
      );
      this.showDropdownList = this.ids.length > 0 ? true : false;
    }
  }

  onEnter() {
    if (
      this.app.view === "home" &&
      this.field === this.app.fieldId.thread.omfNumber
    ) {
      this.selectValue(this.app.typeAheadBasic.model[this.field]);
    }

    if (
      this.app.view === "thread" &&
      this.field === this.app.fieldId.thread.cageCode
    ) {
      this.selectValue(this.app.typeAheadBasic.model[this.field]);
    }
  }

  moveSelection(key: string) {
    if (key === "ArrowDown") {
      if (this.selectedItemIndex < this.ids.length - 1) {
        this.selectedItemIndex++;
      }
    }
    if (key === "ArrowUp") {
      if (this.selectedItemIndex > 0) {
        this.selectedItemIndex--;
      }
    }
  }

  setUsersListDB() {
    const options = this.app.users.userRoles.map(
      (user: UserOptions) => user.name
    );
    // filter list while typing
    options.forEach((x: any) => {
      if (
        x.toLowerCase().includes(this.app.typeAheadBasic.model[this.field]) &&
        !this.ids.includes(x)
      ) {
        this.ids.push(x);
      } else {
        const index = this.ids.findIndex(
          (id) =>
            !id
              .toLowerCase()
              .includes(this.app.typeAheadBasic.model[this.field])
        );
        if (index !== -1) {
          this.ids.splice(index, 1);
        }
      }
    });
    return this.ids;
  }

  /** Clear contents */
  clearContent(field: string) {
    (this.app.typeAheadBasic.model as any)[field] = "";
    (this.app.model as any)[field] = "";
    this.docModel[field] = "";
    this.selected = false;
    this.app.thread.thread[field] = "";
    this.app.manufacturer.correspondingCpns = [];
    this.app.manufacturer.correspondingMpns = [];
    if (field === "thread.creator") {
      this.app.field.manufacturerCodes = [];
    }
    if (field === "thread.crtNumber") {
      this.app.manufacturer.prepareData(
        "thread.artNumber",
        this.app.thread.thread["thread.artNumber"]
      );
    }
    if (field === "thread.artNumber") {
      this.app.manufacturer.prepareData(
        "thread.crtNumber",
        this.app.thread.thread["thread.crtNumber"]
      );
    }
    if (this.app.view !== "part") {
      this.app.filterHeaderTable.clearFilter(field);
    }

    if (this.app.view === "RM") {
      const propertyName = field.split(".")[1];
      delete this.app.filterTree.filterValues[propertyName];

      const index = this.app.filterTree.filters.findIndex(
        (i) => i.filterLabelField === field
      );
      this.app.filterTree.filters.splice(index, 1);

      this.app.filterTree.getTreeSearchResult();
    }

    this.app.field.getTypeAheadOptions(field);
  }

  /** set of functions that have to be runt on the crtNumber and artNumber */
  async doCpnMpnOperations(
    field: "thread.artNumber" | "thread.crtNumber" | "thread.pcnID",
    value: string
  ) {
    if (StringUtils.isNullOrEmpty(value)) {
      return;
    }
    value = value.trim();
    // if (this.app.customers.expectCurrent === Customer.NS) {
    switch (field) {
      case "thread.artNumber":
        /** get the list of cases created by this cpn */
        this.app.thread.getCasesByCPN(value, "", "");
        break;
      case "thread.crtNumber":
        /** get the list of cases created by this mpn */
        this.app.thread.getCasesByCPN("", value, "");
        break;
      case "thread.pcnID":
        /** get the list of cases created by this pcnID */
        this.app.thread.getCasesByCPN("", "", value);
        break;
    }
    // }

    await this.app.overrideMpnFields.overrideFieldsFromOption(
      this.field,
      value
    );

    if (this.field === "thread.artNumber") {
      /** used only for generating the partsToImpacts */
      await this.app.treeRow.generateImpactsFromParts(value);
    }

    if (this.field === "thread.crtNumber") {
      /** used only for generating the partsToImpacts */
      await this.app.treeRow.generateImpactsFromParts(
        this.app.manufacturer.selectedManufacturer.partNumber
      );
    }

    // this.overrideMpnFieldsModal.open();
  }

  /** used for displaying save icon inline with the field so that we can set the
   * value to the model
   */
  showSaveIcon() {
    if (
      (this.result.length === 0 &&
        !this.selected &&
        this.app.thread.thread[this.field] === "") ||
      this.app.thread.thread[this.field] == null
    ) {
      return true;
    }
    if (
      this.app.thread.thread["thread.crtNumber"] !== "" &&
      this.field === "thread.crtNumber"
    ) {
      return false;
    }
    if (
      this.app.thread.thread["thread.artNumber"] !== "" &&
      this.field === "thread.artNumber"
    ) {
      return false;
    }
  }

  setTypeAheadType(field: string) {
    if (
      (field !== "thread.crtNumber" &&
        field !== "thread.artNumber" &&
        field !== "thread.omfNumber" &&
        field !== "thread.pcnID" &&
        field !== "thread.creator" &&
        field !== "thread.cageCode" &&
        field !== "thread.masterRecord") ||
      this.app.view === "home"
    ) {
      this.isDefaultTypeAhead = true;
    } else {
      this.isDefaultTypeAhead = false;
    }
  }

  displayDropdownTop() {
    if (
      this.app.customers.expectCurrent === Customer.NS &&
      this.field === this.app.fieldId.thread.pcnID
    ) {
      this.displayOptionsTop = true;
    }
  }

  disabled() {
    if (
      (this.app.view === "part" && !this.app.state.createFromPart) ||
      this.app.view === "manufacturer"
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this._clearTypeaheadFieldSubscription) {
      this._clearTypeaheadFieldSubscription.unsubscribe();
    }
    if (this._resetTypeaheadFilterSubscription) {
      this._resetTypeaheadFilterSubscription.unsubscribe();
    }
  }
}
