<app-task-completed-note
  #taskCompletedModal
  [currentTask]="app.panel.selectedItem"
></app-task-completed-note>
<app-modal
  #discardChangesModal
  [modalMessage]="app.text.app.discardChanges"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="discardChanges()"
>
</app-modal>
<app-modal #deletePostModal 
  [modalMessage]="app.text.post.deleteTaskQuestion" 
  [modalTitle]="app.text.post.modalTitle"
  (ok)="deleteTask()">
</app-modal>

<div class="panel" *ngIf="app.panel.isOpen">
  <div class="panel-header">
    <button
      class="btn btn-sm btn-incomplete"
      *ngIf="!app.panel.selectedItem.taskCompleted"
      (click)="taskCompletedModal.openModal()"
      [disabled]="
        !showCompleteButton() || app.panel.selectedItem.cannotCloseTask
      "
    >
      <i class="fal fa-check"></i>
      &nbsp;&nbsp;{{ app.text.post.markCompleted }}
    </button>
    <button
      class="btn btn-sm btn-complete"
      *ngIf="app.panel.selectedItem.taskCompleted"
      (click)="
        app.taskCompleted.changeTaskCompletion(
          app.customer,
          app.panel.selectedItem
        )
      "
      [disabled]="!showReopenButton()"
    >
      <i class="fal fa-check"></i>
      &nbsp;&nbsp;{{ app.text.post.completed }}
    </button>
    <button 
      *ngIf="this.app.post.hasEditPermission(app.panel.selectedItem)"
      class="btn m-0" 
      (click)="deletePostModal.open()"
      [title]="app.text.post.delete">
      <i class="fa fa-trash"></i>
    </button>
    <app-attachments-controls *ngIf="hasPermissionToAddAttachment()" [id]="app.panel.selectedItem._id" type="post">
    </app-attachments-controls>
    <button
      class="btn btn-sm btn-close-panel pull-right"
      (click)="app.panel.closePanel()"
    >
      <i class="fa-light fa-arrow-right-to-line"></i>
    </button>
  </div>

<div class="panel-body">
  <!-- Status of the task -->
  <div class="panel-status badge-color selected pull-right mt-2">
    {{ threadStatus }}
  </div>
  <!-- Details of the task -->
  <div class="col-md-12 task-description">
    <textarea
      [(ngModel)]="app.panel.selectedItem.taskDescription"
      [disabled]="!app.panel.hasEditPermissionForDescription"
    >
    </textarea>
    <span
      class="invalidspan"
      *ngIf="app.panel.selectedItem.taskDescription === ''"
    >
      <i class="fal fa-exclamation-circle"></i>
    </span>
  </div>
  <div *ngFor="let postField of columns" [ngClass]="{'taskNote-field-container' :postField === app.fieldId.post.taskNote}">
    <div class="row">
      <app-field
        *ngIf="postField !== app.fieldId.post.taskNote && postField !== ''"
        [model]="app.panel.selectedItemModel"
        [field]="postField"
        [showLabel]="true"
        [noStriped]="true"
        [panelInput]="true"
        [disabled]="!this.app.post.hasEditPermission(app.panel.selectedItem)"
      ></app-field>
      <app-field
      *ngIf="
        postField === app.fieldId.post.taskNote &&
        app.panel.selectedItem.taskCompleted
        "
      [model]="app.panel.selectedItemModel"
      [field]="postField"
      [showLabel]="true"
      [noStriped]="true"
      [panelInput]="true"
      [disabled]="!app.permission.post.hasEditPermissionForTaskNote"
      ></app-field>
    </div>
  </div>
  <div class="attachments-container pt-2">
    <app-attachments 
      *ngIf="app.unlockedId === null" 
      [id]="app.panel.selectedItem._id" 
      type="post"
      [editPermission]="app.post.hasEditPermission(app.panel.selectedItem)"
      [files]="app.panel.selectedItem._attachments"
      [filesDetails]="app.panel.selectedItem.attachmentHistory">
    </app-attachments>
  </div>
</div>

<div class="panel-footer">
  <span> Activity </span>
  <div>
    <div>
      <app-credits class="credits" type="post" [isCreated]="true">
      </app-credits>
    </div>
    <div>
      <app-credits [ngClass]="{
            credits:
              app.panel.selectedItem.create_time !==
              app.panel.selectedItem.update_time
          }" type="post" [isEdited]="true"></app-credits>
    </div>
  </div>
  <div *ngIf="
        app.panel.selectedItem.taskCompleted &&
        app.panel.selectedItem.completedBy
      " class="complete-task">
    <i class="fa-duotone fa-circle-check complete-task-icon"></i>&nbsp;&nbsp;
    <app-user-link [name]="app.panel.selectedItem.completedBy" [type]="'post'"></app-user-link>
    completed this task on
    {{ app.panel.selectedItem.completedAt | date : "yyyy-MM-dd" }}
  </div>
</div>
