<app-create-manufacturer-modal
  #createManufacturer
  [modalMessage]="app.text.part.discard"
  [modalTitle]="app.text.part.modalTitle"
  (ok)="createManufacturer()"
></app-create-manufacturer-modal>

<div class="tableFixHead clear">
  <table class="table table-striped col-md-8">
    <thead>
      <tr>
        <th
          class="p-1 left-align"
          *ngFor="let column of matchedItemColumns"
          [ngClass]="{
            'text-center': column === 'datasheet'
          }"
        >
          {{ app.field.getTableLabel("manufacturer." + column) }}
          <!-- {{ app.field.getLabel("manufacturer." + column) }} -->
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          class="ps-1"
          *ngFor="let column of matchedItemColumns"
          [ngSwitch]="app.table.getColumnTypeDisplay(column)"
        >
          <div *ngSwitchCase="'optionText'" class="p-1">
            {{ app.manufacturer.currentManufacturer["manufacturer." + column] }}
          </div>

          <div
            *ngSwitchCase="'badge'"
            class="p-1 text-center badge"
            [ngStyle]="
              app.field.getColor(
                'manufacturer.' + column,
                app.manufacturer.currentManufacturer['manufacturer.' + column]
              )
            "
          >
            {{
              app.field.getOptionText(
                "manufacturer." + column,
                app.manufacturer.currentManufacturer["manufacturer." + column]
              )
            }}
          </div>

          <div *ngSwitchCase="'link'" class="p-1">
            <span>
              <a
                *ngIf="
                  app.manufacturer.currentManufacturer['manufacturer.' + column]
                "
                class="icon-blue"
                target="_blank"
                [href]="
                  app.manufacturer.currentManufacturer['manufacturer.' + column]
                "
              >
                <i class="fas fa-file"></i>
              </a>
            </span>
          </div>

          <div *ngSwitchCase="'normal'" class="text-left">
            {{ app.manufacturer.currentManufacturer["manufacturer." + column] }}
          </div>
          <div *ngSwitchCase="'navigateAction'">
            {{ app.manufacturer.currentManufacturer["manufacturer." + column] }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <br />

  <button
    class="btn btn-sm btn-clean pull-right mb-3"
    *ngIf="app.manufacturerUtils.substitutes.length != 0"
    (click)="app.manufacturerUtils.exportTables()"
  >
    <i class="fa fa-download"></i>&nbsp; {{ app.text.manufacturer.export }}
  </button>

  <table class="table table-striped">
    <thead>
      <tr>
        <th
          class="p-1 left-align"
          *ngFor="let column of cleanColumns"
          [ngClass]="{
            'text-center':
              column === 'datasheet' || column === 'createManufacturer'
          }"
        >
          <!-- sorting + labels -->
          <div *ngIf="column === 'createManufacturer'">
            <i class="fad fa-industry-alt"></i>
          </div>
          <div *ngIf="column !== 'createManufacturer'">
            <span
              (click)="
                app.table.toggleSortDirection('manufacturer.' + column);
                app.table.sort(app.manufacturerUtils.substitutes)
              "
            >
              {{ app.field.handleSpecialLogicLabels("manufacturer." + column) }}
            </span>
            <span *ngIf="'manufacturer.' + column === app.table.sortField">
              <i
                *ngIf="app.table.sortDirection === 1"
                class="fa fa-chevron-up"
              ></i>
              <i
                *ngIf="app.table.sortDirection === -1"
                class="fa fa-chevron-down"
              ></i>
            </span>
          </div>
          <!-- filtering on the table heads -->
          <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-table
              [column]="column"
              [columns]="cleanColumns"
              [docType]="'manufacturer'"
              [cleanDocs]="app.manufacturerUtils.cleanSubstitutes"
              [selectedFilters]="selectedFilters"
              (resultedDocs)="
                app.filterTable.getFilterResults($event, 'replacements')
              "
            >
            </app-filter-table>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let id of showPaginator
            ? app.paginator.getPage(app.manufacturerUtils.substitutes)
            : app.manufacturerUtils.substitutes
        "
        [ngClass]="{ isSelected: app.isSelected(id) }"
      >
        <td class="p-1 text-center" *ngFor="let column of cleanColumns">
          <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
            <div *ngSwitchCase="'navigateAction'">
              {{ id[column] }}
            </div>
            <div *ngSwitchCase="'optionText'">
              <span class="text-center">
                {{
                  app.field.getOptionText("manufacturer." + column, id[column])
                }}
              </span>
            </div>
            <div *ngSwitchCase="'textCenter'">
              <span *ngIf="showValue(column, id[column])" class="text-center">
                {{
                  app.field.getOptionText("manufacturer." + column, id[column])
                }}
              </span>
            </div>
            <div *ngSwitchCase="'badge'">
              <span
                class="badge text-center"
                [ngStyle]="
                  app.field.getColor('manufacturer.' + column, id[column])
                "
              >
                {{
                  app.field.getOptionText("manufacturer." + column, id[column])
                }}
              </span>
            </div>
            <div *ngSwitchCase="'link'">
              <a
                *ngIf="id[column]"
                class="icon-blue"
                target="_blank"
                [href]="id[column]"
              >
                <i class="fas fa-file"></i>
              </a>
            </div>
            <div *ngSwitchCase="'actions'">
              <a
                *ngIf="!id.isAdded"
                class="btn btn-sm btn-default-orange"
                (click)="
                  app.manufacturer.selectedManufacturer = id;
                  app.state.createFromPart = false;
                  createManufacturer.open()
                "
                [ngbTooltip]="app.text.manufacturer.createNewManufacturer"
              >
                <i class="fas fa-plus"></i>&nbsp;&nbsp;{{ app.text.app.add }}</a
              >
              <div *ngIf="id.isAdded" class="btn btn-sm btn-selected">
                {{ app.text.app.added }}
              </div>
            </div>
            <div *ngSwitchCase="'normal'">
              <span *ngIf="showValue(column, id[column])">
                {{ id[column] }}
              </span>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td
          *ngIf="showLoading && !showNoResults"
          colspan="99"
          class="text-center"
        >
          {{ app.text.manufacturer.loadingReplacement }}
        </td>
        <td *ngIf="showNoResults" colspan="99" class="text-center">
          {{ app.text.manufacturer.replacementsNoResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
