<ng-template #content let-modal>
    <div class="modal-header">
        <div class="col-md-12">
            <button class="btn pull-right" (click)="app.releaseNotesModal.close()">
                <i class="fa fa-times"></i>
            </button>
            <span class="modal-title fw-bold">
                LCM-Client Release Notes
            </span>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-md-12">
            <div *ngFor="let item of app.releaseNotesModal.releaseNotes" class="version-item">
                <div class="release-version">
                    {{app.text.releaseNotesModal.version}} {{item.version}}
                </div>
                <div *ngFor="let change of item.changes">
                    <div class="mb-3">
                        <div>
                            &#8226; {{change[app.language]}}
                        </div>
                        <div>
                            &nbsp; {{app.text.releaseNotesModal.bugs}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="fw-bold">
            {{ app.text.releaseNotesModal.support }}
        </div>
        <div>
            {{ app.text.releaseNotesModal.contact }}
        </div>
        <div>
            <p class="mb-1">Terrance Pope: <a href="mailto:terrance.pope@am-sys.com">terrance.pope&#64;am-sys.com</a></p>
            <p class="mb-1">Kuno Aescht: <a href="mailto:kuno.aescht@am-sys.com">kuno.aescht&#64;am-sys.com</a></p>
            <p class="mb-1">Andrei Gheorghe: <a href="mailto:andrei.gheorghe@am-sys.com">andrei.gheorghe&#64;am-sys.com</a></p>
        </div>
    </div>
</ng-template>