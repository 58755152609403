import { ComponentSettings, empty } from "../settings/settings";
import { field } from "./thread.settings";

const settings = {
  permission: {},
  text: {
    pcn: { de: "PCN", en: "PCN", nl: "PCN" },
    newPcn: { de: "PCN Generator", en: "PCN Generator", nl: "PCN Generator" },
    featureFields: { de: "Stammdaten", en: "Base Data", nl: "Stamdata" },
    save: {
      de: "Neuen Fall im Client anlegen",
      en: "Create new case  in Client",
      nl: "Nieuw Issue aanmelden",
    },
    delete: { de: "Löschen", en: "Delete Case", nl: "Issue verwijderen" },
    back: {
      de: "Zurück zur Inbox",
      en: "Back to Inbox",
      nl: "Terug naar Inbox",
    },
    artNumber: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    deleteQuestion: {
      de: "Möchten Sie diesen PCN/diese PCNs löschen? ",
      en: "Would you like to delete this PCN/PCN's ?",
      nl: "Wilt u deze PCN/PCN's verwijderen? ?",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    main: {
      de: "Stammdaten",
      en: "Master Data",
      nl: "Master data",
    },
    furtherData: {
      de: "Betroffene Einheiten",
      en: "Items Concerned",
      nl: "Overige data",
    },
    description: {
      de: "Beschreibung",
      en: "Description",
      nl: "Description",
    },
    files: {
      de: "Anhänge",
      en: "Attachments",
      nl: "Bijlagen",
    },
    backToNotif: {
      de: "Zurück zu Benachrichtigungen",
      en: "Back to notifications",
      nl: "Terug naar notificaties",
    },
    oDate: {
      de: "Veröffentlichung",
      en: "Issue Date",
      nl: "Publicatiedatum",
    },
    creator: {
      de: "Hersteller",
      en: "Manufacturer",
      nl: "Fabrikant",
    },
    pcnID: {
      de: "Identifikationsschlüssel (ID)",
      en: "Identification Key (ID)",
      nl: "Identificatiesleutel (ID)",
    },
    changeClasses: {
      de: "Kategorie",
      en: "Category",
      nl: "Categorie",
    },
    effectiveDate: {
      de: "Effective Date",
      en: "Effective Date",
      nl: "Ingangsdatum",
    },
    omfShortDescr: {
      de: "Bezeichnung",
      en: "Designation",
      nl: "Omschrijving",
    },
  },
  field,
  url: {},
  list: {
    columns: empty,
    featureFields: empty,
    boxesLeft: empty,
    main: empty,
    furtherData: empty,
    description: empty,
    files: empty,
  },
  additionalInfo: {},
};

export const pcn: ComponentSettings<typeof settings> = settings;
