import { AppType, APP_TYPE } from "../app.type";
import { Type } from "../../../../shared/components";
import {
  getIdsByType,
  deleteHistoryByDocType,
  deleteBulkByType,
  importDocsByType,
  getAllManufacturersCodesDocs,
} from "../api.service";
import { generateRandomDocs } from "./seed";
import { ImportServiceType } from "./import.service.type";
import { Inject, Injectable } from "@angular/core";
import { AlertData } from "../../../../shared/models/alert";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { Part } from "../../../../shared/models/part";
import { Message } from "../../../../shared/models/message";
import { Customer } from "../../../../shared/types/customers";
import { ProductCategory } from "../../../../shared/models/productCategory";
import { createChunk } from "../docs.util";
import { ManufacturerCode } from "../../../../shared/models/manufacturerCode";

export class ErrorColumn {
  fieldId = "";
  columnIndex = "";
  posibleValue: string[] = [];
}

@Injectable()
export class ImportService implements ImportServiceType {
  type: Type | any = null;
  step = "";
  docs: any[] = [];
  props: {
    key: string;
    label: string | undefined;
  }[] = [];
  fieldList: string[] = [];
  importedKeys = new Set<string>();
  invalidHeaderColumns: Set<{ key: string; possibleField: string[] }> =
    new Set();
  stepper: "selectType" | "uploadfile" | "reviewList" = "selectType";

  alertDocs: AlertData[] = [];
  manufacturerDocs: Manufacturer[] = [];
  partDocs: Part[] = [];
  messageDocs: Message[] = [];
  invalidKeys: ErrorColumn[] = [];
  duplicatedItems: string[] = [];
  selected = new Set<string>();
  showGenerate = false;
  savingStarted = false;
  importMessages: string[] = [];
  deleteStarted = false;
  allManufacturerDocs: ManufacturerCode[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get buffer() {
    return this.app.state.importBuffer;
  }

  getDocs(type = this.app.type) {
    try {
      if (type == null) {
        return {};
      }
      const buffer = this.app.state.importBuffer;
      if (buffer == null) {
        return {};
      }
      const docs = buffer.docs[type];
      if (docs == null) {
        return {};
      }
      return { ...docs };
    } catch (err) {
      return {};
    }
  }

  // countDocs(type: Type) {
  //   return this.app.docs.count(this.getDocs(type));
  // }

  clearDocs() {
    this.app.state.next({ importBuffer: { docs: {} } });
  }

  async prepareDocs() {
    const docs: any[] = [];
    const rows = this.app.import.buffer;
    const docsAfterImport: any[] = [];
    this.importedKeys.clear();
    this.showGenerate = false;
    this.props = this.app.field.getFieldListBasedOnType();
    Object.keys(rows).forEach((row) => {
      Object.keys(rows[row]).forEach((sheet) => {
        Object.keys(rows[row][sheet]).forEach((item) => {
          if (rows[row][sheet][item].length >= 1) {
            docsAfterImport.push(rows[row][sheet][item]);
          }
        });
      });
    });
    if (docsAfterImport.length > 1) {
      const [headerRow, ...docRows] = docsAfterImport;
      const cleanHeaderRow = Array.from(this.cleanHeaderRow(headerRow));
      if (this.invalidKeys.length > 0) {
        this.app.import.step = "error";
      } else {
        for (const docRow of docRows) {
          // create doc
          const doc: any = {};
          for (let i = 0; i < cleanHeaderRow.length; i++) {
            const key = cleanHeaderRow[i];
            let raw = "";
            if (docRow[i] !== undefined) {
              raw = docRow[i].toString();
              if (
                key === "Customer Part Number (CPN)" ||
                key === "RAD Item No."
              ) {
                raw = raw.trim();
              }
              if (raw == null) {
                continue;
              }
              try {
                const val = raw;
                doc[key] = val;
              } catch (err) {}
            }
          }
          // save doc with id
          // const rawId = docRow[0];
          const rawId = this.setIndex(docRow, cleanHeaderRow);

          try {
            const id = rawId.toString();
            doc["id"] = id.trim();
            docs.push(doc);
          } catch (err) {}
        }
        this.docs = docs;
        await this.prepareDocsToSave();
        return docs;
      }
    }
  }

  async prepareDocsToSave() {
    switch (this.type) {
      case "part":
        await this.app.part.generateParts(this.docs);
        return;
      case "assembly":
        const result = await this.app.part.generateAssembliesFromParts(
          this.docs
        );
        this.importedKeys.clear();
        this.importedKeys.add("partNumber");
        this.importedKeys.add("parts");
        this.app.import.step = "third";
        return result;
      case "manufacturer":
        const docs = await this.app.manufacturer.generateManufacturers(
          this.docs
        );
        this.stepper = "reviewList";
        this.app.import.step = "third";
        return docs;
      case "thread":
        return this.app.thread.generateThreads(this.docs);
      case "dinCodeResponsible":
        return this.app.dinCodeResponsible.generateResponsibles(this.docs);
      case "commodityGroupResponsible":
        return this.app.commodityGroupResponsible.generateCommodityGroupResponsibles(
          this.docs
        );
      case "internalItem":
        return this.app.internalItems.generateInternalItems(this.docs);
      case "impact":
        if (this.app.customers.expectCurrent === Customer.DB) {
          return this.app.impacts.generateImpactsFromVehicles(this.docs);
        } else {
          return this.app.impacts.generateImpacts(this.docs);
        }
      case "vehicleResponsible":
        return this.app.vehicleResponsible.generateVehicles(this.docs);
      case "productCategory":
        // remove id property
        // not needed for the structure of this type of doc
        this.docs.forEach((doc) => {
          if (doc.id) {
            delete doc.id;
          }
        });
        this.app.state.importBuffer.docs.data = this.docs;
        return this.app.state.importBuffer.docs.data;
      case "manufacturerCode":
        return this.generateManufacturerNames(this.docs);
    }
  }

  async generateManufacturerNames(docs: ManufacturerCode[]) {
    this.allManufacturerDocs = await getAllManufacturersCodesDocs();
    const documents: ManufacturerCode[] = [];
    docs.forEach(async (doc) => {
      const manufacutrerCode = new ManufacturerCode();
      /** check if exists in the already created documents */
      const duplicateDoc = documents.find(
        (e) => e["manufacturerName"] === doc["manufacturerName"]
      );

      this.props.forEach((field) => {
        const fieldName = doc[field.key];
        if (fieldName != null) {
          if (field.key === "cageCode") {
            manufacutrerCode[field.key] = [fieldName];
          } else {
            if (duplicateDoc != null) {
              duplicateDoc["cageCode"] = duplicateDoc["cageCode"].concat(
                doc["cageCode"]
              );
            } else {
              manufacutrerCode[field.key] = fieldName;
            }
          }
        }
      });
      manufacutrerCode.type = "manufacturerCode";
      delete manufacutrerCode._id;
      if (duplicateDoc == null) {
        documents.push(manufacutrerCode);
      }
    });

    if (this.allManufacturerDocs != null) {
      docs = this.updateExistingManufacturerCode(documents);
    } else {
      docs = documents;
    }

    this.docs = docs;
    (docs as []).forEach((doc: any) => {
      this.app.import.selected.add(doc.manufacturerName);
    });

    this.stepper = "reviewList";
    this.step = "third";
    return this.docs;
  }

  updateExistingManufacturerCode(documents: ManufacturerCode[]) {
    const updatedDocs: ManufacturerCode[] = [];
    documents.forEach((doc: ManufacturerCode) => {
      const index = this.allManufacturerDocs.findIndex(
        (d) => d.manufacturerName === doc.manufacturerName
      );
      // Update existing manufacturer code doc
      if (index !== -1) {
        const existingDoc = this.allManufacturerDocs[index];
        if (
          existingDoc["cageCode"].toString() != doc["cageCode"].toString() &&
          existingDoc["manufacturerName"] === doc["manufacturerName"]
        ) {
          doc._id = existingDoc._id;
          doc._rev = existingDoc._rev;
          updatedDocs.push(doc);
        }
      } else {
        // Create new manufacturer code doc
        updatedDocs.push(doc);
      }
    });

    return updatedDocs;
  }

  async prepareJsonDocs() {
    if (this.type !== "all-config") {
      this.props = this.app.field.getFieldListBasedOnType(this.type);
    }
    switch (this.type) {
      case "part":
        return await this.app.part.generatePartsForJsonImport(
          this.app.state.importBuffer.docs.data
        );
      case "manufacturer":
        return await this.app.manufacturer.generateManufacturersForJsonImport(
          this.app.state.importBuffer.docs.data
        );
      case "alert":
        return await this.app.alerts.generateAlertsForJsonImport(
          this.app.state.importBuffer.docs.data
        );
      case "message":
        return await this.app.message.generateMessagesForJsonImport(
          this.app.state.importBuffer.docs.data
        );
      case "all-config":
        return await this.generateAllConfigForJsonImport(
          this.app.state.importBuffer.docs.data
        );
    }
  }

  generateRandomDocs() {
    const docs = generateRandomDocs(this.app);
    this.app.state.next({ importBuffer: { docs } });
  }

  async saveAllDocsBasedOnType(data?: any[]) {
    this.savingStarted = true;
    if (data != null) {
      // only for json import
      this.docs = data;
    } else {
      this.app.import.docs = this.docs.filter((d) =>
        this.selected.has(d[this.getIndex()])
      );
    }
    this.app.spinner.showSpinner();
    switch (this.app.import.type) {
      case "all-config":
        if (this.partDocs.length > 0) {
          this.docs = this.partDocs;
          this.app.state.importBuffer.docs.data = this.docs;
          await this.saveDocsWithChunks("part");
        }
        if (this.manufacturerDocs.length > 0) {
          this.docs = this.manufacturerDocs;
          this.app.state.importBuffer.docs.data = this.docs;
          await this.saveDocsWithChunks("manufacturer");
        }
        if (this.alertDocs.length > 0) {
          this.docs = this.alertDocs;
          this.app.state.importBuffer.docs.data = this.docs;
          await this.saveDocsWithChunks("alert");
        }
        if (this.messageDocs.length > 0) {
          this.docs = this.messageDocs;
          this.app.state.importBuffer.docs.data = this.docs;
          await this.saveDocsWithChunks("message");
        }
        break;
      case "vehicleResponsible":
        this.docs = this.app.vehicleResponsible.docsToImport;
        await this.saveDocsWithChunks(this.app.import.type);
        break;
      case "part":
        await this.saveDocsWithChunks(this.app.import.type);
        this.showGenerate = false;
        break;
      case "assembly":
        this.docs.forEach((doc) => {
          doc.type = "part";
          if (doc.bom) {
            delete doc.bom;
          }
        });
        await this.saveDocsWithChunks("part");
        this.showGenerate = false;
        break;
      case "productCategory":
        const docToSave: ProductCategory = {
          type: "productCategory",
          categories: this.docs,
        };
        this.app.productCategory.saveProductCategoryDoc(docToSave);
        break;
      case "alert":
        break;
      default:
        if (this.docs) {
          await this.saveDocsWithChunks(this.app.import.type);
        }
        break;
    }

    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.import.documentsSavedSuccesfully,
    });
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 1000);
    this.app.spinner.hideSpinner();
    if (this.app.import.type === "assembly") {
      this.step = "third";
      this.app.import.step = "third";
      this.showGenerate = true;
    } else {
      this.step = "first";
      this.stepper = "selectType";
    }
    // this.app.import.type = null;
    this.app.import.docs = [];
    this.app.import.importedKeys.clear();
    this.app.import.type = null;
    this.importedKeys.clear();
  }

  async saveDocsWithChunks(type: any) {
    let chunkNumber = 1;
    let isDelete = false;
    let createHistory = false;
    if (this.docs.length > 0 && this.docs[0]._deleted) {
      isDelete = true;
    }
    this.importMessages.push(`Saving documents started, for type: ${type}!`);
    while (this.docs.length > 0) {
      const obj = createChunk(this.docs);
      const chunk = obj?.chunk;
      this.docs = obj?.newDocs;

      if (chunk == null) {
        return;
      }
      if (isDelete) {
        await deleteBulkByType(this.app.customers.expectCurrent, type, chunk);
      }
      if (!isDelete) {
        if (type === "part" || type === "manufacturer") {
          createHistory = true;
        }
      }
      await importDocsByType(
        this.app.customers.expectCurrent,
        type,
        createHistory,
        chunk
      );
      const message = `Chunk number ${chunkNumber} with a length of ${chunk.length} docs was saved!`;
      this.importMessages.push(message);
      chunkNumber++;
    }
    this.importMessages.push("Saving finished!");
    // generate tree only if were imported parts or manufacturers
    if (type === "part" || type === "manufacturer") {
      this.importMessages.push("Starting building bom!");
      await this.app.operations.triggerWork("build-bom");
      this.importMessages.push("Build bom finished!");
      this.importMessages.push("Starting generate tree!");
      await this.app.tree.generateTree();
      this.importMessages.push("Tree generation finished!");
    }
  }

  async deleteDocs(type: Type | "all-config") {
    this.app.spinner.showSpinner();
    if (type === "all-config") {
      const types: Type[] = ["part", "manufacturer", "alert", "message"];

      for (const t of types) {
        await this.deleteAllDocsBasedOnType(t);
      }
    } else {
      await this.deleteAllDocsBasedOnType(type);
    }
    this.app.spinner.hideSpinner();
    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.import.documentsDeletedSuccesfully,
    });
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 1000);
  }

  async deleteAllDocsBasedOnType(type: Type) {
    try {
      this.app.import.importMessages = [];
      this.deleteStarted = true;
      this.app.import.importMessages.push(
        `Delete docs started, for type ${type}!`
      );
      const alldocs = await this.getDocsToBeDeleted(type);
      if (alldocs.length === 0) {
        this.app.import.importMessages.push(
          "No documents of selected type in our database!"
        );
        return;
      }
      const docsToBeDeleted: any[] = [];
      alldocs.forEach((doc: { id: string; value: string }) => {
        const newDoc = {
          _id: doc.id,
          _rev: doc.value,
          _deleted: true,
        };
        docsToBeDeleted.push(newDoc);
      });
      this.docs = docsToBeDeleted;
      await this.saveDocsWithChunks(type);
      await this.deleteHistory(type);
      this.app.import.importMessages.push("Delete docs finished!");
    } catch (err) {
      console.log(err);
    }
  }

  private async deleteHistory(type: string) {
    this.app.import.importMessages.push("Delete history docs started!");
    this.app.import.importMessages.push("Please wait...");
    await deleteHistoryByDocType(this.app.customers.expectCurrent, type);
    this.app.import.importMessages.push("Delete of history docs finished!");
  }

  async getDocsToBeDeleted(type: Type) {
    const docs = await getIdsByType(this.app.customers.expectCurrent, type);
    let allDocs: any[] = [];

    /** if type = thread, delete all the posts and impacts  */
    if (type === "thread") {
      const posts = await getIdsByType(
        this.app.customers.expectCurrent,
        "post"
      );
      const impacts = await getIdsByType(
        this.app.customers.expectCurrent,
        "impact"
      );
      docs.forEach((thread) => {
        allDocs.push(thread);
      });
      posts.forEach((post) => {
        allDocs.push(post);
      });
      impacts.forEach((impact) => {
        allDocs.push(impact);
      });
    } else {
      allDocs = docs;
    }
    return allDocs;
  }

  setIndex(docRow: string[], cleanHeaderRow: string[]) {
    switch (this.app.import.type) {
      case "assembly":
      case "part":
        return cleanHeaderRow.findIndex((key) => key === "partNumber");
      case "thread":
        return cleanHeaderRow.findIndex((key) => key === "omfNumber");
      case "manufacturer":
        return cleanHeaderRow.findIndex(
          (key) => key === "manufacturerPartNumberRaw"
        );
      case "commodityGroupResponsible":
        return cleanHeaderRow.findIndex(
          (key) => key === "commodityGroupGlobalId"
        );
      case "vehicleResponsile":
        return cleanHeaderRow.findIndex((key) => key === "vehicleName");
      case "impact":
        return cleanHeaderRow.findIndex((key) => key === "omfVehicleName");
      default:
        return docRow[0];
    }
  }

  getIndex() {
    switch (this.app.import.type) {
      case "assembly":
      case "part":
        return "partNumber";
      case "thread":
        return "omfNumber";
      case "manufacturer":
        return "manufacturerPartNumberRaw";
      case "commodityGroupResponsible":
        return "commodityGroupGlobalId";
      case "dinCode":
        return "dinCode";
      case "vehicleResponsible":
        return "vehicleName";
      case "impact":
        return "omfVehicleName";
      case "manufacturerCode":
        return "manufacturerName";
      default:
        return "";
    }
  }

  cleanHeaderRow(rows: any[]) {
    this.importedKeys.clear();
    this.props = this.app.field.getFieldListBasedOnType();
    if (
      (this.app.import.type === "vehicleResponsible" ||
        this.app.import.type === "impact") &&
      this.app.customers.expectCurrent === Customer.DB
    ) {
      this.props = this.setProperties(this.props);
    }
    rows.forEach((key, i) => {
      let cleanKey = "";

      /** added exception for vehicle name because the label for the vehicle name also includes '-' => Vehicle-Name */
      // ["Vehicle", "Name\r\n", "omfVehicleName", ""];
      // ["Fahrzeug\r\n", "omfVehicleName", ""];
      const splitKey = key.split("-");
      if (
        splitKey[2] === "omfVehicleName" ||
        splitKey[2] === "entryCheckbox" ||
        splitKey[2] === "omApproach"
      ) {
        cleanKey = splitKey[2];
      } else {
        cleanKey = splitKey[1];
      }

      this.props.forEach((prop) => {
        if (prop.label === undefined) {
          return;
        }
        if (prop.key === cleanKey) {
          this.importedKeys.add(cleanKey);
        }
      });
      if (this.props.findIndex((field) => field.key === cleanKey) === -1) {
        const newError = new ErrorColumn();
        newError.columnIndex = (i + 1).toString();
        newError.fieldId = cleanKey;

        const possibleKeys = this.props
          .filter((field) => cleanKey.includes(field.key))
          .map((field) => field.key);
        possibleKeys.forEach((k) => {
          newError.posibleValue.push(k);
        });
        const index = this.invalidKeys.findIndex(
          (error: ErrorColumn) => error.fieldId === cleanKey
        );
        if (index !== -1) {
          return;
        } else {
          this.invalidKeys.push(newError);
        }
      }
    });
    if (this.app.import.type === "thread") {
      // delete one of the cells because artNumber = partNumber and they are exactly the same
      this.importedKeys.delete("partNumber");
    }
    return this.importedKeys;
  }

  async generateAllConfigForJsonImport(docs: any) {
    this.alertDocs = await this.app.alerts.generateAlertsForJsonImport(
      docs.filter((doc: AlertData) => doc.type === "alert")
    );
    this.partDocs = await this.app.part.generatePartsForJsonImport(
      docs.filter((doc: Part) => doc.type === "part")
    );
    this.manufacturerDocs =
      await this.app.manufacturer.generateManufacturersForJsonImport(
        docs.filter((doc: Manufacturer) => doc.type === "manufacturer")
      );
    this.messageDocs = await this.app.message.generateMessagesForJsonImport(
      docs.filter((doc: Message) => doc.type === "message")
    );
  }

  async checkIfExists(data: any, doc: any) {
    const dataDoc = data.find((d: any) => d._id === doc._id);
    return dataDoc;
  }

  markAsExisting(doc: any) {
    if (doc.type === "manufacturer") {
      if (
        this.app.manufacturer.existingManufacturers.findIndex(
          (manufacturer) =>
            manufacturer.nameRaw === doc.nameRaw &&
            manufacturer.manufacturerPartNumberRaw ===
              doc.manufacturerPartNumberRaw &&
            manufacturer.partNumber === doc.partNumber
        ) !== -1
      ) {
        return true;
      }
    }
    if (doc.type === "vehicleResponsible") {
      if (
        this.app.vehicleResponsible.existingVNames.findIndex(
          (vehicleName) => vehicleName === doc.vehicleName
        ) !== -1
      ) {
        return true;
      }
    }
    return false;
  }

  toggleDoc(doc: any, action: string) {
    // Used for Assembly -> to verify if the parts object, contains any inavlid partId
    if (doc.bom != null) {
      doc.bom.forEach((partId: string) => {
        if (this.app.part.checkPartNumberCharacters(partId)) {
          this.handleInvalidPartNumbers(
            partId,
            action === "add",
            doc["partNumber"]
          );
        }
      });
    }

    const index = doc[this.getIndex()];
    switch (action) {
      case "remove":
        this.selected.delete(index);
        this.handleInvalidPartNumbers(index, false, doc["partNumber"]);
        break;
      case "add":
        this.selected.add(index);
        this.handleInvalidPartNumbers(index, true, doc["partNumber"]);
        break;
    }
  }

  handleInvalidPartNumbers(
    index: string,
    isAdding: boolean,
    partNumber: string
  ) {
    if (this.app.import.type === "manufacturer") {
      index = partNumber;
    }
    if (this.app.part.checkPartNumberCharacters(index)) {
      if (isAdding) {
        this.app.part.invalidPartNumbers.push(index);
      } else {
        this.app.part.invalidPartNumbers =
          this.app.part.invalidPartNumbers.filter((item) => item !== index);
      }

      let hasInvalidSelectedPart = this.app.part.invalidPartNumbers.some(
        (partId: string) => this.selected.has(partId)
      );

      this.app.part.hasInvalidCPNs = hasInvalidSelectedPart;
    }
  }

  checkIfSelected(doc: any) {
    const index = this.getIndex();
    const result = this.selected.has((doc as Type)[index as any]);
    return result;
  }

  setProperties(props: any[]) {
    const vehicleProps = this.app.field.getFieldListBasedOnType("responsibles");
    vehicleProps.forEach((prop) => {
      props.push(prop);
    });
    return props;
  }
}
