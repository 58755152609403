import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { getApp } from "../app";
import { RM_CLIENT } from "../../../../shared/constants/customers";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  getThreadsCompressed,
  searchDataProviderManufacturer,
} from "../api.service";
import {
  Manufacturer,
  SeMatch,
  SeMatchingResult,
  SeSearchPart,
} from "../../../../shared/models/manufacturer";
import { DataProvider } from "../../../../shared/constants/dataProvider";
import { Z2DataMatch } from "../../../../shared/models/dataProviders/z2data";

@Component({
  selector: "app-external-data-filter",
  templateUrl: "./external-data-filter.component.html",
  styleUrls: ["./external-data-filter.component.scss"],
})
export class ExternalDataFilterComponent implements OnInit {
  redoSearch: string | null = null;
  partNumber = "";
  columns: string[] = [];
  columnRows: string[] = [];

  manufacturerId: string | null = "";
  selectedFilters: { columnName: string; value: string }[] = [];
  resetFilterInputs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  resetFilterInputsObservable: Observable<boolean> =
    this.resetFilterInputs.asObservable();
  private redoSearchSubscription: Subscription = new Subscription();
  @Output() searchResult = new EventEmitter<SeMatchingResult[]>();

  constructor(private route: ActivatedRoute) {}
  app = getApp((app) => {
    this.app = app;
  });

  async ngOnInit() {
    this.redoSearchSubscription =
      this.app.externalDataFilter.redoSearch.subscribe(async (value) => {
        if (value) {
          this.redoSearch = sessionStorage.getItem("se_filter_cpn");
          if (this.redoSearch !== null) {
            await this.searchManufacturer(this.redoSearch);
          }
          sessionStorage.removeItem("se_filter_cpn");
        }
      });

    // if the se_filter_cpn is set, display the old seach result
    const seFilterCpn = sessionStorage.getItem("se_filter_cpn");
    if (seFilterCpn != null) {
      this.app.manufacturerMatching.manufacturer.matches =
        this.app.externalDataFilter.matchesCopy;
      this.partNumber = seFilterCpn != null ? seFilterCpn : "";
      sessionStorage.removeItem("se_filter_cpn");
    }
    /** get the list of parts that are not cpns so that they can be set
     * when creating a new manufacturer from external-data-filter results
     */
    // await this.app.part.getPartsWithoutCpns();

    /** if a search has been done and you navigate between the pages
     * the "redo search" has to happen again, since they get lost
     */
    this.redoSearch = sessionStorage.getItem("redoSearch");
    if (this.redoSearch !== null) {
      await this.searchManufacturer(this.redoSearch);
    }
    sessionStorage.removeItem("redoSearch");
    this.manufacturerId = this.route.snapshot.paramMap.get("manufacturer");
    this.getColumns();
    this.app.thread.threadsCompressed = await getThreadsCompressed(
      this.app.customers.expectCurrent,
      "thread"
    );
    this.app.typeAheadBasic.model["manufacturer.partNumber"] = "";
  }

  /** start the search */
  async searchManufacturer(partNumber: string) {
    this.app.externalDataFilter.searchedPartNumber = partNumber;
    this.app.spinner.showSpinner();
    let result: any = null;

    //use dataProviderId in the search if you are on the matching page (in order to add the isAddedproperty to true)
    if (this.app.view === "matching") {
      let dataProviderId =
        this.app.customers.dataProvider === DataProvider.Z2DATA
          ? this.app.manufacturerMatching.manufacturer.z2DataId
          : this.app.manufacturerMatching.manufacturer.seId;

      result = await searchDataProviderManufacturer(
        this.app.customers.dataProvider,
        this.app.customers.expectCurrent,
        partNumber,
        dataProviderId
      );
    } else {
      result = await searchDataProviderManufacturer(
        this.app.customers.dataProvider,
        this.app.customers.expectCurrent,
        partNumber
      );
    }

    this.app.externalDataFilter.getSearchResults(result);

    this.resetFilterComponent();
    this.app.spinner.hideSpinner();
    this.searchResult.emit(result);
  }

  getColumns() {
    this.columns = this.app.list.matchingManufacturer.columns;
    this.columnRows = this.app.list.matchingManufacturer.columnRows;

    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      this.columns = this.app.list.matchingManufacturer.z2DataColumns;
      this.columnRows = this.app.list.matchingManufacturer.z2DataColumnRows;
    }

    if (this.manufacturerId == null || this.manufacturerId == undefined) {
      this.app.type =
        this.app.customers.dataProvider === DataProvider.Z2DATA
          ? "z2DataMatch"
          : "seMatch";
      this.columnRows.splice(
        this.columnRows.findIndex((i) => i === "select"),
        1
      );
      this.columns.splice(
        this.columns.findIndex((i) => i === "select"),
        1
      );
      this.columnRows.push("createCase");
      this.columns.push("createCase");
      if (this.app.customers.getRMCustomers() === RM_CLIENT) {
        this.columns.unshift("createManufacturer");
        this.columnRows.unshift("createManufacturer");
      }
    } else {
      this.app.type = "manufacturer";
    }
  }

  showValue(column: string, value: string) {
    if (column === "YEOL" && value === "0.0") {
      return false;
    }
    return true;
  }

  resetFilterComponent() {
    this.selectedFilters = [];
    this.resetFilterInputs.next(true);
  }

  showDefaultBtn(manufacturer: Manufacturer, match: SeMatch | Z2DataMatch) {
    switch (this.app.customers.dataProvider) {
      case DataProvider.SE:
        if (manufacturer.seId === match.ComID) {
          return false;
        } else {
          return true;
        }
      case DataProvider.Z2DATA:
        if (manufacturer.z2DataId === match.PartID.toString()) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  ngOnDestroy(): void {
    this.app.manufacturerMatching.manufacturer.matches = [];

    if (this.redoSearchSubscription) {
      this.redoSearchSubscription.unsubscribe();
    }
  }
}
