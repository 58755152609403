import { getDinCodeOptions } from "../models/dinCodes";
import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  dinCode: {
    type: FieldTypes.options,
    text: {
      de: "Din Code",
      en: "Din Code",
      nl: "DIN-code",
    },
    options: getDinCodeOptions(),
    required: true,
  },
  responsibleFirstName: {
    type: FieldTypes.value,
    text: { de: "Vorname", en: "First name", nl: "Voornaam" },
  },
  responsibleLastName: {
    type: FieldTypes.value,
    text: { de: "Nachname", en: "Last name", nl: "Achternaam" },
  },
  responsibleDepartment: {
    type: FieldTypes.value,
    text: { de: "Abteilung", en: "Department", nl: "Afdeling" },
  },
  responsibleEmail: {
    type: FieldTypes.value,
    text: { de: "Email", en: "Email", nl: "e-mail" },
  },
  responsiblePhone: {
    type: FieldTypes.value,
    text: { de: "Telefon", en: "Phone", nl: "Phone" },
  },
  riskIO: {
    text: { de: "IO", en: "IO", nl: "IO" },
    type: FieldTypes.checkbox,
  },
  riskSK: {
    text: { de: "SK", en: "SK", nl: "SK" },
    type: FieldTypes.checkbox,
  },
  riskBK: {
    text: { de: "BK", en: "BK", nl: "BK" },
    type: FieldTypes.checkbox,
  },
  riskUE: {
    text: { de: "UE", en: "UE", nl: "UE" },
    type: FieldTypes.checkbox,
  },
};

const settings = {
  permission: {},
  text: {
    dinCodeResponsible: {
      de: "Din Code Verantwortliche/r",
      en: "Din Code Responsible",
      nl: "DIN-code verantwoordelijk",
    },
  },
  url: {},
  field,
  list: {},
  additionalInfo: {},
};

export const dinCodeResponsible: ComponentSettings<typeof settings> = settings;
