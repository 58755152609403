import { Component, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../app";
import { ActivatedRoute } from "@angular/router";
import { User, UserOptions } from "../../../../shared/models/user";
import { Subscription } from "rxjs";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit, OnDestroy {
  userName = "";
  app = getApp((app) => {
    this.app = app;
  });
  ids: string[] = [];
  showEmailsBox = false;
  private _routeParamsSubscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this._routeParamsSubscription = this.route.params.subscribe(
      async (params) => {
        if (params.user) {
          this.app.users.currentUserName = params.user;
          this.app.users.getUserById(params.user);
          /** used for navigation between users */
          await this.app.users.getUsersWithRoles();
          this.ids = this.app.users.userRoles.map(
            (user: UserOptions) => user.name
          );
        }
        this.app.users.setRequiredFields();
      }
    );

    this.showEmailPreferencesBox();
  }

  getListOfFields(box: string) {
    const list = new Set(this.app.getList(box));
    const currentUser = this.app.users.currentUserModel;

    if (currentUser[this.app.fieldId.user.passwordNeverExpire] === true) {
      list.delete(this.app.fieldId.user.passwordExpireDate);
      currentUser[this.app.fieldId.user.passwordExpireDate] = "";
      this.app.field.getFieldSettings(
        this.app.fieldId.user.passwordExpireDate
      ).required = false;
    } else {
      list.add(this.app.fieldId.user.passwordExpireDate);
      this.app.field.getFieldSettings(
        this.app.fieldId.user.passwordExpireDate
      ).required = true;
      this.app.users.setPasswordExpireDate();
    }

    if (this.app.customer === Customer.DB) {
      if (
        currentUser[this.app.fieldId.user.roles] !== undefined &&
        currentUser[this.app.fieldId.user.roles].includes(
          `${this.app.customers.expectCurrent}-user`
        )
      ) {
        list.add(this.app.fieldId.user.displayAsComponent);
      } else {
        list.delete(this.app.fieldId.user.displayAsComponent);
      }
    }

    return Array.from(list);
  }

  showEmailPreferencesBox() {
    if (
      !this.app.users.isNew &&
      !this.app.auth.isTeam &&
      this.app.customers.expectCurrent !== Customer.OC
    ) {
      this.showEmailsBox = true;
    }
  }

  discardChanges() {
    this.app.unlockedId = null;
    this.app.users.currentUserModel = {} as User;

    Object.assign(
      this.app.users.currentUserModel,
      this.app.users.cleanUserModel
    );

    this.app.userSettings.currentUserEmailPreferences = [
      ...this.app.userSettings.cleanUserEmailPreferences,
    ];

    if (this.app.users.isNew) {
      this.app.users.currentUser = {} as User;
      this.app.routing.navigateUsers();
    }

    this.app.field.inputSubject.next(true);
  }

  /** Fix for logging out while on user page, reset the fields for the login page */
  ngOnDestroy(): void {
    const fieldId = this.app.fieldId.user.name;
    this.app.field.resetAllRequiredFieldSettings("user");
    this.app.field.getFieldSettings(fieldId).mutable = true;
    this.app.field.getFieldSettings("user.name").required = true;
    this.app.field.getFieldSettings("user.name").regex = "";
    this.app.field.getFieldSettings("user.password").required = true;

    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
  }
}
