import { countryCodes } from "../models/countryCodes";
import {
  ComponentSettings,
  empty,
  FieldTypes,
  FilterTypes,
} from "../settings/settings";
import { field, omfStatuses } from "./thread.settings";
import { typeCategories } from "../typeCategories";
import { getDinCodeOptions } from "../models/dinCodes";

export const fields = Object.assign({}, field);

fields.title = {
  xml: "pcnTitle",
  text: {
    de: "PCN/PDN-Titel",
    en: "Title of PCN/PDN",
    nl: "Titel van PCN/PDN",
  },
  required: true,
  maxLength: 256,
  type: field.title.type,
  rows: 1,
  tooltip: {
    de: "kürzer beschreibender Text der PCN/PDN",
    en: "Short descriptive text of the PCN / PDN",
    nl: "Korte beschrijvende tekst van het PCN / PDN",
  },
};
fields.creator = {
  xml: "pcnCompany",
  text: {
    de: "Firmenbezeichnung",
    en: "Company Name",
    nl: "Leverancier",
  },
  maxLength: 128,
  required: true,
  type: field.company.type,
  tooltip: {
    de: "Vollständiger Firmenname des Herstellers",
    en: "Full company name of the manufacturer",
    nl: "Volledige bedrijfsnaam van de fabrikant",
  },
};
fields.crtPostCode = {
  text: { de: "Postleitzahl", en: "Postal Code", nl: "Postcode" },
  required: true,
  type: field.crtPostCode.type,
  tooltip: {
    de: "Angabe Postleitzahl des Herstellers",
    en: "Specification postal Code of the manufacturer",
    nl: "Specificatie postcode van de fabrikant",
  },
};
fields.pcnID = {
  xml: "pcnNumber",
  text: {
    de: "Identifikationsschlüssel (ID)",
    en: "Identification Key (ID)",
    nl: "Identificatiesleutel (ID)",
  },
  maxLength: 128,
  required: true,
  type: field.pcnID.type,
  tooltip: {
    de: "Eindeutiger Identifikationsschlüssel der Änderung oder Abkündigung. Nur Buchstaben und Zahlen verwenden, keine Sonder- und Leerzeichen",
    en: "Unique identification key of the change or discontinuance notice. Use only letters and numbers, not spaces and special character",
    nl: "Unieke identificatiesleutel van het bericht van wijziging of beëindiging. Gebruik alleen letters en cijfers, geen spaties en speciale tekens",
  },
};
fields.crtCountry = {
  text: {
    de: "Ländercode",
    en: "Country Code",
    nl: "Land Code",
  },
  required: true,
  type: FieldTypes.options,
  tooltip: {
    de: "Angabe Ländercode nach ISO 3166-1 des Herstellers",
    en: "Specification country code according to ISO 3166-1 of the manufacturer",
    nl: "Specificatie landcode volgens ISO 3166-1 van de fabrikant",
  },
  options: countryCodes,
};
fields.crtNumber = {
  xml: "itemMfrNumber",
  text: {
    de: "Eindeutige Hersteller-Teilenummer",
    en: "Unique Manufacturer Part Number",
    nl: "Uniek onderdeelnummer van de fabrikant",
  },
  unique: false,
  maxLength: 128,
  filter: FilterTypes.startsWith,
  type: FieldTypes.value,
  required: true,
  tooltip: {
    de: "Angabe der (eindeutigen) Herstellerteilenummer",
    en: "Specification of the (unique) manufacturer part number",
    nl: "Specificatie van het (unieke) onderdeelnummer van de fabrikant",
  },
};
fields.omfShortDescr = {
  xml: "itemMfrName",
  text: {
    de: "Bezeichnung der Einheit",
    en: "Designation of the Item",
    nl: "Benaming van het voorwerp",
  },
  maxLength: 128,
  required: true,
  type: field.omfShortDescr.type,
  tooltip: {
    de: "Name der Einheit gemäß Zeichnung, Datenblatt, techn. Spezifikation, Ersatzteilkatalog.\n Bsp: Zylinder 50 cm, 24 V, 50 Hz, Temp. -15 - +50 °C",
    en: "Name of item according to drawing, data sheet, techn. specification or spare parts catalogue.\n ex.: Cylinder 50 cm, 24 V, 50 Hz, temp. -15 - +50 °C",
    nl: "Benaming van het artikel volgens tekening, gegevensblad, technische specificatie of onderdelencatalogus.\n ex.: Cilinder 50 cm, 24 V, 50 Hz, temp. -15 - +50 °C",
  },
};
fields.drawNumber = {
  xml: "itemMfrTypeIdent",
  text: {
    de: "Typenbezeichnung und / oder Zeichnung",
    en: "Type designation and / or drawing",
    nl: "Typeaanduiding en / of tekening",
  },
  maxLength: 128,
  type: field.omfShortDescr.type,
  tooltip: {
    de: "Angabe einer zusätzlichen oder alternativen Identifizierung der betroffenen Einheit",
    en: "Additional or alternative identification measures of the item concerned",
    nl: 'Aanvullende of alternatieve identificatiemaatregelen van het betrokken artikel".',
  },
};
fields.replmanfItemnumber = {
  xml: "replmanfItemnumber",
  text: {
    de: "Vorgeschlagene Ersatzteilnummer des Herstellers",
    en: "Proposed replacement manufacturer part number",
    nl: "Voorgesteld onderdeelnummer van de vervangingsfabrikant",
  },
  maxLength: 128,
  type: field.replmanfItemnumber.type,
  tooltip: {
    de: "Angabe einer oder mehrerer Ersatz-Herstellerteilenummer als Vorschlag des Herstellers",
    en: "Specification of one or more replacement manufacturer part numbers as suggested by the manufacturer",
    nl: "Specificatie van een of meer vervangende onderdeelnummers van de fabrikant zoals voorgesteld door de fabrikant",
  },
};
fields.dinCode = {
  text: {
    de: "Din Code",
    en: "Din Code",
    nl: "Din Code",
  },
  options: getDinCodeOptions((code) => code),
  required: false,
  type: field.dinCode.type,
  tooltip: {
    de: "Din Code",
    en: "Din Code",
    nl: "Din Code",
  },
};
fields.dinText = {
  text: {
    de: "Din Text",
    en: "Din Text",
    nl: "Din Tekst",
  },
  options: getDinCodeOptions((code, text) => text),
  required: false,
  type: field.dinText.type,
  tooltip: {
    de: "Din Text",
    en: "Din Text",
    nl: "Din Tekst",
  },
};
fields.changeClasses = {
  required: true,
  xml: "itemChangeType",
  text: {
    de: "Kategorie der Änderung / Abkündigung",
    en: "Category of change / discontinuation",
    nl: "Categorie van wijziging / beëindiging",
  },
  multiple: true,
  sort: true,
  options: typeCategories,
  type: field.changeClasses.type,
  tooltip: {
    de: "Einteilung in verschiedene Kategorien, die als Kurzform die Art der Veränderungen angeben. - Mehrfachauswahl möglich ",
    en: "Grouping into different categories stating the type of changes in a short phrase. - Multiple selection possible.",
    nl: "Groepering in verschillende categorieën met vermelding van het soort veranderingen in een korte zin. - Meervoudige selectie mogelijk.",
  },
};
fields.identificationMethod = {
  xml: "pcnChangeIdentificationMethod",
  text: {
    de: "Identifikation der geänderten Einheit(en)",
    en: "Identification of the changed item(s)",
    nl: "Identificatie van de gewijzigde items",
  },
  maxLength: 2048,
  type: field.identificationMethod.type,
  tooltip: {
    de: "Beschreibung wie geänderte Produkte Identifiziert werden können",
    en: "Description of how changed products can be identified",
    nl: "Beschrijving van de wijze waarop gewijzigde producten kunnen worden geïdentificeerd",
  },
};
fields.omfStatus = {
  text: {
    de: "Status",
    en: "Status",
    nl: "Status",
  },
  options: omfStatuses,
  required: false,
  // mutable: false,
  type: field.omfStatus.type,
};
fields.omfNumber = {
  text: {
    de: "Fall-Nummer",
    en: "Case Number",
    nl: "Issue Nummer",
  },
  required: false,
  mutable: false,
  format: {
    text: "OMF",
    values: { number: { at: 3, digits: 4, fixed: false } },
    regex: "^(OMF)([0-9]{4})$", //default omf number format
    textInFilter: "",
  },
  type: field.omfNumber.type,
};
fields.creator_class = {
  type: field.creator_class.type,
  xml: "pcnType",
  required: true,
  text: {
    de: "Art der Mitteilung",
    en: "Type of Notice",
    nl: "Type Notificatie",
  },
  options: {
    PCN: {
      text: {
        de: "Änderungsmitteilung",
        en: "Product Change Notice",
        nl: "Productwijziging",
      },
    },
    PDN: {
      text: {
        de: "Abkündigungsmitteilung",
        en: "Product Discontinuance Notice",
        nl: "Productbeëindiging",
      },
    },
  },
  tooltip: {
    de: "Angabe ob Änderung oder Abkündigung",
    en: "Indication if change or discontinuation",
    nl: "Indicatie indien wijziging of stopzetting",
  },
};
fields.crtState = {
  type: field.crtState.type,
  text: { de: "Bundesland", en: "State", nl: "Provincie / Staat" },
  tooltip: {
    de: "Angabe Bundesstaat des Herstellers",
    en: "Specification state of the manufacturer",
    nl: "Specificatie toestand van de fabrikant",
  },
};
fields.crtStreet = {
  type: field.crtStreet.type,
  required: true,
  text: { de: "Straße", en: "Street", nl: "Straat" },
  tooltip: {
    de: "Angabe Strasse des Herstellers",
    en: "Specification street of the manufacturer",
    nl: "Specificatie straat van de fabrikant",
  },
};
fields.crtCity = {
  type: field.crtCity.type,
  required: true,
  text: { de: "Stadt", en: "City", nl: "Stad" },
  tooltip: {
    de: "Angabe Stadt des Herstellers",
    en: "Specification city of the manufacturer",
    nl: "Specificatie stad van de fabrikant",
  },
};
fields.pcnRevision = {
  type: field.pcnRevision.type,
  xml: "pcnRevision",
  text: {
    de: "Revisionsnummer",
    en: "Revision number",
    nl: "Revisie nummer",
  },
  maxLength: 16,
  tooltip: {
    de: "Angabe einer Revisionsnummer als Ergänzung zum Identifikationsschlüssel (ID)",
    en: "Revision number as a supplement to the identification key (ID)",
    nl: "Revisienummer als aanvulling op de identificatiesleutel (ID)",
  },
};
fields.oDate = {
  xml: "issueDate",
  required: true,
  type: field.oDate.type,
  text: {
    de: "Datum der Veröffentlichung",
    en: "Date of publication",
    nl: "Publicatiedatum",
  },
};
fields.typeIdNum = {
  type: field.typeIdNum.type,
  xml: "itemRev",
  text: {
    de: "Revisionsstand",
    en: "Revision status",
    nl: "Revisie status",
  },
  maxLength: 32,
  tooltip: {
    de: "Revisionsstand der betroffenen Einheit",
    en: "Revision status of the item concerned",
    nl: "Herzieningsstatus van het betrokken artikel",
  },
};
fields.itemSubData = {
  xml: "itemSubData",
  type: field.itemSubData.type,
  text: {
    de: "Weitere Merkmale",
    en: "Other identifying measures",
    nl: "Andere identificerende kenmerken",
  },
  maxLength: 2048,
  tooltip: {
    de: `a. interne Kunden-Artikelnummer b. wenn zutreffend verwendete Normung`,
    en: `a. internal customer part number b. if applicable used norm/standard`,
    nl: `a. intern onderdeelnummer van de klant b. indien van toepassing gebruikte norm/norm`,
  },
};
fields.itemEOP = {
  type: field.itemEOP.type,
  xml: "itemEOPeffDate",
  text: {
    de: "Einstellung der Produktion (EOP) / Effective Date",
    en: "End of Production (EOP) / Effective Date",
    nl: "Eind van de productie (EOP) / Ingangsdatum",
  },
  required: true,
};
fields.productCategory = {
  type: field.productCategory.type,
  xml: "itemCategory",
  required: true,
  tooltip: {
    de: "Grobe Kategorisierung der Einheit zur regelbasierten Verarbeitung",
    en: "Broad categorisation of the item for rule-based processing",
    nl: "Brede categorisering van het artikel voor verwerking op basis van regels",
  },
  options: {
    ACEL: {
      text: {
        de: "Aktive Elektronik",
        en: "Active Electronic",
        nl: "Actieve Elektronica",
      },
    },
    DACE: {
      text: {
        de: "Daten / Zertifikat",
        en: "Data / Certificates",
        nl: "Data / Certificaten",
      },
    },
    SERV: {
      text: {
        de: "Dienstleistung",
        en: "Services",
        nl: "Diensten",
      },
    },
    DOCU: {
      text: {
        de: "Dokumentation",
        en: "Documentation",
        nl: "Documentatie",
      },
    },
    ELME: {
      text: {
        de: "Elektromechanik",
        en: "Electro Mechanical",
        nl: "Elektromechanica",
      },
    },
    FLUI: {
      text: {
        de: "Fluid",
        en: "Fluids",
        nl: "Vloeistoffen",
      },
    },
    AUXM: {
      text: {
        de: "Hilfsmaterial",
        en: "Auxiliary Materials",
        nl: "Hulpmateriaal",
      },
    },
    HYDR: {
      text: {
        de: "Hydraulik",
        en: "Hydraulic",
        nl: "Hydraulisch",
      },
    },
    MECH: {
      text: {
        de: "Mechanik",
        en: "Mechanical",
        nl: "Mechanisch",
      },
    },
    MULT: {
      text: {
        de: "Mehrere Kategorien",
        en: "Multiple Categories",
        nl: "Verschillende Categorieën",
      },
    },
    PAEL: {
      text: {
        de: "Passive Elektrik / Elektronik",
        en: "Passive Electrical / Electronic",
        nl: "Passieve Elektrisch / Elektronisch",
      },
    },
    PNEU: {
      text: {
        de: "Pneumatik",
        en: "Pneumatic",
        nl: "Pneumatisch",
      },
    },
    RAWM: {
      text: {
        de: "Rohmaterial",
        en: "Raw Material",
        nl: "Basismateriaal",
      },
    },
    SWFW: {
      text: {
        de: "Software / Firmware",
        en: "Software / Firmware",
        nl: "Software / Firmware",
      },
    },
    OTHR: {
      text: {
        de: "Sonstige",
        en: "Other",
        nl: "Overige",
      },
    },
    CCBL: {
      text: {
        de: "Steckverbinder / Kabel",
        en: "Connectors / Cables",
        nl: "Stekkers / Kabels",
      },
    },
    ASSY: {
      text: {
        de: "Zusammenbau",
        en: "Assembly",
        nl: "Assemblage",
      },
    },
  },
  text: {
    de: "Kategorie der Einheit",
    en: "Category of the Item",
    nl: "Item productcategorie",
  },
  maxLength: 16,
};
fields.descr = {
  xml: "pcnChangeDetail",
  type: field.descr.type,
  text: {
    de: "Technische Beschreibung",
    en: "Technical Description",
    nl: "Beschrijving",
  },
  maxLength: 4096,
  required: true,
  tooltip: {
    de: "Beschreibung der Änderung oder Abkündigung, (Ursache der Änderung oder Abkündigung; Kompatibilitätsaussagen zur geänderten Einheit oder zum vorgeschlagenen Ersatz; etc.)",
    en: "Description of change or discontinuance (cause of change or discontinuance; compatibility statements for the item being changed or for the proposed replacement; etc.)",
    nl: "Beschrijving van de wijziging of beëindiging (oorzaak van de wijziging of beëindiging; compatibiliteitsverklaringen voor het item dat wordt gewijzigd of voor de voorgestelde vervanging; enz.)",
  },
};
fields.crtContact = {
  xml: "pcnEmail",
  text: {
    de: "Hersteller Email",
    en: "Manufacturer Email",
    nl: "Email leverancier",
  },
  maxLength: 256,
  required: true,
  type: field.crtContact.type,
  tooltip: {
    de: "E-Mail des Ansprechpartners, der für das Produkt / die Komponente verantwortlich ist (Einkauf, Produktverantwortlicher oder -manager)",
    en: "Email of person in charge for the affected product / component. (purchasing department, product manager)",
    nl: "E-mail van de persoon die verantwoordelijk is voor het betrokken product/onderdeel. (afdeling inkoop, productmanager)",
  },
  regex: "^[0-9a-zA-Z]+(?:.[0-9a-zA-Z]+)*@[a-zA-Z0-9]{2,}(?:.[a-zA-z]{2,})+$",
};
fields.mfrhighlevel = {
  type: field.mfrhighlevel.type,
  xml: "pcnMfrName",
  text: {
    de: "Firmenkurzbezeichnung",
    en: "Short Name of Company",
    nl: "Korte naam van de leverancier",
  },
  maxLength: 32,
  required: true,
  tooltip: {
    de: "Firmenkurzbezeichnung des Herstellers",
    en: "Concise short name of the manufacturer",
    nl: "Beknopte korte naam van de leverancier",
  },
};
fields.artNumber = {
  type: FieldTypes.value,
  xml: "artNumber",
  text: {
    de: "Interne Artikelnummer",
    en: "Internal item number",
    nl: "Intern artikelnummer",
  },
  unique: false,
  filter: FilterTypes.startsWith,
  tooltip: {
    de: "Interne Artikelnummer",
    en: "Internal item number",
    nl: "Intern artikelnummer",
  },
};

const settings = {
  permission: {},
  text: {
    required_fields: {
      de: "Pflichtfelder",
      en: "Mandatory Fields",
      nl: "Verplichte velden",
    },
    upload_pcn: {
      de: "PCN/PDN importieren",
      en: "Import PCN/PDN",
      nl: "PCN/PDN importeren",
    },
    mainBox: {
      de: "Stammdaten",
      en: "Master Data",
      nl: "Stamdata",
    },
    btn_next: {
      de: "Weiter",
      en: "Next",
      nl: "Volgende",
    },
    btn_back: {
      de: "Zurück",
      en: "Back",
      nl: "Terug",
    },
    itemsBox: {
      de: "Betroffene Einheiten",
      en: "Items Concerned",
      nl: "Items Betrokken",
    },
    label_item: {
      de: "Hersteller Artikel Nummern",
      en: "Manufacturer Item Numbers",
      nl: "Artikelnummers fabrikant",
    },
    itemDetail: {
      de: "Einheit hinzufügen",
      en: "Add Item",
      nl: "Item toevoegen",
    },
    delete: {
      de: "Löschen",
      en: "Delete",
      nl: "Verwijder",
    },
    affected_item: {
      de: "Betroffene Einheit",
      en: "Item Concerned",
      nl: "Betrokken item",
    },
    temporal_information: {
      de: "Zeitliche Informationen",
      en: "Time-Related Information",
      nl: "Tijdgerelateerde informatie",
    },
    filesBox: {
      de: "Beschreibung & Anhänge",
      en: "Description & Attachments",
      nl: "Beschrijving & Bijlagen",
    },
    relevant_documentation: {
      de: "Relevante Dokumentation",
      en: "Relevant Documentation",
      nl: "Relevante Documenten",
    },
    upload_attachment: {
      de: "Anhang hochladen",
      en: "Upload Attachment",
      nl: "Upload Bijlage",
    },
    sendBox: {
      de: "PCN/\nPDN senden",
      en: "Send PCN/PDN",
      nl: "Stuur PCN/PDN",
    },
    email_confirmation_yes: {
      de: "Ich möchte eine Bestätigungs-E-Mail an die folgende Adresse erhalten: ",
      en: "I would like to recieve a confirmation email at the following address: ",
      nl: "Ik zou graag een bevestigingsmail ontvangen op het volgende adres: ",
    },
    email_attach_type: {
      de: "Senden Sie mir die erstellte PCN/PDN als .pdf und die digitale Datei mit der Erweiterung an meine E-Mail-Adresse: ",
      en: "Send to my email address the generated PCN/PDN as a .pdf and the digital file with the extension: ",
      nl: "Stuur naar mijn e-mailadres het gegenereerde PCN/PDN als .pdf en het digitale bestand met de extensie: ",
    },
    email_confirmation_send: {
      de: "Wir müssen sicherstellen, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind. Daher werden wir Ihnen zunächst eine E-Mail schicken, in der wir Sie bitten, Ihre Adresse zu bestätigen. Wenn Sie auf den in der E-Mail enthaltenen Link klicken, erhalten Sie automatisch die E-Mail mit den generierten Daten. Die E-Mail-Adresse wird nur bei der ersten Verwendung überprüft.",
      en: "We must make sure that you are the owner of the email address provided. Therefore, we will first send you an email asking you to confirm your address. If you click on the link contained in the email, you will automatically receive the email with the generated data. The email address is only checked the first time an email address is used. ",
      nl: "Wij moeten er zeker van zijn dat u de eigenaar bent van het opgegeven e-mailadres. Daarom sturen wij u eerst een e-mail waarin wij u vragen uw adres te bevestigen. Als u op de link in de e-mail klikt, ontvangt u automatisch de e-mail met de gegenereerde gegevens. Het e-mailadres wordt alleen gecontroleerd bij de eerste keer dat een e-mailadres wordt gebruikt. ",
    },
    pcn_send: {
      de: "Die PCN/PDN wurde erfolgreich importiert und an die angegebene E-Mail-Adresse gesendet.",
      en: "Your PCN/PDN was succesfuly imported and sent to the indicated email address.",
      nl: "Uw PCN/PDN is succesvol geïmporteerd en verzonden naar het aangegeven email adres.",
    },
    mandatory_fields_1: {
      de: "Die Pflichtfelder sind mit einem",
      en: "Fields marked with",
      nl: "Velden gemarkeerd met",
    },
    mandatory_fields_2: {
      de: " markiert",
      en: " are required",
      nl: " zijn verplicht",
    },
    import: {
      de: "Importieren Sie Ihre PCN/PDN-Datei direkt",
      en: "Directly import your PCN/PDN file",
      nl: "Rechtstreeks importeren van uw PCN/PDN-bestand",
    },
    name: {
      de: "Name",
      en: "Name",
      nl: "Name",
    },
    email: {
      de: "E-Mail Adresse",
      en: "E-mail address",
      nl: "Email",
    },
    manually: {
      de: "Manuell ergänzen",
      en: "Manually complete",
      nl: "Handmatig invullen",
    },
    importPcn: {
      de: "PCN/PDN importieren",
      en: "Import PCN/PDN",
      nl: "PCN/PDN importeren",
    },
    attachPcn: {
      de: "PCN/PDN hochladen",
      en: "Upload PCN/PDN",
      nl: "PCN/PDN hochladen",
    },
    required: {
      de: "Fields marked with * are required",
      en: "Fields marked with * are required",
      nl: "Fields marked with * are required",
    },
    invalidEmail: {
      de: "Ungültiger Name oder E-Mail-Adresse",
      en: "Invalid name or email",
      nl: "Ongeldige naam of e-mail",
    },
    invalidFileType: {
      de: "You are trying to import an unsupported file. Please use only .zip file that contain PCNbody.xml in the structure.",
      en: "You are trying to import an unsupported file. Please use only .zip file that contain PCNbody.xml in the structure.",
      nl: "You are trying to import an unsupported file. Please use only .zip file that contain PCNbody.xml in the structure.",
    },
    invalidZipStructure: {
      de: "cannot find the file: PCNbody.xml. You are trying to upload an zip file with a not valid structure",
      en: "cannot find the file: PCNbody.xml. You are trying to upload an zip file with a not valid structure",
      nl: "cannot find the file: PCNbody.xml. You are trying to upload an zip file with a not valid structure",
    },
    invalidFileFormat: {
      de: "Leider wird Ihr Dateiformat nicht unterstützt. Bitte passen Sie den Anhang an oder kontaktieren Sie 'support@am-sys.com' für weitere Informationen.",
      en: "Unfortunately your file format is not supported, please adjust the attachment or contact 'support@am-sys.com' for further information.",
      nl: "Unfortunately your file format is not supported, please adjust the attachment or contact 'support@am-sys.com' for further information.",
    },
    missingFields: {
      de: "The following mandatory fields are missing:",
      en: "The following mandatory fields are missing:",
      nl: "The following mandatory fields are missing:",
    },
    clientMessage: {
      de: "Senden Sie Ihre Abkündungsmeldungen direkt an",
      en: "Send your discontinuance notice direct to",
      nl: "Stuur uw opzegging rechtstreeks naar",
    },
  },
  field: fields,
  url: {},
  list: {
    boxesLeft: empty,
    mainBox: [],
    itemsBox: [],
    filesBox: [],
    sendBox: [],
    itemDetail: [],
  },
  additionalInfo: {},
};

export const pcnGenerator: ComponentSettings<typeof settings> = settings;
