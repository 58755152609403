import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {
    import: false,
    users: false,
    seeNewDesign: false,
  },
  text: {
    title: { de: "Titel", en: "Title" },
    welcome: { de: "Willkommen", en: "Welcome", nl: "Welkom" },
    loading: {
      de: "Daten werden geladen. Bitte warten Sie einen Moment...",
      en: "Loading data. Please wait a moment...",
      nl: "Data wordt geladen, een moment geduld …",
    },
    loadingTreeRows: {
      de: "Bitte warten Sie ein paar Sekunden, bis wir die Baumdaten geladen haben!",
      en: "Please wait a few seconds until we load the tree data!",
      nl: "Wacht een paar seconden tot we de boomgegevens laden!",
    },
    confirmChanges: {
      de: "Beachten Sie, dass die Änderungen nicht gespeichert wurden!",
      en: "Note that the changes were not changed!",
      nl: "Let op ! De wijzigingen zijn niet opgeslagen",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    delete: {
      de: "Löschen",
      en: "Delete",
      nl: "Verwijder",
    },
    error: {
      de: "Ein Fehler ist aufgetreten.",
      en: "An error has occured.",
      nl: "Er is een fout opgetreden.",
    },
    users: {
      de: "Benutzermanagement",
      en: "User Management",
      nl: "Usermanagement",
    },
    userList: { de: "Benutzerliste", en: "User List", nl: "User List" },
    mail: {
      de: "Ihre Benachrichtigungen",
      en: "Your notifications",
      nl: "Uw Notificaties",
    },
    fileTooLargeError: {
      de: "Datei ist zu groß! Maximale Größe: 100 MB",
      en: "File is too large! Maximum size: 100MB",
      nl: "Bestand is te groot! Maximale grootte: 100MB",
    },
    fileFormatNotAccpeted: {
      de: "Dateiformat wird nicht akzeptiert!",
      en: "File format is not accepted!",
      nl: "Bestandsformaat is niet geaccepteerd!",
    },
    fileNameNotAccepted: {
      de: "Ungültiger Dateiname! Die einzigen akzeptierten Sonderzeichen sind - und _",
      en: "Invalid file name! The only accepted special charaters are - and _",
      nl: "Ongeldige bestandsnaam! De enige toegestane speciale tekens zijn - en _",
    },
    noResults: {
      de: "Keine Ergebnisse gefunden",
      en: "No results found",
      nl: "Geen resultaten gevonden",
    },
    search: {
      de: "Suchen",
      en: "Search",
      nl: "Zoeken",
    },
    confirm: {
      en: "Confirm",
      de: "Bestätigen",
      nl: "Bevestigen",
    },
    abort: {
      en: "Abort",
      de: "Abbrechen",
      nl: "Annuleren",
    },
    yes: {
      en: "Yes",
      de: "Ja",
      nl: "Ja",
    },
    no: {
      en: "No",
      de: "Nein",
      nl: "Nee",
    },
    suggestions: {
      de: "Vorschläge: ",
      en: "Suggestions: ",
      nl: "Suggesties: ",
    },
    data: {
      de: "Daten",
      en: "Data",
      nl: "Data",
    },
    fieldInvalid: {
      de: "Feld ist ungültig",
      en: "Field is invalid",
      nl: "Veld is ongeldig",
    },
    noDataAvailable: {
      de: "Die von Ihnen angeforderten Daten sind nicht mehr verfügbar",
      en: "The data you requested is not available anymore",
      nl: "De door u gevraagde gegevens zijn niet meer beschikbaar",
    },
    backToList: {
      de: "Zurück zur Liste",
      en: "Back to the list",
      nl: "Terug naar de lijst",
    },
    backToCase: {
      de: "Zurück zu Fall-Details",
      en: "Back to case details",
      nl: "Terug naar issue details",
    },
    back: {
      de: "Zurück",
      en: "Back",
      nl: "Back",
    },
    add: {
      de: "Hinzufügen",
      en: "Add",
      nl: "Toevoegen",
    },
    save: {
      de: "Speichern",
      en: "Save",
      nl: "Bewaar",
    },
    edit: {
      de: "Bearbeiten",
      en: "Edit",
      nl: "Bewerk",
    },
    expand: {
      de: "Click to expand detail box",
      en: "Click to expand detail box",
      nl: "Click to expand detail box",
    },
    narrow: {
      de: "Click to narrow detail box",
      en: "Click to narrow detail box",
      nl: "Click to narrow detail box",
    },
    myTasks: {
      de: "Meine Aufgaben/Aufgaben-Management",
      en: "My Tasks/Tasks Management",
      nl: "Mijn Taken/Taakbeheer",
    },
    wentWrong: {
      de: "Etwas ist schief gelaufen!",
      en: "Something went wrong!",
      nl: "Er is iets misgegaan! ",
    },
    deleteProrfilePicture: {
      de: "Sind Sie sicher, dass Sie Ihr Profilbild löschen möchten?",
      en: "Are you sure you want to delete your profile image?",
      nl: "Weet je zeker dat je je profielafbeelding wilt verwijderen?",
    },
    assignedTo: {
      de: "Assigned to",
      en: "Assigned to",
      nl: "Assigned to",
    },
    added: {
      de: "Hinzugefügt",
      en: "Added",
      nl: "Toegevoegd",
    },
    discardChanges: {
      de: "Es wurden einige Änderungen vorgenommen. Möchten Sie sie verwerfen?",
      en: "Some changes have been made. Would you like to discard them?",
      nl: "Er zijn enkele wijzigingen aangebracht. Wil je ze weggooien?",
    },
    // export
    allManufacturers: {
      de: "Bericht_Tabellenansicht ",
      en: "Table_View_Report",
      nl: "Report_Tabel",
    },
    matchedManufacturers: {
      de: "Bericht_Zugeordnete_Einheiten ",
      en: "Matched_Items_Report",
      nl: "Report_Direct Match",
    },
    notMatchedManufacturers: {
      de: "Bericht_Nicht_Zugeordnete_Einheiten",
      en: "Not_Matched_Items_Report",
      nl: "Report_Geen Overeenstemming",
    },
    manualAssignmentManufacturers: {
      de: "Bericht_Zuweisung_Erforderlich_Einheiten",
      en: "Assignement_Required_Items_Report",
      nl: "Report_Handmatige_toewijzing_vereist",
    },
    editedManufacturers: {
      de: "Bericht_Bearbeitete_Einheiten",
      en: "Edited_Items_Report",
      nl: "Report_Bewerkt",
    },
    manualOverrideManufacturers: {
      de: "Bericht_Manuell_Überschriebene_Einheiten",
      en: "Manual_Override_Report",
      nl: "Report_Handmatige_bewerking_Actief",
    },
    toDeletedManufacturers: {
      de: "Bericht_Hersteller_ohne_Kundenteile",
      en: "Manufacturers_without_Parts_Report",
      nl: "Report_Fabrikant_zonder_Artikelen",
    },
    allParts: {
      de: "Bericht_Liste_der_Kundenteile",
      en: "List_of_Parts_Report",
      nl: "Report_Lijst_van_artikelen",
    },
    partsWithoutMPNs: {
      de: "Bericht_Kundenteile_ohne_Hersteller",
      en: "Parts_without_Manufacturers_Report",
      nl: "Report_Artikelen_zonder_fabrikant",
    },
    partsNotUsed: {
      de: "Bericht_Nicht_in_Baugruppen_verwendete_Kundenteile",
      en: "Parts_not_used_in Assemblies_Report",
      nl: "Report_Niet_in_assemblages_gebruikte_artikelen",
    },
    toDeletedParts: {
      de: "Bericht_Bauteile_die_gelöscht_werden_können",
      en: "Parts_that_can_be_Deleted",
      nl: "Report_Artikelen_die_kunnen_worden_verwijderd",
    },
    allAlerts: {
      de: "Bericht_Alarme",
      en: "Alerts_Report",
      nl: "Report_RM_Alerts",
    },
    allChanges: {
      de: "Bericht_Änderungs_Alarm",
      en: "Change_Alerts_Report",
      nl: "Report_RM_Changes",
    },
    allLeadTimeAlerts: {
      de: "Bericht_Lieferzeiten_Alarm",
      en: "Lead_Time_Alerts_Report",
      nl: "Report_Lefertijden_Alerts",
    },
    allInvMonitoringAlerts: {
      de: "Inventory_Monitoring_Alerts_Report",
      en: "Inventory_Monitoring_Alerts_Report",
      nl: "Inventory_Monitoring_Alerts_Report",
    },
    allCases: {
      de: "Bericht_OM_Faelle",
      en: "OM_Cases_Report",
      nl: "Report_OM_Gevallen",
    },
    myTasksExport: {
      de: "Bericht_Meine_Aufgaben",
      en: "My_Tasks_Report",
      nl: "Report_Mijn_Taken",
    },
    createdTasks: {
      de: "Bericht_Erstellte_Aufgaben",
      en: "Created_Tasks_Report",
      nl: "Report_Gemaakte_taken",
    },
    allTasks: {
      de: "Bericht_Alle_Aufgaben",
      en: "All_Tasks_Report",
      nl: "Report_Alle_Taken",
    },
  },
  list: {
    languages: ["nl", "de", "en"],
    homeMode: ["list"],
  },
  url: {},
  field: {},
  additionalInfo: {},
};

export const app: ComponentSettings<typeof settings> = settings;
