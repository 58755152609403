import { Component, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { Part } from "../../../../shared/models/part";
import { FieldTypes } from "../../../../shared/settings/settings";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";
import {
  createPart,
  doc2Model,
  getPartsCompressed,
  model2Doc,
} from "../api.service";
import { getApp } from "../app";
import { DocModel } from "../state/state";

@Component({
  selector: "app-create-part-modal",
  templateUrl: "./create-part-modal.component.html",
  styleUrls: ["./create-part-modal.component.scss"],
})
export class CreatePartModalComponent implements OnInit {
  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();
  app = getApp((app) => {
    this.app = app;
  });
  cleanupFields: Set<any> = new Set();
  model: DocModel = doc2Model("part", new Part());
  columns: string[] = [];
  warning: string | null = null;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.columns = this.app.list.part.createPartFieldsList;
    this.app.field.getFieldSettings(this.app.fieldId.part.partNumber).mutable =
      true;
    this.setDefaultForNS();
  }

  setDefaultForNS() {
    if (this.app.customers.expectCurrent === Customer.NS) {
      this.app.field.getFieldSettings(
        this.app.fieldId.part.obsolescenceManagement
      ).mutable = false;
      this.model[this.app.fieldId.part.obsolescenceManagement] = "true";
    }
  }

  async open() {
    this.app.field.getFieldSettings(this.app.fieldId.part.partNumber).type =
      FieldTypes.value;
    try {
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
        backdrop: "static",
        keyboard: false,
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  hasRequiredFields() {
    let result = true;
    if (
      !StringUtils.isNullOrEmpty(
        this.model[this.app.fieldId.part.partNumber]
      ) &&
      !StringUtils.isNullOrEmpty(
        this.model[this.app.fieldId.part.description]
      ) &&
      !StringUtils.isNullOrEmpty(
        this.model[this.app.fieldId.part.obsolescenceManagement]
      ) &&
      !this.app.part.checkPartNumberCharacters(
        this.model[this.app.fieldId.part.partNumber]
      )
    ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  async save() {
    const partDoc = model2Doc("part", this.model) as Part;
    partDoc["_id"] = this.model[this.app.fieldId.part.partNumber];
    partDoc["type"] = "part";
    const partExists = await this.checkPartNumber(partDoc);
    if (partExists) {
      this.warning = this.app.text.part.partNumberExist;
      return;
    }
    try {
      await createPart(this.app.customers.expectCurrent, partDoc);

      this.app.partsTable.parts = await this.app.partsTable.getParts(
        this.app.RM.mode
      );
      this.app.state.next({
        hasSuccess: true,
        successText: this.app.text.part.createdSuccesfully,
      });
      setTimeout(() => {
        this.app.state.next({ hasSuccess: false });
      }, 3000);
    } catch (err) {
      console.log(err);
    }
    this.dismiss();
  }

  private async checkPartNumber(currentPart: Part) {
    const existingPartNumbers = this.app.partsTable.parts.map(
      (part) => part.partNumber
    );
    if (existingPartNumbers.includes(currentPart.partNumber)) {
      return true;
    }
    return false;
  }

  dismiss() {
    this.warning = null;
    this.modalService.dismissAll();
    this.resetModel();
  }

  resetModel() {
    this.model = doc2Model("part", new Part());
    this.app.field.changeFieldSettings(
      this.app.fieldId.part.partNumber,
      FieldTypes.typeahead
    );
    this.app.field.changeFieldSettings(
      this.app.fieldId.part.description,
      FieldTypes.typeahead
    );
    this.setDefaultForNS();
  }
}
