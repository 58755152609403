import { AppComponent } from "../app/app.component";
import { AuthComponent } from "../auth/auth.component";
import { AuthGuard } from "../auth/auth.guard";
import { DebugComponent } from "../debug/debug.component";
import { GraphsComponent } from "../graphs/graphs.component";
import { HomeComponent } from "../home/home.component";
import { ImpactComponent } from "../impact/impact.component";
import { ImportComponent } from "../import/import.component";
import { ImportStepperComponent } from "../import/import-stepper/import-stepper.component";
import { MailComponent } from "../mail/mail.component";
import { ManufacturerComponent } from "../manufacturer/manufacturer.component";
import { ManufacturerMatchingComponent } from "../manufacturer-matching/manufacturer-matching.component";
import { PartComponent } from "../part/part.component";
import { RMComponent } from "../RM/RM.component";
import { RMSearchComponent } from "../rm-search/rm-search.component";
import { PasswordComponent } from "../password/password.component";
import { ProfileComponent } from "../profile/profile.component";
import { Routes } from "@angular/router";
import { SettingsComponent } from "../settings/settings.component";
import { ThreadComponent } from "../thread/thread.component";
import { TrainComponent } from "../train/train.component";
import { TrainsListComponent } from "../trains-list/trains-list.component";
import { UserComponent } from "../user/user.component";
import { UserProfileListComponent } from "../user-profile-list/user-profile-list.component";
import { UsersComponent } from "../users/users.component";
import { VehicleResponsibleComponent } from "../vehicle-responsible/vehicle-responsible.component";
import { VehicleResponsibleListComponent } from "../vehicle-responsible-list/vehicle-responsible-list.component";
import { TasksComponent } from "../tasks/tasks.component";
import { TreeComponent } from "../tree/tree.component";
import { ManufacturersFilterTableComponent } from "../RM/manufacturers-filter-table/manufacturers-filter-table.component";
import { ChangesComponent } from "../changes/changes.component";
import { PcnsComponent } from "../pcns/pcns.component";
import { AlertsComponent } from "../alerts/alerts.component";
import { ExternalDataFilterComponent } from "../external-data-filter/external-data-filter.component";
import { PcnComponent } from "../pcn/pcn.component";
import { PcnGeneratorComponent } from "../pcn-generator/pcn-generator.component";
import { OperationsComponent } from "../operations/operations.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { WorkflowAutomationComponent } from "../impact/wf-automation/wf-automation.component";
import { NotFoundComponent } from "../not-found/not-found.component";
import { PartsFilterTableComponent } from "../RM/parts-filter-table/parts-filter-table.component";
import { OCHomeComponent } from "../obsolescence-community/OC-home/OC-home.component";
import { MFACompleteComponent } from "../MFA-complete/MFA-complete.component";
import { DashboardComponent } from "../dashboard/dashboard.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "reset-password",
    data: { view: "reset-passowrd" },
    component: ResetPasswordComponent,
  },
  {
    path: ":customer/reset-password/:token",
    data: { view: "reset-password" },
    component: ResetPasswordComponent,
  },
  {
    path: "login",
    component: AuthComponent,
  },
  {
    path: "mfa-complete",
    component: MFACompleteComponent,
  },
  {
    path: ":customer",
    component: AppComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        data: { view: "home" },
        pathMatch: "full",
        redirectTo: "home",
      },
      {
        path: "dashboard",
        data: { view: "dashboard" },
        component: DashboardComponent,
      },
      {
        path: "not-found",
        data: { view: "not-found" },
        component: NotFoundComponent,
      },
      {
        path: "landing",
        data: { view: "home", landing: true },
        component: HomeComponent,
      },
      {
        path: "reset-password",
        data: { view: "reset-passowrd" },
        component: AppComponent,
      },

      {
        path: "home",
        data: { view: "home", title: "OM Module" },
        component: HomeComponent,
      },
      {
        path: "pcn",
        data: { view: "pcn" },
        component: PcnGeneratorComponent,
      },
      {
        path: "oc-home",
        data: { view: "oc-home", title: "Obsolescence Community" },
        component: OCHomeComponent,
      },
      {
        path: "oc-home/:postId",
        data: { view: "oc-home", title: "Obsolescence Community" },
        component: OCHomeComponent,
      },
      {
        path: "thread/:threadId",
        data: { view: "thread" },
        component: ThreadComponent,
      },
      {
        path: "thread-history/:threadId",
        data: { view: "thread", history: true },
        component: ThreadComponent,
      },
      {
        path: "thread-new",
        data: { view: "thread", newThread: true },
        component: ThreadComponent,
      },
      {
        path: "users",
        data: { view: "users" },
        component: UsersComponent,
      },
      {
        path: "user-profile-list",
        data: { view: "user-profile-list" },
        component: UserProfileListComponent,
      },
      {
        path: "user-new",
        data: { view: "user", newUser: true },
        component: UserComponent,
      },
      {
        path: "user/:user",
        data: { view: "user" },
        component: UserComponent,
      },
      {
        path: "mail",
        data: { view: "mail" },
        component: MailComponent,
      },
      {
        path: "profile-password",
        data: { view: "profile-password" },
        component: PasswordComponent,
      },
      {
        path: "profile/:user",
        data: { view: "profile" },
        component: ProfileComponent,
      },
      {
        path: "vehicleResponsibles",
        data: { view: "vehicleResponsibleList" },
        component: VehicleResponsibleListComponent,
      },
      {
        path: "train/:threadId/list",
        data: { view: "trains-list" },
        component: TrainsListComponent,
      },
      {
        path: "train/:threadId/new",
        data: { view: "train", newTrain: true },
        component: TrainComponent,
      },
      {
        path: "train/:threadId/:train",
        data: { view: "train" },
        component: TrainComponent,
      },
      {
        path: "vehicleResponsible/vehicleResponsible-new",
        data: { view: "vehicleResponsible", newVehicleResponsible: true },
        component: VehicleResponsibleComponent,
      },
      {
        path: "vehicleResponsible/:vehicleResponsible",
        data: { view: "vehicleResponsible" },
        component: VehicleResponsibleComponent,
      },
      {
        path: "thread/:threadId/impact-new",
        data: { view: "impact", newImpact: true },
        component: ImpactComponent,
      },
      {
        path: "thread/:threadId/impact/:impact",
        data: { view: "impact" },
        component: ImpactComponent,
      },
      {
        path: "thread/:threadId/impact-wf/:impact",
        data: { view: "wf-automation" },
        component: WorkflowAutomationComponent,
      },

      {
        path: "settings",
        data: { view: "settings" },
        component: SettingsComponent,
      },
      {
        path: "graphs/:graph",
        data: { view: "graphs" },
        component: GraphsComponent,
      },
      {
        path: "external-data-filter",
        data: { view: "external-data-filter" },
        component: ExternalDataFilterComponent,
      },
      {
        path: "debug",
        data: { view: "debug" },
        component: DebugComponent,
      },
      {
        path: "import",
        data: { view: "import" },
        component: ImportComponent,
      },
      {
        path: "import-stepper",
        data: { view: "import-stepper" },
        component: ImportStepperComponent,
      },
      {
        path: "part/:partNumber",
        data: { view: "part" },
        component: PartComponent,
      },
      {
        path: "rm",
        component: RMComponent,
        children: [
          {
            path: "tree",
            data: {
              view: "RM",
              title: "Risk Management",
              displayRMHeader: true,
            },
            pathMatch: "full",
            component: TreeComponent,
          },
          {
            path: "tree/:assembly",
            data: { view: "RMSearch", displayRMHeader: false },
            component: TreeComponent,
          },
          {
            path: "table/manufacturers/:mode",
            data: { view: "RM", displayRMHeader: true },
            component: ManufacturersFilterTableComponent,
          },
          {
            path: "table/parts/:mode",
            data: { view: "RM", displayRMHeader: true },
            component: PartsFilterTableComponent,
          },
          {
            path: "search",
            data: { view: "RMSearch", displayRMHeader: false },
            component: RMSearchComponent,
          },
        ],
      },
      {
        path: "operations",
        data: { view: "operations" },
        component: OperationsComponent,
      },
      {
        path: "mail",
        component: MailComponent,
        children: [
          {
            path: "",
            data: { view: "mail" },
            pathMatch: "full",
            redirectTo: "change",
          },
          {
            path: "change",
            data: { view: "mail", mode: "change" },
            component: ChangesComponent,
          },
          {
            path: "thread",
            data: { view: "mail", mode: "thread" },
            component: PcnsComponent,
          },
          {
            path: "thread/:pcnId",
            data: { view: "mail", mode: "thread" },
            component: PcnComponent,
          },
          {
            path: "alert",
            data: { view: "mail", mode: "alert" },
            component: AlertsComponent,
          },
          {
            path: "change-alert",
            data: { view: "mail", mode: "changeAlert" },
            component: AlertsComponent,
          },
          {
            path: "lead-alert",
            data: { view: "mail", mode: "leadTimeAlert" },
            component: AlertsComponent,
          },
          {
            path: "inventory-monitoring",
            data: { view: "mail", mode: "inventoryMonitoringAlert" },
            component: AlertsComponent,
          },
          {
            path: "env-compliance",
            data: { view: "mail", mode: "envComplianceAlert" },
            component: AlertsComponent,
          },
        ],
      },
      {
        path: "manufacturer/:manufacturerId",
        data: { view: "manufacturer" },
        component: ManufacturerComponent,
      },
      {
        path: "matching/:part/:manufacturer",
        data: { view: "matching" },
        component: ManufacturerMatchingComponent,
      },
      {
        path: "matching/:manufacturer",
        data: { view: "matchingManufacturer" },
        component: ManufacturerMatchingComponent,
      },
      {
        path: "tasks",
        data: { view: "tasks" },
        component: TasksComponent,
      },
    ],
  },
];
