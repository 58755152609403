<app-manufacturer-matching-modal
  #unMatchModal
  [match]="app.manufacturerMatching.manufacturer"
  [type]="'discard'"
>
</app-manufacturer-matching-modal>

<app-manufacturer-matching-modal
  #saveMatchModal
  [match]="app.externalDataFilter.currentMatch"
  [manufacturer]="app.manufacturerMatching.manufacturer"
  [type]="'save'"
>
</app-manufacturer-matching-modal>

<app-create-manufacturer-modal
  #createManufacturer
  [modalMessage]="app.text.part.discard"
  [modalTitle]="app.text.part.modalTitle"
  (ok)="createManufacturer()"
></app-create-manufacturer-modal>

<div class="card my-3 pb-3">
  <div class="searchArea">
    <div class="row p-3 m-2">
      <div class="col-md-4 px-0">
        <input
          type="text"
          class="form-control searchInput"
          [placeholder]="
            app.field.getLabel(app.fieldId.manufacturer.manufacturerPartNumber)
          "
          [(ngModel)]="partNumber"
          (keydown.enter)="searchManufacturer(partNumber)"
          (click)="app.filterTable.filterFocus = false"
        />
      </div>
      <div class="col-md-2 ps-0 pt-1 searchButton">
        <button class="btn" (click)="searchManufacturer(partNumber)">
          <i class="fa fa-search"></i>&nbsp;<span>{{
            app.text.app.search
          }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="tableFixHead mt-2">
    <table class="table table-striped">
      <thead>
        <tr>
          <th
            [ngClass]="{
              'text-center': column === 'createManufacturer'
            }"
            *ngFor="let column of columns"
            class="p-1 align-top table-header"
          >
            <!-- columns that can be sorted & filtered -->
            <div class="d-inline-flex">
              <div
                [ngClass]="{
                  'resize-table': app.table.canColumnBeResized(column)
                }"
              >
                <span
                  *ngIf="app.table.canColumnBeSorted(column)"
                  (click)="
                    app.table.toggleSortDirection(
                      app.manufacturerMatching.getColumn(column)
                    );
                    app.table.sort(
                      app.manufacturerMatching.manufacturer.matches
                    )
                  "
                >
                  {{ app.field.handleSpecialLogicLabels(column) }}
                </span>

                <!-- special columns with special logic -->
                <div>
                  <span *ngIf="column === 'select' || column === 'datasheet'">
                    {{ app.field.handleSpecialLogicLabels(column) }}
                  </span>
                  <span
                    *ngIf="
                      column === 'createCase' &&
                      app.permission.thread.hasPermissionToCreateThread
                    "
                    (click)="$event.stopPropagation()"
                  >
                    <span>
                      <i class="fas fa-folders"></i>
                    </span>
                  </span>
                  <span
                    *ngIf="column === 'createManufacturer'"
                    [title]="app.text.manufacturer.createNewManufacturer"
                  >
                    <i class="fad fa-industry-alt"></i
                  ></span>
                  <span *ngIf="column === 'assignedTo'">
                    <i class="fas fa-sitemap fa-rotate-270"></i>
                  </span>
                </div>
              </div>
              &nbsp;
              <span
                *ngIf="
                  app.manufacturerMatching.getColumn(column) ===
                    app.table.sortField && app.table.canColumnBeSorted(column)
                "
              >
                <i
                  *ngIf="app.table.sortDirection === 1"
                  class="fa fa-chevron-up"
                ></i>
                <i
                  *ngIf="app.table.sortDirection === -1"
                  class="fa fa-chevron-down"
                ></i>
              </span>
            </div>
            <div *ngIf="app.filterTable.displayFilterTable(column)">
              <app-filter-table
                [column]="
                  app.manufacturersFilter.getValuesAsManufacturerKeys(column)
                "
                [columns]="columnRows"
                [docType]="'manufacturer'"
                [cleanDocs]="app.externalDataFilter.matchesCopy"
                [selectedFilters]="selectedFilters"
                (resultedDocs)="
                  app.filterTable.getFilterResults($event, 'seMatch')
                "
                [resetComponent]="resetFilterInputsObservable"
              >
              </app-filter-table>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let match of app.manufacturerMatching.manufacturer.matches">
          <td
            class="p-1"
            *ngFor="let column of columnRows"
            [ngClass]="{
              'text-center': column === 'Datasheet'
            }"
          >
            <span *ngIf="column === 'createManufacturer'">
              <a
                [ngClass]="{
                  'btn-selected': match.isAdded
                }"
                class="btn btn-sm btn-default-orange"
                (click)="
                  app.manufacturer.selectedManufacturer = match;
                  app.state.createFromPart = false;
                  createManufacturer.open()
                "
                [title]="app.text.manufacturer.createNewManufacturer"
              >
                <i class="fas fa-plus"></i>&nbsp;&nbsp;{{ app.text.app.add }}</a
              ></span
            >
            <span *ngIf="!app.manufacturerMatching.isSpecialColumn(column)">
              <span *ngIf="showValue(column, match[column])">
                {{ match[column] }}
              </span>
            </span>
            <span
              *ngIf="column === 'Lifecycle' || column === 'LifecycleStatus'"
              class="badge"
              [ngStyle]="
                app.field.getColor(
                  app.fieldId.manufacturer.obsolescenceStatus,
                  match[column]
                )
              "
            >
              {{
                app.field.getOptionText(
                  app.fieldId.manufacturer.obsolescenceStatus,
                  match[column]
                )
              }}
            </span>
            <span
              *ngIf="column === 'RoHS' || column == 'RoHSStatus'"
              class="badge"
              [ngStyle]="
                app.field.getColor(
                  app.fieldId.manufacturer.euRoHS,
                  match[column]
                )
              "
            >
              {{
                app.field.getOptionText(
                  app.fieldId.manufacturer.euRoHS,
                  match[column]
                )
              }}
            </span>
            <span *ngIf="column === 'Datasheet' && match.Datasheet">
              <a
                class="datasheet text-center"
                [href]="match.Datasheet"
                target="_blank"
              >
                <i class="fas fa-file icon-blue"></i>
              </a>
            </span>
            <span
              *ngIf="column === 'EstimatedYearsToEOL' && match[column] != '0'"
            >
              {{ match[column] }}
            </span>
            <span *ngIf="column === 'select'">
              <button
                class="btn btn-sm btn-default-orange"
                *ngIf="
                  showDefaultBtn(app.manufacturerMatching.manufacturer, match)
                "
                (click)="
                  saveMatchModal.open();
                  app.externalDataFilter.currentMatch = match
                "
                [disabled]="!app.permission.manufacturer.canMatch"
              >
                <i class="fas fa-plus"></i>&nbsp;&nbsp;{{
                  app.text.matchingManufacturer.select
                }}
              </button>
              <button
                class="btn btn-sm btn-selected"
                *ngIf="
                  !showDefaultBtn(app.manufacturerMatching.manufacturer, match)
                "
                (click)="unMatchModal.open()"
                [disabled]="!app.permission.manufacturer.canMatch"
              >
                {{ app.text.matchingManufacturer.selected }}
              </button>
            </span>
            <span *ngIf="column === 'assignedTo'">
              <i
                *ngIf="match.isAdded"
                class="fas fa-sitemap fa-rotate-270 icon-blue pointer p-1"
                (click)="
                  app.manufacturer.selectedManufacturer = match;
                  seeAssignedToModal.open()
                "
              ></i>
            </span>
            <span
              *ngIf="
                column === 'createCase' &&
                app.permission.thread.hasPermissionToCreateThread
              "
            >
              <i
                class="far fa-folder-plus icon-blue pointer p-1"
                *ngIf="!app.thread.getCase(match)"
                (click)="
                  app.externalDataFilter.newCase(match, partNumber);
                  app.externalDataFilter.currentMatch = match
                "
              ></i>
              <i
                class="fas fa-folder-open icon-blue pointer p-1"
                *ngIf="app.thread.getCase(match)"
                (click)="
                  seeOpenedCasesModal.open();
                  app.externalDataFilter.selectedMatch = match;
                  app.thread.getCasesByPartNumber(match)
                "
              ></i>
            </span>
          </td>
        </tr>
        <tr
          *ngIf="
            (!manufacturerId && app.externalDataFilter.matches.length === 0) ||
            (app.manufacturerMatching.manufacturer.matches &&
              app.manufacturerMatching.manufacturer.matches.length === 0)
          "
        >
          <td colspan="10" class="text-center">
            {{ app.text.matchingManufacturer.noResults }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-modal
  #seeOpenedCasesModal
  [modalTitle]="app.text.thread.threads"
  [modalType]="'showListOfCases'"
  [showButtons]="false"
>
</app-modal>
<app-modal
  #seeAssignedToModal
  [modalTitle]="app.text.app.assignedTo"
  [modalType]="'assignedTo'"
  [showButtons]="false"
>
</app-modal>
