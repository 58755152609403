import { ActivatedRoute } from "@angular/router";
import {
  checkIfTokenValid,
  getCustomerWithLongestPass,
  model2Doc,
} from "../api.service";
import { checkPasswordPolicy } from "../password/password-policy";
import { Component, OnInit, Output, ViewChild } from "@angular/core";
import { DocModel } from "../state/state";
import { getApp } from "../app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NO_ERROR } from "../users/users.service";
import { ResetPasswordEmailDetails } from "../../../../shared/models/reset-password-email-details";
import { ResetPasswordObject } from "../../../../shared/models/reset-password-object";
import { Subject } from "rxjs";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild("content", { static: true }) content: any;
  @Output() ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });

  model: DocModel = {};
  routeParams: any;
  tokenValid = false;
  tokenHasBeenUsed = false;
  hasSuccess = false;

  constructor(private route: ActivatedRoute, private modalService: NgbModal) {
    this.routeParams = this.route.snapshot.params;
  }
  async ngOnInit() {
    if (this.routeParams.token != null && this.routeParams.token !== "") {
      this.tokenValid = await checkIfTokenValid(this.routeParams.token);

      if (!this.tokenValid) {
        setTimeout(() => {
          this.app.routing.navigateLogin();
        }, 3000);
      }
    }
  }

  async open() {
    try {
      // this.model = this.app.seFilter.setSeFields();
      this.hasSuccess = false;
      this.app.field.isValidRegex("user.email", this.model["user.email"]);
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
        backdrop: "static",
        keyboard: false,
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  get requiredFields() {
    this.app.field.resetAllRequiredFieldSettings("user");
    this.app.field.getFieldSettings("user.name").required = true;
    this.app.field.getFieldSettings("user.email").required = true;
    this.app.field.getFieldSettings("user.email").mutable = true;
    if (this.app.view === "home") {
      this.app.field.getFieldSettings("user.password").required = true;
    }
    const requiredFields = this.app.field.getInvalidFields(
      "user",
      this.app.model
    );
    return requiredFields;
  }

  async requestChangePass() {
    const checkEmail = this.validateEmail();
    if (checkEmail) {
      const customer = await getCustomerWithLongestPass(
        this.model["user.name"]
      );
      const doc: ResetPasswordEmailDetails = {
        username: this.model["user.name"].trim(),
        webUrl: "",
        customer: customer,
      };

      try {
        this.hasSuccess = true;
        await this.app.auth.resetPassword(doc);
        this.app.hasSuccess = true;
        this.app.successText = this.app.text.resetPassword.messageSent;
        setTimeout(() => {
          this.app.state.next({ hasSuccess: false, errorText: "" });
        }, 3000);
      } catch (error) {
        this.app.hasError = true;
        this.app.errorText = this.app.text.resetPassword.userNotFound;
        setTimeout(() => {
          this.app.state.next({ hasError: false, errorText: "" });
        }, 3000);
      }
    } else {
      this.app.hasError = true;
      this.app.errorText = this.app.text.resetPassword.emailNotCorrect;
      setTimeout(() => {
        this.app.state.next({ hasError: false, errorText: "" });
      }, 3000);
    }
    this.model = {};
    this.modalService.dismissAll();
  }

  validateEmail() {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.model["user.email"]);
  }

  async changePassword() {
    const passwordObject = model2Doc("user", this.model);
    const customer = window.location.pathname.split("/")[1];
    const checkPassword = await checkPasswordPolicy(
      this.app.text,
      passwordObject,
      customer
    );

    if (checkPassword !== NO_ERROR) {
      this.app.hasError = true;
      this.app.errorText = checkPassword;
    } else {
      const doc: ResetPasswordObject = {
        password: this.model["user.password"],
        retypePassword: this.model["user.retypePassword"],
        token: this.routeParams.token,
      };
      const result = await this.app.auth.savePassword(doc);
      if (result) {
        this.app.hasSuccess = true;
        this.app.successText = "User saved successfully!";
        this.app.routing.navigateLogin();
      } else {
        this.app.hasError = true;
        this.app.errorText = "Can not change password!";
      }
    }
  }
}
