<ng-template #overrideMpnFields let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <span class="modal-title">
        <span class="font-weight-bold"> Confirm </span>
      </span>
    </div>
  </div>
  <div class="modal-body">
    <div class="heading">
      <div>
        {{ app.text.field.fillThreadFieldsAutomatically }}
      </div>
    </div>

    <div
      *ngIf="
        app.overrideMpnFields.selectedField === app.fieldId.thread.artNumber
      "
    >
      <dl class="row" *ngFor="let key of app.overrideMpnFields.partKeys">
        <dt class="col-sm-5 text-right">
          {{ app.field.getLabel(key) }} &nbsp;
          <i class="far fa-arrow-alt-right ms-1"></i>
        </dt>
        <dd class="col-sm-7">
          {{
            app.field.getFieldValueAsText(
              key,
              app.manufacturer.selectedPart[key.split(".")[1]]
            )
          }}
        </dd>
        <div class="mt-2" *ngIf="key === 'part.partNumber'">
          <small class="changeColor my-1">
            {{ app.text.field.whereUsedPartNumber }}
          </small>
          <app-where-used-in></app-where-used-in>
        </div>
      </dl>
    </div>
    <div
      *ngIf="
        app.overrideMpnFields.selectedField === app.fieldId.thread.crtNumber
      "
    >
      <dl
        class="row"
        *ngFor="let key of app.overrideMpnFields.manufacturerKeys"
      >
        <dt class="col-sm-5 text-right">
          {{ app.field.getLabel(key) }} &nbsp;
          <i class="far fa-arrow-alt-right ms-1"></i>
        </dt>
        <dd class="col-sm-7">
          {{
            app.field.getFieldValueAsText(
              key,
              app.manufacturer.selectedManufacturer[key.split(".")[1]]
            )
          }}
        </dd>
        <div class="mt-2" *ngIf="key === 'manufacturer.partNumber'">
          <small class="changeColor">
            {{ app.text.field.whereUsedPartNumber }}
          </small>
          <app-where-used-in></app-where-used-in>
        </div>
      </dl>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel text-center m-1" (click)="closeModal()">
      {{ app.text.modal.cancel }}
    </button>
    <button
      class="btn btn-cancel text-center m-1"
      (click)="
        app.overrideMpnFields.setFieldsOnThread(
          app.overrideMpnFields.selectedField
        );
        closeModal()
      "
    >
      {{ app.text.modal.ok }}
    </button>
  </div>
</ng-template>
