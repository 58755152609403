import { ComponentSettings, empty, FieldTypes } from "../settings/settings";

const field = {
  work_type: {
    type: FieldTypes.value,
    text: { de: "Betriebsart", en: "Operation Type", nl: "Type operatie" },
  },
  create_time: {
    type: FieldTypes.number,
    text: { de: "Startzeit", en: "Start Time", nl: "Begintijd" },
  },
  complete_time: {
    type: FieldTypes.number,
    text: { de: "Komplette Zeit", en: "Complete Time", nl: "Volledige tijd" },
  },
  duration: {
    type: FieldTypes.number,
    text: { de: "Laufzeit", en: "Duration", nl: "Duur" },
  },
  user_id: {
    type: FieldTypes.value,
    text: { de: "Gestartet von", en: "Triggered by", nl: "Begonnen door" },
  },
  work_log: {
    type: FieldTypes.data,
    text: { de: "Log", en: "Log", nl: "Log" },
  },
};

const settings = {
  text: {
    operations: {
      de: "Betriebsleiter",
      en: "Operations Manager",
      nl: "Operations Manager",
    },
    actionsTitle: {
      de: "Betrieb Aktionen",
      en: "Operations Actions",
      nl: "Operaties Acties",
    },
    historyTitle: {
      de: "Betrieb Historie",
      en: "Operations History",
      nl: "Operationele geschiedenis",
    },
    run: {
      de: "Lauf",
      en: "Run",
      nl: "Rennen",
    },
    all: {
      de: "Alle",
      en: "All",
      nl: "Alle",
    },
    matchParts: {
      de: "Bauteile matchen",
      en: "Match Parts",
      nl: "Wedstrijd Onderdelen",
    },
    requestDetails: {
      de: "Details abfragen",
      en: "Request Details",
      nl: "Details aanvragen",
    },
    updateDetails: {
      de: "Details setzen",
      en: "Update Details",
      nl: "Details bijwerken",
    },
    generateTree: {
      de: "Baum generieren",
      en: "Generate Tree",
      nl: "Boom genereren",
    },
    deleteDocs: {
      de: "Dokumente löschen",
      en: "Delete Docs",
      nl: "Documenten verwijderen",
    },
    buildBOM: {
      de: "BOM erstellen",
      en: "Build BOM",
      nl: "Boom samenstellen",
    },
    updateImpacts: {
      de: "Auswirkungen der Aktualisierung",
      en: "Update Impacts",
      nl: "Update effecten",
    },
    updateTaskResponsibles: {
      de: "Aufgaben aktualisieren Zuständigkeiten",
      en: "Update Tasks Responsibles",
      nl: "Taken bijwerken Verantwoordelijken",
    },
    matchPartsDescription: {
      de: "Bauteile matchen besteht aus der Suche nach einzelnen Teilenummern im Teilekatalog und der Zuordnung eines oder mehrerer Hersteller aus der Online-Datenbank.",
      en: "Match Parts consists of searching individual part numbers in the parts catalogue and assign one or multiple manufacturers from the online database.",
      nl: "Wedstrijd Onderdelen bestaat uit het zoeken van individuele onderdeelnummers in de onderdelencatalogus en het toewijzen van een of meerdere fabrikanten uit de online database.",
    },
    requestDetailsDescription: {
      de: "Details abfragen besteht darin, detaillierte Daten über jede Teilenummer anzufordern und sie für die Berechnung von Schlüsseleigenschaften wie Obsoleszenzstatus usw. zu speichern.",
      en: "Request Details consists of requesting detailed data about each part number and storing it for calculating key properties like Obsolescence Status, etc.",
      nl: "Details aanvragen bestaat uit het opvragen van gedetailleerde gegevens over elk onderdeelnummer en het opslaan ervan voor het berekenen van belangrijke eigenschappen zoals de verouderingsstatus, enz.",
    },
    updateDetailsDescription: {
      de: "Details setzen besteht darin, alle Herstellerdaten für ein Teil zu aggregieren, dann die Eigenschaften aller Unterteile in der Baugruppe zu aggregieren und dann zusätzliche Warnungen für die Änderungen zu erstellen.",
      en: "Update Details consists of aggregating all manufacturers' data for one part, then aggregating all sub-parts's properties into the assembly, and then creating additional Alerts for the changes.",
      nl: "Details bijwerken bestaat uit het samenvoegen van alle gegevens van de fabrikant voor één onderdeel, vervolgens het samenvoegen van de eigenschappen van alle subonderdelen in de assemblage, en vervolgens het aanmaken van bijkomende waarschuwingen voor de wijzigingen.",
    },
    generateTreeDescription: {
      de: "Die Generierung des Baums besteht in der Erstellung/Aktualisierung der Daten, die im Baum angezeigt werden. Nach der Generierung bleiben die Daten unverändert, bis der Baum aufgrund von Auslösern (Import / Manuelle Bearbeitung einer Baugruppe / Manuelle Auslösung (nur für die Administratorrolle)) neu generiert wird.",
      en: "Generate Tree consists on creating/updating the data the is displayed on the tree. Once generated the data stays the same until the tree is regenerated on triggers (Import / Manual editing of an assembly / Manual triggered (Admin role only)). The newly uploaded data won't be displayed unless the tree has been generated after the upload.",
      nl: "Genereer boom bestaat uit het creëren/updaten van de gegevens die worden weergegeven in de boom. Eenmaal gegenereerd blijven de gegevens hetzelfde totdat de boom wordt geregenereerd op triggers (import / handmatig bewerken van een assembly / handmatig getriggerd (alleen Admin rol)). De nieuw geuploade gegevens zullen niet worden weergegeven tenzij de boom is gegenereerd na de upload.",
    },
    deleteDocsDescription: {
      de: "Die Löschung der Dokumente wurde erfolgreich ausgelöst! Bitte aktualisieren Sie die Seite, um die Änderungen zu sehen.",
      en: "The deletion of the documents has been triggered successfully! Please refresh the page to see the changes.",
      nl: "The deletion of the documents has been triggered successfully! Please refresh the page to see the changes.",
    },
    buildBOMDescription: {
      de: "Build BOM besteht aus der Erstellung einer Liste aller erweiterten Unterteile für jede CPN im System. Dies ist ein zeitaufwändiger Vorgang, der daher separat ausgeführt werden sollte. Er muss vor 'Details setzen' ausgeführt werden, da sonst falsche Daten in die Struktur gelangen können.",
      en: "Build BOM consists of building a list of all expanded sub-parts for each CPN in the system. This is a time consuming operation so it should be run separately. Must be run before 'Update Details' otherwise wrong data may end up in the Tree.",
      nl: "De BOM samenstellen bestaat uit het samenstellen van een lijst met alle uitgebreide subonderdelen voor elke CPN in het systeem. Dit is een tijdrovende bewerking en moet daarom apart worden uitgevoerd. Moet worden uitgevoerd vóór 'Details bijwerken', anders kunnen er verkeerde gegevens in de boomstructuur terechtkomen.",
    },
    updateImpactsDescription: {
      de: "Die Aktualisierung der Auswirkungen besteht in der Aktualisierung aller Auswirkungen aus allen Threads auf der Grundlage der Fahrzeugdokumente",
      en: "Update impacts consists of updating all impacts from all threads based on the vehicles documents",
      nl: "De impacts bijwerken bestaat uit het bijwerken van alle impacts van alle threads op basis van de voertuigdocumenten",
    },
    inProgressMessage: {
      de: "In Bearbeitung",
      en: "In progress",
      nl: "In uitvoering",
    },
    remainingCount: {
      de: "verbleibend",
      en: "remaining",
      nl: "resterend",
    },
    usedCount: {
      de: "gebraucht",
      en: "used",
      nl: "gebruikt",
    },
    limitCount: {
      de: "Part Detail Limit",
      en: "Part Detail Limit",
      nl: "Part Detail Limit",
    },
    expirationDate: {
      de: "Expiration Date",
      en: "Expiration Date",
      nl: "Expiration Date",
    },
    createDate: {
      de: "Creation Date",
      en: "Creation Date",
      nl: "Creation Date",
    },
    generateAlerts: {
      de: "Generate Alerts",
      en: "Generate Alerts",
      nl: "Generate Alerts",
    },
    saveData: {
      de: "Save data",
      en: "Save data",
      nl: "Save data",
    },
  },
  url: {},
  field,
  list: {
    columns: empty,
  },
  permission: {
    seeOperations: false,
    seeOperationsHistory: false,
    seeMatchParts: false,
    seeRequestDetails: false,
    seeUpdatesDetails: false,
    seeGenerateTree: false,
    seeBuildBOM: false,
    seeUpdatesImpacts: false,
    seeGenerateAlerts: false,
  },
  additionalInfo: {},
};

export const operations: ComponentSettings<typeof settings> = settings;
