import { typeCategories } from "../typeCategories";
import {
  ComponentSettings,
  empty,
  FieldTypes,
  FilterTypes,
} from "../settings/settings";
import {
  obsolescenceStatusFields,
  obsolescencelifeCycleRiskOptions,
  likelihoodConst,
  euRoHSStatusFields,
  obsolescenceStatusOptions,
  leadTimeConst,
} from "./part.settings";

export const checkBoxOptions: any = {
  true: { text: { de: "Wahr", en: "True", nl: "Ware" } },
  false: { text: { de: "Falsch", en: "False", nl: "Fout" } },
};

export const reachAffectedStatusOptions = {
  yes: {
    text: {
      de: "Yes",
      en: "Yes",
    },
    color: "#EB5757",
  },
  no: {
    text: {
      de: "No",
      en: "No",
    },
    color: "#27AE60",
  },
  unknown: {
    text: {
      de: "Unknown",
      en: "Unknown",
    },
    color: "#F7F7F7",
  },
};

export const tscaStatusOptions = {
  uncompliant: {
    text: {
      de: "Uncompliant",
      en: "Uncompliant",
    },
    color: "#EB5757",
  },
  compliant: {
    text: {
      de: "Compliant",
      en: "Compliant",
    },
    color: "#27AE60",
  },
  unknown: {
    text: {
      de: "Unknown",
      en: "Unknown",
    },
    color: "#F7F7F7",
  },
};

export const pfasStatusOptions = {
  affected: {
    text: { de: "Affected", en: "Affected", nl: "Affected" },
    color: "#EB5757",
  },
  not_affected: {
    text: {
      de: "Not Affected",
      en: "Not Affected",
      nl: "Not Affected",
    },
    color: "#27AE60",
  },
  unknown: {
    text: { de: "Unknown", en: "Unknown", nl: "Unknown" },
    color: "#F7F7F7",
  },
};

export const californiaStatusOptions = {
  exempted: {
    text: { de: "Exempted", en: "Exempted", nl: "Exempted" },
    color: "#27AE60",
  },
  not_exempted: {
    text: { de: "Not Exempted", en: "Not Exempted", nl: "Not Exempted" },
    color: "#EB5757",
  },
  unknown: {
    text: { de: "Unknown", en: "Unknown", nl: "Unknown" },
    color: "#F7F7F7",
  },
};

const field = {
  partNumber: {
    type: FieldTypes.value,
    text: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    filter: FilterTypes.startsWith,
    mutable: false,
    search: true,
  },
  manufacturerPartNumber: {
    type: FieldTypes.value,
    text: {
      de: "Herstellerteilenummer (HTN)",
      en: "Manufacturer Part Number (MPN)",
      nl: "Fabrikant Artikel Nummer (MPN)",
    },
    filter: FilterTypes.startsWith,
    search: true,
  },
  obsolescenceStatusOverride: {
    text: {
      de: "Status manuell gesetzt",
      en: "Manual override",
      nl: "Status handmatig ingesteld",
    },
    type: FieldTypes.checkbox,
    search: false,
    checkboxLabels: checkBoxOptions,
  },
  purchasePartNumber: {
    type: FieldTypes.value,
    text: {
      de: "Typenbezeichnung",
      en: "Type Designation",
      nl: "Typeaanduiding",
    },
    filter: FilterTypes.startsWith,
    search: true,
  },
  active: {
    type: FieldTypes.radio,
    text: {
      de: "Bevorzugte Einheit",
      en: "Preferred Item",
      nl: "Geprefereerde Eenheid",
    },
    options: {
      true: { text: { de: "Ja", en: "Yes", nl: "Ja" } },
      false: { text: { de: "Nein", en: "No", nl: "Nee" } },
    },
    search: false,
  },
  valid: {
    type: FieldTypes.checkbox,
    text: {
      de: "Gültig",
      en: "Valid",
      nl: "Geldig",
    },
    checkboxLabels: checkBoxOptions,
  },
  name: {
    type: FieldTypes.value,
    text: { de: "Hersteller", en: "Manufacturer", nl: "Fabrikant" },
    search: true,
  },
  ...obsolescenceStatusFields,
  ...likelihoodConst,
  ...euRoHSStatusFields,
  ...leadTimeConst,
  euRoHSStatus: euRoHSStatusFields.euRoHS,
  obsolescenceStatus: {
    type: FieldTypes.options,
    text: {
      de: "Aktueller Obsoleszenzstatus",
      en: "Current Obsolescence Status",
      nl: "Actuele Obsolescence Status",
    },
    options: obsolescenceStatusOptions,
    mutable: true,
    search: true,
  },
  nameRaw: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller (raw)",
      en: "Manufacturer (raw)",
      nl: "Fabrikant (raw)",
    },
    search: true,
  },
  nameClean: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller (clean)",
      en: "Manufacturer (clean)",
      nl: "Fabrikant (clean)",
    },
    search: false,
  },
  code: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller Code",
      en: "Manufacturer Code",
      nl: "Fabrikant  Code",
    },
    search: false,
  },
  manufacturerPartNumberRaw: {
    type: FieldTypes.value,
    text: {
      de: "Herstellerteilenummer (HTN) (raw)",
      en: "Manufacturer Part Number (MPN) (raw)",
      nl: "Fabrikant Artikel Nummer (MPN) (raw)",
    },
    filter: FilterTypes.startsWith,
    search: false,
  },
  manufacturerPartNumberClean: {
    type: FieldTypes.value,
    text: {
      de: "Herstellerteilenummer (HTN) (clean)",
      en: "Manufacturer Part Number (MPN) (clean)",
      nl: "Fabrikant Artikel Nummer (MPN) (clean)",
    },
    filter: FilterTypes.startsWith,
    search: false,
  },
  manufacturerPartDescription: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller Bezeichnung der Einheit",
      en: "Manufacturer Designation of the Item",
      nl: "Fabrikant Artikel omschrijving",
      search: false,
    },
    search: false,
  },
  manufacturerPartDescriptionRaw: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller Bezeichnung der Einheit (raw)",
      en: "Manufacturer Designation of the Item (raw)",
      nl: "Fabrikant Artikel omschrijving (raw)",
    },
    search: false,
  },
  manufacturerPartDescriptionClean: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller Bezeichnung der Einheit (clean)",
      en: "Manufacturer Designation of the Item (clean)",
      nl: "Fabrikant Artikel omschrijving (clean)",
    },
    search: false,
  },
  obsolescenceDate: {
    text: { de: "Obsoleszenz", en: "Obsolescence", nl: "Obsolescence" },
    type: FieldTypes.date,
    search: false,
  },
  startOfProduction: {
    type: FieldTypes.date,
    text: {
      de: "Beginn der Produktion (SOP)",
      en: "Start of Production (SOP)",
      nl: "Start van de productie (SOP)",
    },
    search: false,
  },
  seId: {
    type: FieldTypes.value,
    text: {
      de: "Silicon Expert ComID",
      en: "Silicon Expert ComID",
      nl: "Silicon Expert ComID",
    },
    mutable: false,
    search: false,
  },
  z2DataId: {
    type: FieldTypes.value,
    text: {
      de: "Z2Data Part Id",
      en: "Z2Data Part Id",
      nl: "Z2Data Part Id",
    },
    mutable: false,
    search: false,
  },
  estimatedYearsToEOL: {
    type: FieldTypes.number,
    text: {
      de: "Vorhersage Jahre bis Einstellung der Produktion (YTEOP)",
      en: "Years to End of Production (YTEOP) Forecast",
      nl: "Years to End of Production (YTEOP) Forecast",
    },
    search: true,
  },
  estimatedEOLDate: {
    text: {
      de: "Vorhersage Einstellung der Produktion (EOP)",
      en: "End of Production (EOP) Forecast",
      nl: "End of Production (EOP) Forecast",
    },
    type: FieldTypes.date,
    search: true,
  },
  ltbDate: {
    text: {
      de: "Einstellung des Vertriebs (EOS) (Last Time Buy - LTB)",
      en: "End of Sale (EOS) (Last Time Buy - LTB)",
      nl: "Last Need Buy (LNB)",
    },
    type: FieldTypes.date,
    search: true,
  },
  cage: {
    type: FieldTypes.value,
    text: {
      de: "Hersteller CAGE Code",
      en: "Manufacturer CAGE Code",
      nl: "Manufacturer CAGE Code",
    },
    search: false,
  },
  risk: {
    type: FieldTypes.options,
    text: {
      de: "Obsoleszenzwahrscheinlichkeit",
      en: "Obsolescence Risk",
      nl: "Risico",
    },
    options: {
      low: { text: { de: "Gering", en: "Low", nl: "Low" } },
      medium: { text: { de: "Mittel", en: "Medium", nl: "Medium" } },
      high: { text: { de: "Hoch", en: "High", nl: "High" } },
    },
    search: false,
  },
  euRoHSVersion: {
    type: FieldTypes.value,
    text: { de: "RoHS Version", en: "RoHS Version", nl: "RoHS Version" },
    search: false,
  },
  package: {
    type: FieldTypes.value,
    text: { de: "Gehäuse", en: "Package", nl: "Verpakking" },
    search: false,
  },
  packageDiameter: {
    type: FieldTypes.value,
    text: { de: "Gehäuse Durchmesser", en: "Package Diameter" },
    search: false,
  },
  packageLength: {
    type: FieldTypes.value,
    text: { de: "Gehäuse Länge", en: "Package Length" },
    search: false,
  },
  packageWidth: {
    type: FieldTypes.value,
    text: { de: "Gehäuse Breite", en: "Package Width" },
    search: false,
  },
  packageHeight: {
    type: FieldTypes.value,
    text: { de: "Gehäuse Höhe", en: "Package Height" },
    search: false,
  },
  encryptionStandard: {
    type: FieldTypes.value,
    text: { de: "Encryption Standard", en: "Encryption Standard" },
    search: false,
  },
  yearsToEndOfLife: {
    type: FieldTypes.value,
    text: {
      de: "Vorhersage Einstellung der Produktion (YTEOL)",
      en: "End of Production Forecast (YTEOL)",
      nl: "End of Production Forecast (YTEOL)",
    },
    search: false,
  },
  sources: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl der Hersteller (Duplikate entfernt)",
      en: "Number of Manufacturers (Duplicates removed)",
      nl: "Aantal Fabrikanten (Duplicaten verwijderd)",
    },
    search: false,
  },
  taxonomy: {
    type: FieldTypes.value,
    text: {
      de: "Kategorie der Einheit",
      en: "Category of the Item",
      nl: "Item Product Categorie",
    },
    search: false,
  },
  taxonomyPath: {
    type: FieldTypes.value,
    text: {
      de: "Taxonomie Pfad",
      en: "Taxonomy Path",
      nl: "Taxonomy Path",
    },
    search: false,
  },
  conformityCertificate: {
    type: FieldTypes.value,
    text: {
      de: "Konformitätszertifikat",
      en: "Conformity Certificate",
      nl: "Conformiteitscertificaat",
    },
    search: false,
  },
  datasheet: {
    type: FieldTypes.value,
    text: {
      de: "Data Sheet",
      en: "Data Sheet",
      nl: "Data Sheet",
    },
    search: false,
  },
  lifecycleCode: {
    type: FieldTypes.options,
    text: {
      de: "Lebenszykluscode",
      en: "Life Cycle Code",
      nl: "Life Cycle Code",
    },
    options: {
      introduction: {
        text: {
          de: "Markteinführung",
          en: "Introduction",
        },
        color: "#919191",
      },
      growth: {
        text: {
          de: "Wachstum",
          en: "Growth",
        },
        color: "#4BCA79",
      },
      mature: {
        text: {
          de: "Reife",
          en: "Mature",
        },
        color: "#27AE60",
      },
      decline: {
        text: {
          de: "Sättigung",
          en: "Decline",
        },
        color: "#F2C94C",
      },
      phaseOut: {
        text: {
          de: "Auslaufphase",
          en: "Phase Out",
        },
        color: "#FA9C1B",
      },
      obsolete: {
        text: {
          de: "Obsolete",
          en: "Obsolete",
        },
        color: "#EB5757",
      },
      unknown: {
        text: {
          de: "Unknown",
          en: "Unknown",
        },
        color: "#F7F7F7",
      },
    },
    search: true,
  },
  eccn: {
    type: FieldTypes.value,
    text: {
      de: "Exportkontrolle",
      en: "Export Control",
      nl: "Export Control",
    },
    search: false,
  },
  minTemp: {
    type: FieldTypes.value,
    text: {
      de: "Min. Temp (°C)",
      en: "Min. Temp (°C)",
      nl: "Min. Temp (°C)",
    },
    search: false,
  },
  maxTemp: {
    type: FieldTypes.value,
    text: {
      de: "Max. Temp (°C)",
      en: "Max. Temp (°C)",
      nl: "Max. Temp (°C)",
    },
    search: false,
  },
  crossType: {
    type: FieldTypes.value,
    text: { de: "Cross-Typ", en: "Cross-Type", nl: "Cross-Type" },
    search: false,
  },
  differences: {
    type: FieldTypes.value,
    text: { de: "Unterschiede", en: "Differences", nl: "Verschillen" },
    search: false,
  },
  replacementType: {
    type: FieldTypes.value,
    text: {
      de: "Replacement-Typ",
      en: "Replacement-Type",
      nl: "Replacement-Type",
    },
    search: false,
  },
  eolLink: {
    type: FieldTypes.value,
    text: {
      de: "Download PCN/PDN",
      en: "Download PCN/PDN",
      nl: "Download PCN/PDN",
    },
    search: false,
  },
  pcnHistory: {
    type: FieldTypes.value,
    text: {
      en: "PCN/PDN History",
      de: "PCN/PDN Historie",
      nl: "PCN/PDN Geschiedenis",
    },
  },
  seStatus: {
    type: FieldTypes.value,
    text: { de: "SE Status", en: "SE Status", nl: "SE Status" },
    search: false,
  },
  environmentalLink: {
    type: FieldTypes.value,
    text: { de: "Link", en: "Link", nl: "Link" },
    search: false,
  },
  conflictMineralStatus: {
    type: FieldTypes.options,
    text: { de: "Konfliktmineralien", en: "Conflict Mineral" },
    options: {
      drc: {
        text: {
          de: "DRC Conflict Free",
          en: "DRC Conflict Free",
        },
        color: "#27AE60",
      },
      partialUndeterminable: {
        text: {
          de: "DRC Conflict Free (Undeterminable for specific parts)",
          en: "DRC Conflict Free (Undeterminable for specific parts)",
        },
        color: "#27AE60",
      },
      ndrc: {
        text: {
          de: "Not DRC Conflict Free",
          en: "Not DRC Conflict Free",
        },
        color: "#EB5757",
      },
      undeterminable: {
        text: {
          de: "DRC Conflict Undeterminable",
          en: "DRC Conflict Undeterminable",
        },
        color: "#F7F7F7",
      },
      unknown: {
        text: {
          de: "Unknown",
          en: "Unknown",
        },
        color: "#F7F7F7",
      },
    },
    search: false,
  },
  conflictMineralStatement: {
    type: FieldTypes.value,
    text: {
      de: "Konfliktmineralaussage",
      en: "Conflict Mineral Statement",
    },
    search: false,
  },
  chinaRoHSStatus: {
    type: FieldTypes.options,
    text: {
      de: "China RoHS betroffen",
      en: "China RoHS Affected",
      nl: "China RoHS betrokken",
    },
    options: {
      yes: {
        text: {
          de: "Yes",
          en: "Yes",
        },
        color: "#EB5757",
      },
      no: {
        text: {
          de: "No",
          en: "No",
        },
        color: "#27AE60",
      },
      unknown: {
        text: {
          de: "Unknown",
          en: "Unknown",
        },
        color: "#F7F7F7",
      },
    },
  },
  chinaRoHSDeclaration: {
    type: FieldTypes.value,
    text: {
      de: "China RoHS Material Deklaration",
      en: "China RoHS Material Declaration",
      nl: "China RoHS Materiële Verklaring",
    },
    search: false,
  },
  tscaStatus: {
    type: FieldTypes.options,
    text: {
      de: "TSCA Status",
      en: "TSCA Status",
      nl: "TSCA Status",
    },
    options: tscaStatusOptions,
  },
  tscaDeclaration: {
    type: FieldTypes.value,
    text: {
      de: "TSCA Deklaration",
      en: "TSCA Declaration",
      nl: "TSCA Verklaring",
    },
    search: false,
  },
  image: {
    type: FieldTypes.value,
    text: { de: "Bild", en: "Image", nl: "Beeld" },
    search: false,
  },
  loadReplacements: {
    type: FieldTypes.value,
    text: {
      de: "Crosses und Replacements laden",
      en: "Load Crosses and Replacements",
      nl: "Crosses en Replacements laden",
    },
    search: false,
  },
  _id: {
    type: FieldTypes.value,
    text: {
      de: "Id",
      en: "Id",
    },
    search: false,
  },
  productEOSR: {
    type: FieldTypes.date,
    text: {
      de: "Vorhersage Einstellung Service & Reparatur (EOSR)",
      en: "End of Service & Repair (EOSR) Forecast",
      nl: "Jaren tot het einde van de dienst en reparatie (EOSR)",
    },
    csv: "EOSR",
    search: false,
  },
  productYearsToEOSR: {
    type: FieldTypes.number,
    text: {
      de: "Vorhersage Jahre Einstellung Service & Reparatur (YTEOSR)",
      en: "Years to End of Service & Repair (YTEOSR) Forecast",
      nl: "Jaren tot het einde van de dienst en reparatie (YTEOSR)",
    },
    csv: "EOSR",
    search: false,
  },
  likelihood_yteol: {
    type: FieldTypes.options,
    text: {
      de: "Wahrscheinlichkeit basierend auf YTEOL Vorhersage",
      en: "Likelihood based on YTEOL Forecast",
      nl: "Waarschijnlijkheid op basis van YTEOL verwachting",
    },
    options: obsolescencelifeCycleRiskOptions,
    mutable: false,
    search: false,
  },
  likelihood_yteosr: {
    type: FieldTypes.options,
    text: {
      de: "Wahrscheinlichkeit basierend auf YTEOSR Vorhersage",
      en: "Likelihood based on YTEOSR Forecast",
      nl: "Waarschijnlijkheid op basis van YTEOSR verwachting",
    },
    options: obsolescencelifeCycleRiskOptions,
    mutable: false,
    search: false,
  },
  numberOfMatches: {
    type: FieldTypes.value,
    text: {
      de: "Number of matches",
      en: "Number of matches",
      nl: "Number of matches",
    },
    search: false,
  },
  countryOfOrigin: {
    type: FieldTypes.text,
    text: {
      de: "Herkunftsland",
      en: "Country of Origin",
      nl: "Land van Oorsprong",
    },
    search: true,
  },
  customsTariff: {
    type: FieldTypes.value,
    text: {
      de: "TARIC",
      en: "TARIC",
      nl: "TARIC",
    },
    search: false,
  },
  htsusa: {
    type: FieldTypes.value,
    text: {
      de: "HTSUSA",
      en: "HTSUSA",
      nl: "HTSUSA",
    },
    search: false,
  },
  //reachAffected was also created in part.settings because on the manufacturers filters it has to be disaplyed but on the parts filters no
  reachAffected: {
    type: FieldTypes.options,
    text: {
      de: "Enthält SVHC",
      en: "Contains SVHC",
      nl: "Bevat SVHC",
    },
    options: reachAffectedStatusOptions,
  },
  reachSubstances: {
    type: FieldTypes.value,
    text: {
      de: "SVHC",
      en: "SVHC",
      nl: "SVHC",
    },
    search: true,
  },
  svhcItems: {
    type: FieldTypes.value,
    text: {
      de: "SVHC Details",
      en: "SVHC Details",
      nl: "SVHC Details",
    },
    search: false,
  },
  svhcListVersion: {
    type: FieldTypes.date,
    text: {
      de: "EU SVHC Liste Version",
      en: "EU SVHC List Version",
      nl: "EU SVHC List Version",
    },
    search: true,
  },
  materialDeclaration: {
    type: FieldTypes.value,
    text: {
      de: "Material Deklaration",
      en: "Material Declaration",
      nl: "Materiële Verklaring",
    },
    search: false,
  },
  edited: {
    type: FieldTypes.checkbox,
    text: {
      de: "Manuelle Zuweisung erfordelich",
      en: "Manual assignment required",
      nl: "Handmatige toewijzing vereist",
    },
    checkboxLabels: checkBoxOptions,
  },
  searchResults: {
    type: FieldTypes.value,
    text: {
      de: "Hat SE Suchergebnisse",
      en: "Has SE search results",
      nl: "Heeft SE-zoekresultaten",
    },
  },
  averagePrice: {
    type: FieldTypes.number,
    text: {
      de: "Letzter bekannter Durchschnittspreis (USD)",
      en: "Last known Average Price (USD)",
      nl: "Laatst bekende gemiddelde prijs (USD)",
    },
  },
  typeOfChange: {
    type: FieldTypes.value,
    text: {
      de: "Kategorie der Art der PCN/PDN",
      en: "Category of the type of PCN/PDN",
      nl: "Categorie van wijziging",
    },
    options: typeCategories,
  },
  effectiveDate: {
    type: FieldTypes.value,
    text: {
      de: "Effective Date",
      en: "Effective Date",
      nl: "Effectieve datum",
    },
  },
  manufacturerEditedFields: {
    type: FieldTypes.value,
    text: {
      de: "Manufacturer Fields eddited",
      en: "Manufacturer Fields eddited",
      nl: "Manufacturer Fields eddited",
    },
    search: false,
    multiple: true,
  },
  lastCheckDate: {
    type: FieldTypes.value,
    text: {
      de: "Last Check Date",
      en: "Last Check Date",
      nl: "Last check date",
    },
  },
  lastUpdatedOn: {
    type: FieldTypes.value,
    text: {
      de: "Aktualisiert am",
      en: "Updated on",
      nl: "Bijgewerkt op",
    },
    mutable: false,
    search: true,
  },
  maxWeeks: {
    type: FieldTypes.value,
    text: {
      de: "Max Hersteller Lieferzeiten (Wochen)",
      en: "Max Manufacturer Lead Time (weeks)",
      nl: "Max Manufacturer Lead Time (weeks)",
    },
    search: true,
  },
  minWeeks: {
    type: FieldTypes.value,
    text: {
      de: "Min Hersteller Lieferzeiten (Wochen)",
      en: "Min Manufacturer Lead Time (weeks)",
      nl: "Min Manufacturer Lead Time (weeks)",
    },
    search: true,
  },
  partner: {
    type: FieldTypes.value,
    text: {
      de: "Lieferant",
      en: "Supplier",
      nl: "Leverancier",
    },
    search: true,
  },
  /* substanceIdentification, casNumber and concentration are fields used only for labeling in substance export **/
  substanceIdentification: {
    type: FieldTypes.value,
    text: {
      de: "Stoffidentifikation",
      en: "Substance Identification",
      nl: "Stofidentificatie",
    },
    search: false,
  },
  casNumber: {
    type: FieldTypes.value,
    text: {
      de: "CAS Nummer",
      en: "CAS Number",
      nl: "CAS Nummer",
    },
    search: false,
  },
  concentration: {
    type: FieldTypes.value,
    text: {
      de: "Konzentration [ppm]",
      en: "Concentration [ppm]",
      nl: "Concentratie [ppm]",
    },
    search: false,
  },
  thresholdLimitExceeded: {
    type: FieldTypes.value,
    text: {
      en: "Exceeds Threshold Limit",
      de: "Überschreitet Schwellenwert",
      nl: "Overschrijdt de drempellimiet",
    },
    search: false,
  },
  pcnSource: {
    type: FieldTypes.value,
    text: {
      de: "PCN Source",
      en: "PCN Source",
      nl: "PCN Source",
    },
  },
  pcnEffectiveDate: {
    type: FieldTypes.timestamp,
    text: {
      de: "Effective Date",
      en: "Effective Date",
      nl: "Ingangsdatum",
    },
  },
  pcnTypeOfChange: {
    type: FieldTypes.options,
    text: {
      de: "Kategorie der Art der PCN/PDN",
      en: "Category of the type of PCN/PDN",
      nl: "Categorie PCN/PDN",
    },
    options: typeCategories,
    multiple: true,
  },
  pcnID: {
    type: FieldTypes.value,
    xml: "pcnNumber",
    text: {
      de: "Identifikationsschlüssel (ID) der PCN/PDN",
      en: "Identification Key (ID) of the PCN/PDN",
      nl: "Identificatieschlüssel (ID) der PCN/PDN",
    },
    maxLength: 128,
  },
  pcnDescription: {
    type: FieldTypes.value,
    text: {
      de: "Beschreibung der Änderung",
      en: "Description of Change",
      nl: "Beschrijving van de wijziging",
    },
  },
  pcnIssueDate: {
    type: FieldTypes.timestamp,
    text: {
      de: "Datum der Veröffentlichung",
      en: "Issue Date",
      nl: " Publicatiedatum",
    },
  },
  exemption: {
    type: FieldTypes.value,
    text: {
      de: "Ausnahme",
      en: "Exemption",
      nl: "Exemption",
    },
    search: false,
  },
  expirationDate: {
    type: FieldTypes.date,
    text: {
      de: "Ablaufdatum",
      en: "Expiration Date",
      nl: "Afloopdatum",
    },
    search: false,
  },
  stockRange: {
    type: FieldTypes.number,
    text: {
      de: "Stock Range in Months",
      en: "Stock Range in Months",
      nl: "Stock Range in Months",
    },
    search: true,
  },
  sapStatus: {
    type: FieldTypes.value,
    text: {
      de: "Materialstatus (SAP)",
      en: "Material status (SAP)",
    },
    search: true,
  },
  designType: {
    type: FieldTypes.value,
    text: {
      de: "Bauart / Typ",
      en: "Design / Type",
    },
    search: true,
  },
  supplierNumber: {
    type: FieldTypes.value,
    text: {
      de: "Supplier Number",
      en: "Supplier Number",
    },
    search: false,
  },
  materialName: {
    type: FieldTypes.value,
    text: {
      de: "Klassenname",
      en: "Class Name",
    },
  },
  materialClass: {
    type: FieldTypes.value,
    text: {
      de: "Materialklasse",
      en: "Material Class",
    },
  },
  supplierPending: {
    type: FieldTypes.checkbox,
    text: {
      de: "Informationen vom Lieferanten ausstehend",
      en: "Information from Supplier Pending",
      nl: "Informatie van leverancier in afwachting",
    },
    checkboxLabels: {
      true: { text: { de: "Pending", en: "Pending", nl: "Pending" } },
      false: { text: { de: "", en: "", nl: "" } },
    },
  },
  instructions: {
    type: FieldTypes.value,
    text: {
      de: "Besondere Anweisungen",
      en: "Special instructions",
      nl: "Speciale instructies",
    },
  },
  pfasStatus: {
    type: FieldTypes.options,
    text: {
      de: "Globale PFAS Bewertung Status",
      en: "Global PFAS Assessment Status",
      nl: "Wereldwijde PFAS beoordeling Status",
    },
    options: pfasStatusOptions,
    search: false,
  },
  pfasDeclaration: {
    type: FieldTypes.value,
    text: {
      de: "Globale PFAS Deklaration",
      en: "Global PFAS Declaration",
      nl: "Wereldwijde PFAS Verklaring",
    },
  },
  californiaStatus: {
    type: FieldTypes.options,
    text: {
      de: "California Proposition 65 Status",
      en: "California Proposition 65 Status",
      nl: "California Proposition 65 Status",
    },
    options: californiaStatusOptions,
    search: false,
  },
  californiaDeclaration: {
    type: FieldTypes.value,
    text: {
      de: "California Proposition 65 Deklaration",
      en: "California Proposition 65 Declaration",
      nl: "California Proposition 65 Verklaring",
    },
  },
  totalRisk: {
    type: FieldTypes.options,
    text: {
      de: "Obsoleszenzrisiko",
      en: "Obsolescence Risk",
      nl: "Risico",
    },
    options: obsolescencelifeCycleRiskOptions,
    mutable: false,
  },
  mtbf: {
    type: FieldTypes.number,
    text: {
      de: "MTBF (Stunden)",
      en: "MTBF (hours)",
      nl: "MTBF (hours)",
    },
  },
  distributor: {
    type: FieldTypes.value,
    text: {
      de: "Name Lieferant",
      en: "Distributor Name",
      nl: "Distributor Name",
    },
  },
  distributorType: {
    type: FieldTypes.value,
    text: {
      de: "Tup Lieferant",
      en: "Distributor Type",
      nl: "Distributor Type",
    },
  },
  quantity: {
    type: FieldTypes.value,
    text: {
      de: "Menge",
      en: "Quantity",
      nl: "Quantity",
    },
  },
  lastUpdated: {
    type: FieldTypes.value,
    text: {
      de: "Zuletzt Aktualisiert (Tagen) ",
      en: "Last updated (days)",
      nl: "Last updated (days)",
    },
  },
  numberOfDistributors: {
    type: FieldTypes.value,
    text: {
      de: "Anzahl Lieferanten",
      en: "Number of Distributors",
      nl: "Number of Distributors",
    },
    mutable: false,
  },
  totalMarketInv: {
    type: FieldTypes.value,
    text: {
      de: "Gesamter Marktbestand (Duplikate entfernt)",
      en: "Total Market Inventory (Duplicates removed)",
      nl: "Totale Marktinventaris (Duplicaten verwijderd)",
    },
    mutable: false,
  },
  ipc: {
    type: FieldTypes.value,
    text: {
      de: "IPC-1752A",
      en: "IPC-1752A",
      nl: "IPC-1752A",
    },
    search: false,
  },
};

// review permisions
const settings = {
  permission: {
    edit: false,
    displayDataProviderId: false,
    deleteManufacturer: false,
    canMatch: false,
  },
  text: {
    manufacturer: { de: "Hersteller", en: "Manufacturer", nl: "Manufacturer" },
    save: { de: "Änderungen übernehmen", en: "Save changes" },
    savedSuccesfully: {
      de: "Hersteller erfolgreich gespeichert",
      en: "Manufacturer saved successfully",
      nl: "Fabrikant succesvol opgeslagen",
    },
    edit: {
      de: "Bearbeiten",
      en: "Edit",
      nl: "Bewerk",
    },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard changes",
      nl: "Wijzigingen negeren",
    },
    deleteManufacturers: {
      de: "Hersteller löschen",
      en: "Delete manufacturers",
      nl: "Verwijder de fabrikant",
    },
    crossesAndReplacements: {
      de: "Crosses und Replacements",
      en: "Crosses and Replacements",
      nl: "Crosses en Replacements",
    },

    modalTitle: { de: "Bestätigung", en: "Confirm", nl: "Bevestigen" },

    close: { de: "Schließen", en: "Close" },
    assignAsPreferred: {
      de: "Als Bevorzugte Einheit markieren",
      en: "Assign as preferred item",
    },
    unassignAsPreferred: {
      de: "Als Bevorzugte Einheit abwählen",
      en: "Unassign as preferred item",
    },
    clientSection: {
      de: "Kundendaten (Import)",
      en: "Client Data (Import)",
      nl: "Client Data (Import)",
    },
    matchingSection: {
      de: "Datenbankabgleich",
      en: "Matching Results",
      nl: "Passende resultaten",
    },
    obsolescenceSection: {
      de: "Obsoleszenzdaten",
      en: "Obsolescence Data",
      nl: "Obsolescence Data",
    },
    tehnicalSection: {
      de: "Technische und weitere Daten",
      en: "Technical and further Data",
      nl: "Technische en verdere Data",
    },
    supplyChainSection: {
      de: "Supply Chain",
      en: "Supply Chain",
      nl: "Supply Chain",
    },
    environmentalCompliance: {
      de: "Umweltkonformität",
      en: "Environmental Compliance",
      nl: "Milieuconformiteit",
    },
    history: { de: "Historie", en: "History", nl: "Historie" },
    loadingReplacement: {
      de: "Daten werden geladen. Bitte warten Sie einen Moment...",
      en: "Loading data. Please wait a moment...",
      nl: "Data wordt geladen, een moment geduld …",
    },
    replacementsNoResults: {
      de: "Keine Ergebnisse gefunden",
      en: "No results found",
      nl: "Geen resultaten gevonden",
    },
    cancelOverride: {
      de: "Möchten Sie diese Aktion wirklich ausführen? Alle manuell eingegebenen Daten gehen verloren",
      en: "Do you really want to perform this action? All manually inserted data will be lost",
    },
    createNewManufacturer: {
      de: "Neuen Hersteller hinzufügen",
      en: "Add new manufacturer",
      nl: "Nieuwe fabrikant toevoegen",
    },
    remove: {
      de: "Entfernen",
      en: "Remove",
      nl: "Verwijderen",
    },
    deleteManufacturer: {
      de: "Hersteller löschen",
      en: "Delete manufacturer",
      nl: "Fabrikant verwijderen",
    },
    removeModal: {
      de: "Sind Sie sicher, dass Sie die ausgewählten HTN entfernen möchten?",
      en: "Are you sure you want to remove the selected MPNs?",
      nl: "Weet u zeker dat je de geselecteerde MPN's wilt verwijderen?",
    },
    deleteModal: {
      de: "Sind Sie sicher, dass Sie die ausgewählten HTN löschen möchten?",
      en: "Are you sure you want to delete the selected MPNs?",
      nl: "Weet u zeker dat je de geselecteerde MPN's wilt verwijderen?",
    },
    createdSuccesfully: {
      de: "Hersteller erfolgreich erstellt",
      en: "Manufacturer created successfully",
      nl: "Fabrikant met succes gemaakt",
    },
    deletedSuccesfully: {
      de: "Hersteller erfolgreich gelöscht",
      en: "Manufacturer deleted successfully",
      nl: "Fabrikant succesvol verwijderd",
    },
    link: { de: "Link", en: "Link", nl: "Link" },
    substanceIdentification: {
      de: "Stoffidentifikation",
      en: "Substance Identification",
      nl: "Stofidentificatie",
    },
    casNumber: {
      de: "CAS Nummer",
      en: "CAS Number",
      nl: "CAS Nummer",
    },
    concentration: {
      de: "Konzentration [ppm]",
      en: "Concentration [ppm]",
      nl: "Concentratie [ppm]",
    },
    svhcDetailsModalTitle: {
      de: "SVHC Details",
      en: "SVHC Details",
      nl: "SVHC Details",
    },
    deleteSvhcModalText: {
      de: "Wollen Sie diesen SVHC wirklich entfernen?",
      en: "Do you really want to remove the SVHC?",
      nl: "Wil je echt de SVHC verwijderen?",
    },
    pcnHistory: {
      en: "PCN/PDN History",
      de: "PCN/PDN Historie",
      nl: "PCN/PDN Geschiedenis",
    },
    addNewLineSvhc: {
      de: "Neue Zeile hinzufügen",
      en: "Add new line",
      nl: "Nieuwe lijn toevoegen",
    },
    export: {
      de: "Liste exportieren",
      en: "Export list",
      nl: "Lijst exporteren",
    },
    details: {
      de: "Details",
      en: "Details",
      nl: "Details",
    },
    mpnDetails: {
      de: "Manufacturer Details",
      en: "Manufacturer Details",
      nl: "Manufacturer Details",
    },
    activeShort: {
      de: "Bevorzugt",
      en: "Preferred",
      nl: "Preferred",
    },
    manuallyUpload: {
      de: "Manually uploaded attachment, check manufacturer detail section for download",
      en: "Manually uploaded attachment, check manufacturer detail section for download",
      nl: "Manually uploaded attachment, check manufacturer detail section for download",
    },
    noManufacturer: {
      en: "No related manufacturer",
      de: "Kein zugehöriger Hersteller",
      nl: "Geen verwante fabrikant",
    },
    assignedCPN: {
      de: "Zugewiesen Kundenteilenummer (KTN):",
      en: "Assigned Customer Part Number (CPN):",
      nl: "Toegewezen Artikel Nummer (CPN):",
    },
    sap: {
      de: "SAP",
      en: "SAP",
      nl: "SAP",
    },
    pending: {
      de: "Pending",
      en: "Pending",
      nl: "Pending",
    },
    maxWeeks: {
      de: "Max Wochen",
      en: "Max Weeks",
      nl: "Max Weeks",
    },
    origin: {
      de: "Herkunft",
      en: "Origin",
      nl: "Origin",
    },
    averagePrice: {
      de: "Durchschnittspreis",
      en: "Average Price",
      nl: "Average Price",
    },
    totalMarketInv: {
      de: "Marktbestand",
      en: "Market Inventory",
      nl: "Market Inventory",
    },
    numberOfDistributors: {
      de: "Lieferanten",
      en: "Distributors",
      nl: "Distributors",
    },
  },
  url: {
    defaultImage: "./assets/dummy.jpg",
  },
  field,
  list: {
    boxesLeft: empty,
    boxesLeftEditMode: empty,
    clientSection: empty,
    matchingSection: empty,
    obsolescenceSection: empty,
    environmentalCompliance: empty,
    tehnicalSection: empty,
    supplyChainSection: empty,
    supplyChainColumns: empty,
    loadReplacements: empty,
    loadZ2DataReplacements: empty,
    history: empty,
    csvExportManufacturerColumns: empty,
    csvExportReachSubstances: empty,
    fieldsOnCreate: empty,
    pcnHistoryColumns: empty,
    subTitleSectionClean: empty,
    subTitleSectionRaw: empty,
  },
  additionalInfo: {},
};

export const manufacturer: ComponentSettings<typeof settings> = settings;
