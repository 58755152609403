import { ComponentSettings } from "../settings/settings";

export const settings = {
  permission: {},
  text: {
    login: {
      de: "Bitte melden Sie sich an",
      en: "Please log in to continue",
      nl: "Gelieve in te loggen",
    },
    loginButton: { de: "Anmelden", en: "Log in", nl: "Aanmelden" },
    logout: { de: "Abmelden", en: "Logout", nl: "Afmelden" },
    accept: {
      de: "Mit Ihrem Login akzeptieren Sie die",
      en: "By signing in you agree to our",
      nl: "Door in te loggen gaat u akkoord met onze",
    },
    additionalInfoOC: {
      de: "and acknowledge that English is the official language",
      en: "and acknowledge that English is the official language",
      nl: "and acknowledge that English is the official language",
    },
    loginError: {
      de: "Name oder Passwort ist falsch.",
      en: "Name or password is incorrect.",
      nl: "Naam of Wachtwoord is niet correct.",
    },
    loginName: {
      de: "Name darf nicht leer sein.",
      en: "Name cannot be blank.",
      nl: "Er is geen Naam ingevuld.",
    },
    loginPassword: {
      de: "Passwort darf nicht leer sein.",
      en: "Password cannot be blank.",
      nl: "Er is geen Wachtwoord ingevuld.",
    },
    help: {
      de: "Benötigen Sie Hilfe bei der Anmeldung in Ihrem Account?",
      en: "Need help accessing your account?",
      nl: "Hulp nodig bij het inloggen op uw account?",
    },
    twoFactorAuth: {
      de: "Two-factor Authentication",
      en: "Two-factor Authentication",
      nl: "Two-factor Authentication",
    },
    twoFactorText: {
      de: "Geben Sie den Code ein, der an Ihre E-Mail-Adresse gesendet wurde",
      en: "Enter the code that was sent to your email address",
      nl: "Voer de code in die naar je e-mailadres is gestuurd",
    },
    submitCode: {
      de: "Code einreichen",
      en: "Submit Code",
      nl: "Code indienen",
    },
    resendCode: {
      de: "Code erneut senden",
      en: "Resend Code",
      nl: "Code opnieuw verzenden",
    },
    MFAError: {
      de: "Ihr Authentifizierungscode ist falsch",
      en: "Your authentication code is incorrect",
      nl: "Uw verificatiecode is onjuist",
    },
    MFASessionExpired: {
      de: "Ihre Sitzung ist abgelaufen. Sie wurden zur Anmeldeseite weitergeleitet",
      en: "Your session has expired. You have been redirected to the login page",
      nl: "Uw sessie is verlopen. U bent doorgestuurd naar de inlogpagina",
    },
  },
  list: {},
  url: {
    logo: "./assets/lcm-client-logo-rgb.png",
    landingLogo: "./assets/om-portal-logo.png",
    coopLogoLanding: "./assets/coop-logo-landing.png",
    ocLogo: "./assets/oc-logo.png",
  },
  field: {},
  additionalInfo: {
    minPasswordLength: 8,
  },
};

export const auth: ComponentSettings<typeof settings> = settings;
