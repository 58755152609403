import { ComponentSettings, empty, FieldTypes } from "../settings/settings";

const field = {
  searchField: {
    type: FieldTypes.typeahead,
    text: {
      de: "Search",
      en: "Search",
      nl: "Search",
    },
  },

  filterField: {
    type: FieldTypes.typeahead,
    text: {
      de: "Filter...",
      en: "Filter...",
      nl: "Filter...",
    },
  },
};

const settings = {
  permission: {
    seeRMModule: false,
    adminOnly: false,
    isAnalyst: false,
    createCase: false,
    canExport: false,
  },
  text: {
    riskManagementSearch: {
      de: "Risiko Management Suche",
      en: "Risk Management Search",
      nl: "Risk Management Zoeken",
    },
    parts: { de: "RM Module", en: "RM Module", nl: "RM Module" },
    assemblies: { de: "Baugruppen", en: "Assemblies", nl: "Assemblies" },
    partNumber: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    manufacturerPartNumber: {
      de: "Herstellerteilenummer (HTN)",
      en: "Manufacturer Part Number (MPN)",
      nl: "Fabrikant Artikel Nummer (MPN)",
    },
    searchAssembly: {
      de: "Suche nach einer Baugruppe",
      en: "Search for an assembly",
      nl: "Een assemblage zoeken",
    },
    searchPart: {
      de: "Suche nach einer Kundenteilenummer",
      en: "Search for a Customer Part Number",
      nl: "Zoeken naar een onderdeelnummer van een klant",
    },
    searchManufacturer: {
      de: "Suche nach einer Herstellerteilenummer",
      en: "Search for a Manufacturer Part Number",
      nl: "Zoeken naar een onderdeelnummer van de fabrikant",
    },
    completeTree: {
      de: "Vollständiger Baum",
      en: "Complete tree",
      nl: "Volledige boom",
    },
    allManufacturers: {
      de: "Enthaltene Hersteller",
      en: "Contained Manufacturers",
      nl: "Ingeperkte Fabrikanten",
    },
    match: { de: "Bauteile matchen", en: "Match parts", nl: "Match parts" },
    used: { de: "verbraucht", en: "used", nl: "gebruikt" },
    remaining: { de: "verbleibend", en: "remaining", nl: "overige" },
    requestDetails: {
      de: "Details abfragen",
      en: "Request details",
      nl: "Request details",
    },
    updateDetails: {
      de: "Details setzen",
      en: "Update details",
      nl: "Update details",
    },
    rohsColumns: { de: "EU RoHS", en: "EU RoHS", nl: "EU RoHS" },
    reachColumns: { de: "EU REACH", en: "EU REACH", nl: "EU REACH" },
    leadColumns: { de: "Supply Chain", en: "Supply Chain", nl: "Supply Chain" },
    designationColumn: {
      de: "Bezeichnung / Hersteller",
      en: "Designation / Manufacturer",
      nl: "Omschrijving / Fabrikant",
    },
    manage: {
      de: "Proaktiv/Bevorzugt",
      en: "Proactive/Preferred",
      nl: "Proactief",
    },
    treeView: { de: "Baumansicht", en: "Tree view", nl: "Boomstructuur" },
    manufacturerView: {
      de: "Hersteller",
      en: "Manufacturers",
      nl: "Fabrikanten",
    },
    partView: {
      de: "Kundenteile",
      en: "Parts",
      nl: "Artikelen",
    },
    table: { de: "Tabelle", en: "Table", nl: "Tabel" },
    matched: {
      de: "Zugeordnet",
      en: "Matched",
      nl: "Direct Match",
    },
    unmatched: {
      de: "Nicht Zugeordnet",
      en: "Not Matched",
      nl: "Geen Overeenstemming",
    },
    manual: {
      de: "Zuweisung Erforderlich",
      en: "Assignment Required",
      nl: "Handmatige toewijzing vereist",
    },
    override: {
      de: "Manuell Überschrieben",
      en: "Manual Override",
      nl: "Handmatige bewerking Actief",
    },
    linkedToDeletedCPN: {
      de: "Hersteller ohne Kundenteile",
      en: "Manufacturers without parts",
      nl: "Fabrikant zonder Artikelen",
    },
    noResults: {
      de: "Keine Ergebnisse gefunden",
      en: "No results found",
      nl: "Geen resultaten gevonden",
    },
    filter: { de: "Filter...", en: "Filter...", nl: "Filter..." },
    resetFilter: {
      de: "Filter zurücksetzen",
      en: "Reset filters",
      nl: "Reset filters",
    },
    selectedFilter: { de: "Ausgewählt:", en: "Selected:", nl: "Geselecteerd:" },
    filterApplied: {
      de: "Filter applied",
      en: "Filter applied",
      nl: "Filter applied",
    },
    exportReach: {
      de: "REACH Export",
      en: "REACH Export",
      nl: "REACH Export",
    },
    withoutData: {
      de: "Without Data",
      en: "Without Data",
      nl: "Zonder data",
    },
    cpn: {
      de: "KTN",
      en: "CPN",
      nl: "CPN",
    },
    mpn: {
      de: "HTN",
      en: "MPN",
      nl: "MPN",
    },
    designation: {
      de: "Bezeichnung",
      en: "Designation",
      nl: "Omschrijving",
    },
    filterByAssembly: {
      de: "Filter by assembly",
      en: "Filter by assembly",
      nl: "Filter by assembly",
    },
    filterByText: {
      de: "Filter by designation",
      en: "Filter by designation",
      nl: "Filter by designation",
    },
    yteop: {
      de: "YTEOP Forecast",
      en: "YTEOP Forecast",
      nl: "YTEOP Forecast",
    },
    eop: {
      de: "EOP Forecast",
      en: "EOP Forecast",
      nl: "EOP Forecast",
    },
    eos: {
      de: "EOS (LTB)",
      en: "EOS (LTB)",
      nl: "EOS (LTB)",
    },
    search: {
      de: "Stichwort eingeben",
      en: "Enter keyword",
      nl: "Trefwoord invoeren",
    },
    selectType: {
      de: "Wählen Sie einen Typ",
      en: "Select a type",
      nl: "Selecteer een type",
    },
    selectProperty: {
      de: "Wählen Sie eine Eigenschaft",
      en: "Select a property",
      nl: "Selecteer een woning",
    },
    enter: {
      de: "Eingabe einer",
      en: "Enter a",
      nl: "Voer een",
    },
  },
  list: {
    dataProvider: empty,
    commonColumns: empty,
    statusColumns: empty,
    riskColumns: empty,
    likelihoodColumns: empty,
    impactColumns: empty,
    rohsColumns: empty,
    reachColumns: empty,
    partsColumns: empty,
    partsStatusColumns: empty,
    manufacturersCommonColumns: empty,
    manufacturersStatusColumns: empty,
    leadColumns: empty,
    rmSearchPartDropdown: empty,
    rmSearchManufacturerDropdown: empty,
    rmSearchPartColumns: empty,
    rmSearchManufacturerColumns: empty,
    partMappingFields: empty,
    manufacturerMappingFields: empty,
  },
  field: field,
  additionalInfo: {
    limitPartCount: 0,
    creationDate: "",
    expirationDate: "",
  },
  url: {},
};

export const RM: ComponentSettings<typeof settings> = settings;
