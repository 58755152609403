<button
  class="btn btn-sm btn-clean pull-right mb-3"
  *ngIf="app.manufacturerUtils.pcnHistoryDocs.length > 0"
  (click)="app.manufacturerUtils.exportTables()"
>
  <i class="fa fa-download"></i>&nbsp;
  {{ app.text.manufacturer.export }}
</button>
<!-- <app-paginator
  [length]="app.manufacturerUtils.pcnHistoryDocs.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator> -->
<div
  class="tableFixHead clear"
  [ngClass]="{ 'min-height': app.manufacturerUtils.pcnHistoryDocs.length >= 2 }"
>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="p-1 left-align" *ngFor="let column of cleanedColumns">
          {{ app.field.getLabel("manufacturer." + column) }}
        </th>
      </tr>
      <tr>
        <th class="p-1" *ngFor="let column of cleanedColumns">
          <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-table
              [column]="column"
              [columns]="cleanColumns"
              [docType]="'manufacturer'"
              [cleanDocs]="app.manufacturerUtils.cleanHistoryDocs"
              [selectedFilters]="selectedFilters"
              (resultedDocs)="
                app.filterTable.getFilterResults($event, 'manufacturer')
              "
              [resetComponent]="resetFilterInputsObservable"
            >
            </app-filter-table>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let doc of app.paginator.getPageWithDoc()">
        <td class="p-1" *ngFor="let column of cleanedColumns">
          <span
            *ngIf="column !== 'pcnTypeOfChange' && column !== 'pcnSource'"
            >{{ doc[column] }}</span
          >
          <span *ngIf="column === 'pcnTypeOfChange'" class="pcnTypeOfChange">
            {{ getPcnTypeOfChange(doc[column]) }}
          </span>
          <span
            *ngIf="app.table.getColumnTypeDisplay(column) === 'link' && doc[column]"
          >
            <a [href]="doc[column]" target="_blank">
              <i class="fas fa-file icon-blue"></i>
            </a>
          </span>
        </td>
      </tr>
      <tr>
        <td *ngIf="app.manufacturerUtils.pcnHistoryLoading" colspan="99" class="text-center">
          {{ this.app.text.core.loading }}
        </td>
        <td *ngIf="!app.manufacturerUtils.pcnHistoryLoading && app.manufacturerUtils.pcnHistoryDocs.length === 0" colspan="99" class="text-center">{{ app.text.app.noResults }}</td>
      </tr>
    </tbody>
  </table>
</div>
<!-- <app-paginator
  [length]="app.manufacturerUtils.pcnHistoryDocs.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator> -->
