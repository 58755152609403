import { ComponentSettings } from "../settings/settings";

const _settings = {
  permission: {
    view: false,
  },
  text: {
    title: { de: "Einstellungen", en: "Settings", nl: "Instellingen" },
    text: { de: "Text", en: "Text", nl: "Tekst" },
    permissions: {
      de: "Berechtigungen",
      en: "Permissions",
      nl: "Toestemmingen",
    },
    lists: { de: "Listen", en: "Lists", nl: "Lijsten" },
    urls: { de: "Links", en: "Links", nl: "Links" },
  },
  list: {},
  url: {},
  field: {},
  additionalInfo: {},
};

export const settings: ComponentSettings<typeof _settings> = _settings;
