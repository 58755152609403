import { AppType, APP_TYPE } from "../app.type";
import { hasChanges } from "../utils/app.utils";
import {
  COMMENT,
  PostServiceType,
  PostType,
  SOLUTION,
  STREAM,
  SectionType,
  TASK,
} from "./post.service.type";
import { Inject, Injectable } from "@angular/core";
import {
  model2Doc,
  doc2Model,
  savePost,
  getPosts,
  getFinalSolutionsCompressed,
  changeStatusReady,
} from "../api.service";
import { Post } from "../../../../shared/models/post";
import { Customer } from "../../../../shared/types/customers";
import { BehaviorSubject } from "rxjs";
import { writeConfigFile } from "../export/config-export";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { JOBTITLE_OM } from "../users/users.service.type";
import { UserOptions } from "../../../../shared/models/user";

function getUpdateTime(post: Post) {
  const { create_time, update_time } = post;
  return Math.max(
    create_time == null ? 0 : create_time,
    update_time == null ? 0 : update_time
  );
}

// The needEvaluatuion array is user only for ns client to display a link inside post item component
// needEvaluation array = types of solution that need a safety evaluation
export const needEvaluation = [
  "substitute_not_fff_level",
  "reverse_engineering_physical_prod",
  "higher_level_fff_prod_lvl",
  "higher_level_not_fff_prod_lvl",
];

@Injectable()
export class PostService implements PostServiceType {
  model: any = {} as Post;
  posts: Post[] = [];
  cleanPosts: Post[] = [];
  ids: string[] = [];

  comments: Post[] = [];
  numberOfComments = 0;
  solutions: Post[] = [];
  numberOfSolutions = 0;
  tasks: Post[] = [];
  numberOfTasks = 0;
  numberOfOpenTasks = 0;
  uncompletedTasksByStatus: Post[] = [];
  completedTasksByStatus: Post[] = [];

  acceptedSCompressed: Partial<Post>[] = [];
  postFilters: string[] = [];

  isNew = false;
  isSolution = false;
  showSaveButton = false;
  isReadMode = true;

  sectionType: SectionType = STREAM;
  postType: PostType = TASK;
  filterMode = "all";
  sortingOrder = "myTasks";

  // KNDS WF
  projectNames: string[] = [];
  currentDisplayedPosts: Post[] = [];
  selectedOptions: Set<string> = new Set();
  projectFilters: string[] = [];

  isFilterSet = false;
  isSortingOrderSet = false;

  hasFinalSolution = false;

  groupingByFilterSubject: BehaviorSubject<Post[]> = new BehaviorSubject<
    Post[]
  >([]);
  discardPostSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getThreadPosts(customer: string, threadId: string) {
    this.isReadMode = true;
    this.posts = await getPosts(customer, threadId);
    this.cleanPosts = this.posts;
    this.groupingByFilterSubject.next(this.cleanPosts);
    this.filterPosts();
    this.app.tasks.getTasksByStatus();
    this.app.post.getPostsByFilter(this.app.post.filterMode);
    this.app.taskCompleted.checkOpenTasksKNDS.next(true);
  }

  async save() {
    this.app.spinner.showSpinner();
    let postDoc: any = {} as Post;

    // Set two decimals on fields of type number using the post model
    this.model = this.app.field.setTwoDecimalsOnNumberFields(this.model);
    postDoc = model2Doc("post", this.model); // doc2Model function will be removed once model won't have "post."

    // set projectName on post document based on the user who created the post
    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.setProjectNameOnPosts(postDoc);
    }

    // Set post fields before saving
    await this.setPostDataBeforeSavings(postDoc);
    // Save post
    await savePost(this.app.customers.expectCurrent, postDoc);
    // Set post fields after saving
    await this.setPostDataAfterSaving(postDoc);

    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.app.post.triggerOMEmail();
      this.app.stepper.isStepDisabled(
        this.app.thread.thread["thread.omfStatus"]
      );
      this.filterPostsByProject();
    }
    this.app.spinner.hideSpinner();
  }

  async setProjectNameOnPosts(postDoc: Post) {
    // check if the logged in user is responsible for the project -> the project name is the project where the user is responsible
    const loggedInUserResponsible = this.app.impacts.impacts.filter(
      (project: any) =>
        project.projectResponsible === this.app.users.currentUser.name
    );

    if (loggedInUserResponsible.length > 0) {
      postDoc["projectNumber"] = loggedInUserResponsible[0].projectNumber;
    } else if (postDoc.taskResponsible != "") {
      // if the OM user creates a task assigned to a KM user -> the project name is the project where the KM is responsible
      const projectNameByResponsible = this.app.users.userRoles
        .filter((user: UserOptions) => user.name === postDoc.taskResponsible)
        .map((x: any) => x.projectNumber)
        .join("");
      postDoc["projectNumber"] = projectNameByResponsible;
    } else {
      return;
    }
  }

  async setFinalSolution(post: Post) {
    if (
      this.app.customers.expectCurrent === Customer.NS &&
      this.app.post.hasFinalSolution &&
      !post.acceptedSolution
    ) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.post.finalSolutionExist;

      setTimeout(() => {
        this.app.state.next({ hasError: false, errorText: "" });
      }, 3000);

      return;
    }

    this.app.spinner.showSpinner();

    if (this.app.unlockedId != null) {
      this.model[this.app.fieldId.post.acceptedSolution] =
        !this.model[this.app.fieldId.post.acceptedSolution];
    } else {
      post.acceptedSolution = !post.acceptedSolution;
      await savePost(this.app.customers.expectCurrent, post);
      await this.setPostDataAfterSaving(post);
    }

    this.app.spinner.hideSpinner();
  }

  async getAcceptedSCompressed() {
    this.acceptedSCompressed = await getFinalSolutionsCompressed(
      this.app.customers.expectCurrent
    );
  }

  async exportData() {
    await writeConfigFile(this.app, "post");
  }

  async delete(post: Post) {
    this.app.spinner.showSpinner();

    // Delete selected post
    post._deleted = true;
    await savePost(this.app.customers.expectCurrent, post);

    // Update the uncompleted tasks
    if (this.app.permission.post.hasTasks && post.typeOfPost === "task") {
      this.app.tasks.uncompletedTasks();
    }

    // Get the posts & thread details updated
    if (this.app.thread.id == null) {
      throw new Error("id is null");
    }

    await this.getThreadPosts(
      this.app.customers.expectCurrent,
      this.app.thread.id
    );
    this.app.stepper.isStepDisabled(this.app.thread.thread["thread.omfStatus"]);

    await this.app.thread.getThread(this.app.thread.thread["thread._id"]);
    this.app.spinner.hideSpinner();
  }

  setRequiredFields(type: PostType, isEditMode: boolean) {
    // Set the postType based on the Add button selected
    this.postType = type;

    // Create a new model
    if (!isEditMode) {
      const newPost = new Post();
      this.model = doc2Model("post", newPost);
    }

    // Display the save button and set the read mode to false
    this.isReadMode = false;
    this.showSaveButton = true;

    // Set the required fields
    if (this.postType === TASK) {
      this.app.field.getFieldSettings("post.taskResponsible").required = true;
      this.app.field.getFieldSettings("post.taskDueDate").required = true;
      this.app.field.getFieldSettings("post.taskDescription").required = true;
      this.app.field.getFieldSettings("post.content").required = false;
      this.app.field.getFieldSettings("post.resolveClass").required = false;
      // this.app.post.sortingOrder = "myTasks";
    } else {
      this.app.field.getFieldSettings("post.taskResponsible").required = false;
      this.app.field.getFieldSettings("post.taskDueDate").required = false;
      this.app.field.getFieldSettings("post.taskDescription").required = false;
      this.app.field.getFieldSettings("post.content").required = true;

      if (this.postType === COMMENT) {
        this.app.field.getFieldSettings("post.resolveClass").required = false;
      }
      if (this.postType === SOLUTION) {
        this.app.field.getFieldSettings("post.resolveClass").required = true;
      }
    }
  }

  changePostSection(type: any, sortingOrder: string, filterMode: string) {
    // Reset data to the read mode
    this.sectionType = type;
    this.filterMode = filterMode;
    this.isReadMode = true;
    this.showSaveButton = false;

    if (this.sectionType !== STREAM) {
      this.postType = type;
    }

    // Keeps the tabs displayed when exit the edit mode by clicking one of the tab
    if (!this.app.thread.isEditMode()) {
      this.app.unlockedId = null;
    }

    // Sort & Filter the existing docs based on the sectionType
    // If a sorting has been applied in one section (eg. Stream), this should also apply to the other sections
    if (!this.isSortingOrderSet || this.sortingOrder === "myTasks") {
      this.setDefaultSorting(type);
    }
    this.sortPosts(this.posts, sortingOrder, type);

    this.setFilters(type);

    // If a filter has been applied in one section (eg. Stream), this should also apply to the other sections
    if (
      !this.isFilterSet ||
      filterMode === "openTasks" ||
      filterMode === "closedTasks" ||
      filterMode === "myTasks"
    ) {
      this.setDefaultFilter(type);
    }
    this.getPostsByFilter(this.filterMode);

    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.currentDisplayedPosts = [];
      this.selectedOptions = new Set<string>();
      this.filterPostsByProject();
    }
  }

  setDefaultSorting(type: string) {
    const customer = this.app.customers.expectCurrent;
    switch (type) {
      case "task":
      case "stream":
        if (customer === Customer.OMP || customer === Customer.COOP) {
          this.sortingOrder = "descending";
        } else {
          this.sortingOrder = "myTasks";
        }
        break;
      case "comment":
      case "solution":
        this.sortingOrder = "descending";
        break;
      default:
        this.sortingOrder = "myTasks";
    }
  }

  setDefaultFilter(sectionType: SectionType) {
    switch (this.app.customers.expectCurrent) {
      case Customer.KNDS:
        if (sectionType === TASK) {
          if (this.app.users.currentUser.jobTitle !== JOBTITLE_OM) {
            this.filterMode = "myTasks";
          } else {
            this.filterMode = "byMe";
          }
        }
        break;
      case Customer.DB:
        if (sectionType === TASK) {
          this.filterMode = "openTasks";
        }
        break;
      default:
        this.filterMode = "all";
    }
  }

  showResolveClassField(id: string | null) {
    const currentPost = this.posts.find((post) => post._id === id);
    if (currentPost == null) {
      return false;
    }
    if (
      id != null &&
      currentPost.resolveClass != null &&
      currentPost.resolveClass !== ""
    ) {
      this.isSolution = true;
      return true;
    }
    this.isSolution = false;
    return false;
  }

  modelHasChanges(postId: string | null) {
    const currentPost = this.posts.find((post) => post._id === postId);

    if (currentPost == null) {
      return false;
    }

    if (postId != null) {
      const modelHasChanges = hasChanges(
        doc2Model("post", currentPost),
        this.model
      );
      return modelHasChanges;
    } else {
      return false;
    }
  }

  sortPosts(posts: Post[], order: string, sectionType: SectionType) {
    posts = this.getCleanPostByType(sectionType);

    const sorted = posts.sort((a: any, b: any) => {
      const postA = getUpdateTime(a);
      const postB = getUpdateTime(b);
      if (order === "ascending") {
        return postA - postB;
      } else {
        return postB - postA;
      }
    });

    if (
      (sectionType === TASK || sectionType === STREAM) &&
      order === "myTasks"
    ) {
      this.sortTaskByUser(posts);
    }

    return sorted;
  }

  filterPosts() {
    this.resetPosts();
    this.posts.forEach((post: Post) => {
      switch (post.typeOfPost) {
        case COMMENT:
          this.comments.push(post);
          break;
        case SOLUTION:
          this.solutions.push(post);
          break;
        case TASK:
          this.tasks.push(post);
          break;
        default:
          this.comments.push(post);
      }
    });

    this.cleanPosts = this.posts;
    this.numberOfComments = this.comments.map((x) => x._id).length;
    this.numberOfSolutions = this.solutions.map((x) => x._id).length;
    this.numberOfTasks = this.tasks.map((x) => x._id).length;
    this.numberOfOpenTasks = this.tasks.filter(
      (task) => task.taskCompleted == false
    ).length;

    this.sortPosts(this.cleanPosts, this.sortingOrder, STREAM);
    this.sortPosts(this.comments, this.sortingOrder, COMMENT);
    this.sortPosts(this.solutions, this.sortingOrder, SOLUTION);
    this.sortPosts(this.tasks, this.sortingOrder, TASK);

    // check if the next/ previous status should be disabled based on solutions
    this.app.stepper.isStepDisabled(
      this.app.thread.thread["thread.omfStatus"],
      this.solutions
    );

    /** if the customer is db -> the final solutions have to be sorted out descending by their updated time */
    if (this.app.customers.expectCurrent === Customer.DB) {
      this.sortSelectedSolutions();
    }
  }

  sortTaskByUser(posts?: Post[]) {
    if (this.sectionType === STREAM) {
      posts = [...this.cleanPosts];
    }

    if (this.sectionType === TASK) {
      posts = [...this.tasks];
    }

    if (posts) {
      const ownTasks = posts.filter(
        (task: Post) => task.taskResponsible === this.app.users.currentUser.name
      );

      const ownOpenTasks = ownTasks.filter(
        (post: Post) => post.taskCompleted !== true
      );

      const ownCompletedTasks = ownTasks.filter(
        (post: Post) => post.taskCompleted === true
      );

      const otherTasks = posts.filter(
        (task: Post) => task.taskResponsible !== this.app.users.currentUser.name
      );

      if (ownOpenTasks != null) {
        posts = [...ownOpenTasks, ...ownCompletedTasks, ...otherTasks];
      }
    }

    if (posts && this.sectionType === STREAM) {
      this.cleanPosts = posts;
    }
    if (posts && this.sectionType === TASK) {
      this.tasks = posts;
    }
  }

  sortSelectedSolutions() {
    if (this.solutions) {
      let favorites = this.solutions.filter(
        (post: Post) => post.acceptedSolution === true
      );

      if (favorites != null) {
        favorites = favorites.sort((a: Post, b: Post) => {
          if (a.update_time != null && b.update_time != null) {
            return b.update_time - a.update_time;
          }
          return 0;
        });
        const otherSolutions = this.solutions.filter(
          (post: Post) => post.acceptedSolution !== true
        );
        this.solutions = [...favorites, ...otherSolutions];
      }
    }
  }

  setFilters(sectionType: string) {
    this.postFilters = [];

    if (sectionType === STREAM) {
      this.postFilters.unshift("byOthers");
      this.postFilters.unshift("byMe");
      this.postFilters.push("all");
    }

    if (sectionType === COMMENT) {
      this.postFilters.unshift("byOthers");
      this.postFilters.unshift("byMe");
      this.postFilters.push("all");
    }

    if (sectionType === SOLUTION) {
      this.postFilters.unshift("byOthers");
      this.postFilters.unshift("byMe");
      this.postFilters.push("all");
    }

    if (sectionType === TASK) {
      this.postFilters.unshift("byOthers");
      this.postFilters.unshift("byMe");
      this.postFilters.unshift("openTasks");
      this.postFilters.unshift("closedTasks");
      this.postFilters.unshift("myTasks");
      this.postFilters.push("all");
    }
    return this.postFilters;
  }

  getPostsByFilter(filter: string) {
    let result: Post[] = [];
    let copy: Post[] = [];
    const user = this.app.user;

    this.filterMode = filter;
    this.filterPosts();
    copy = this.getCleanPostByType(this.sectionType);

    switch (filter) {
      case "byMe":
        result = copy.filter((post: Post) => post.user_id === user);
        break;
      case "byOthers":
        result = copy.filter((post: Post) => post.user_id !== user);
        break;
      case "closedTasks":
        result = copy.filter((post: Post) => post.taskCompleted === true);
        break;
      case "openTasks":
        result = copy.filter((post: Post) => post.taskCompleted !== true);
        break;
      case "myTasks":
        result = copy.filter(
          (post: Post) => post.taskResponsible === this.app.user
        );
        break;
      default:
        result = copy;
    }

    this.groupingByFilterSubject.next(result);
    return result;
  }

  isOptionSelected(option: string) {
    if (this.projectFilters == null) {
      return false;
    }
    this.selectedOptions = new Set(this.projectFilters as string[]);
    return this.selectedOptions.has(option);
  }

  toggleOption(option: string) {
    this.selectedOptions = new Set(this.projectFilters as string[]);
    if (this.selectedOptions.has(option)) {
      // deselect option
      this.selectedOptions.delete(option);
      this.projectFilters.splice(this.projectFilters.indexOf(option), 1);

      this.app.post.filterPostsByProject(true);
    } else {
      this.selectedOptions.add(option);
      this.projectFilters.push(option);
      this.app.post.filterPostsByProject();
    }
    this.projectFilters = Array.from(this.selectedOptions).sort();

    return;
  }

  filterPostsByProject(isRemoved?: boolean) {
    console.log("projectFilters", this.projectFilters);
    console.log("selectedOption", this.selectedOptions);
    this.filterPosts();
    const copy: Post[] = this.getCleanPostByType(this.sectionType);
    let result: Post[] = [];

    if (this.projectFilters.length === 0) {
      this.currentDisplayedPosts = [];
      this.groupingByFilterSubject.next(copy);
      return;
    }

    if (isRemoved) {
      result = this.currentDisplayedPosts.filter(
        (post: Post) =>
          post.projectNumber != null &&
          this.projectFilters.includes(post.projectNumber)
      );

      this.currentDisplayedPosts = result;
      this.groupingByFilterSubject.next(result);
    } else {
      const filtered = copy.filter(
        (post: Post) =>
          post.projectNumber != null &&
          this.projectFilters.includes(post.projectNumber)
      );

      // add result values only if they are not already present in currentDisplayedPosts
      filtered.forEach((post: Post) => {
        if (
          !this.currentDisplayedPosts.some(
            (existingPost: Post) => existingPost._id === post._id
          )
        ) {
          this.currentDisplayedPosts.push(post);
          // result = this.currentDisplayedPosts;
        }
      });

      // this.currentDisplayedPosts = result;
      this.groupingByFilterSubject.next(this.currentDisplayedPosts);
    }
  }

  markTaskCompleted(deletedProject: string) {
    const result = this.cleanPosts.filter(
      (post: any) =>
        post.projectNumber === deletedProject &&
        post.taskCompleted === false &&
        post.typeOfPost === "task"
    );

    result.forEach(async (post: any) => {
      post.taskCompleted = true;
      // TODO: ask for note text
      post.taskNote = "Project was deleted";
      await savePost(this.app.customers.expectCurrent, post);
    });
  }

  getPostFilterText(post: string) {
    switch (post) {
      case "all":
        return this.app.text.post.all;
      case "allTasks":
        return this.app.text.post.allTasks;
      case "allComments":
        return this.app.text.post.allComments;
      case "allSolutions":
        return this.app.text.post.allSolutions;
      case "openTasks":
        return this.app.text.post.openTasks;
      case "closedTasks":
        return this.app.text.post.closedTasks;
      case "myTasks":
        return this.app.text.post.myTasks;
      case "byMe":
        return this.app.text.post.byMe;
      case "byOthers":
        return this.app.text.post.byOthers;
      default:
        return post;
    }
  }

  needEvaluationCheck(solutionType: string) {
    return needEvaluation.indexOf(solutionType) !== -1;
  }

  hasEditPermission(post: Post) {
    const { permission, user } = this.app;

    if (post.typeOfPost === TASK && !this.app.permission.post.canAddTask) {
      return false;
    }

    if (permission.post.edit) {
      return true;
    }

    if (permission.post.editOwn || permission.post.markOwnTaskCompleted) {
      const createUser = post.user_id;
      if (createUser === user) {
        return true;
      }
    }

    return false;
  }

  getLabelBySectionType(sectionType: SectionType) {
    switch (sectionType) {
      case COMMENT:
        if (this.app.post.isReadMode) {
          return this.app.text.post.addCom;
        } else {
          return this.app.text.post.saveCom;
        }

      case SOLUTION:
        if (this.app.post.isReadMode) {
          return this.app.text.post.addSol;
        } else {
          return this.app.text.post.saveSol;
        }
      case TASK:
        if (this.app.post.isReadMode) {
          return this.app.text.post.addTask;
        } else {
          return this.app.text.post.saveTask;
        }
      case STREAM:
        if (!this.app.post.isReadMode) {
          return this.getSaveLabelByPostType();
        }
        break;
      default:
        return this.app.text.post.addCom;
    }
  }

  getSaveLabelByPostType() {
    switch (this.postType) {
      case COMMENT:
        return this.app.text.post.saveCom;
      case SOLUTION:
        return this.app.text.post.saveSol;
      case TASK:
        return this.app.text.post.saveTask;
      default:
        return this.app.text.post.saveCom;
    }
  }

  discardChanges() {
    this.isReadMode = true;
    this.showSaveButton = false;
    this.app.unlockedId = null;
    this.app.post.discardPostSubject.next(true);
  }

  getNewSolutionFieldsList(solutionName: string | undefined): string[] {
    const excludedFields = new Set(this.app.list.post.excludedFieldsFromPost);
    switch (solutionName) {
      case "Alternative":
        return this.app.list.post.substituteFieldsList.filter(
          (field) => !excludedFields.has(field)
        );
      case "Alternativer Lieferant":
        return this.app.list.post.divestmentFieldsList.filter(
          (field) => !excludedFields.has(field)
        );
      case "LTB-Last Time Buy":
        return this.app.list.post.ltbSolutionFields.filter(
          (field) => !excludedFields.has(field)
        );
      case "Redesign":
        if (this.app.list.post.redesignFieldsList.length > 0) {
          return this.app.list.post.redesignFieldsList.filter(
            (field) => !excludedFields.has(field)
          );
        }
        break;
      default:
        // extraSolutionfields list is used for all remaining solution types as default list
        return this.app.list.post.defaultSolutionFields.filter(
          (field) => !excludedFields.has(field)
        );
    }

    return [];
  }

  getSolutionFieldsList(solutionName: string) {
    switch (solutionName) {
      case "Alternative":
        return this.app.list.post.substituteFieldsList;
      case "Alternativer Lieferant":
        return this.app.list.post.divestmentFieldsList;
      case "Redesign":
        return this.app.list.post.redesignFieldsList;
      case "LTB-Last Time Buy":
        return this.app.list.post.ltbSolutionFields;
      default:
        return this.app.list.post.defaultSolutionFields;
    }
  }

  resetPosts() {
    this.numberOfComments = 0;
    this.comments = [];
    this.numberOfSolutions = 0;
    this.solutions = [];
    this.numberOfTasks = 0;
    this.numberOfOpenTasks = 0;
    this.tasks = [];
  }

  hasPermissionToAddPost(postType: string) {
    switch (postType) {
      case COMMENT:
        return this.app.permission.post.canAddComment;
      case SOLUTION:
        return this.app.permission.post.canAddSolution;
      case TASK:
        return this.app.permission.post.canAddTask;
      default:
        return false;
    }
  }

  async setUpdatesBasedOnTaskResponsible(post: Post | Partial<Post>) {
    // Add the case to favourites to the task responsible profile
    if (post.taskResponsible) {
      await this.app.thread.addCaseToFav(
        [post.taskResponsible],
        this.app.thread.thread["thread._id"],
        true
      );
    }

    // Own profile has to be updated in case task responsible is the same with the logged in user
    if (post.taskResponsible === this.app.user) {
      this.app.profile.ownProfile = await this.app.profile.getProfile(
        post.taskResponsible
      );
    }
  }

  // used only for KNDS
  async triggerOMEmail() {
    const projectResponsibles = this.app.impacts.impacts
      .filter((o: any) => o.projectResponsible != undefined)
      .map((x: any) => x.projectResponsible);

    const solutionCreator: string[] = [];
    this.app.post.posts.forEach((x: any) => {
      if (
        x.typeOfPost === "solution" &&
        projectResponsibles.includes(x.user_id)
      ) {
        solutionCreator.push(x.user_id);
      }
    });
    const responsiblesWithoutSolution = projectResponsibles.filter(
      (x) => !solutionCreator.includes(x)
    );
    if (responsiblesWithoutSolution.length > 0) {
      return;
    }

    const allTasksCompleted = this.checkAllCompletedTasks();
    if (!allTasksCompleted) {
      return;
    }

    if (
      allTasksCompleted &&
      responsiblesWithoutSolution.length === 0 &&
      this.app.thread.thread[this.app.fieldId.thread.omfStatus] ===
        "In Bearbeitung"
    ) {
      const threadDoc: any = model2Doc("thread", this.app.thread.thread);
      await changeStatusReady(this.app.customers.expectCurrent, threadDoc);
    }
  }

  checkAllCompletedTasks() {
    let allTasksCompleted = false;
    const postCount = this.app.thread.thread["thread.postCount"];
    const completedTasks = postCount["completedTasks"];

    if (postCount["tasksCount"] === completedTasks.length.toString()) {
      allTasksCompleted = true;
    } else {
      allTasksCompleted = false;
    }

    return allTasksCompleted;
  }

  private async setPostDataBeforeSavings(doc: Post) {
    if (this.app.thread.id == null) {
      throw new Error("id is null");
    }

    // Set common fields
    doc.omfNumber = this.app.thread.id;
    doc.typeOfPost = !StringUtils.isNullOrEmpty(doc.typeOfPost)
      ? doc.typeOfPost
      : this.postType;
    doc.snapshot = {
      thread: {
        omfStatus: this.app.thread.thread[this.app.fieldId.thread.omfStatus],
      },
    };

    // Set fields based on the type of the post
    if (doc.typeOfPost === SOLUTION) {
      if (this.isNew) {
        this.setLeadTime(doc);
      }
      this.setStorageCost(doc);

      if (this.app.customers.expectCurrent === Customer.RHEINMETALLAIRDEFENCE) {
        this.setDesignChangeCost(doc);
      }
    }

    if (doc.typeOfPost === TASK) {
      await this.setUpdatesBasedOnTaskResponsible(doc);
    }
  }

  private setStorageCost(post: Post) {
    if (
      post.noOfItems != null &&
      post.pricePerItem != null &&
      post.costForStorage != null &&
      post.yearsOfStorage != null
    ) {
      const total =
        post.noOfItems * post.pricePerItem +
        post.noOfItems * post.costForStorage * post.yearsOfStorage;
      post.totalAmount = parseFloat(total.toFixed(2));
    }
  }

  private setDesignChangeCost(post: Post) {
    if (
      post.hours != null &&
      post.hourlyRate != null &&
      post.nonRecurrentCost != null
    ) {
      const total = post.hours * post.hourlyRate + post.nonRecurrentCost;
      post.designChangeCost = parseFloat(total.toFixed(2));
    }
  }

  private setLeadTime(post: Post) {
    // Lead Time (days) = Creation date of the Solution - Creation date of the case
    const date = new Date();
    const oneDay = 1000 * 60 * 60 * 24;

    const solutionCreateDate = date.getTime();
    const caseCreateDate =
      this.app.thread.thread[this.app.fieldId.thread.create_time];
    const days = (solutionCreateDate - caseCreateDate) / oneDay;

    post.leadTime = Math.ceil(days);
  }

  private async setPostDataAfterSaving(post: Post) {
    // Update the uncompleted tasks if the responsible of the newly created task is the logged in user
    if (
      this.app.permission.post.hasTasks &&
      post.typeOfPost === TASK &&
      post.taskResponsible === this.app.user
    ) {
      this.app.tasks.uncompletedTasks();
    }

    // Set case to favorite
    if (this.app.thread.id == null) {
      throw new Error("id is null");
    }

    // Exception: for NS client, team role we should not set the case to fav
    if (!this.app.thread.isFav(this.app.thread.thread["thread._id"])) {
      if (
        !(
          (this.app.customers.expectCurrent === Customer.NS &&
            this.app.auth.isTeam) ||
          this.app.customers.expectCurrent === Customer.DB
        )
      ) {
        this.app.thread.setFav(this.app.thread.id, true);
      }
    }

    // Get the posts & thread details updated
    await this.getThreadPosts(
      this.app.customers.expectCurrent,
      this.app.thread.id
    );
    await this.app.thread.getThread(this.app.thread.thread["thread._id"]);

    // Discard changes
    this.discardChanges();
    this.app.field.inputSubject.next(true);
  }

  private getCleanPostByType(sectionType: SectionType) {
    switch (sectionType) {
      case STREAM:
        return this.cleanPosts;
      case COMMENT:
        return this.comments;
      case SOLUTION:
        return this.solutions;
      case TASK:
        return this.tasks;
      default:
        return this.cleanPosts;
    }
  }
}
