import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    sync: { de: "Kopieren nach", en: "Copy to", nl: "Kopieer naar" },
    syncText: { de: "Syncronize", en: "Syncronize", nl: "Syncronize" },
    syncCaseFromPortal: {
      de: "Soll der Fall wirklich mit dem LCM Client synchronisiert werden?",
      en: "Do you really want to push the case to the LCM Client?",
      nl: "Echt de casus nasynchroniseren met LCM Client?",
    },
    syncCaseFromClient: {
      de: "Soll der Fall wirklich mit dem OM-Portal synchronisiert werden?",
      en: "Do you really want to synchronize the case to the OM-Portal?",
      nl: "Echt de casus nasynchroniseren met OM-Portal?",
    },
    syncCommentFromPortal: {
      de: "Soll dies wirklich in den LCM Client synchronisiert werden?",
      en: "Do you really want to push this to the LCM Client?",
      nl: "Echt de casus nasynchroniseren met LCM Client?",
    },
    syncCommentFromClient: {
      de: "Soll dies wirklich mit dem OM-Portal synchronisiert werden?",
      en: "Do you really want to synchronize this to the OM-Portal?",
      nl: "Echt de casus nasynchroniseren met OM-Portal?",
    },
  },
  list: {},
  url: {},
  field: {},
  additionalInfo: {},
};

export const sync: ComponentSettings<typeof settings> = settings;
