<app-impact-modal #openImpact [modalTitle]="app.text.impact.impact">
</app-impact-modal>
<app-modal
  #deleteImpactModal
  [modalMessage]="app.text.impact.deleteMessage"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="deleteSelectedImpact(app.impact.currentImpact)"
></app-modal>

<!-- Different text is displayed before where used table based on the current view -->
<div
  class="col-md-11 descriptionList mt-2"
  *ngIf="part && !app.overrideMpnFields.openOverrideModal"
>
  <div *ngIf="!shouldDisplayText()">
    <div class="flex">
      <div *ngIf="app.customer === 'lre'" class="field-label">
        {{ app.field.getLabel("thread.artNumber") }}:&nbsp;
      </div>
      <div *ngIf="app.customer !== 'lre'" class="field-label">
        {{ app.field.getLabel("part.partNumber") }}:&nbsp;
      </div>
      <div class="lineHeight" *ngIf="part">
        {{ part._id }}
      </div>
    </div>
    <div class="flex">
      <div class="field-label" *ngIf="totalUsages !== 0">
        {{ app.text.impact.sumOfUsages }} &nbsp;
      </div>
      <div class="lineHeight" *ngIf="totalUsages !== 0">
        {{ totalUsages }}
      </div>
    </div>
    <div class="flex">
      <div class="field-label" *ngIf="freqUse !== 0">
        {{ app.text.impact.freqUse }} &nbsp;
      </div>
      <div class="lineHeight" *ngIf="freqUse !== 0">
        {{ freqUse }}
      </div>
    </div>
    <div class="flex">
      <div class="field-label" *ngIf="mpns.length > 0">
        {{ app.text.RM.manufacturerPartNumber }}:&nbsp;
      </div>
      <div class="lineHeight" *ngIf="mpns.length > 0">
        <a
          class="links pointer"
          *ngFor="let o of mpns"
          (click)="app.routing.openInNewTab(null, 'impact.manufacturers', o.id)"
          >{{ o.mpn }}
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="shouldDisplayText()">
    <div class="flex">
      <div class="field-label">
        {{ app.field.getLabel(app.fieldId.part.partNumber) }}:&nbsp;
      </div>

      <div class="lineHeight">
        {{ app.treeRow.currentPart.partNumber }}
      </div>
    </div>
    <div class="flex">
      <div class="field-label">
        {{ app.field.getLabel(app.fieldId.part.description) }}:&nbsp;
      </div>
      <div class="lineHeight">
        {{ app.treeRow.currentPart.description }}
      </div>
    </div>
  </div>
</div>

<!-- Where used table for RM clients -->
<div class="tableFixHead">
  <table class="table table-striped">
    <thead>
      <tr class="p-1">
        <!-- RMClientColumns based on each customer -->
        <th class="p-1" *ngFor="let column of columns">
          <div>
            <span
              (click)="
                app.table.toggleSortDirection(column);
                setVehiclesForSorting(vehicles);
                resetNodes()
              "
              >{{ specialLogicLabels(column) }}</span
            >&nbsp;
            <span *ngIf="column === app.table.sortField">
              <i
                *ngIf="app.table.sortDirection === 1"
                class="fa fa-chevron-up"
              ></i>
              <i
                *ngIf="app.table.sortDirection === -1"
                class="fa fa-chevron-down"
              ></i>
            </span>
          </div>
        </th>
        <!-- Action column -->
        <th
          class="p-1"
          *ngIf="
            threadPage &&
            app.permission.impact.edit &&
            !app.overrideMpnFields.openOverrideModal
          "
        >
          {{ app.field.getLabel("impact.actions") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let impact of vehicles; let i = index"
        [ngClass]="{ 'blue-striped': app.view !== 'thread' }"
      >
        <!-- RMClientColumns based on each customer -->
        <td class="p-1" *ngFor="let column of columns">
          <div
            [ngSwitch]="app.table.getColumnTypeDisplay(column.split('.')[1])"
          >
            <div *ngSwitchCase="'artNumber'" class="pull-left">
              <span *ngFor="let parent of impact.parent"
                >&nbsp;&nbsp;&nbsp;</span
              >
              <span *ngIf="impact.impacts.length > 0">
                <i
                  class="far fa-plus icon-gray pointerIcon p-1 pointer"
                  *ngIf="!openNodes.has(impact.index)"
                  (click)="toggleNode(impact); $event.stopPropagation()"
                ></i>
                <i
                  class="fa fa-minus icon-gray pointerIcon p-1 pointer"
                  *ngIf="openNodes.has(impact.index)"
                  (click)="toggleNode(impact); $event.stopPropagation()"
                ></i>
                <span *ngIf="!impact.parent" class="pointer">
                  <span
                    *ngIf="
                      app.view == 'thread' &&
                      !app.overrideMpnFields.openOverrideModal
                    "
                    (click)="openImpact.open(impact)"
                  >
                    {{ impact.artNumber }}</span
                  >
                </span>
                <span
                  *ngIf="
                    impact.parent ||
                    app.view != 'thread' ||
                    app.overrideMpnFields.openOverrideModal
                  "
                  >{{ impact.artNumber }}</span
                >
              </span>
              <span
                *ngIf="impact.impacts.length === 0 && app.customer !== 'knds'"
              >
                <i class="far fa-minus icon-gray pointerIcon p-1"> </i>
                {{ impact.artNumber }}
              </span>
              <span
                *ngIf="impact.impacts.length === 0 && app.customer === 'knds'"
                class="pointer"
              >
                <span
                  *ngIf="
                    app.view == 'thread' &&
                    !app.overrideMpnFields.openOverrideModal
                  "
                  (click)="openImpact.open(impact)"
                >
                  <i class="far fa-minus icon-gray pointerIcon p-1"> </i>
                  {{ impact.artNumber }}</span
                >
                <span
                  *ngIf="
                    app.view != 'thread' ||
                    app.overrideMpnFields.openOverrideModal
                  "
                >
                  <i class="far fa-minus icon-gray pointerIcon p-1"> </i>
                  {{ impact.artNumber }}
                </span>
              </span>
            </div>
            <div *ngSwitchCase="'normal'">
              <span *ngIf="!app.overrideMpnFields.openOverrideModal">
                {{ impact[column.split(".")[1]] }}
              </span>
            </div>
            <div *ngSwitchCase="'optionText'">
              <span *ngIf="!app.overrideMpnFields.openOverrideModal">
                {{
                  app.field.getOptionText(column, impact[column.split(".")[1]])
                }}
              </span>
            </div>
            <div *ngSwitchCase="'decimalNumber'">
              <span *ngIf="impact[column.split('.')[1]] > -1">
                {{
                  app.field.formatNumberWithDecimals(
                    impact[column.split(".")[1]],
                    2
                  )
                }}
              </span>
            </div>
          </div>
        </td>
        <!-- Action column -->
        <td
          class="p-1 text-center"
          *ngIf="
            !impact.parent &&
            threadPage &&
            !app.overrideMpnFields.openOverrideModal
          "
        >
          <a
            *ngIf="app.permission.impact.edit"
            (click)="
              app.impact.currentImpact = impact; deleteImpactModal.open()
            "
            class="pointer"
          >
            <i class="fa fa-trash"></i>
          </a>
        </td>
        <td *ngIf="impact.parent && threadPage"></td>
      </tr>
      <!-- No results line -->
      <tr *ngIf="vehicles.length === 0">
        <td colspan="99" class="text-center">
          {{ app.text.app.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
