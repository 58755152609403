import {
  _ComponentSettings,
  getTranslatedText,
  Language,
  Text,
} from "../../../shared/settings/settings";
import { AlertsService } from "./alerts/alerts.service";
import { AlertsServiceType } from "./alerts/alerts.service.type";
import { AppType } from "./app.type";
import { AuthService } from "./auth/auth.service";
import { AuthServiceType } from "./auth/auth.service.type";
import { BehaviorSubject } from "rxjs";
import { CommodityGroupResponsibleService } from "./commodity-group-responsible/commodity-group-responsible.service";
import { CommodityGroupResponsibleServiceType } from "./commodity-group-responsible/commodity-group-responsible.service.type";
import { CustomersService } from "./customers/customers.service";
import { CustomersServiceType } from "./customers/customers.service.type";
import {
  defaultSettings,
  getDocTypes,
  Settings,
  Type,
} from "../../../shared/components";
import { DinCodeResponsibleService } from "./din-code-responsible/din-code-responsible.service";
import { DinCodeResponsibleServiceType } from "./din-code-responsible/din-code-responsible.service.type";
import { doc2Model } from "./api.service";
import { DocsService } from "./docs/docs.service";
import { DocsServiceType } from "./docs/docs.service.type";
import { FieldService } from "./field/field.service";
import { FieldServiceType } from "./field/field.service.type";
import { FileService } from "./file/file.service";
import { FileServiceType } from "./file/file.service.type";
import { FilterListService } from "./filter-list/filter-list.service";
import { FilterListServiceType } from "./filter-list/filter-list.service.type";
import { FilterTableService } from "./filter-table/filter-table.service";
import { FilterTableServiceType } from "./filter-table/filter-table.service.type";
import { FilterHeaderTableService } from "./filter-header-table/filter-header-table.service";
import { FilterHeaderTableServiceType } from "./filter-header-table/filter-header-table.service.type";
import { getModelProxy } from "../../../shared/proxies/model.proxy";
import { GraphsService } from "./graphs/graphs.service";
import { GraphsServiceType } from "./graphs/graphs.service.type";
import { HomeService } from "./home/home.service";
import { HomeServiceType } from "./home/home.service.type";
import { ImpactService } from "./impact/impact.service";
import { ImpactServiceType } from "./impact/impact.service.type";
import { ImpactsService } from "./impacts/impacts.service";
import { ImpactsServiceType } from "./impacts/impacts.service.type";
import { ImportService } from "./import/import.service";
import { ImportServiceType } from "./import/import.service.type";
import { importJson, importXlsx } from "./import/import.utils";
import { Injectable } from "@angular/core";
import { LeftNavService } from "./left-nav-component/left-nav.service";
import { LeftNavServiceType } from "./left-nav-component/left-nav.service.type";
import { MailService } from "./mail/mail.service";
import { MailServiceType } from "./mail/mail.service.type";
import { ManufacturerMatchingService } from "./manufacturer-matching/manufacturer-matching.service";
import { ManufacturerMatchingServiceType } from "./manufacturer-matching/manufacturer-matching.service.type";
import { ManufacturerService } from "./manufacturer/manufacturer.service";
import { ManufacturerServiceType } from "./manufacturer/manufacturer.service.type";
import { ManufacturersFilterTableService } from "./RM/manufacturers-filter-table/manufacturers-filter-table.service";
import { ManufacturersFilterTableServiceType } from "./RM/manufacturers-filter-table/manufacturers-filter-table.service.type";
import { MessageService } from "./message/message.service";
import { MessageServiceType } from "./message/message.service.type";
import { PaginatorService } from "./paginator/paginator.service";
import { PaginatorServiceType } from "./paginator/paginator.service.type";
import { PartService } from "./part/part.service";
import { PartServiceType } from "./part/part.service.type";
import { RMService } from "./RM/RM.service";
import { RMServiceType } from "./RM/RM.service.type";
import { RMSearchService } from "./rm-search/rm-search.service";
import { RMSearchServiceType } from "./rm-search/rm-search.service.type";
import { PcnService } from "./pcn/pcn.service";
import { PcnServiceType } from "./pcn/pcn.service.type";
import { PersonService } from "./person/person.service";
import { PersonServiceType } from "./person/person.service.type";
import { PostService } from "./post/post.service";
import { PostServiceType } from "./post/post.service.type";
import { ProfileService } from "./profile/profile.service";
import { ProfileServiceType } from "./profile/profile.service.type";
import { ProxyService } from "./core/proxy/proxy.service";
import { ProxyServiceType } from "./core/proxy/proxy.service.type";
import { RMSubmenuService } from "./rm-submenu/rm-submenu.service";
import { RMSubmenuServiceType } from "./rm-submenu/rm-submenu.service.type";
import { RoutingService } from "./routing/routing.service";
import { RoutingServiceType } from "./routing/routing.service.type";
import { ServiceHelper } from "./injector";
import { StateService } from "./state/state.service";
import { StateServiceType } from "./state/state.service.type";
import { TableService } from "./table/table.service";
import { TableServiceType } from "./table/table.service.type";
import { ThreadService } from "./thread/thread.service";
import { ThreadServiceType } from "./thread/thread.service.type";
import { TrainService } from "./train/train.service";
import { TrainServiceType } from "./train/train.service.type";
import { TreeCellService } from "./tree-cell/tree-cell.service";
import { TreeCellServiceType } from "./tree-cell/tree-cell.service.type";
import { UsersService } from "./users/users.service";
import { UsersServiceType } from "./users/users.service.type";
import { UserSettingsService } from "./user-settings/user-settings.service";
import { UserSettingsServiceType } from "./user-settings/user-settings.service.type";
import { VehicleResponsibleService } from "./vehicle-responsible/vehicle-responsible.service";
import { VehicleResponsibleServiceType } from "./vehicle-responsible/vehicle-responsible.service.type";
import { SyncService } from "./sync/sync.service";
import { SyncServiceType } from "./sync/sync.service.type";
import { writeFile } from "./export/export.service";
import { DocModel } from "./state/state";
import { SpinnerServiceType } from "./core/spinner/spinner.service.type";
import { SpinnerService } from "./core/spinner/spinner.service";
import { TasksService } from "./tasks/tasks.service";
import { TasksServiceType } from "./tasks/tasks.service.type";
import { StepperService } from "./stepper/stepper.service";
import { StepperServiceType } from "./stepper/stepper.service.type";
import { PcnsServiceType } from "./pcns/pcns.service.type";
import { PcnsService } from "./pcns/pcns.service";
import { FilterTreeServiceType } from "./filter-tree/filter-tree.service.type";
import { FilterTreeService } from "./filter-tree/filter-tree.service";
import { ExternalDataFilterServiceType } from "./external-data-filter/external-data-filter.service.type";
import { ExternalDataFilterService } from "./external-data-filter/external-data-filter.service";
import { ApplicationAreaServiceType } from "./application-area/application-area.service.type";
import { ApplicationAreaService } from "./application-area/application-area.service";
import { TaskCompletedService } from "./task-completed-note/task-completed.service";
import { TaskCompletedServiceType } from "./task-completed-note/task-completed.service.type";
import { FilterFieldsService } from "./filter-fields/filter-fields.service";
import { FilterFieldsServiceType } from "./filter-fields/filter-fields.service.type";
import { AttachmentServiceType } from "./attachment/attachment.service.type";
import { AttachmentService } from "./attachment/attachment.service";
import { PcnGeneratorServiceType } from "./pcn-generator/pcn-generator.service.type";
import { PcnGeneratorService } from "./pcn-generator/pcn-generator.service";
import { ImportXmlService } from "./import-xml/import-xml.service";
import { OperationsService } from "./operations/operations.service";
import { OperationsServiceType } from "./operations/operations.service.type";
import { InternalItemsServiceType } from "./internal-items/internal-items.service.type";
import { InternalItemsService } from "./internal-items/internal-items.service";
import { AttachmentsService } from "./attachments/attachments.service";
import { AttachmentsServiceType } from "./attachments/attachments.service.type";
import { HeaderService } from "./header/header.service";
import { HeaderServiceType } from "./header/header.service.type";
import { HistoryServiceType } from "./history/history.service.type";
import { HistoryService } from "./history/history.service";
import { OverrideMpnFieldsServiceType } from "./override-mpnFields-modal/override-mpnFields-service.type";
import { OverrideMpnFieldsService } from "./override-mpnFields-modal/override-mpnFields.service";
import { SVHCItemsServiceType } from "./SVHC-items/SVHC-items.service.type";
import { SVHCItemsService } from "./SVHC-items/SVHC-items.service";
import { ManufacturerUtilsServiceType } from "./manufacturer/manufacturer-utils.service.type";
import { ManufacturerUtilsService } from "./manufacturer/manufacturer-utils.service";
import { Customer } from "../../../shared/types/customers";
import { ChangesServiceType } from "./changes/changes.service.type";
import { ChangesService } from "./changes/changes.service";
import { DetailTableServiceType } from "./part/detail-table/detail-table.service.type";
import { DetailTableService } from "./part/detail-table/detail-table.service";
import { TreeService } from "./tree/tree.service";
import { TreeServiceType } from "./tree/tree.service.type";
import { ProductCategoryServiceType } from "./product-category/product-category.service.type";
import { ProductCategoryService } from "./product-category/product-category.service";
import { PartsFilterTableServiceType } from "./RM/parts-filter-table/parts-filter-table.service.type";
import { PartsFilterTableService } from "./RM/parts-filter-table/parts-filter-table.service";
import { ManufacturerNameService } from "./manufacturer/manufacturer-name/manufacturer-name.service";
import { ManufacturerNameServiceType } from "./manufacturer/manufacturer-name/manufacturer-name.service.type";
import { TreeRowService } from "./tree-row/tree-row.service";
import { TreeRowServiceType } from "./tree-row/tree-row.service.type";
import { FilterServiceType } from "./filter/filter.service.type";
import { FilterService } from "./filter/filter.service";
import { CreateManufacturerServiceType } from "./create-manufacturer-modal/create-manufacturer-modal.service-type";
import { CreateManufacturerService } from "./create-manufacturer-modal/create-manufacturer-modal.service";
import { PanelServiceType } from "./panel/panel.service.type";
import { PanelService } from "./panel/panel.service";
import { TypeAheadBasicService } from "./field/type-ahead/typeahead-basic.service";
import { TypeAheadBasicServiceType } from "./field/type-ahead/typeahead-basic.service.type";
import { OCHomeService } from "./obsolescence-community/OC-home/OC-home.service";
import { OCHomeServiceType } from "./obsolescence-community/OC-home/OC-home.service.type";
import { OCPostService } from "./obsolescence-community/OC-post/OC-post.service";
import { OCPostServiceType } from "./obsolescence-community/OC-post/OC-post.service.type";
import { OCCommentService } from "./obsolescence-community/OC-comment/OC-comment.service";
import { OCCommentServiceType } from "./obsolescence-community/OC-comment/OC-comment.service.type";
import { OCPostItemService } from "./obsolescence-community/OC-post-item/OC-post-item.service";
import { OCPostItemServiceType } from "./obsolescence-community/OC-post-item/OC-post-item.service.type";
import { ReleaseNotesModalService } from "./release-notes/release-notes-modal.service";
import { ReleaseNotesModalServiceType } from "./release-notes/release-notes-modal.service.type";
import { FooterServiceType } from "./footer/footer.service.type";
import { FooterService } from "./footer/footer.service";

@Injectable()
export class App implements AppType {
  get current() {
    return _app.value;
  }

  get error() {
    return this.state.error;
  }

  set error(error: boolean) {
    this.state.next({ error, busy: false });
  }

  get hasError() {
    return this.state.hasError;
  }

  set hasError(hasError: boolean) {
    this.state.next({ hasError });
  }

  get hasSuccess() {
    return this.state.hasSuccess;
  }

  set hasSuccess(hasSuccess: boolean) {
    this.state.next({ hasSuccess });
  }

  get errorLoginName() {
    return this.state.errorLoginName;
  }

  set errorLoginName(errorLoginName: boolean) {
    this.state.next({ errorLoginName });
  }

  get errorLoginPassword() {
    return this.state.errorLoginPassword;
  }

  set errorLoginPassword(errorLoginPassword: boolean) {
    this.state.next({ errorLoginPassword });
  }

  get loginError() {
    return this.state.loginError;
  }

  set loginError(loginError: boolean) {
    this.state.next({ loginError });
  }

  get errorText() {
    return this.state.errorText;
  }

  set errorText(errorText: string) {
    this.state.next({ errorText });
  }
  get successText() {
    return this.state.successText;
  }

  set successText(successText: string) {
    this.state.next({ successText });
  }

  get numberOfUnreadAlerts() {
    return this.state.numberOfUnreadAlerts;
  }

  set numberOfUnreadAlerts(numberOfUnreadAlerts: number) {
    this.state.next({ numberOfUnreadAlerts });
  }

  get numberOfUnreadChangeAlerts() {
    return this.state.numberOfUnreadChangeAlerts;
  }
  set numberOfUnreadChangeAlerts(numberOfUnreadChangeAlerts: number) {
    this.state.next({ numberOfUnreadChangeAlerts });
  }

  get numberOfUnreadLeadTimeAlerts() {
    return this.state.numberOfUnreadLeadTimeAlerts;
  }
  set numberOfUnreadLeadTimeAlerts(numberOfUnreadLeadTimeAlerts: number) {
    this.state.next({ numberOfUnreadLeadTimeAlerts });
  }

  get numberOfUnreadPcns() {
    return this.state.numberOfUnreadPcns;
  }

  set numberOfUnreadPcns(numberOfUnreadPcns: number) {
    this.state.next({ numberOfUnreadPcns });
  }

  get numberOfUnreadInvMonAlerts() {
    return this.state.numberOfUnreadInvMonAlerts;
  }

  set numberOfUnreadInvMonAlerts(numberOfUnreadInvMonAlerts: number) {
    this.state.next({ numberOfUnreadInvMonAlerts });
  }

  get numberOfUnreadEnvComplianceAlerts() {
    return this.state.numberOfUnreadEnvComplianceAlerts;
  }

  set numberOfUnreadEnvComplianceAlerts(
    numberOfUnreadEnvComplianceAlerts: number
  ) {
    this.state.next({ numberOfUnreadEnvComplianceAlerts });
  }

  get isAnalyst() {
    return this.state.isAnalyst;
  }
  set isAnalyst(isAnalyst: boolean) {
    this.state.next({ isAnalyst });
  }

  get isRMAdmin() {
    return this.state.isRMAdmin;
  }
  set isRMAdmin(isRMAdmin: boolean) {
    this.state.next({ isRMAdmin });
  }

  get busy() {
    return this.state.busy;
  }

  get unlockedId() {
    return this.state.unlockedId;
  }

  set unlockedId(unlockedId: string | null) {
    this.state.next({ unlockedId });
  }

  get languages(): Language[] {
    return ["nl", "de", "en"];
  }

  get language() {
    return this.state.language;
  }

  set language(language: Language) {
    this.state.next({ language });
  }

  get settings(): Settings {
    const customer = this.customers.current;
    if (customer != null) {
      const buffer = this.state.buffer[customer];
      if (buffer != null && buffer.settings != null) {
        return buffer.settings;
      }
    }

    return defaultSettings;
  }

  get text() {
    return this.proxy.getTextProxy(this.language);
  }

  get permission() {
    return this.proxy.getPermissionProxy();
  }

  get additionalInfo() {
    return this.proxy.getAdditionalInfoProxy();
  }

  get fieldId() {
    return this.proxy.getFieldIdProxy();
  }

  get list() {
    return this.proxy.getListProxy();
  }

  get listId() {
    return this.proxy.getListIdProxy();
  }

  get url() {
    return this.proxy.getUrlProxy();
  }

  get elements(): Type[] {
    const { settings } = this;
    if (settings == null) {
      return [];
    }
    return Object.keys(settings) as Type[];
  }

  get element() {
    return this.state.element;
  }

  set element(element: Type | null) {
    this.state.next({ element });
  }

  get docs(): DocsServiceType {
    return ServiceHelper.get(DocsService);
  }

  get field(): FieldServiceType {
    return ServiceHelper.get(FieldService);
  }

  get home(): HomeServiceType {
    return ServiceHelper.get(HomeService);
  }

  get impact(): ImpactServiceType {
    return ServiceHelper.get(ImpactService);
  }
  get impacts(): ImpactsServiceType {
    return ServiceHelper.get(ImpactsService);
  }

  get users(): UsersServiceType {
    return ServiceHelper.get(UsersService);
  }

  get userSettings(): UserSettingsServiceType {
    return ServiceHelper.get(UserSettingsService);
  }

  get profile(): ProfileServiceType {
    return ServiceHelper.get(ProfileService);
  }

  get table(): TableServiceType {
    return ServiceHelper.get(TableService);
  }

  get paginator(): PaginatorServiceType {
    return ServiceHelper.get(PaginatorService);
  }

  get mail(): MailServiceType {
    return ServiceHelper.get(MailService);
  }

  get routing(): RoutingServiceType {
    return ServiceHelper.get(RoutingService);
  }

  get auth(): AuthServiceType {
    return ServiceHelper.get(AuthService);
  }

  get pcns(): PcnsServiceType {
    return ServiceHelper.get(PcnsService);
  }

  get user() {
    return this.state.user;
  }

  get expectUser() {
    return this.expect(this.state.user);
  }

  get view() {
    // Removed this part of code because profile view was always null
    // const { view, profileUser } = this.state;
    // if (profileUser != null) {
    //   return null;
    // }
    // return view;

    const { view } = this.state;
    return view;
  }

  get types() {
    return getDocTypes();
  }

  get type() {
    return this.state.type;
  }

  set type(type: Type | null) {
    this.state.next({
      type,
      id: null,
      newId: null,
      field: null,
      featureChart: null,
    });
  }

  get expectType() {
    return this.expect(this.type) as Type;
  }

  get id() {
    return this.state.id;
  }

  set id(id: string | null) {
    this.state.next({ id, newId: null });
    this.current.docs.resetDocModel();
    this.current.docs.resetJsonModel();
  }

  get expectId() {
    return this.expect(this.id);
  }

  get newId() {
    return this.state.newId;
  }

  set newId(newId: string | null) {
    this.state.next({ id: null, newId, model: {}, jsonModel: "{}" });
  }

  get currentId() {
    const { id, newId } = this.state;
    if (id != null) {
      return id;
    }
    if (newId != null) {
      return newId;
    }
    return null;
  }

  get model() {
    return this.state.model;
  }

  set model(model: DocModel) {
    this.state.next({ model });
  }

  get debugJson() {
    return this.state.debugJson;
  }

  set debugJson(debugJson: boolean) {
    this.state.next({ debugJson });
  }

  get file(): FileServiceType {
    return ServiceHelper.get(FileService);
  }

  get dinCodeResponsible(): DinCodeResponsibleServiceType {
    return ServiceHelper.get(DinCodeResponsibleService);
  }

  get commodityGroupResponsible(): CommodityGroupResponsibleServiceType {
    return ServiceHelper.get(CommodityGroupResponsibleService);
  }

  get thread(): ThreadServiceType {
    return ServiceHelper.get(ThreadService);
  }

  get pcn(): PcnServiceType {
    return ServiceHelper.get(PcnService);
  }

  get vehicleResponsible(): VehicleResponsibleServiceType {
    return ServiceHelper.get(VehicleResponsibleService);
  }

  get post(): PostServiceType {
    return ServiceHelper.get(PostService);
  }

  get part(): PartServiceType {
    return ServiceHelper.get(PartService);
  }

  get RM(): RMServiceType {
    return ServiceHelper.get(RMService);
  }

  get RMSearch(): RMSearchServiceType {
    return ServiceHelper.get(RMSearchService);
  }

  get operations(): OperationsServiceType {
    return ServiceHelper.get(OperationsService);
  }

  get manufacturer(): ManufacturerServiceType {
    return ServiceHelper.get(ManufacturerService);
  }

  get createManufacturer(): CreateManufacturerServiceType {
    return ServiceHelper.get(CreateManufacturerService);
  }

  get manufacturerMatching(): ManufacturerMatchingServiceType {
    return ServiceHelper.get(ManufacturerMatchingService);
  }

  get pcnGenerator(): PcnGeneratorServiceType {
    return ServiceHelper.get(PcnGeneratorService);
  }

  get externalDataFilter(): ExternalDataFilterServiceType {
    return ServiceHelper.get(ExternalDataFilterService);
  }

  get tree(): TreeServiceType {
    return ServiceHelper.get(TreeService);
  }

  get treeCell(): TreeCellServiceType {
    return ServiceHelper.get(TreeCellService);
  }

  get treeRow(): TreeRowServiceType {
    return ServiceHelper.get(TreeRowService);
  }

  get leftNav(): LeftNavServiceType {
    return ServiceHelper.get(LeftNavService);
  }

  get message(): MessageServiceType {
    return ServiceHelper.get(MessageService);
  }

  get alerts(): AlertsServiceType {
    return ServiceHelper.get(AlertsService);
  }

  get manufacturersFilter(): ManufacturersFilterTableServiceType {
    return ServiceHelper.get(ManufacturersFilterTableService);
  }

  get partsTable(): PartsFilterTableServiceType {
    return ServiceHelper.get(PartsFilterTableService);
  }

  get RMSubmenu(): RMSubmenuServiceType {
    return ServiceHelper.get(RMSubmenuService);
  }

  get import(): ImportServiceType {
    return ServiceHelper.get(ImportService);
  }

  get importXml(): ImportXmlService {
    return ServiceHelper.get(ImportXmlService);
  }

  get graphs(): GraphsServiceType {
    return ServiceHelper.get(GraphsService);
  }

  get filter(): FilterServiceType {
    return ServiceHelper.get(FilterService);
  }

  get filterList(): FilterListServiceType {
    return ServiceHelper.get(FilterListService);
  }

  get customer() {
    return this.customers.current;
  }

  get customers(): CustomersServiceType {
    return ServiceHelper.get(CustomersService);
  }

  get person(): PersonServiceType {
    return ServiceHelper.get(PersonService);
  }

  get train(): TrainServiceType {
    return ServiceHelper.get(TrainService);
  }

  get filterTable(): FilterTableServiceType {
    return ServiceHelper.get(FilterTableService);
  }

  get filterHeaderTable(): FilterHeaderTableServiceType {
    return ServiceHelper.get(FilterHeaderTableService);
  }

  get state(): StateServiceType {
    return ServiceHelper.get(StateService);
  }

  get proxy(): ProxyServiceType {
    return ServiceHelper.get(ProxyService);
  }

  get spinner(): SpinnerServiceType {
    return ServiceHelper.get(SpinnerService);
  }

  get tasks(): TasksServiceType {
    return ServiceHelper.get(TasksService);
  }

  get filterTree(): FilterTreeServiceType {
    return ServiceHelper.get(FilterTreeService);
  }

  get filterFields(): FilterFieldsServiceType {
    return ServiceHelper.get(FilterFieldsService);
  }

  get sync(): SyncServiceType {
    return ServiceHelper.get(SyncService);
  }

  get stepper(): StepperServiceType {
    return ServiceHelper.get(StepperService);
  }
  get applicationArea(): ApplicationAreaServiceType {
    return ServiceHelper.get(ApplicationAreaService);
  }
  get taskCompleted(): TaskCompletedServiceType {
    return ServiceHelper.get(TaskCompletedService);
  }

  get internalItems(): InternalItemsServiceType {
    return ServiceHelper.get(InternalItemsService);
  }

  get attachment(): AttachmentServiceType {
    return ServiceHelper.get(AttachmentService);
  }

  get attachments(): AttachmentsServiceType {
    return ServiceHelper.get(AttachmentsService);
  }
  get history(): HistoryServiceType {
    return ServiceHelper.get(HistoryService);
  }

  get overrideMpnFields(): OverrideMpnFieldsServiceType {
    return ServiceHelper.get(OverrideMpnFieldsService);
  }

  get manufacturerUtils(): ManufacturerUtilsServiceType {
    return ServiceHelper.get(ManufacturerUtilsService);
  }
  get SVHCItems(): SVHCItemsServiceType {
    return ServiceHelper.get(SVHCItemsService);
  }

  get header(): HeaderServiceType {
    return ServiceHelper.get(HeaderService);
  }
  get change(): ChangesServiceType {
    return ServiceHelper.get(ChangesService);
  }

  get detailTable(): DetailTableServiceType {
    return ServiceHelper.get(DetailTableService);
  }

  get productCategory(): ProductCategoryServiceType {
    return ServiceHelper.get(ProductCategoryService);
  }

  get manufacturerName(): ManufacturerNameServiceType {
    return ServiceHelper.get(ManufacturerNameService);
  }

  get panel(): PanelServiceType {
    return ServiceHelper.get(PanelService);
  }

  get typeAheadBasic(): TypeAheadBasicServiceType {
    return ServiceHelper.get(TypeAheadBasicService);
  }

  get OCHome(): OCHomeServiceType {
    return ServiceHelper.get(OCHomeService);
  }

  get OCPost(): OCPostServiceType {
    return ServiceHelper.get(OCPostService);
  }

  get OCComment(): OCCommentServiceType {
    return ServiceHelper.get(OCCommentService);
  }

  get OCPostItem(): OCPostItemServiceType {
    return ServiceHelper.get(OCPostItemService);
  }

  get releaseNotesModal(): ReleaseNotesModalServiceType {
    return ServiceHelper.get(ReleaseNotesModalService);
  }

  get footer(): FooterServiceType {
    return ServiceHelper.get(FooterService);
  }

  get logo() {
    switch (this.customer) {
      case Customer.OMP:
        return "./assets/om-portal-logo.png";
      case Customer.NS:
        return "./assets/ns-logo.png";
      case Customer.BVG:
        return "./assets/bvg-logo.png";
      case Customer.KOMAX:
        return "./assets/komax-logo.png";
      case Customer.DB:
        return "./assets/db-logo.png";
      case Customer.MRCE:
        return "./assets/mrce-logo.png";
      case Customer.RHEINBAHN:
        return "./assets/rheinbahn-logo.png";
      case Customer.SCHIEBEL:
        return "./assets/schiebel-logo.png";
      case Customer.AIRBUSDS:
        return "./assets/airbusds-logo.png";
      case Customer.SICK:
        return "./assets/sick-logo.png";
      case Customer.CAE:
        return "./assets/cae-logo.png";
      case Customer.CEOTRONICS:
        return "./assets/ceotronics-logo.png";
      case Customer.MAQUETGMBH:
        return "./assets/maquetgmbh-logo.png";
      case Customer.MAQUETCARDIO:
        return "./assets/maquetgmbh-logo.png";
      case Customer.IAV:
        return "./assets/iav-logo.png";
      case Customer.MTU:
        if (this.header.showSidebar || this.pcnGenerator.isPcnGenerator) {
          return "./assets/mtu-logo.png";
        } else {
          return "./assets/mtu-small-logo.png";
        }
      case Customer.HERRMANNULTRASCHALL:
        if (this.header.showSidebar || this.pcnGenerator.isPcnGenerator) {
          return "./assets/herrmannultraschall-logo.png";
        } else {
          return "./assets/herrmannultraschall-small-logo.png";
        }
      case Customer.SSI:
        return "./assets/ssi-logo.png";
      case Customer.ATLAS:
        return "./assets/atlas-logo.png";
      case Customer.LRE:
        if (this.header.showSidebar || this.pcnGenerator.isPcnGenerator) {
          return "./assets/lre-logo.png";
        } else {
          return "./assets/lre-small-logo.png";
        }
      case Customer.KVB:
        return "./assets/kvb-logo.png";
      case Customer.RUAGCH:
        return "./assets/ruagch-logo.png";
      case Customer.KATEK:
      case Customer.KATEKMEMM:
      case Customer.KATEKMAUER:
      case Customer.KATEKLEIP:
      case Customer.KATEKGRASS:
        return "./assets/katek-logo.png";
      case Customer.ESYSTEMS:
        return "./assets/esystems-logo.png";
      case Customer.EMZ:
        return "./assets/emz-logo.png";
      case Customer.KNDS:
        if (this.header.showSidebar || this.pcnGenerator.isPcnGenerator) {
          return "./assets/knds-logo.png";
        } else {
          return "./assets/knds-small-logo.png";
        }
      case Customer.ROSEN:
        if (this.header.showSidebar || this.pcnGenerator.isPcnGenerator) {
          return "./assets/rosen-logo.png";
        } else {
          return "./assets/rosen-small-logo.png";
        }
      case Customer.TAE:
        return "./assets/tae-logo.png";
      case Customer.COOP:
        return "./assets/coop-logo.png";
      case Customer.KARLSTORZ:
        return "./assets/karlstorz-logo.png";
      case Customer.TSK:
        return "./assets/tsk-logo.png";
      case Customer.XLIVE:
        return "./assets/isae-logo.png";
      case Customer.STADLER:
        return "./assets/stadler-logo.png";
      case Customer.BEFLEX:
        return "./assets/beflex-logo.png";
      case Customer.AUTOFLUG:
        return "./assets/autoflug-logo.png";
      case Customer.SEW:
        return "./assets/sew-logo.png";
      case Customer.PLASTICOMNIUM:
        return "./assets/plasticomnium-logo.png";
      case Customer.RHEINMETALL:
      case Customer.RHEINMETALLAIRDEFENCE:
        if (this.header.showSidebar || this.pcnGenerator.isPcnGenerator) {
          return "./assets/rheinmetall-logo.png";
        } else {
          return "./assets/rheinmetall-small-logo.png";
        }
      case Customer.BELIMO:
        return "./assets/belimo-logo.png";
      case Customer.PILZ:
        return "./assets/pilz-logo.png";
      case Customer.RADWELL:
        return "./assets/radwell-logo.png";
      case Customer.DUOMETRIC:
        return "./assets/duometric-logo.png";
      case Customer.OC:
        return "./assets/oc-favicon.png";
      case Customer.HAGENUK:
        return "./assets/hagenuk-logo.png";
      case Customer.SIEMENSLOGISTICS:
        return "./assets/siemens-logistics-logo.png";
      default:
        return "./assets/amsys-logo.png";
    }
  }

  get langLogo() {
    switch (this.language) {
      case "de":
        return "./assets/de.png";
      case "en":
        return "./assets/en.png";
      case "nl":
        return "./assets/nl.png";
    }
  }

  // private proxies = {
  //   text: lazy(() => getTextProxy(this.settings, this.language)),
  //   permission: lazy(() => getPermissionProxy(this.settings)),
  //   fieldId: lazy(() => getFieldIdProxy()),
  //   list: lazy(() => getListProxy(this.settings)),
  //   listId: lazy(() => getListIdProxy()),
  //   url: lazy(() => getUrlProxy(this.settings)),
  // };

  getTranslatedText(text: Text, language: Language = this.language): string {
    return getTranslatedText(text, language);
  }

  async run<T>(f: () => Promise<T>) {
    // this.current.lock();
    try {
      return await f();
    } catch (err) {
      this.current.error = true;
      throw err;
    } finally {
      this.current.unlock();
    }
  }

  lock() {
    if (this.error) {
      // console.log("failed to lock app: error flag is set");
      return;
    }
    if (this.busy) {
      // TODO
      // console.log("failed to lock app: app is already locked");
      return;
    }
    this.spinner.showSpinner();
    this.state.next({ busy: true });
  }

  unlock() {
    if (!this.busy) {
      return; // TODO
      // throw new Error("not busy");
    }
    this.spinner.hideSpinner();
    this.state.next({ busy: false });
  }

  isLocked(id: string | null) {
    if (id == null) {
      return false;
    }
    return id !== this.unlockedId;
  }

  getUrl(id: string) {
    const [type, name] = id.split(".");
    const componentSettings = this.settings[type as Type] as _ComponentSettings;
    return componentSettings.url[name];
  }

  getText(id: string | null) {
    if (id == null) {
      return "";
    }
    try {
      const [type, name] = id.split(".");
      const componentSettings = this.settings[
        type as Type
      ] as _ComponentSettings;
      const textSettings = componentSettings.text[name];
      return this.getTranslatedText(textSettings);
    } catch (err) {
      // console.log('failed to get text:', JSON.stringify(id));
      return id;
    }
  }

  getTypeText(type: string) {
    return this.getText(type + "." + type);
  }

  getList(id: string) {
    const [type, name] = id.split(".");
    const componentSettings = this.settings[type as Type] as _ComponentSettings;
    return componentSettings.list[name];
  }

  getSettings(type: keyof _ComponentSettings, element = this.element) {
    if (element == null) {
      throw new Error("no element");
    }
    return Object.keys(this.settings[element][type]);
  }

  expect(id: string | null | undefined) {
    // if (StringUtils.isNullOrEmpty(id)) {
    //   throw new Error("id expected");
    // }
    if (id == null || id === "") {
      throw new Error("id expected");
    }
    return id;
  }

  clearModel() {
    const { model } = this;
    Object.keys(model).forEach((key) => delete model[key]);
  }

  getModelProxy(model: DocModel) {
    return getModelProxy(this.settings, model);
  }

  createModel(id: string | null, type: Type) {
    if (id == null) {
      throw new Error("id is null");
    }
    const doc = this.docs.getDoc(id, type);
    return doc2Model(type, doc);
  }

  createId() {
    return "NEW_" + Math.floor(Math.random() * 1000000);
  }

  async importXlsx(files: FileList) {
    const file = files[0];
    const importBuffer = { docs: await importXlsx(file) };
    this.state.next({ importBuffer });
  }

  async importJson(files: FileList) {
    const file = files[0];
    const importBuffer = { docs: await importJson(file) };
    this.state.next({ importBuffer });
  }

  exportXlsx() {
    writeFile(this);
  }

  isSelected(id: string) {
    return new Set([
      this.thread.id,
      this.users.currentUser.name,
      this.impact.id,
    ]).has(id);
  }
}

/** The current application */
const _app = new BehaviorSubject(new App());

/** Subscribe to updates regarding changes in the current app */
export const getApp = (handler?: (app: AppType) => void): AppType => {
  if (handler != null) {
    _app.subscribe(handler);
  }
  return _app.value;
};
