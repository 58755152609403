<div>
  <div
    class="tableFixHead mx-2 pb-2"
    [ngClass]="{
      tableHeight: app.filterTable.isDropdownOpen
    }"
  >
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="p-1" *ngFor="let column of copyOfColumns">
            <div class="d-inline-flex">
              <div
                [ngClass]="{
                  'resize-table': app.table.canColumnBeResized(column)
                }"
              >
                <span
                  (click)="
                    app.table.toggleSortDirection(column);
                    app.table.sort(app.detailTable.docs)
                  "
                >
                  {{ app.field.handleSpecialLogicLabels(column) }}
                </span>
                <div *ngIf="column === 'remove'">
                  <i
                    class="far fa-square"
                    *ngIf="app.table.selected.size === 0"
                    (click)="app.table.selectAll(app.detailTable.docs, true)"
                  ></i>
                  <i
                    class="far fa-check-square"
                    *ngIf="app.table.selected.size > 0"
                    (click)="app.table.clearSelection()"
                  ></i>
                </div>
                &nbsp;
                <span *ngIf="column === app.table.sortField">
                  <i
                    *ngIf="app.table.sortDirection === 1"
                    class="fa fa-chevron-up"
                  ></i>
                  <i
                    *ngIf="app.table.sortDirection === -1"
                    class="fa fa-chevron-down"
                  ></i>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                app.filterTable.getFilterTableColumns(
                  app.mail.getColumnShort(column)
                )
              "
            >
              <app-filter-table
                [column]="app.mail.getColumnShort(column)"
                [columns]="columns"
                [docType]="type"
                [cleanDocs]="app.detailTable.cleanDocs"
                [selectedFilters]="selectedFilters"
                (resultedDocs)="
                  app.filterTable.getFilterResults($event, 'detailTable')
                "
              >
              </app-filter-table>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="pointer"
          *ngFor="
            let id of showPaginator
              ? app.paginator.getPage(app.detailTable.docs)
              : app.detailTable.docs;
            let i = index
          "
          [ngClass]="{ isSelected: app.isSelected(id) }"
        >
          <td class="p-1" *ngFor="let column of cleanColumns">
            <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
              <div *ngSwitchCase="'normal'" (click)="navigate(id, column)">
                {{ id[column] }}
              </div>
              <div *ngSwitchCase="'badge'" (click)="navigate(id, column)">
                <span
                  class="badge badgeStatus"
                  [ngStyle]="app.field.getColor('part.' + column, id[column])"
                >
                  {{ app.field.getOptionText("part." + column, id[column]) }}
                </span>
              </div>
              <div *ngSwitchCase="'navigateAction'">
                <a class="partNumber" (click)="navigate(id, column)">{{
                  id[column]
                }}</a>
              </div>
              <div *ngSwitchCase="'actions'">
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  *ngIf="column === 'remove'"
                  (click)="app.table.toggleSelect(id); $event.stopPropagation()"
                >
                  <i
                    class="far fa-check-square"
                    *ngIf="app.table.selected.has(id)"
                  ></i>
                  <i
                    class="far fa-square"
                    *ngIf="!app.table.selected.has(id)"
                  ></i>
                </button>
                <span *ngIf="column === 'createCase'">
                  <i
                    class="far fa-folder-plus click-area icon-blue"
                    *ngIf="
                      !app.thread.getCase(id) &&
                      id.manufacturerPartNumber !== ''
                    "
                    (click)="app.thread.newCase(id)"
                  ></i>
                  <i
                    class="fas fa-folder-open click-area icon-blue"
                    *ngIf="app.thread.getCase(id)"
                    (click)="
                      seeOpenedCasesModal.open();
                      app.manufacturersFilter.currentManufacturer = id;
                      app.thread.getCasesByPartNumber(id)
                    "
                  ></i>
                </span>
              </div>
              <div *ngSwitchCase="'checkBox'" (click)="navigate(id, column)">
                <span *ngIf="column === 'valid'">
                  <i
                    class="far fa-square"
                    *ngIf="
                      !id[column] || id[column] == null || id[column] === ''
                    "
                  ></i>
                  <i class="far fa-check-square" *ngIf="id[column]"></i>
                </span>
              </div>
              <div *ngSwitchCase="'radio'" (click)="navigate(id, column)">
                <span *ngIf="column === 'active'">
                  <i
                    *ngIf="id[column] === 'true'"
                    class="fas fa-star-of-life"
                  ></i>
                </span>
              </div>
              <div *ngSwitchCase="'textCenter'" class="text-center">
                {{ id[column] }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td *ngIf="loading" colspan="99" class="text-center">
            {{ app.text.core.loading }}
          </td>
          <td
            *ngIf="!loading && app.detailTable.docs.length === 0"
            colspan="99"
            class="text-center"
          >
            {{ app.text.part.bomNoResults }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-modal
    #seeOpenedCasesModal
    [modalTitle]="app.text.thread.threads"
    [modalType]="'showListOfCases'"
    [showButtons]="false"
  >
  </app-modal>
</div>
