import { EncodingUtils } from "../../../../shared/utils/encoding.utils";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { TOKEN } from "./token";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";

@Injectable()
export class ExtensionTokenInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent.type === 0) {
          return;
        }

        if (httpEvent instanceof HttpResponse) {
          if (httpEvent.headers.has("X-AM-ExtensionToken")) {
            const tokenValue = httpEvent.headers.get("X-AM-ExtensionToken");

            if (typeof Storage !== "undefined" && tokenValue != null) {
              if (localStorage.getItem(TOKEN) != null) {
                localStorage.setItem(
                  TOKEN,
                  EncodingUtils.encodeBase64(tokenValue)
                );
              } else if (sessionStorage.getItem(TOKEN) != null) {
                sessionStorage.setItem(
                  TOKEN,
                  EncodingUtils.encodeBase64(tokenValue)
                );
              }
            }
          }
        }
      })
    );
  }
}
