import { Buffer } from "buffer";

export class EncodingUtils {
  public static encodeBase64(data: string): string {
    if (data == undefined) {
      return "";
    }

    return Buffer.from(data).toString("base64");
  }

  public static decodeBase64(data: string): string {
    if (data == undefined) {
      return "";
    }

    return Buffer.from(data, "base64").toString("utf8");
  }
}
