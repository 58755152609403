import { Component } from "@angular/core";
import { getApp } from "../app";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  app = getApp((app) => {
    this.app = app;
  });
  year = 0;
  version = "";

  constructor() {}

  ngOnInit() {
    const date = new Date();
    this.year = date.getFullYear();
    this.version = environment.version;
  }
}
