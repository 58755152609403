import { Inject, Injectable } from "@angular/core";
import {
  Inventory,
  Manufacturer,
  PcnData,
  PCNDto,
  SeDetail,
} from "../../../../shared/models/manufacturer";
import {
  doc2Model,
  getDataByProviderId,
  getSubstitutes,
  getInventoryDataById,
} from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { formatDate } from "../utils/date.util";
import { ManufacturerUtilsServiceType } from "./manufacturer-utils.service.type";
import * as XLSX from "xlsx";
import { ManufacturerService } from "./manufacturer.service";
import { BehaviorSubject } from "rxjs";
import { StringUtils } from "../../../../shared/utils/string.utils";
import {
  PCN,
  Z2DataDetail,
} from "../../../../shared/models/dataProviders/z2data";
import { DataProvider } from "../../../../shared/constants/dataProvider";

@Injectable()
export class ManufacturerUtilsService implements ManufacturerUtilsServiceType {
  currentDetailsSE: SeDetail = new SeDetail();
  currentDetailsZ2Data: Z2DataDetail = new Z2DataDetail();
  pcnHistoryAsInSE: PCNDto[] = [];
  pcnHistoryAsInZ2Data: PCN[] = [];
  pcnHistoryDocs: any[] = [];
  pcnHistoryLoading = false;
  substitutes: Partial<Manufacturer>[] = [];
  cleanSubstitutes: Partial<Manufacturer>[] = [];
  type: "pcnHistory" | "replacements" = "replacements";
  exportDocs: any[] = [];
  exportName = "";
  resultExists: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pcnIds: string[] = [];
  cleanHistoryDocs: PcnData[] = [];
  currentInvDocs: Inventory[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getInventoryDetails() {
    let dataProviderId =
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? this.app.manufacturer.currentManufacturer[
            this.app.fieldId.manufacturer.z2DataId
          ]
        : this.app.manufacturer.currentManufacturer[
            this.app.fieldId.manufacturer.seId
          ];

    if (dataProviderId != null && dataProviderId != "") {
      let inventoryData = await getInventoryDataById(
        this.app.customers.expectCurrent,
        this.app.customers.dataProvider,
        dataProviderId
      );
      this.currentInvDocs = inventoryData;
    }
  }

  async getCurrentDetails(dataProviderId: string) {
    if (StringUtils.isNullOrEmpty(dataProviderId)) {
      this.app.manufacturerUtils.pcnHistoryDocs = [];
      return;
    }

    if (this.app.customers.dataProvider === DataProvider.SE) {
      this.currentDetailsSE = await getDataByProviderId(
        this.app.customers.expectCurrent,
        this.app.customers.dataProvider,
        dataProviderId
      );
      if (this.currentDetailsSE && this.currentDetailsSE.PCNDetails) {
        if (
          this.currentDetailsSE.PCNDetails.PCNDto &&
          !this.currentDetailsSE.PCNDetails.PCNDto.length
        ) {
          this.pcnHistoryAsInSE.push(
            this.currentDetailsSE.PCNDetails.PCNDto as any
          );
        } else {
          this.pcnHistoryAsInSE = [];
          this.pcnHistoryAsInSE = this.currentDetailsSE.PCNDetails.PCNDto;
        }
        this.preparePcnHistoryDocsSE();
      }
    }

    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      this.currentDetailsZ2Data = await getDataByProviderId(
        this.app.customers.expectCurrent,
        this.app.customers.dataProvider,
        dataProviderId
      );
      if (this.currentDetailsZ2Data && this.currentDetailsZ2Data.PCNHistory) {
        this.pcnHistoryAsInZ2Data = this.currentDetailsZ2Data.PCNHistory;
      }
      this.preparePcnHistoryDocsZ2Data();
    }
  }

  preparePcnHistoryDocsSE() {
    /** map the fields from the incoming SE document to a property of type PcnData
     * prepare the list to be displayed in the modal component
     */
    if (this.pcnHistoryAsInSE) {
      this.pcnHistoryDocs = [];

      this.pcnHistoryAsInSE.forEach((doc: PCNDto) => {
        const pcnHistory = new PcnData();
        if (doc == null) {
          return;
        } else {
          Object.keys(pcnHistory).forEach((key: string) => {
            const value = (doc as any)[this.setCorrespondingSeField(key)];
            if (value != null) {
              (pcnHistory as any)[key] = value;
            }
            if (key === "pcnEffectiveDate" || key === "pcnIssueDate") {
              (pcnHistory as any)[key] = formatDate(value);
            }
            if (key === "pcnTypeOfChange") {
              (pcnHistory as any)[key] = this.app.alerts.setTypeOfChange(value);
            }
          });
          pcnHistory._id = this.app.createId();
        }
        this.pcnHistoryDocs.push(pcnHistory);
      });

      this.cleanHistoryDocs = this.pcnHistoryDocs;

      this.pcnHistoryDocs = this.sortHistoryDocs();
      this.pcnIds = this.pcnHistoryDocs.map((doc: PcnData) => doc._id);
    }
  }

  preparePcnHistoryDocsZ2Data() {
    /** map the fields from the incoming Z2Data document to a property of type PcnData
     * prepare the list to be displayed in the modal component
     */
    if (this.pcnHistoryAsInZ2Data) {
      this.pcnHistoryDocs = [];

      this.pcnHistoryAsInZ2Data.forEach((doc: PCN) => {
        const pcnHistory = new PcnData();
        if (doc == null) {
          return;
        } else {
          Object.keys(pcnHistory).forEach((key: string) => {
            const value = (doc as any)[this.setCorrespondingZ2DataField(key)];
            if (value != null) {
              (pcnHistory as any)[key] = value;
            }
            if (key === "pcnEffectiveDate" || key === "pcnIssueDate") {
              (pcnHistory as any)[key] = formatDate(value);
            }
            if (key === "pcnTypeOfChange") {
              (pcnHistory as any)[key] = this.app.alerts.setTypeOfChange(value);
            }
          });
          pcnHistory._id = this.app.createId();
        }
        this.pcnHistoryDocs.push(pcnHistory);
      });

      this.cleanHistoryDocs = this.pcnHistoryDocs;

      this.pcnHistoryDocs = this.sortHistoryDocs();
      this.pcnIds = this.pcnHistoryDocs.map((doc: PcnData) => doc._id);
    }
  }

  /** sort history docs based on the issueDate */
  sortHistoryDocs() {
    this.pcnHistoryDocs.sort((a: PcnData, b: PcnData) => {
      const aValue =
        a["pcnIssueDate"] !== undefined ? new Date(a["pcnIssueDate"]) : "";
      const bValue =
        b["pcnIssueDate"] !== undefined ? new Date(b["pcnIssueDate"]) : "";
      if (bValue > aValue) {
        return 1;
      }

      if (bValue == aValue) {
        return 0;
      }

      return -1;
    });
    return this.pcnHistoryDocs;
  }

  setCorrespondingSeField(field: string) {
    /** select corresponding field from the SE document */
    switch (field) {
      case "pcnIssueDate":
        return "NotificationDate";
      case "pcnDescription":
        return "DescriptionOfChange";
      case "pcnTypeOfChange":
        return "TypeOfChange";
      case "pcnID":
        return "PCNNumber";
      case "pcnSource":
        return "PcnSource";
      case "pcnEffectiveDate":
        return "EffectiveDate";
      default:
        return field;
    }
  }

  setCorrespondingZ2DataField(field: string) {
    /** select corresponding field from the SE document */
    switch (field) {
      case "pcnIssueDate":
        return "NotificationDate";
      case "pcnDescription":
        return "ChangeDescription";
      case "pcnTypeOfChange":
        return "TypeOfChanges";
      case "pcnID":
        return "TrackingNumber";
      case "pcnSource":
        return "Source";
      case "pcnEffectiveDate":
        return "EffectiveDate";
      default:
        return field;
    }
  }

  async getSubstitutes(dataProviderId: string) {
    this.substitutes = [];
    this.cleanSubstitutes = [];
    const data: any = await getSubstitutes(
      this.app.customers.expectCurrent,
      this.app.customers.dataProvider,
      dataProviderId
    );
    const substitutes = data;
    if (substitutes.length === 0) {
      this.resultExists.next(false);
    } else {
      this.resultExists.next(true);
    }
    for (const substitute of substitutes) {
      substitute.obsolescenceStatus = ManufacturerService.parseStatus(
        substitute.obsolescenceStatus
      );
      substitute.euRoHSStatus = ManufacturerService.parseRoHS(
        substitute.eurohs
      );
    }

    this.substitutes = substitutes;
    this.cleanSubstitutes = substitutes;
  }

  exportTables() {
    const book = this.getBook();
    XLSX.writeFile(book, this.exportName);
  }

  private getBook() {
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, this.getSheet());
    return book;
  }
  private getSheet() {
    const fields = this.getListOfFieldsForExport(this.type);
    return XLSX.utils.json_to_sheet(
      [
        fields.map((field) => this.app.field.getLabel(field)),
        ...this.exportDocs.map((id) =>
          fields.map((field) => this.getValues(field, id))
        ),
      ],
      {
        skipHeader: true,
      }
    );
  }

  getListOfFieldsForExport(type: string) {
    this.exportName = "";
    switch (type) {
      case "replacements":
        this.exportDocs = this.substitutes;
        this.exportName = "config.xlsx";
        return this.app.list.manufacturer.loadReplacements;
      case "pcnHistory":
        this.exportDocs = this.pcnHistoryDocs;
        const mpn =
          this.app.manufacturer.currentManufacturer[
            "manufacturer.manufacturerPartNumberClean"
          ] !== ""
            ? this.app.manufacturer.currentManufacturer[
                "manufacturer.manufacturerPartNumberClean"
              ]
            : this.app.manufacturer.currentManufacturer[
                "manufacturer.maufacturerPartNumberRaw"
              ];
        this.exportName = `${mpn}.xlsx`;
        return this.app.list.manufacturer.pcnHistoryColumns;
      default:
        return this.app.list.manufacturer.pcnHistoryColumns;
    }
  }
  private getValues(fieldId: string, id: Partial<Manufacturer>) {
    /** get the values as they are displayed in the view in order to have the same result in the export */
    switch (fieldId) {
      case "manufacturer.obsolescenceStatus":
        if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
          return id["obsolescenceStatus"] != null
            ? this.app.field.getOptionText(fieldId, id["obsolescenceStatus"])
            : "";
        }

        return id["lifeCycleRisk"] != null
          ? this.app.field.getOptionText(fieldId, id["lifeCycleRisk"])
          : "";

      case "manufacturer.pcnEffectiveDate":
      case "manufacturer.pcnIssueDate":
        const date = formatDate(id[fieldId.split(".")[1]]);
        return date;

      case "manufacturer.euRoHS":
        return id["euRoHSStatus"] != null
          ? this.app.field.getOptionText(fieldId, id["euRoHSStatus"])
          : "";
      case "manufacturer.typeOfChange":
        return this.app.field.getFieldValueAsText(fieldId, id[fieldId]);
      case "manufacturer.pcnTypeOfChange":
        const values = id[fieldId.split(".")[1]];
        const result = this.app.field.getFieldValueAsText(fieldId, values);
        return result;
      default:
        return this.app.field.getFieldValueAsText(
          fieldId,
          id[fieldId.split(".")[1]]
        );
    }
  }

  setFieldsFromSelected() {
    const model = doc2Model("manufacturer", {} as Manufacturer);
    Object.keys(this.app.manufacturer.selectedManufacturer).forEach(
      (key: string) => {
        model["manufacturer." + key] =
          this.app.manufacturer.selectedManufacturer[key];
      }
    );
    model["manufacturer.name"] =
      this.app.manufacturer.selectedManufacturer["name"];
    model["manufacturer.nameRaw"] =
      this.app.manufacturer.selectedManufacturer["name"];
    model["manufacturer.manufacturerPartDescription"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartDescription"];
    model["manufacturer.manufacturerPartDescriptionRaw"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartDescription"];
    model["manufacturer.manufacturerPartNumber"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartNumber"];
    model["manufacturer.manufacturerPartNumberRaw"] =
      this.app.manufacturer.selectedManufacturer["manufacturerPartNumber"];
    return model;
  }
}
