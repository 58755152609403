import { Settings } from "../components";
import { _ComponentSettings } from "../settings/settings";

export type AdditionalInfoProxy = {
  [name in keyof Settings]: ComponentAdditionalInfoProxy<
    Settings[name]["additionalInfo"]
  >;
};

type ComponentAdditionalInfoProxy<T> = {
  [name in keyof T]: any;
};

export function getAdditionalInfoProxy(settings: Settings) {
  const proxy: any = {};
  Object.keys(settings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => {
        const component: _ComponentSettings = (<any>settings)[name];
        return getComponentAdditioalInfoProxy(component);
      },
    });
  });

  return <AdditionalInfoProxy>proxy;
}

function getComponentAdditioalInfoProxy<T extends _ComponentSettings>(
  component: T
) {
  const proxy: any = {};
  const { additionalInfo } = component;
  Object.keys(additionalInfo).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => component.additionalInfo[name],
    });
  });
  return <ComponentAdditionalInfoProxy<T>>proxy;
}
