import {
  ComponentSettings,
  FilterTypes,
  FieldTypes,
  empty,
} from "../settings/settings";

const field = {
  vehiclePartNumber: {
    type: FieldTypes.value,
    text: {
      de: "Artikelnummer",
      en: "Part number",
      nl: "Artikelnummer",
    },
    filter: FilterTypes.startsWith,
  },
  vehicleName: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeugname",
      en: "Vehicle-Name",
      nl: "Materieelserie",
    },
  },
  vehicleClass: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeugklasse",
      en: "Vehicle-Type",
      nl: "Materieeltype",
    },
  },
  vehicleFleet: {
    type: FieldTypes.value,
    text: {
      de: "Flotte",
      en: "Fleet",
      nl: "Vloot",
    },
  },
  vehicleStatus: {
    type: FieldTypes.value,
    text: {
      de: "Vehicle Status",
      en: "Fahrzeugstatus",
      nl: "Vehicle Status",
    },
  },
  vehicleGroup: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeugfamilie",
      en: "Vehicle group",
      nl: "Materieelfamilie",
    },
  },
  responsibleFirstName: {
    type: FieldTypes.value,
    text: {
      de: "Vorname",
      en: "First name",
      nl: "Voornaam",
    },
  },
  responsibleLastName: {
    type: FieldTypes.value,
    text: {
      de: "Nachname",
      en: "Last name",
      nl: "Achternaam",
    },
  },
  responsibleDepartment: {
    type: FieldTypes.value,
    text: {
      de: "Abteilung",
      en: "Department",
      nl: "Afdeling",
    },
  },
  responsibleEmail: {
    type: FieldTypes.value,
    text: {
      de: "Email",
      en: "Email",
      nl: "e-mail",
    },
  },
  responsibleTelephone: {
    type: FieldTypes.value,
    text: {
      de: "Telefon",
      en: "Telephone",
      nl: "Telefoon",
    },
  },
  responsibles: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeug verantwortlich",
      en: "Vehicle Responsible",
      nl: "Voertuig verantwoordelijk",
    },
  },
};

export const settings = {
  permission: {
    edit: false,
  },
  text: {
    vehicleResponsible: {
      de: "Flottenverantwortliche/r",
      en: "Vehicle responsible",
      nl: "Materieelmanager",
    },
    edit: { de: "Konfigurieren", en: "Configure", nl: "Configuratie" },
    headerVehicle: {
      de: "Fahrzeug Verantwortlicher - Detailansicht",
      en: "Vehicle Responsible - Detail View",
      nl: "Verantwoordelijk voertuig - Detailweergave",
    },
  },
  url: {},
  field,
  list: {
    filterBoxesVehicle: empty,
    fieldsOnFilter: empty,
    vehicleResponsible: empty,
  },
  additionalInfo: {},
};

export const vehicleResponsible: ComponentSettings<typeof settings> = settings;
