<div>
  <div
    class="row"
    *ngIf="!pcnId"
    [ngClass]="{ 'bottom-line py-4': pcnId == null }"
  >
    <!-- <app-filter *ngIf="mode !== 'alert' && mode !== 'changeAlert'" class="col-md-9"
      [ngClass]="{ 'col-md-12': app.filterList.open }" [types]="['change']"></app-filter> -->
    <div *ngIf="!app.filterList.open && app.view" class="col-md-12">
      <button
        class="btn btn-sm"
        (click)="app.routing.navigateMail('change')"
        *ngIf="!app.permission.RM.isAnalyst && !app.auth.isRMAdmin"
        [ngClass]="{ 'btn-default-red': 'change' === mode && pcnId == null }"
      >
        <i class="fa fa-envelope"></i>&nbsp;&nbsp;
        <span>
          {{ app.text.mail.notification }}
        </span>
        <span
          *ngIf="app.change.unreadMails > 0"
          class="badge"
          [ngClass]="{ selectedBadge: 'change' === mode && pcnId == null }"
        >
          {{ app.change.unreadMails }}
        </span>
      </button>
      <button
        class="btn btn-sm"
        (click)="app.routing.navigateMail('thread')"
        *ngIf="app.permission.mail.showNewCasesNotifications"
        [ngClass]="{
          'btn-default-red': 'thread' === mode || pcnId != null
        }"
      >
        <i class="far fa-folder-plus"></i>&nbsp;&nbsp;
        <span>
          {{ app.text.mail.pcnInbox }}
        </span>
        <span
          *ngIf="app.numberOfUnreadPcns"
          class="badge"
          [ngClass]="{
            selectedBadge: 'thread' === mode || pcnId != null
          }"
        >
          {{ app.numberOfUnreadPcns }}
        </span>
      </button>
      <button
        class="btn btn-sm"
        type="button"
        (click)="app.routing.navigateMail('alert')"
        *ngIf="app.permission.alert.alertPermission"
        [ngClass]="{ 'btn-default-red': 'alert' === mode }"
      >
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;
        <span>
          {{ app.text.mail.alert }}
        </span>
        <span
          *ngIf="app.numberOfUnreadAlerts"
          class="badge"
          [ngClass]="{ selectedBadge: 'alert' === mode }"
        >
          {{ app.numberOfUnreadAlerts }}
        </span>
      </button>
      <button
        class="btn btn-sm"
        type="button"
        (click)="app.routing.navigateMail('changeAlert')"
        *ngIf="app.permission.alert.changeAlertPermission"
        [ngClass]="{
          'btn-default-red': 'changeAlert' === mode
        }"
      >
        <i class="fab fa-rev"></i>&nbsp;&nbsp;
        <span>
          {{ app.text.mail.change }}
        </span>
        <span
          *ngIf="app.numberOfUnreadChangeAlerts"
          class="badge"
          [ngClass]="{ selectedBadge: 'changeAlert' === mode }"
        >
          {{ app.numberOfUnreadChangeAlerts }}
        </span>
      </button>
      <button
        class="btn btn-sm"
        type="button"
        (click)="app.routing.navigateMail('leadTimeAlert')"
        *ngIf="app.permission.alert.leadTimeAlertsPermission"
        [ngClass]="{
          'btn-default-red': 'leadTimeAlert' === mode
        }"
      >
        <i class="fas fa-truck-container"></i>&nbsp;&nbsp;
        <span>
          {{ app.text.alert.leadTimeAlert }}
        </span>
        <span
          *ngIf="app.numberOfUnreadLeadTimeAlerts"
          class="badge"
          [ngClass]="{
            selectedBadge: 'leadTimeAlert' === mode
          }"
        >
          {{ app.numberOfUnreadLeadTimeAlerts }}
        </span>
      </button>
      <button
        class="btn btn-sm"
        type="button"
        (click)="app.routing.navigateMail('inventoryMonitoringAlert')"
        *ngIf="app.customer === 'mtu'"
        [ngClass]="{
          'btn-default-red': 'inventoryMonitoringAlert' === mode
        }"
      >
        <i class="fa-solid fa-flag-pennant"></i>&nbsp;&nbsp;{{
          app.text.part.inventoryMonitoringAlerts
        }}
        <span
          *ngIf="app.numberOfUnreadInvMonAlerts"
          class="badge"
          [ngClass]="{
            selectedBadge: 'inventoryMonitoringAlert' === mode
          }"
        >
          {{ app.numberOfUnreadInvMonAlerts }}
        </span>
      </button>
      <button
        class="btn btn-sm"
        type="button"
        (click)="app.routing.navigateMail('envComplianceAlert')"
        *ngIf="app.permission.alert.envComplianceAlertsPermission"
        [ngClass]="{
          'btn-default-red': 'envComplianceAlert' === mode
        }"
      >
        <i class="fa-brands fa-envira"></i>&nbsp;&nbsp;
        <span>
          {{ app.text.alert.envComplianceAlerts }}
        </span>
        <span
          *ngIf="app.numberOfUnreadEnvComplianceAlerts"
          class="badge"
          [ngClass]="{
            selectedBadge: 'envComplianceAlert' === mode
          }"
        >
          {{ app.numberOfUnreadEnvComplianceAlerts }}
        </span>
      </button>
      <button
        *ngIf="showExport"
        class="btn btn-sm btn-clean pull-right"
        (click)="app.alerts.exportData(mode)"
      >
        <i class="fa fa-download"></i>&nbsp;
        {{ app.text.alert.export }}
      </button>
    </div>
    <div class="my-2">
      <div
        class="descr ps-2 ms-1"
        *ngIf="mode === 'change' && pcnId == null && app.customer != 'oc'"
      >
        {{ app.text.mail.notificationDescr }}
      </div>
      <div class="descr ps-2 ms-1" *ngIf="mode === 'thread' || pcnId != null">
        {{ app.text.mail.pcnDescr }} -
        <a [href]="url" target="_blank">{{ url }}</a>
      </div>
      <div class="descr ps-2 ms-1" *ngIf="mode === 'alert'">
        {{ app.text.mail.alertsDescr }}
      </div>
      <div class="descr ps-2 ms-1" *ngIf="mode === 'changeAlert'">
        {{ app.text.mail.changesDescr }}
      </div>
      <div class="descr ps-2 ms-1" *ngIf="mode === 'leadTimeAlert'">
        {{ app.text.mail.leadTimeDescr }}
      </div>
      <div class="descr ps-2 ms-1" *ngIf="mode === 'envComplianceAlert'">
        {{ app.text.mail.envComplianceDescr }}
      </div>
    </div>
  </div>
  <div *ngIf="!app.filterList.open">
    <router-outlet></router-outlet>
  </div>
</div>
