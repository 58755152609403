<div class="container-fluid steps-nav">
  <div class="container">
    <div class="row pt-4">
      <div class="col-md-2 d-flex justify-content-start">
        <button
          class="btn btn-controls"
          (click)="previous(); app.hasError = false"
          [title]="app.text.import.next"
          [ngClass]="{
            'btn-hidden': app.import.step === 'first'
          }"
        >
          <i class="far fa-arrow-left"></i>
          {{ app.text.app.back }}&nbsp;
        </button>
      </div>

      <div class="col-md-8 d-flex justify-content-center">
        <div class="btn-group">
          <div
            class="box-btn mx-3"
            [ngClass]="{ 'box-btn-active': app.import.step === 'first' }"
          >
            <button
              class="btn nav nav-tab"
              (click)="reset(); app.hasError = false"
            >
              <span
                class="round-tab"
                [ngClass]="{
                  'nav-tab-active': app.import.step === 'first',
                  'round-tab-complete': app.import.step !== 'first'
                }"
              >
                {{ app.import.step === "first" ? "1" : "" }}
                <i *ngIf="app.import.step !== 'first'" class="far fa-check"></i>
              </span>
              <span class="text m-40">{{ app.text.import.select }}</span>
            </button>
          </div>
          <div
            class="box-btn mx-3"
            [ngClass]="{ 'box-btn-active': app.import.step === 'second' }"
          >
            <button class="btn nav nav-tab">
              <span
                class="round-tab"
                [ngClass]="{
                  'nav-tab-active': app.import.step === 'second',
                  'round-tab-complete':
                    app.import.step !== 'first' && app.import.step !== 'second'
                }"
              >
                {{
                  app.import.step === "first" || app.import.step === "second"
                    ? "2"
                    : ""
                }}
                <i
                  *ngIf="
                    app.import.step !== 'first' && app.import.step !== 'second'
                  "
                  class="far fa-check"
                ></i>
              </span>
              <span class="text m-40"> {{ app.text.import.upload }} </span>
            </button>
          </div>
          <div
            class="box-btn mx-3"
            [ngClass]="{ 'box-btn-active': app.import.step === 'third' }"
          >
            <button class="btn nav nav-tab">
              <span
                class="round-tab"
                [ngClass]="{
                  'nav-tab-active': app.import.step === 'third'
                }"
              >
                3
              </span>
              <span class="text m-40">{{ app.text.import.review }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-2 d-flex justify-content-end">
        <button
          class="btn btn-controls"
          (click)="next()"
          [title]="app.text.import.next"
          [disabled]="app.import.type === null"
          [ngClass]="{
            'btn-hidden': app.import.step === 'third',
            'btn-disabled':
              app.import.type === null || app.import.step === 'second'
          }"
        >
          {{ app.text.next.next }} &nbsp;
          <i class="far fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
