import { ComponentSettings, empty, FieldTypes } from "../settings/settings";

const field = {
  trainName: {
    type: FieldTypes.value,
    text: {
      de: "Zugname",
      en: "Train Name",
      nl: "Materieelserie",
    },
    required: true,
  },
  trainResponsible: {
    type: FieldTypes.options,
    text: {
      de: "Zugverantwortlicher",
      en: "Train responsible",
      nl: "Supply chain manager",
    },
    required: true,
  },
  delete: {
    type: FieldTypes.value,
    text: {
      de: "Delete",
      en: "Delete",
      nl: "Verwijder",
    },
    search: false,
  },
  _rev: {
    type: FieldTypes.value,
    text: {
      de: "Rev Number",
      en: "Rev Number",
    },
    search: false,
  },
  omfNumber: {
    type: FieldTypes.value,
    text: {
      de: "Fall-Nummer",
      en: "Case Number",
      nl: "Issue nummer",
    },
  },
  trainRemoved: {
    type: FieldTypes.value,
    text: {
      de: "Zug entfernt.",
      en: "Train removed.",
      nl: "Materieelserie verwijderd.",
    },
  },
};
const settings = {
  text: {
    trainseries: {
      de: "Zugserie",
      en: "Trainseries",
      nl: "Materieelseries",
    },
    trains: {
      de: "Zug",
      en: "Train",
      nl: "Materieelserie",
    },
    add: {
      de: "Hinzufügen",
      en: "Add",
      nl: "Toevoegen",
    },
    deleteTrain: {
      de: "Delete train",
      en: "Delete train",
      nl: "Trein verwijderen",
    },
    deleteMessage: {
      de: "Möchten Sie diesen Zug wirklich löschen?",
      en: "Do you really want to remove the train?",
      nl: "Wil je echt de trein verwijderen?",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    success: {
      de: "Success",
      en: "Success",
      nl: "Succes",
    },
    error: {
      de: "Fehler",
      en: "Error",
      nl: "Fout",
    },
    trainNameExist: {
      de: "Zugname existiert bereits!",
      en: "Train name already exist!",
      nl: "Treinnaam bestaat al!",
    },
    trainAlreadyInCase: {
      de: "Zug bereits diesem Fall zugewiesen!",
      en: "Train already assigned to this case!",
      nl: "Trein al toegewezen aan deze issue!",
    },
    trainHeader: {
      de: "Züge Liste",
      en: "Trains List",
      nl: "Treinen Lijst",
    },
  },
  field,
  url: {},
  permission: {
    hasEditPermission: false,
    canAddNewTrain: false,
    canAddTrainToCase: false,
  },
  list: {
    columns: empty,
    trains: empty,
    pdfColumns: empty,
    fieldsOnFilter: empty,
  },
  additionalInfo: {},
};

export const train: ComponentSettings<typeof settings> = settings;
