<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row mt-5">
          <div class="col-md-3">
            <div class="dropdown quickFilters">
              <div class="small label-field">{{ app.text.RM.selectType }}</div>
              <button
                class="btn dropdown-toggle btn-group-rm text-center"
                type="button"
                id="quickFilters"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span> {{ getTextDisplay(app.RMSearch.selectedType) }} </span>
              </button>
              <div class="icon" [ngSwitch]="app.RMSearch.selectedType">
                <span *ngSwitchCase="'assembly'">
                  <i class="fas fa-chart-pie-alt"></i>
                </span>
                <span *ngSwitchCase="'part'">
                  <i class="far fa-chart-pie-alt"></i>
                </span>
                <span *ngSwitchCase="'manufacturer'">
                  <i class="far fa-chart-pie"></i>
                </span>
              </div>
              <div
                id="filterTypeDropdown"
                class="dropdown-menu"
                aria-labelledby="filterType"
                role="menu"
              >
                <a
                  class="dropdown-item pointer"
                  *ngFor="let type of types"
                  (click)="setType(type)"
                >
                  {{ getTextDisplay(type) }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="dropdown quickFilters">
              <div class="small label-field">
                {{ app.text.RM.selectProperty }}
              </div>
              <button
                class="btn dropdown-toggle btn-group-rm text-center"
                type="button"
                id="quickFilters"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>
                  {{ app.RMSearch.getFilterLabel(app.RMSearch.filterMode) }}
                </span>
              </button>
              <div class="icon">
                <i class="fas fa-keyboard"></i>
              </div>
              <div
                id="filterTypeDropdown"
                class="dropdown-menu"
                aria-labelledby="filterType"
                role="menu"
              >
                <a
                  class="dropdown-item pointer"
                  *ngFor="let prop of properties"
                  (click)="app.RMSearch.setFilterMode(prop)"
                >
                  {{ app.RMSearch.getFilterLabel(prop) }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="small label-field">
              {{ app.text.RM.enter }}
              {{ app.RMSearch.getFilterLabel(app.RMSearch.filterMode) }}
            </div>
            <div class="btn-group-rm">
              <app-field
                [model]="model"
                [field]="app.fieldId.RM.searchField"
                [showLabel]="false"
                [showMandatory]="true"
              >
              </app-field>
            </div>

            <div
              id="filterTypeDropdown"
              class="dropdown-menu"
              aria-labelledby="filterType"
              role="menu"
            >
              <span *ngIf="properties.length > 0">
                <a
                  class="dropdown-item pointer"
                  *ngFor="let prop of properties"
                  (click)="app.RMSearch.setFilterMode(prop)"
                >
                  {{ app.RMSearch.getFilterLabel(prop) }}</a
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div
      *ngIf="app.RMSearch.displayResults"
      class="col-md-12 no-padding tableFixHead"
    >
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="p-1" *ngFor="let column of app.RMSearch.columns">
              <div>
                <span
                  (click)="
                    app.table.toggleSortDirection(column);
                    app.table.sort(app.RMSearch.RMDocs)
                  "
                  >{{ app.field.handleSpecialLogicLabels(column) }}</span
                >&nbsp;
                <span *ngIf="column === app.table.sortField">
                  <i
                    *ngIf="app.table.sortDirection === 1"
                    class="fa fa-chevron-up"
                  ></i>
                  <i
                    *ngIf="app.table.sortDirection === -1"
                    class="fa fa-chevron-down"
                  ></i>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let doc of app.RMSearch.RMDocs" class="rm-table-row">
            <td
              class="p-1"
              *ngFor="let column of app.RMSearch.columns"
              (click)="
                app.RM.navigateRMComponents(
                  app.RMSearch.selectedType,
                  true,
                  doc._id
                )
              "
            >
              <div [ngSwitch]="app.table.getColumnTypeDisplay(column.split('.')[1])">
                <span *ngSwitchCase="'badge'">
                  <span
                    class="col-6 btn badge"
                    [ngStyle]="
                      app.field.getColor(column, doc[column.split('.')[1]])
                    "
                    >{{
                      app.field.getOptionText(column, doc[column.split(".")[1]])
                    }}</span
                  >
                </span>
                <span *ngSwitchCase="'optionText'">
                  {{
                    app.field.getOptionText(column, doc[column.split(".")[1]])
                  }}
                </span>
                <div *ngSwitchDefault class="rm-cell">
                  {{ doc[column.split(".")[1]] }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td
              *ngIf="app.RMSearch.RMDocs.length === 0"
              colspan="99"
              class="text-center"
            >
              {{ app.text.app.noResults }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
