export class Customer {
  public static OMP: string = "omp";
  public static ABC: string = "abc";
  public static DB: string = "db";
  public static BVG: string = "bvg";
  public static DEMO: string = "demo";
  public static DEMOZ2DATA: string = "demoz2data";
  public static KOMAX: string = "komax";
  public static NS: string = "ns";
  public static RHEINBAHN: string = "rheinbahn";
  public static SCHIEBEL: string = "schiebel";
  public static MRCE: string = "mrce";
  public static AIRBUSDS: string = "airbusds";
  public static SICK: string = "sick";
  public static CAE: string = "cae";
  public static CEOTRONICS: string = "ceotronics";
  public static MAQUETGMBH: string = "maquetgmbh";
  public static MAQUETCARDIO: string = "maquetcardio";
  public static IAV: string = "iav";
  public static MTU: string = "mtu";
  public static HERRMANNULTRASCHALL: string = "herrmannultraschall";
  public static SSI: string = "ssi";
  public static ATLAS: string = "atlas";
  public static LRE: string = "lre";
  public static KVB: string = "kvb";
  public static RUAGCH: string = "ruagch";
  public static KATEK: string = "katek";
  public static KATEKMEMM: string = "katekmemm";
  public static KATEKMAUER: string = "katekmauer";
  public static KATEKLEIP: string = "katekleip";
  public static KATEKGRASS: string = "katekgrass";
  public static ESYSTEMS: string = "esystems";
  public static EMZ: string = "emz";
  public static KNDS: string = "knds";
  public static ROSEN: string = "rosen";
  public static TAE: string = "tae";
  public static COOP: string = "coop";
  public static KARLSTORZ: string = "karlstorz";
  public static TSK: string = "tsk";
  public static XLIVE: string = "xlive";
  public static STADLER: string = "stadler";
  public static BEFLEX: string = "beflex";
  public static AUTOFLUG: string = "autoflug";
  public static SEW: string = "sew";
  public static PLASTICOMNIUM: string = "plasticomnium";
  public static RHEINMETALL: string = "rheinmetall";
  public static RHEINMETALLAIRDEFENCE: string = "rheinmetallairdefence";
  public static BELIMO: string = "belimo";
  public static PILZ: string = "pilz";
  public static RADWELL: string = "radwell";
  public static DUOMETRIC: string = "duometric";
  public static TRIAL1: string = "trial1";
  public static TRIAL2: string = "trial2";
  public static TRIAL3: string = "trial3";
  public static TRIAL4: string = "trial4";
  public static OMC: string = "omc";
  public static OC: string = "oc";
  public static HAGENUK: string = "hagenuk";
  public static SIEMENSLOGISTICS = "siemenslogistics";
  public static EXAMPLE: string = "example";
}
