import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {
    download: false,
  },
  text: {
    list: { de: "Liste", en: "List", nl: "Lijst" },
    table: { de: "Tabelle", en: "Table", nl: "Tabel" },
    export: { de: "Exportieren", en: "Export", nl: "Export" },
    exportData: { de: "Export Data", en: "Export Data", nl: "Export Data" },
    exportCases: { de: "Fälle", en: "Cases", nl: "Issues" },
    own: { de: "Eigene Fälle", en: "Own cases", nl: "Eigen Issues" },
    fav: { de: "Favoriten", en: "Favorites", nl: "Favorieten" },
    showOnly: { de: "Nur anzeigen:", en: "Show only:", nl: "Alleen tonen:" },
    showAllCases: { de: "Alle Fälle", en: "All cases", nl: "Alle Issues" },
    personal: { de: "Meine Todo's", en: "My Todo's", nl: "My Todo's " },
    deputy: {
      de: "Meine VertreterFälle",
      en: "My Representative Cases",
      nl: "My Representative Cases",
    },
    openCases: {
      de: "Offene Fälle",
      en: "Open Cases",
      nl: "Open Issues",
    },
    closedCases: {
      de: "Geschlossene Fälle",
      en: "Closed Cases",
      nl: "Afgerond Issues",
    },
    responsible: {
      de: "Meine Verantwortlichkeit",
      en: "My responsibility",
      nl: "Mijn verantwoordelijkheid",
    },
    sort: { de: "Sortieren:", en: "Sort:", nl: "Sorteren:" },
    showInternalCases: {
      de: "NUR interne Fälle anzeigen",
      en: "ONLY show internal cases",
      nl: "Toon alleen interne Issues",
    },
    noResults: {
      de: "Keine Ergebnisse gefunden!",
      en: "No results found!",
      nl: "Geen resultaten gevonden !",
    },
    filters: {
      de: "Filter",
      en: "Filters",
      nl: "Filters",
    },
    listView: {
      de: "Listenansicht",
      en: "List view",
      nl: "Lijstweergave",
    },
    tableView: {
      de: "Tabellenansicht",
      en: "Table view",
      nl: "Tabelweergave",
    },
    exportList: {
      de: "Liste exportieren",
      en: "Export list",
      nl: "Lijst exporteren",
    },
    offer: {
      de: "Biete Anzeigen",
      en: "Offer Posts",
      nl: "Offer Posts",
    },
    seek: {
      de: "Suche Anzeigen",
      en: "Seek Posts",
      nl: "Seek Posts",
    },
  },
  list: {},
  url: {},
  field: {},
  additionalInfo: {},
};

export const home: ComponentSettings<typeof settings> = settings;
