import { typeCategories } from "../typeCategories";
import { ComponentSettings, FieldTypes, empty } from "../settings/settings";
import {
  euRoHSStatusOptions,
  obsolescencelifeCycleRiskOptions,
  obsolescenceStatusOptions,
} from "./part.settings";
import {
  reachAffectedStatusOptions,
  tscaStatusOptions,
  pfasStatusOptions,
  californiaStatusOptions,
} from "./manufacturer.settings";

const field = {
  checkedByOm: {
    type: FieldTypes.value,
    text: { de: "Gelesen", en: "Seen", nl: "Gelezen" },
  },
  issueDate: {
    type: FieldTypes.timestamp,
    text: {
      de: "Veröffentlichung",
      en: "Issue Date",
      nl: "Publicatiedatum",
    },
  },
  partNumber: {
    type: FieldTypes.value,
    text: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
  },
  oldSeStatus: {
    type: FieldTypes.options,
    text: {
      de: "Status Von",
      en: "Status From",
      nl: "Status Van",
    },
    options: obsolescenceStatusOptions,
  },
  statusChange: {
    type: FieldTypes.options,
    text: {
      de: "Status Zu",
      en: "Status To",
      nl: "Status Naar",
    },
    options: obsolescenceStatusOptions,
  },
  name: {
    type: FieldTypes.value,
    text: { de: "Hersteller", en: "Manufacturer", nl: "Fabrikant" },
  },
  manufacturerPartNumber: {
    type: FieldTypes.value,
    text: {
      de: "Herstellerteilenummer (HTN)",
      en: "Manufacturer Part Number (MPN)",
      nl: "Fabrikant Artikel Nummer (MPN)",
    },
  },
  purchasePartNumber: {
    type: FieldTypes.value,
    text: {
      de: "Typenbezeichnung",
      en: "Type Designation",
      nl: "Typeaanduiding",
    },
  },
  severity: {
    type: FieldTypes.options,
    text: {
      de: "Einstellung des Vertriebs (EOS)",
      en: "End of Sale (EOS) (Last Time Buy - LTB)",
      nl: "End of Sale (EOS)",
    },
    options: obsolescencelifeCycleRiskOptions,
  },

  delete: {
    type: FieldTypes.value,
    text: {
      de: "Löschen",
      en: "Delete",
      nl: "Verwijder",
    },
  },
  typeOfChange: {
    type: FieldTypes.options,
    text: {
      de: "Kategorie",
      en: "Category",
      nl: "Categorie",
    },
    options: typeCategories,
    multiple: true,
  },
  datasheet: {
    type: FieldTypes.value,
    text: {
      de: "Link",
      en: "Link",
      nl: "Link",
    },
  },
  pcnSource: {
    type: FieldTypes.value,
    text: {
      de: "PCN Source",
      en: "PCN Source",
      nl: "PCN Source",
    },
  },
  effectiveDate: {
    type: FieldTypes.value,
    text: {
      de: "Effective Date",
      en: "Effective Date",
      nl: "Ingangsdatum",
    },
  },
  criticality: {
    type: FieldTypes.options,
    text: {
      de: "Kritikalität",
      en: "Criticality",
      nl: "Kriticiteit",
    },
    options: {
      "very high": {
        text: {
          de: "Very High",
          en: "Very High",
          nl: "Very High",
        },
        color: "#EB5757",
      },
      high: {
        text: {
          de: "High",
          en: "High",
          nl: "High",
        },
        color: "#EB5757",
      },
      medium: {
        text: {
          de: "Medium",
          en: "Medium",
          nl: "Medium",
        },
        color: "#F2C94C",
      },
      low: {
        text: {
          de: "Low",
          en: "Low",
          nl: "Low",
        },
        color: "#27AE60",
      },
    },
  },
  leadTimeFrom: {
    type: FieldTypes.options,
    text: {
      de: "Lieferzeiten Von",
      en: "Lead Time From",
      nl: "Lefertijden Van",
    },
    options: obsolescencelifeCycleRiskOptions,
  },
  leadTimeTo: {
    type: FieldTypes.options,
    text: {
      de: "Lieferzeiten Zu",
      en: "Lead Time To",
      nl: "Lefertijden Naar",
    },
    options: obsolescencelifeCycleRiskOptions,
  },
  maxWeeks: {
    type: FieldTypes.value,
    text: {
      de: "Max (Wochen)",
      en: "Max (weeks)",
      nl: "Max (Weeks)",
    },
  },
  minWeeks: {
    type: FieldTypes.value,
    text: {
      de: "Min (Wochen)",
      en: "Min (weeks)",
      nl: "Min (Weeks)",
    },
  },
  pcnID: {
    type: FieldTypes.value,
    text: {
      en: "Identification Key (ID)",
      de: "Identifikationsschlüssel (ID)",
      nl: "Identificatiesleutel (ID)",
    },
  },
  alertCategory: {
    type: FieldTypes.options,
    required: false,
    text: {
      de: "Kategorie",
      en: "Category",
      nl: "Category",
    },
    options: {
      range_shorter_than_end_serie: {
        text: {
          de: "Reichweite kürzer als End Serie",
          en: "Range shorter than End Serie",
          nl: "Range shorter than End Serie",
        },
      },
      range_shorter_than_end_delivery: {
        text: {
          de: "Reichweite kürzer als Ende Lieferverpflichtung",
          en: "Range shorter than end of the delivery obligation",
          nl: "Range shorter than end of the delivery obligation",
        },
      },
      range_not_before_date: {
        text: {
          de: "Reichweite nicht bis Datum",
          en: "Range not before date",
          nl: "Range not before date",
        },
      },
      global_stock_under_minimum_stock: {
        text: {
          de: "Weltweiter Bestand unter Mindestbestand",
          en: "Global Stock under Minimum Stock",
          nl: "Global Stock under Minimum Stock",
        },
      },
      range_shorter_than_manual_monitoring: {
        text: {
          de: "Reichweite kürzer als Manuelle Überwachungsreichweite",
          en: "Range shorter than manual monitoring Range",
          nl: "Range shorter than manual monitoring Range",
        },
      },
      range_shorter_than_standard_range: {
        text: {
          de: "Reichweite kürzer als standard Reichweite",
          en: "Range shorter than standard Range",
          nl: "Range shorter than standard Range",
        },
      },
      global_stock_is_0: {
        text: {
          de: "Weltweiter Bestand ist 0",
          en: "Global Stock is 0",
          nl: "Global Stock is 0",
        },
      },
    },
  },
  envComplianceAlertCategory: {
    type: FieldTypes.options,
    required: false,
    text: {
      de: "Kategorie",
      en: "Category",
      nl: "Category",
    },
    options: {
      euReachAffected: {
        text: {
          de: "Enthält SVHC",
          en: "Contains SVHC",
          nl: "Bevat SVHC",
        },
      },
      euRohsStatus: {
        text: {
          de: "EU RoHS Status",
          en: "EU RoHS Status",
          nl: "EU RoHS Status",
        },
      },
      globalPfasStatus: {
        text: {
          de: "Global PFAS Bewertung Status",
          en: "Global PFAS Assessment Status",
          nl: "Wereldwijde PFAS beoordeling Status",
        },
      },
      chinaRohsStatus: {
        text: {
          de: "China RoHS betroffen",
          en: "China RoHS Affected",
          nl: "China RoHS betrokken",
        },
      },
      tscaStatus: {
        text: {
          de: "TSCA Status",
          en: "TSCA Status",
          nl: "TSCA Status",
        },
      },
      californiaProposition: {
        text: {
          de: "California Proposition 65",
          en: "California Proposition 65",
          nl: "California Proposition 65",
        },
        options: {
          exempted: {
            text: { de: "Exempted", en: "Exempted", nl: "Exempted" },
            color: "#27AE60",
          },
          not_exempted: {
            text: {
              de: "Not Exempted",
              en: "Not Exempted",
              nl: "Not Exempted",
            },
            color: "#EB5757",
          },
          unknown: {
            text: { de: "Unknown", en: "Unknown", nl: "Unknown" },
            color: "#F7F7F7",
          },
        },
      },
    },
  },
  sapStatus: {
    type: FieldTypes.value,
    text: {
      de: "SAP",
      en: "SAP",
    },
    search: true,
  },
  oldValue: {
    type: FieldTypes.options,
    text: {
      de: "Wert Von",
      en: "Value from",
      nl: "Value from",
    },
    options: {
      ...euRoHSStatusOptions,
      ...reachAffectedStatusOptions,
      ...tscaStatusOptions,
      ...pfasStatusOptions,
      ...californiaStatusOptions,
    },
  },
  newValue: {
    type: FieldTypes.options,
    text: {
      de: "Wert Zu",
      en: "Value to",
      nl: "Value to",
    },
    options: {
      ...euRoHSStatusOptions,
      ...reachAffectedStatusOptions,
      ...tscaStatusOptions,
      ...pfasStatusOptions,
      ...californiaStatusOptions,
    },
  },
};

const settings = {
  text: {
    alerts: { de: "Artikel", en: "Part", nl: "Item" }, //Einheit Item need to looked
    alert: { de: "Alert", en: "Alert", nl: "Alert" },
    changeAlert: {
      de: "Benachrichtigungen über Änderungen von Einheiten",
      en: "Notifications on Item Changes",
      nl: "RM Changes",
    },
    leadTimeAlert: {
      de: "Lieferzeiten-Alarm",
      en: "Lead Time Alerts",
      nl: "Lefertijden Alerts",
    },
    notificaionsAlerts: {
      de: "Obsoleszenz Alarm",
      en: "Obsolescence Alerts",
      nl: "Obsolescence Waarschuwing",
    },
    envComplianceAlerts: {
      de: "Umweltkonformität Alarm",
      en: "Environmental Compliance Alerts",
      nl: "Environmental Compliance Alerts",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    deleteMessage: {
      de: "Wollen Sie die Warnung wirklich entfernen?",
      en: "Do you really want to remove the alert?",
      nl: "Wil je deze Alert verwijderen?",
    },
    deleteAllSelected: {
      de: "Löschen",
      en: "Delete",
      nl: "Verwijder",
    },
    markAsSeen: {
      de: "Markieren wie gesehen / ungesehen",
      en: "Mark as seen / unseen",
      nl: "Merk als gezien / ongezien",
    },
    createThread: {
      de: "Neuen Fall anlegen",
      en: "Create Case",
      nl: "Nieuw Issue aanmelden",
    },
    export: {
      de: "Liste exportieren",
      en: "Export list",
      nl: "Lijst exporteren",
    },
  },
  url: {},
  field,
  list: {
    alertColumns: empty,
    changeAlertColumns: empty,
    leadTimeAlertColumns: empty,
    inventoryMonitoringColumns: empty,
    envComplianceAlertColumns: empty,
    alertsCsvExportColumns: empty,
    changeAlertsCsvExportColumns: empty,
    leadTimeAlertCsvExportColumns: empty,
    invMonAlertCsvExportColumns: empty,
    envComplianceAlertCsvExportColumns: empty,
  },
  permission: {
    alertPermission: false,
    changeAlertPermission: false,
    leadTimeAlertsPermission: false,
    invMonAlertsPermission: false,
    envComplianceAlertsPermission: false,
    canCreateCase: true,
    canMarkAlert: true,
  },
  additionalInfo: {},
};

export const alert: ComponentSettings<typeof settings> = settings;
