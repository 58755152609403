<app-modal
  #discardChangesModal
  [modalMessage]="app.text.user.discard"
  [modalTitle]="app.text.user.modalTitle"
  (ok)="discardChanges()"
></app-modal>

<div class="card mb-3">
  <div class="d-flex justify-content-between">
    <div class="card-header" [ngClass]="{ 'p-2': app.view === 'profile' }">
      {{ app.text.profilePassword.passwordChange }}
    </div>
    <div *ngIf="app.user != null">
      <button
        *ngIf="app.view === 'profile-password'"
        class="btn btn-sm mt-2"
        (click)="discardChangesModal.open()"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="border-top pt-1">
    <app-field
      *ngFor="let field of app.list.profilePassword.main; index as index"
      [model]="app.model"
      [field]="field"
      [index]="index"
      [shadowInput]="true"
      [noStriped]="true"
    ></app-field>
  </div>

  <div class="text-policy m-1 p-1">
    {{ passwordPolicyText }}
  </div>
  <div class="text-center my-3">
    <button
      class="btn btn-password"
      (click)="save()"
      [disabled]="requiredFields.length > 0"
    >
      {{ app.text.profilePassword.passwordChange }}
    </button>
  </div>
</div>
