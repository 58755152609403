import { Credits } from "./credits";
import { Manufacturer } from "./manufacturer";
import {
  EuRoHSStatus,
  ObsolescencelifeCycleRisk,
  ObsolescenceStatus,
  ReachAffectedStatus,
} from "./rm-common-properties";

export interface Parts {
  [partNumber: string]: number;
}

export class Turnover {
  calendarYear: string = "";
  turnover: string = "";
  pricePerItem?: string = "";
  totalAmount?: string = "";
}
export class Turnovers {
  year: string = "";
  turnover: string = "";
}

export class PartData extends Credits {
  partNumber: string = "";
  description: string = "";
  parts: Parts = {};
  level: string = "";
  responsibleName?: string = "";
  responsibleDepartment?: string = "";
  productCategory?: string = "";
  productAcronym?: string = "";
  productOwner?: string = "";
  productPmName?: string = "";
  productPm?: string = "";
  productStatus?: string = "";
  productSOP?: string = "";
  productEOP?: string = "";
  forecast?: string = "";
  purchaseId?: string = "";
  usedInPartNumber?: string = "";
  usedInQuantity?: string = "";
  sapDescription?: string = "";
  turnover?: Turnover[] = [];
  turnOverQuantity?: string = "";
  turnOverPrice?: string = "";
  turnOverCurrentYear?: string = "";
  equivalentInformation?: string = "";
  productNumber?: string = "";
  productName?: string = "";
  obsolescenceManagement?: string = "false";
  open?: boolean = false;
  seen?: boolean = false;
  quantity?: string = "";
  itemType?: string = "";
  classNumber?: string = "";
  usedInSystem?: string = "";
  norm?: string = "";
  readilyAvailableStock?: string = "";
  actualStock?: string = "";
  safetyStock?: string = "";
  totalLifeCycleUsage?: string = "";
  totalForecast2Years?: string = "";
  forecastEOLStock?: string = "";
  stockLevelDeclination?: string = "";
  purchasePartNumber?: string = "";
  historyFields?: string[] = [];
  numberSecSource?: string = "";
  likelihood_secSource?: string = "";
  freqUse?: string = "";
  freqUseImpact?: string = "";
  sapStatus?: string = "";
  designType?: string = "";
  catComplexity?: string = "";
  catComplexityImpact?: string = "";
  posType?: string = "";
  matType?: string = "";
  stockPAS?: string = "";
  stockPS2?: string = "";
  stockMS5?: string = "";
  stockFrom?: string = "";
  stockRangeYears?: string = "";
  stockRange?: number = -1;
  outflows?: string = "";
  outflows5years?: string = "";
  sapComments?: string = "";
  config?: string = "";
  emissionEMREL?: string = "";
  nuclearPower?: string = "";
  responsibleOM?: string = "";
  responsibleRD?: string = "";
  partner?: string = "";
  product?: string = "";
  startSerie?: string = "";
  endSerie?: string = "";
  endDelivery?: string = "";
  startRedesign?: string = "";
  electronicalMat?: string = "";
  euRoHS?: EuRoHSStatus = "";
  obsolescenceStatus?: ObsolescenceStatus = "";
  obsolescenceStatus2years?: ObsolescenceStatus = "";
  obsolescenceStatus4years?: ObsolescenceStatus = "";
  obsolescenceStatus6years?: ObsolescenceStatus = "";
  obsolescenceStatus8years?: ObsolescenceStatus = "";
  likelihood?: ObsolescencelifeCycleRisk = "";
  impact?: ObsolescencelifeCycleRisk = "";
  totalRisk?: ObsolescencelifeCycleRisk = "";
  reachSubstances?: string[] = [];
  reachAffected?: ReachAffectedStatus = "";
  leadTime?: ObsolescencelifeCycleRisk = "";
  topLevel?: boolean = false;
  inventoryMonitoring?: boolean = false;
  hazardousSubstance: string = "";
  productSafety: string = "";
  extent: string = "";
  componentApproval: string = "";
  exemption: string[] = [];
  devStatus: string = "";
  statusAlerts?: StatusAlert = new StatusAlert();
  turnovers?: Turnovers[] = [];
  category?: string = "";
  activeMPNs?: number = 0;
  stockRangeUntil?: string = "";
  manualMonitoringRange?: number = 0;
  standardRange: number = 0;
  monitoringRangeUpTo?: string = "";
  globalStock?: string = "";
  minimumStock?: number = 0;
  verwa?: string = "";
  productHierarchy?: string = "";
  lastImportTime?: number = 0;
}

export class Part extends PartData {
  [key: string]: any;
  _id: string = ""; // mandatory id (equals part number)
}
export interface Row {
  [key: string]: any;
  level: number;
  quantity?: number;
  node?: PartNode;
  manufacturer?: Manufacturer;
}

export interface PartNode {
  part: Partial<Part>;
  bom: { node: PartNode; quantity?: number }[];
}

export class StatusAlert {
  currentStatusCounter = 0;
  riskCounter = 0;
  likelihoodCounter = 0;
  impactCounter = 0;
  rohsCounter = 0;
  reachCounter = 0;
  leadTimeCounter = 0;
}
