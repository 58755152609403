import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  substanceIdentification: {
    type: FieldTypes.options,
    text: {
      de: "Stoffidentifikation",
      en: "Substance Identification",
      nl: "Stofidentificatie",
    },
    required: true,
  },
  casNumber: {
    type: FieldTypes.options,
    text: {
      de: "CAS Nummer",
      en: "CAS Number",
      nl: "CAS Nummer",
    },
    required: true,
  },
  concentration: {
    type: FieldTypes.number,
    text: {
      de: "Konzentration [ppm]",
      en: "Concentration [ppm]",
      nl: "Concentratie [ppm]",
    },
    required: true,
  },
  thresholdLimitExceeded: {
    type: FieldTypes.value,
    text: {
      en: "Exceeds Threshold Limit",
      de: "Überschreitet Schwellenwert",
      nl: "Overschrijdt de drempellimiet",
    },
  },
};

const settings = {
  text: {},
  field,
  url: {},
  permission: {},
  list: {},
  additionalInfo: {},
};

export const SVHCItems: ComponentSettings<typeof settings> = settings;
