<app-modal
  #unsavedChanges
  [modalMessage]="app.text.app.confirmChanges"
  [showButtons]="false"
></app-modal>
<div [ngClass]="{ 'app-container': !app.pcnGenerator.isPcnGenerator }">
  <div class="app-content">
    <div
      *ngIf="app.error && !app.hasError"
      class="error-notification top-bar"
      style="cursor: pointer"
    >
      <div class="container">
        <div class="p-1 text-pre-line d-flex">
          <i class="fas fa-exclamation-triangle px-2"></i>
          {{ app.text.app.error }}
          <div class="close-btn pull-right ms-auto" (click)="app.error = false">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="app.hasError"
      class="error-notification top-bar"
      style="cursor: pointer"
    >
      <div class="container">
        <div class="p-1 text-pre-line d-flex">
          <i class="fas fa-exclamation-triangle px-2"></i>
          {{ app.errorText }}
          <div
            class="close-btn pull-right ms-auto"
            (click)="app.hasError = false"
          >
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- SUCCESS -->
    <div
      *ngIf="app.hasSuccess"
      class="success success-notification top-bar"
      style="cursor: pointer"
    >
      <div class="container">
        <div class="p-1">
          <i class="fa fa-check"></i>
          {{ app.successText }}
          <div class="close-btn pull-right" (click)="app.hasSuccess = false">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- LOADING -->
    <!-- <div *ngIf="app.busy" class="top-bar">
      <div class="container">
        <div class="p-1">
          <i class="fas fa-sync-alt spin spinner"></i>
          {{ app.text.app.loading }}
        </div>
      </div>
    </div> -->

    <div class="wrapper" *ngIf="!app.busy">
      <!-- Sidebar -->
      <div *ngIf="!app.pcnGenerator.isPcnGenerator" id="sidebar">
        <app-sidebar></app-sidebar>
      </div>

      <!-- Page Contetnt -->
      <div
        id="content"
        [ngClass]="{ whiteBKGD: app.pcnGenerator.isPcnGenerator }"
      >
        <!-- Header -->
        <app-header *ngIf="app.user != null"></app-header>
        <!-- Content -->
        <div class="container-fluid" [ngClass]="app.header.removePadding()">
          <router-outlet></router-outlet>
        </div>
      </div>

      <!-- Panel right -->
      <app-panel></app-panel>

      <!-- Backdrop for the grayed-out panel effect -->
      <div
        class="backdropPanel"
        [class.active]="app.panel.isOpen"
        (click)="app.panel.closePanel()"
      ></div>

      <!-- Filters -->
      <div id="filters">
        <!-- Filters OM -->
        <div *ngIf="app.filterList.open" id="collapseFilter" class="filter">
          <app-filter [types]="types"></app-filter>
        </div>
        <!-- Filters RM -->
        <div *ngIf="app.RM.filterOpen" id="collapseFilter" class="filter">
          <app-filter-tree></app-filter-tree>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="app-footer">
  <app-footer></app-footer>
</div>

<ngx-spinner size="medium" type="ball-atom" [fullScreen]="true">
  <p class="spinner-text">Loading ...</p>
</ngx-spinner>
