import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  manufacturerName: {
    type: FieldTypes.value,
    text: {
      de: "Firma/Abteilung",
      en: "Firma/Abteilung",
    },
  },
  cageCode: {
    type: FieldTypes.options,
    text: {
      de: "Herst.",
      en: "Herst.",
    },
  },
};

const settings = {
  permission: {},
  text: {},
  url: {},
  field,
  list: {},
  additionalInfo: {},
};

export const manufacturerCode: ComponentSettings<typeof settings> = settings;
